import React, { useState } from 'react';

/* csss */
import './../Companyprofile.css'
import css from "../../../main.module.css";
import css3 from './../../common.module.css';







const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const MyProfileRole = (props) => {
    const [checkedbox, setCheckedBox] = useState(false);
    const done=()=>{
        props.done(true)
    }
    
    const handleOnAllChange = () => {
        setCheckedBox((prevCheckedBox ) => {
            if (prevCheckedBox){
                setCheckedBox(false)
            }else{
                setCheckedBox(true) 
            }
        });
    }

    const handleOnChange = (e) => {
        if (e.target.checked){
            props.submitdata(e.target.name)
    
        }
       else{
        props.setremove(e.target.name)
       }
    }

    return (
        <div className='container mb-5'>
            <div className='col-md-6 mt-5 d-flex justify-content-start'>
                <label className={_(["", css.checkbox_group2])} htmlFor="check1">
                    <input type="checkbox" checked={checkedbox} onChange={handleOnAllChange} className={_(["mr-2", css.checkbox_size])} id="check1"></input>
                    <span className={_(["mr-1", css.box])}></span>
                </label>
                <p className='ml-1'>Select All</p>
            </div>
            <label className='col-md-12 p-0 Headingstyle'>Send Notifications :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check2">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='send_schedule_nofti' className={_(["mr-2", css.checkbox_size])} id="check2"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Send/Schedule Notifications</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='manage_noftification' className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Notifications</p>
                </div>

            </div>
            <div className={_(["d-flex flex-row justify-content-around align-items-center mt-5", css.font1])}
            >
                <button
                    onClick={done
                    }
                    // type='submit'
                    className={_(["btn btn_B d-flex justify-content-center align-items-center w-25 ", css3.inside_button, css.inside_button1, css.font_18])}
                >
                    Create a Role
                </button>
            </div>

        </div>
    )
}


export default MyProfileRole