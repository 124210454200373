import css2 from './../common.module.css'
import css from './../../main.module.css'
import {React } from "react";
import {  useNavigate } from "react-router-dom";

import { Modal } from "reactstrap";
import Icon from '../../assets/GIF2/Dialogue_access_confirmation.json'
import LottieGIF from "../lottieComponent";
import axiosInstance from "../../axios";

import "./../../styles.css"

const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

function AccessRequestPopup(props) {

    const history = useNavigate();

    const PermissionAccess = async () => {
        let formData = new FormData();
        formData.append("permission_name", props.permissionname)
        formData.append("title", props.title)
        formData.append("msg", props.msg)
        formData.append("path", props.path)
        
        await axiosInstance.post(
            "admin_dash/Allow_access", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            props.toggle()
            console.log(response.data.success)
            if(response.data.success==='false'){
                alert(response.data.error_msg)
            }
        }).catch((err) => {
            console.log('Get an error',err)
        })
    }
	
    return (
        <>
        <Modal  isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100",css2.container])} backdrop={true} toggle={props.toggle}
        >
				<div className={_([" shadow d-flex flex-column justify-content-center align-items-center p-4",css2.center_container])}
					>
						
                    <div className={_(["d-flex flex-column justify-content-center align-items-center", css2.body_header])}>
                        
                    
                    <LottieGIF json={Icon} loop={true} height="8rem" width="8rem"></LottieGIF>
                    
                        
                    </div>
                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >	
                        <div 	className={_(["d-flex flex-column justify-content-center align-items-center mt-0 mb-4", css.font1])}
                            >
                            <p className={_(["mb-0", css.font_18, css.font1])}>{props.heading}</p>
                            <p className={_(["mb-0", css.font_18, css.font2])}>You don't have permission to {props.subheading}.</p>
                            <p className={_(["mb-0", css.font_18, css.font2])}>Kindly ask admin to allow access.</p>
                        </div>

                        <div 	className={_(["d-flex flex-row justify-content-around align-items-center mt-3 mb-5", css.font1])}
                            >
                             <button
                                onClick={props.toggle}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button2, css.font_18])}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={PermissionAccess}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])}
                            >
                                Request Access
                            </button>
                        </div>

                    </div>
						
						
                        

				</div>
				
    </Modal>

        </>
    );
} 

export default AccessRequestPopup;