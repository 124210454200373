import css2 from '../../common.module.css'
import css from './../../../main.module.css'
import css3 from "./../../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import React, { useState } from "react";
import { Modal } from "reactstrap";
import "./../../../styles.css";
import "./../popup.css"
import visibleIcon from "../../../assets/Icons/Icon awesome-eye.svg"
import TimePicker from 'react-time-picker';
import CheckAnimate from "../../Reusable Popup/CheckAnimate";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function AddBannerPopup(props) {


    const [editMyAccount, setEditMyAccount] = useState({
        countryCode: "",
        countryCodeData: "US",

    })

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);
    const [state, setState] = useState(
        { time: '00:00' }
    );





    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_([" shadow d-flex flex-column justify-content-center align-items-center", css2.center_container])}
                >

                    <div className={_(["d-flex flex-column justify-content-center align-items-center", css2.body_header])}>
                        <h1 className={_(["mb-0", css.font_18, css.font1])}>Add a Banner</h1>
                        <p className={_(["mb-0", css.font_18, css.font2])}>Present your content a better way!</p>
                    </div>


                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >
                        <div className='row'>
                            <div className='col-md-8 bannerstyle'>

                            </div>
                            <div className='col-md-4'>
                                <div className='d-flex'>
                                    <img src={visibleIcon} alt="loading..." className="mr-2"></img>
                                    <label className={_(['mt-2', css.font1])}>Visible On Application</label>

                                </div>
                                <div className='d-flex'>
                                    <img src={visibleIcon} alt="loading..." className="mr-2"></img>
                                    <label className={_(['mt-2', css.font1])}>Visible On Website</label>

                                </div>
                                <div className={_(['d-flex justify-content-start',])}>
                                    <div className="mt-1">
                                        <label htmlFor='rememberMe' className={_(["d-flex align-items-center container56",])}>
                                            <input id='rememberMe' type='checkbox' />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="ml-4">
                                        <p className="">Clickable</p>
                                    </div>
                                </div>
                                <div className={_(["", css2.input_font, css.font_18])}>
                                    <div className={_(["", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Link
                                        </label>


                                        <input
                                            type='input'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='Link'
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <div className='row d-flex justify-content-between'>
                                <div className='col-md-3 p-0'>
                                    <div className='row'>
                                        <div className={_(['col-md-12 p-0 d-flex',])}>
                                            <div className="mt-1">
                                                <label htmlFor='check1' className={_(["d-flex align-items-center container56",])}>
                                                    <input id='check1' type='checkbox' />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="ml-4">
                                                <p className="">Publish Later</p>
                                            </div>
                                        </div>
                                        <div className={_(["col-md-12  p-0", css3.column_width3])}>
                                            <label className={_([" ", css.font1])}>
                                                Choose Publish Date
                                            </label>
                                            <input type="date" className={_(["input_box", css.font_18, css.font2, css.input_box_style])}></input>
                                        </div>
                                        <div className='col-md-12 mt-2 p-0'>
                                            <TimePicker

                                                value={state.time}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <div className='row'>
                                        <div className={_(['col-md-12 p-0 d-flex ',])}>
                                            <div className="mt-1">
                                                <label htmlFor='check1' className={_(["d-flex align-items-center container56",])}>
                                                    <input id='check1' type='checkbox' />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="ml-4">
                                                <p className="">Set Time Limits</p>
                                            </div>
                                        </div>
                                        <div className={_(["col-md-12  p-0", css3.column_width3])}>
                                            <label className={_([" ", css.font1])}>
                                                Choose Start Date &amp; Time
                                            </label>
                                            <input type="date" className={_(["input_box", css.font_18, css.font2, css.input_box_style])}></input>
                                        </div>
                                        <div className='col-md-12 mt-2 p-0 '>
                                            <TimePicker

                                                value={state.time}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <div className='row'>
                                        <div className={_(['col-md-12 p-0 d-flex ',])}>
                                            <div className="mt-1">

                                            </div>
                                            <div className="ml-4">
                                                <p className=""></p>
                                            </div>
                                        </div>
                                        <div className={_(["col-md-12  mt-4 p-0", css3.column_width3])}>
                                            <label className={_([" ", css.font1])}>
                                                Choose Publish Date
                                            </label>
                                            <input type="date" className={_(["input_box", css.font_18, css.font2, css.input_box_style])}></input>
                                        </div>
                                        <div className='col-md-12 mt-2 p-0 '>
                                            <TimePicker

                                                value={state.time}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <div className='checkanimate d-flex justify-content-center mt-5'>
                                <div className='d-flex flex-row mt-2'>
                                    <div className="col-4 align-self-center">
                                        <div className="d-flex position-relative">
                                            <h6>Active</h6>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className='d-flex justify-content-end'>
                                            <CheckAnimate />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={_(["d-flex flex-row justify-content-around align-items-center mt-3 mb-5", css.font1])}
                        >

                            <button
                                onClick={props.toggle}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button2, css.font_18])}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                }}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])}
                            >
                                Publish a Banner
                            </button>
                        </div>

                    </div>

                </div>


            </Modal >

        </>
    );
}

export default AddBannerPopup;