// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpdatePrice1_price_row__tG1m2{\n    display: flex;\n    flex-flow: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.UpdatePrice1_price_row__tG1m2 div{\n    width: 45%;\n}\n\n.UpdatePrice1_button_row__x2s-G{\n    width: 75%;\n}\n.UpdatePrice1_button_row__x2s-G button{\n    width:35%;\n}\n\n.UpdatePrice1_map__Vjq1F{\n    margin-top: 2rem;\n    width: 100%;\n    height: 400px;\n    margin-bottom: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/components/popups/UpdatePrice1.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;AACA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".price_row{\n    display: flex;\n    flex-flow: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.price_row div{\n    width: 45%;\n}\n\n.button_row{\n    width: 75%;\n}\n.button_row button{\n    width:35%;\n}\n\n.map{\n    margin-top: 2rem;\n    width: 100%;\n    height: 400px;\n    margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price_row": "UpdatePrice1_price_row__tG1m2",
	"button_row": "UpdatePrice1_button_row__x2s-G",
	"map": "UpdatePrice1_map__Vjq1F"
};
export default ___CSS_LOADER_EXPORT___;
