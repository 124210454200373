import React, { useState, useEffect, useContext } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import PaginationComponent from "../../components/Pagination";

import searchIcon from "../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg";
import editIcon from "../../assets/Icons/Icon material-edit.svg";
import { Link, useNavigate } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import DeletePopup from "../Reusable Popup/DeletePopup";
import DeleteSuccess from "../Reusable Popup/DeleteSucess";

import "../../styles.css";
import axiosInstance from "../../axios";
import Loader from "../Reusable Popup/Loader";
import { padding } from "@mui/system";
import noParkings from "../../assets/Icons/Group 26816.svg";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};
const RESULT_LIMIT_IN_PAGE = 6;

function ApplicationMessages() {
  const history = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");

  const [confirmation, setconfirmation] = useState(false);
  const [deletion, setDelete] = useState(false);

  const DeleteToggle = (e) => {
    if (e.target.value === "Cancel") {
      setDelete(false);
    } else {
      console.log(e.target.value, arr.length === 0);

      if (e.target.value === "Yes" && arr.length !== 0) {
        setDelete(false);
        setarr([]);
        DeletedArr();
        setconfirmation(true);
      } else {
        console.log(e.target.value, arr);
        setDelete(false);
        Deleted();
        setconfirmation(true);
      }
    }
  };

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
  };
  let [color, setColor] = useState("#ffffff");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });

  useEffect(() => {
    callSearch();
  }, [selectedOption, page]);

  const callSearch = async () => {
    setLoading(true);
    setState([]);

    console.log(selectedOption);
    let formData = new FormData();

    formData.append("status", selectedOption);
    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("page", page);

    await axiosInstance
      .post("admin_dash/search_application_alert", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response.result);
        setState(response.data.response.result);
        setPagination({
          count: response.data.pagination.count,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  const [change, setChange] = useState(false);
  const changeToggle = () => {
    if (change) {
      setChange(false);
    } else {
      setChange(true);
    }
  };
  const [arr, setarr] = useState([]);
  const DeleteArr = (e) => {
    if (e.target.checked) {
      arr.push(e.target.name);
    } else {
      arr.splice(arr.indexOf(e.target.name), 1);
    }
  };

  const ActiveToggle = async (event) => {
    const element = event.target;
    const vehicleId = await element.getAttribute("data-id");
    const vehicleActive = await element.getAttribute("data-active");

    if (vehicleActive === "true") {
      var st = "False";
    } else {
      var st = "True";
    }

    let formData = new FormData();
    formData.append("id", vehicleId);
    formData.append("active", st);

    await axiosInstance
      .put("admin_dash/add_vehicle_from_admin/", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        changeToggle();
        callSearch();
      })
      .catch((err) => {
        console.log("got an error", err);
        alert("Failed !");

        setLoading(false);
      });
  };
  const [valu, setvalue] = useState();

  const deletetoggle = (e) => {
    const element = e.target;
    setvalue(element.getAttribute("data-id"));
    setDelete(true);
  };

  const Deleted = async (e) => {
    // setLoading(true);
    // setColor('#5c5b5b');
    console.log("id", valu);

    setDelete(false);

    let formData = new FormData();

    formData.append("id", valu);

    await axiosInstance
      .post("admin_dash/application_deletion", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response);
        // alertToggle()
        // setLoading(false);
        callSearch();
      })
      .catch((err) => {
        console.log("got an error", err.response);
        setLoading(false);
      });
  };

  const DeletedArr = async (event) => {
    // setLoading(true);
    // setColor('#5c5b5b');

    let formData = new FormData();

    formData.append("id", arr);

    await axiosInstance
      .post("admin_dash/application_deletion", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response);
        callSearch();
        setarr([]);
      })
      .catch((err) => {
        console.log("got an error", err);
        setLoading(false);
        setarr([]);
      });
  };

  return (
    <>
      <div className="col-md-12 p-0 mb-5">
        <div className={_(["w-100", css2.search_row])}>
          <div>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "" ? css2.selected_search_button : "",
              ])}
              name=""
              onClick={toggleButtonSearch}
            >
              All
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "Active" ? css2.selected_search_button : "",
              ])}
              name="Active"
              onClick={toggleButtonSearch}
            >
              Active
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "Deactive"
                  ? css2.selected_search_button
                  : "",
              ])}
              name="Deactive"
              onClick={toggleButtonSearch}
            >
              Deactivated
            </button>
          </div>
          <div className={_(["d-flex justify-content-end"])}>
            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
              <img src={searchIcon} alt="search"></img>

              <input
                className={_(["ml-2 w-100", css.font_18, css.font2])}
                type="input"
                name="search"
                placeholder="Search here"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    callSearch();
                  }
                }}
                style={{ border: "0px", background: "none" }}
              ></input>
            </div>
          </div>
        </div>
        <div
          className={_([
            "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
            css2.search_row_2,
          ])}
        >
          {selectedOption === "" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  All
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  4 Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "Active" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Active
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  6 Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "Deactive" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Deactivated
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  6 Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          <div
            className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
          >
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            >
              <button
                className={_([
                  " pl-2 pr-2 d-flex align-items-center",
                  css.font_18,
                  css.inside_button4,
                ])}
                onClick={() => {
                  setDelete(true);
                }}
              >
                <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className={_(["flex-grow-1", css2.table])}>
          <div className={_(["", css.font_18, css.font2, css2.search_list])}>
            <div className={_(["row w-100", css2.search_header])}>
              <div className="col-md-1 mt-3">#</div>
              <div className="col-md-3 mt-3">Title</div>
              <div className="col-md-3 mt-3">Heading</div>
              <div className="col-md-3 mt-3">Message</div>
              <div className="col-md-2 mt-3">Actions</div>
            </div>

            <div className="row justify-content-center align-items-center">
              <Loader color={color} loading={loading} />
            </div>

            {state.map((state, index) => {
              return (
                <>
                  <div className={_(["row"])}>
                    <div className="col-md-1 mt-3">
                      <div className="d-flex justify-content-center align-items-center ml-3">
                        <label
                          className={_(["mb-3", css.checkbox_group])}
                          htmlFor={"check" + index}
                        >
                          <input
                            type="checkbox"
                            className={_(["", css.checkbox_size])}
                            name={state.id}
                            id={"check" + index}
                            onChange={DeleteArr}
                          ></input>
                          <span className={_(["mr-1", css.box])}></span>
                        </label>
                        <div className="index d-flex align-items-center">
                          <p>{index}</p>
                        </div>
                      </div>
                    </div>
                    <div className={_(["col-md-3 mt-3"])}>
                      <div
                        className={_([
                          "justify-content-center align-items-center",
                        ])}
                      >
                        <label className={_(["", css.font4])}>
                          {state.title}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div
                        className={_([
                          "justify-content-center align-items-center",
                        ])}
                      >
                        <label className={_(["", css.font4])}>
                          {state.heading}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div
                        className={_([
                          "justify-content-center align-items-center",
                        ])}
                      >
                        <label className={_(["", css.font4])}>
                          {state.message}
                        </label>
                      </div>
                    </div>
                    <div className=" mt-3 col-md-2">
                      {selectedOption === "" ? (
                        <div className="dropdown mr-5">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={ActiveToggle}
                              data-id={state.id}
                              data-active={state.is_active}
                            >
                              {state.is_active ? "Deactive" : "Active"}
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={deletetoggle}
                              data-id={state.id}
                            >
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Active" ? (
                        <div className="dropdown mr-5">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={ActiveToggle}
                              data-id={state.id}
                              data-active={state.is_active}
                            >
                              {state.is_active ? "Deactive" : "Active"}
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setDelete(true);
                              }}
                              data-id={state.id}
                            >
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Deactive" ? (
                        <div className="dropdown mr-5">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={ActiveToggle}
                              data-id={state.id}
                              data-active={state.is_active}
                            >
                              {state.is_active ? "Deactive" : "Active"}
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setDelete(true);
                              }}
                              data-id={state.id}
                            >
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            })}

            {state?.length === 0 ? (
              <>
                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                  <img src={noParkings} alt="No Parkings"></img>
                  <span className={_(["mt-4", css.font2, css.font_18])}>
                    No parking spots available right now. :({" "}
                  </span>
                </div>
              </>
            ) : null}
          </div>
          <DeletePopup
            isOpen={deletion}
            heading={"Alert!"}
            subheading={"Are you sure you want to delete"}
            subheading2={"Application message?"}
            buttontitle={"Cancel"}
            buttontitle2={"Yes"}
            toggle={DeleteToggle}
          />
          <DeleteSuccess
            isOpen={confirmation}
            heading={"Great!"}
            subheading={"Successfully Deleted"}
            subheading2={"Application message"}
            buttontitle={"Okey"}
            toggle={() => {
              setconfirmation(false);
            }}
          />
        </div>
        <div className={_(["", css.font_18, css.font2, css2.pagination])}>
          {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
            <PaginationComponent
              count={pagination.noOfPages}
              page={page}
              setPage={setPage}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ApplicationMessages;
    