import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const generatePDF = (data, selectedOption, filename = 'parkingspot.pdf') => {
    const doc = new jsPDF();
    const rows = [];
    const columns = ['ID', 'Name', 'Parking Spot Number', 'Hourly Rate', 'Monthly Rate', 'Total Booking', 'Total Earning', 'Rating'];

    data.forEach(entry => {
        const row = [
            entry.id,
            entry.name,
            entry.parking_spot_number,
            entry.hourly_rate,
            entry.monthly_rate,
            entry.total_count_booked,
            entry.total_earned,
            entry.total_ratings
        ];
    rows.push(row);
    });
    // data.forEach(entry => {
    //     const row = columns.map(col => entry[col]);
    //     rows.push(row);
    // });
    
    

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    const fontSize = 10.5;
    doc.setFontSize(fontSize);
    const textWidth = doc.getStringUnitWidth(`Parking Data for ${selectedOption}`) * fontSize / doc.internal.scaleFactor;
    
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPos = (pageWidth - textWidth) / 2;
    const yPos = 10;
    
    doc.text(`Parking Data for ${selectedOption}`, xPos, yPos);
    doc.save(filename);
};


