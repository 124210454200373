import React, { useState, useEffect } from "react";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css";
import mainCss from "../../main.module.css";
import "./Companyprofile.css";
//
import axiosInstance from "./../../axios";
import { DATA_PROCESSOR, ERROR__PROCESSOR } from "./../../Tools";
import Loader from "../Reusable Popup/Loader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const SocialProfile = () => {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  const [state, setState] = useState({
    playstore_url: "",
    appstore_url: "",
    company_url: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    linkedin_url: "",
    youtube_url: "",
  });

  const [stateError, setStateError] = useState({
    playstore_url: null,
    appstore_url: null,
    company_url: null,
    facebook_url: null,
    twitter_url: null,
    instagram_url: null,
    linkedin_url: null,
    youtube_url: null,
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setStateError({ ...stateError, [e.target.name]: null });
  };

  async function makerequest() {
    setLoading(true);
    setColor("#5c5b5b");
    let data = "";
    await axiosInstance
      .get("admin_dash/social_media_settings")
      .then((response) => {
        console.log(response.data);
        data = response.data.response.result;
        DATA_PROCESSOR(data, state, setState);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  }

  useEffect(() => {
    makerequest();
  }, []);

  async function Save() {
    setLoading(true);
    let formData = new FormData();
    //formData.append('profile_pic',state.email);
    formData.append("playstore_url", state.playstore_url);
    formData.append("appstore_url", state.appstore_url);
    formData.append("company_url", state.company_url);
    formData.append("twitter_url", state.twitter_url);
    formData.append("instagram_url", state.instagram_url);
    formData.append("linkedin_url", state.linkedin_url);

    await axiosInstance
      .post("admin_dash/social_media_settings", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          makerequest();
          console.log("responseaeaeq", response);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.errors) {
          ERROR__PROCESSOR(err.response.data.errors, stateError, setStateError);
        }
      });
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader color={color} loading={loading} />
        </div>
      ) : (
        <div class="container my-5">
          <div class="row">
            <div class="col">
              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>
                  Playstore Link
                </label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Playstore Link"
                  name="playstore_url"
                  value={state?.playstore_url}
                  // ref={username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>
                  Appstore Link
                </label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Appstore Link"
                  name="appstore_url"
                  value={state.appstore_url}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>Twitter</label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Twitter Link"
                  name="twitter_url"
                  value={state.twitter_url}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>Facebook</label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Facebook Link"
                  name="playstore_url"
                  value={state.playstore_url}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>Instagram</label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Instagram Link"
                  name="instagram_url"
                  value={state.instagram_url}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={_(["col-md-12", css2.column_width3])}>
                <label className={_(["mt-2", mainCss.font1])}>Linkedin</label>

                <input
                  type="text"
                  className={_(["", mainCss.input_box_style1, mainCss.font2])}
                  placeholder="Linkedin Link"
                  name="linkedin_url"
                  value={state.linkedin_url}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button className="sharebtn" onClick={Save}>
              Update Social Profiles
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SocialProfile;
