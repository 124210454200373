import React from 'react';
import Lottie from "lottie-react";
import animationData from './../assets/Animations/logoout.json';


export default function LottieGIF(props) {
  const style = {
    height: props.height,
    width: props.width
                    };
    return (
      <div>
        <Lottie 
          loop={props.loop}
          autoplay= {props.autoplay}
          animationData={props.json}
          style={style}
        />
      </div>
    );
  }