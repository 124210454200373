import css from "./../../main.module.css";
import css2 from "./AddParkingSpot.module.css"
import React,{ } from "react";
import { useNavigate } from 'react-router-dom';
const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

function AppliedForApproval (){

    // const history = useNavigate();
    const navigate = useNavigate();

    return (
        <>
        <div className={_(["d-flex flex-column justify-content-center align-items-center w-100 mt-5", css.shade_background2])}>
        <div className={_(["d-flex flex-column justify-content-center align-items-center mt-5 mb-5", css2.center_container])}>
            <p className={_(["mb-5", css.font2, css.font_24])} style={{width:'70%', textAlign:'center'}}>
                Parking Bud has received your information. Sit back and relax while we process
                your information. We will keep you posted with further updates.
            </p>
            <button className={_(["mt-5", css.inside_button1, css2.button1])} onClick={() => navigate("/Dashboard/ParkingSpots")}>
                View All Parkings
            </button>
        </div>  
        </div>
        </>
    )
}

export default AppliedForApproval;
