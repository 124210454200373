import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const generateUsersPDF = (data, providertype) => {
    console.log(data,'vcuash')
    const doc = new jsPDF();
    const rows = [];
    const columns = [
        "ID",
        "Name",
        "Email",
        "Phone number",
        "No. of parking",
        "No. of Booking",
        "User Type",
        "Status",
    ];
      
    data.forEach(entry => {
        const row = [
            entry?.id,
            entry?.first_name + ' ' + entry?.last_name,
            entry?.email,
            entry?.phone_number,
            entry?.parking?.length,
            entry?.total_count_booked,//booking
            entry?.user_type,
            entry?.status,
        ];
    rows.push(row);
    }); 

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    const fontSize = 10.5;
    doc.setFontSize(fontSize);
    const textWidth = doc.getStringUnitWidth(`Users Data for ${providertype}`) * fontSize / doc.internal.scaleFactor;
    
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPos = (pageWidth - textWidth) / 2;
    const yPos = 10;
    
    doc.text(`Users Data for ${providertype}`, xPos, yPos);
    doc.save('users.pdf');
};


