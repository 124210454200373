import React, { useState, useEffect } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css";
import mainCss from "../../main.module.css";
import "./Companyprofile.css";

import { useNavigate } from "react-router-dom";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const CompanyProfile = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [selected, setSelected] = useState("Company_Information");

  const handleClick = (e) => {
    setSelected(e.target.name);
    let name = e.target.name;
    if (name === "CompanyInformation") {
      setTitle("Company Information");
    } else if (name === "SocialProfiles") {
      setTitle("Social Profiles");
    } else if (name === "Admins") {
      setTitle("Admins");
    } else if (name === "Roles") {
      setTitle("Roles");
    } else if (name === "UsersReviews") {
      setTitle("Users Reviews");
    } else {
      setTitle("Manage Banners");
    }
  };

  const url = new URL(window.location.href);
  useEffect(() => {
    setSelected(url.pathname.split("/")[3]);
    setTitle(
      url.pathname
        .split("/")[3]
        .replace(/([A-Z])/g, " $1")
        .trim()
    );
  }, []);

  return (
    <div className="container bg-white mt-5">
      <div className="container mt-4">
        <p
          className={_([
            "align-self-start mb-4 mt-4 font-family-regular",
            mainCss.cursor_pointer,
          ])}
         
        >
          <span
            onClick={() =>
              navigate("/Dashboard/details", {
                replace: true,
              })
            }
          >
            Profile
          </span>{" "}
          {">"} <span className="link-font">Company Profile</span>
        </p>
        <h4 className={_(["linkstyle fw-bold"])}>{title} </h4>
      </div>
      <div className="container mt-4">
        <div className="col-md-12 p-0">
          <ul
            className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}
          >
            <Link
              to="/Dashboard/CompanyProfile/CompanyInformation"
              name="CompanyInformation"
              className={
                selected === "CompanyInformation"
                  ? _(["py-2", css2.active_link3, css2.link_text2])
                  : _(["py-2", css2.link_text2])
              }
              onClick={handleClick}
            >
              Company Information
            </Link>
            <Link
              to="/Dashboard/CompanyProfile/SocialProfile"
              name="SocialProfiles"
              className={
                selected === "SocialProfiles"
                  ? _(["", css2.active_link3, css2.link_text2])
                  : _(["", css2.link_text2])
              }
              onClick={handleClick}
            >
              Social Profiles
            </Link>
            <Link
              to="/Dashboard/CompanyProfile/Admins"
              name="Admins"
              className={
                selected === "Admins"
                  ? _(["", css2.active_link3, css2.link_text2])
                  : _(["", css2.link_text2])
              }
              onClick={handleClick}
            >
              Admins
            </Link>
            <Link
              to="/Dashboard/CompanyProfile/Roles"
              name="Roles"
              className={
                selected === "Roles"
                  ? _(["", css2.active_link3, css2.link_text2])
                  : _(["", css2.link_text2])
              }
              onClick={handleClick}
            >
              Roles
            </Link>
            <Link
              to="/Dashboard/CompanyProfile/UserReviews"
              name="UserReviews"
              className={
                selected === "UserReviews"
                  ? _(["", css2.active_link3, css2.link_text2])
                  : _(["", css2.link_text2])
              }
              onClick={handleClick}
            >
              Users Reviews
            </Link>
            <Link
              to="/Dashboard/CompanyProfile/ManageBanners"
              name="ManageBanners"
              className={
                selected === "ManageBanners"
                  ? _(["", css2.active_link3, css2.link_text2])
                  : _(["", css2.link_text2])
              }
              onClick={handleClick}
            >
              Manage Banners
            </Link>
          </ul>
        </div>
        <div className="col-md-12 p-0 m-0 contnt-company-profile d-flex flex-column align-items-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
