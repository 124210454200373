import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
/* css */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const UsersDetail = () => {

    const links = [
        { to: "/Dashboard/UsersDetail/UsersProfile", text: "Profile" },
        { to: "/Dashboard/UsersDetail/UsersBookings", text: "Bookings" },
        { to: "", text: "Inquiries" }
    ];

    const history=useNavigate()

    const [activeLink, setActiveLink] = useState("/Dashboard/ProviderDetail/Profile");

    const handleLinkClick = (to) => {
        setActiveLink(to);
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className='col-md-12 d-flex'>
                    <p className={_(["",mainCss.cursor_pointer])} onClick={() =>
                    history("/Dashboard/Users")}
                    >Users</p><p>{'>'}</p><p>Park Plus</p>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 shadow p-3 mb-5 bg-body rounded-3">
                    <div className="w-100">
                        {/* <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                            <li>
                                <NavLink to="/Dashboard/UsersDetail/UsersProfile" className={_([" mb-2 px-2 py-2", css2.link_text])}><p>Profile</p>
                                    <p>{'>'}</p></NavLink>
                            </li>
                            <li>
                                <NavLink to="/Dashboard/UsersDetail/UsersBookings" className={_(["mb-2 px-2 py-2", css2.link_text])}><p>Bookings</p>
                                    <p>{'>'}</p></NavLink>
                            </li>
                            <li>
                                <NavLink to="" className={_(["mb-2 px-2 py-2", css2.link_text])}><p>Inquiries</p>
                                    <p>{'>'}</p></NavLink>
                            </li>
                        </ul> */}
                        <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                            {links.map((link, index) => (
                                <li key={index}>
                                    <NavLink 
                                        to={link.to} 
                                        className={_(["mb-2 px-2 py-2", css2.link_text, activeLink === link.to ? css2.active_link : ""])}
                                        onClick={() => handleLinkClick(link.to)}
                                    >
                                        <p>{link.text}</p>
                                        <p>{'>'}</p>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col-md-9 mx-4">
                    <Outlet/>
                </div>
            </div>
        </div>

    )


}


export default UsersDetail;