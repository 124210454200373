import React from 'react'
import noParkings from '../assets/JSON-Gifs/GIF/3.404.json'
import LottieGIF from '../components/lottieComponent'
import css from "../components/common.module.css";
const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};
export default function NoPermission() {
  return (<>
    <div className='mt-5 mb-3  row justify-content-center align-items-center'>
        <h1><strong>Opps</strong></h1>
    
    </div>
    <span className={_(["mb-5  row justify-content-center align-items-center", css.font2, css.font_18])}>You have no permission :(</span>
    </> )
}
