
import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import noParkings from "../../assets/Icons/Group 26816.svg";
import AddFAQPopup from "../popups/Aboutus/AddFAQPopup";
import { DATA_PROCESSOR } from "../../Tools";
import axiosInstance from "../../axios";
import SavedPopup from "../Reusable Popup/SavedPopup";
import Loader from "../Reusable Popup/Loader";

/* csss */
import './aboutus.css'

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const HelpFAQs = () => {

    const [loadingData, setLoadingData] = useState(false);
    let [statusloading, setStatusLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    // popup
    const [save, setSave] = useState(false);
    const saveToggle = () => {
        if (save) {
            setSave(false)
        }
        else {
            setSave(true)
        }
    }

    const [deleteSucc, setDeleteSucc] = useState(false);
    const deleteSuccToggle = () => {
        if (deleteSucc) {
            setDeleteSucc(false)
        }
        else {
            setDeleteSucc(true)
        }
    }
    const [AddFAQ, setAddFAQ] = useState(false);
    const AddFAQToggle = () => {
        if (AddFAQ) {
            setAddFAQ(false)
        }
        else {
            setAddFAQ(true)
        }
    }

    const [state, setState] = useState([]);

    const [faqdata, setFaqdata] = useState([]);

    async function makerequest() {
        setLoadingData(true)
        setColor('#5c5b5b');

        await axiosInstance.get(
            "admin_dash/csm_read_create_FAQ_api"
        ).then((response) => {
            let arr = response.data.response.result;
            setState(arr)
            setLoadingData(false)
        }).catch((err) => {
            console.log(err.response);
            setLoadingData(false)

        })

    }

    useEffect((e) => {
        makerequest()

    }, [])

    // update

    const [answer, setAnswer] = useState('');
    // const [question, setQuestion] = useState('');
    const handleChange = (e) => {
        setAnswer(e.target.value)
    }
    async function putrequest(e) {
        setLoadingData(true)
        setStatusLoading(true);
        setColor('#5c5b5b');

        const element = e.target
        const faqsId = element.getAttribute('data-id')
        console.log('category','All' );
        console.log('question', '');
        console.log('answer', answer);

        let formData = new FormData();
        // formData.append('question','' )
        formData.append('answer', answer)

        await axiosInstance.put(
            "admin_dash/csm_FAQ_URD_api-".concat(faqsId), formData,
        ).then((response) => {

            console.log(response)
            if (response.data.success) {
                saveToggle();
                setLoadingData(false);;
                makerequest()
                setStatusLoading(false);
                window.location.reload(true)
            } return 


        }).catch((err) => {
            console.log(err.response);
            setLoadingData(false)
            setStatusLoading(false);
        })

    }

    // delete

    async function deleterequest(e) {
        setLoadingData(true)
        setColor('#5c5b5b');
        setStatusLoading(true);

        const element = e.target
        const faqsId = element.getAttribute('data-id')
        console.log('id', faqsId);




        await axiosInstance.delete(
            "admin_dash/csm_FAQ_URD_api-".concat(faqsId),
        ).then((response) => {

            console.log(response)
            if (response.data.success) {
                deleteSuccToggle();
                setLoadingData(false);
                makerequest();
                setStatusLoading(false);
                window.location.reload(true)
            } return


        }).catch((err) => {
            console.log(err.response);
            setLoadingData(false)
            setStatusLoading(false);

        })

    }



    return (
        <div className="container p-4">
            <div class="row">
                <div class="col d-flex align-items-center titlehead">
                    Frequently Asked Question For Parking Users
                </div>
                <div class="col d-flex justify-content-end align-self-center">
                    <button
                        className="align-self-end font_18 inside_button1 add_new_button"
                        onClick={AddFAQToggle}
                    >
                        Add FAQs
                    </button>
                </div>
            </div>
            <div className="col-md-12 faqs-container mt-3 p-2">


                {state.map((spot, index) => {

                    return (<>
                        {spot.category==="All" || spot.category=="User" ?<Accordion className="col-md-12 mt-4">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                            >
                                <Typography
                                    style={{
                                        fontWeight: 10,
                                    }}
                                >
                                    <ul className="ABuslist">
                                        <li> {spot.question}</li>
                                    </ul>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {statusloading ? (
                                        <div className='spinner-border text-muted mt-4 mb-4'>
                                        </div>
                                    ) : (
                                        <div className=" col-md-12 d-flex flex-column justify-content-center">

                                            <textarea className="col-md-12  rounded border border-grey" onChange={handleChange} rows="4" cols="110" placeholder="No, But some documents are mandatory if you want to register as a provider...." maxlength="100">
                                                {spot.answer}
                                            </textarea>

                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <button className="deletebtn" data-id={spot.id} onClick={deleterequest} ><img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>Delete</button>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end">
                                                        <button className="inside_button3" onClick={() => {
                                                            makerequest()
                                                        }}>Cancel</button>&nbsp;&nbsp;
                                                        <button className="inside_button2" data-id={spot.id} onClick={putrequest}>Update</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    )}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>:null}
                    </>)
                })}
                {loadingData ?
                    (
                        <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                            <Loader color={color} loading={loadingData} />
                        </div>
                    ) :


                    (state.length === 0 ?
                        (
                            <>
                                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                    <img src={noParkings} alt="No Parkings"></img>
                                    <span className={_(["mt-4", css.font2, css.font_18])}>No FAQ available right now. :(</span>
                                </div>
                            </>
                        )
                        :
                        null

                    )
                }

            </div>
            <div class="row mt-4">
                <div class="col d-flex align-items-center titlehead">
                    Frequently Asked Question For Parking Providers
                </div>
            </div>
            <div className="col-md-12 faqs-container mt-3 p-2">
                {state.map((spot, index) => {

                    return (<>{spot.category==='All' || spot.category==='Provider'?
                        <Accordion className="col-md-12 mt-4">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                            >
                                <Typography
                                    style={{
                                        fontWeight: 10,
                                    }}
                                >
                                    <ul className="ABuslist">
                                        <li> {spot.question}</li>
                                    </ul>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {statusloading ? (
                                        <div className='spinner-border text-muted mt-4 mb-4'>
                                        </div>
                                    ) : (
                                        <div className=" col-md-12 d-flex flex-column justify-content-center">

                                            <textarea className="col-md-12 rounded border border-grey" onChange={handleChange} rows="4" cols="110" placeholder="No, But some documents are mandatory if you want to register as a provider...." maxlength="100">
                                                {spot.answer}
                                            </textarea>

                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <button className="deletebtn" data-id={spot.id} onClick={deleterequest}><img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>Delete</button>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end">
                                                        <button className="inside_button3" onClick={() => {
                                                            window.location.reload()
                                                        }}>Cancel</button>&nbsp;&nbsp;
                                                        <button className="inside_button2" data-id={spot.id} onClick={putrequest}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>:null}
                    </>)
                })}
                {loadingData ?
                    (
                        <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                            <Loader color={color} loading={loadingData} />
                        </div>
                    ) :


                    (state.length === 0 ?
                        (
                            <>
                                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                    <img src={noParkings} alt="No Parkings"></img>
                                    <span className={_(["mt-4", css.font2, css.font_18])}>No FAQ available right now. :(</span>
                                </div>
                            </>
                        )
                        :
                        null

                    )
                }

            </div>



            <AddFAQPopup isOpen={AddFAQ} toggle={AddFAQToggle} />
            <SavedPopup isOpen={save} heading={'FAQs'} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={saveToggle} />
            <SavedPopup isOpen={deleteSucc} heading={'FAQs Deleted !'} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={deleteSuccToggle} />
        </div>
    )

}


export default HelpFAQs;