export const downloadBookingCSV = (data, filename) => {

    var csvContent =
     "data:text/csv;charset=utf-8, \r\n id, amount, checkin, checkout, extendedTime,totalTime, name, phoneNumber, \r\n";
    
    data.map((a) => {
        let arr = Object.values(a);
        let row = arr.join(",");
        csvContent += row + "\r\n";
        console.log(arr,'arrihuws')
    })


    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".



}

export const downloadParkingCSV = (data, filename) => {

    var csvContent =
     "data:text/csv;charset=utf-8, \r\n id, name, price(monthly), price(Hourly), rating , is_active, loc_lat, loc_lon, parking_spot_number, pic_1, total_cancele_booked, total_count_booked, total_earned, total_ratings \r\n";
    
    data.map((a) => {
        let arr = Object.values(a);
        let row = arr.join(",");
        csvContent += row + "\r\n";
    })


    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".



}