import React, { useEffect, useState } from "react";

/* css */
import css2 from "./../../Profile/ProfilePage.module.css";
import css from "./../../../Dashboard_page/CompleteProfile.module.css";
import mainCss from "./../../../main.module.css";

/* icons */
import detailsIcon from "./../../../assets/Icons/Icon ionic-ios-information-circle-outline.svg";
import axiosInstance from "../../../axios";
import DownloadIcon from "../../../assets/Icon feather-download.svg";

// Utility function to combine CSS classes
const combineClasses = (...classes) => classes.join(" ");

// Function to download an image
// Function to download a file
// Function to download a file
const downloadFile = async (fileUrl, fileName) => {
  try {
    // Fetch the file data
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Get the file extension from the MIME type
    const fileExtension = blob.type.split("/")[1]; 
    const fullFileName = `${fileName}.${fileExtension}`;

    // Create a temporary anchor element for download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fullFileName;

    // Append link to the body and trigger click to download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};



// Reusable component for document display
const DocumentSection = ({ title, documents, imgKey, defaultFileName }) => {
  return (
    <div className={combineClasses("mb-4", css2.image_body)}>
      <div>
        <label className={combineClasses("d-flex align-items-center", mainCss.font1)}>
          {title}
          <img alt="documents" className="ml-2" src={detailsIcon} />
        </label>
      </div>
      {documents?.map((item, index) => (
        <div key={index} className={combineClasses("ml-3 mb-3 d-flex align-items-center", css.images_width)}>
          <p>{index + 1}.</p>
          <div className="ml-3">
            <img className={css.image_dimensions} src={item[imgKey]} alt="document" />
          </div>
          <div className={combineClasses("ml-3 d-flex justify-content-between", css.wfull, mainCss.font2)}>
            <span>{defaultFileName || `Document_${index + 1}.pdf`}</span>
            <span>1.23 MB</span>
          </div>
          <div className="ml-3">
            <img
              src={DownloadIcon}
              alt="Download"
              onClick={() => downloadFile(item[imgKey], defaultFileName || `Document_${index + 1}.pdf`)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};


const ProviderDocuments = () => {
  const id = localStorage.getItem("providerId");
  const [documentData, setDocumentData] = useState({});

  useEffect(() => {
    const fetchUserDocuments = async (providerId) => {
      try {
        const response = await axiosInstance.get(
          `/admin_dash/get_provider_docs?providerId=${providerId}`
        );
        setDocumentData(response?.data?.response?.documents || {});
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    if (id) {
      fetchUserDocuments(id);
    }
  }, [id]);

  return (
    <div className="container">
      <div className="row">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p className={combineClasses(mainCss.font1, mainCss.font_24)}>My Documents</p>
        </div>
        <DocumentSection
          title="Photo ID"
          documents={documentData?.photo_id}
          imgKey="photo_id"
          defaultFileName="PhotoID.pdf"
        />
        <DocumentSection
          title="Parking Spot Property Ownership Supporting Document"
          documents={documentData?.parking_id}
          imgKey="parking_id"
          defaultFileName="ParkingDocument.pdf"
        />
        <DocumentSection
          title="Rental Property Supporting Document"
          documents={documentData?.rental_id}
          imgKey="rental_id"
          defaultFileName="RentalDocument.pdf"
        />
      </div>
    </div>
  );
};

export default ProviderDocuments;
