import React, { } from 'react';




const ProviderInquries = () => {




    return ( <div class="container">
    <div class="row">
        <div class="col">
            Column
        </div>
        <div class="col">
            Column
        </div>
        <div class="col">
            Column
        </div>
    </div>
</div>);


}


export default ProviderInquries;