import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg";
import editIcon from "../../assets/Icons/Icon material-edit.svg";
import arrowUpDown from "../../assets/useicon/Arrow Up Down.svg";
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import AddModal from "../Reusable Popup/AddModel";
import CheckAnimate from "../Reusable Popup/CheckAnimate";
import "./parkingdata.css";
import "../../styles.css";
import noParkings from "../../assets/Icons/Group 26816.svg";
import axiosInstance from "../../axios";
import SavedPopup from "../Reusable Popup/SavedPopup";
import Loader from "../Reusable Popup/Loader";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const RESULT_LIMIT_IN_PAGE = 6

function CancellationReasons() {

    const [loading, setLoading] = useState(false);
    

    const [save, setSave] = useState(false);
    const SaveToggle = () => {
        if (save) {
            setSave(false)
        }
        else {
            setSave(true)
        }
    }

    const [pause, setPause] = useState(false);
    const pauseToggle = () => {
        if (pause) {
            setPause(false)
        }
        else {
            setPause(true)
        }
    }


    const [alert, setAlert] = useState(false);
    const alertToggle = () => {
        if (alert) {
            setAlert(false)
        }
        else {
            setAlert(true)
        }
    }

    const cancelCreateCancellation = React.createRef();

    const openCreateCancellation = () => {
        cancelCreateCancellation.current.toggleModal();

    }

    // add reasons

    const [reason, setReason] = useState({
        reason_name: "",
        is_pause: "",
    });

    const handleChange = (e) => {
        setReason((prevState) => { return { ...prevState, [e.target.name]: e.target.value }; });
    }

    const [checkbox, setCheckbox] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState('False');
    const Changetoggle = () => {
        if (checkbox) {
            setCheckbox(false)
            setCheckboxValue('False')
            console.log(checkbox, 'value', checkboxValue)
        } else {
            setCheckbox(true)
            setCheckboxValue('True')
            console.log(checkbox, 'value', checkboxValue)
        }
    }

    async function Save() {
        setLoading(true);

        let formData = new FormData();

        formData.append('reason_name', reason.reason_name);
        formData.append('is_pause', checkboxValue);
        //formData.append('created_by', pic.created_by);

        await axiosInstance.post(
            "admin_dash/add_cancle",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                console.log(response.data.response)
                SaveToggle()
            }
            setLoading(false);
            // openCreateCancellation()
            return

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })

    }

    // search cancellation

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })

    const [selectedOption, setSelectedOption] = useState('')

    const toggleButtonSearch = (e) => {
        setSearch("")
        setSelectedOption(e.target.name);
    }

    const [search, setSearch] = useState("");
    const [state, setState] = useState([]);
    const [page, setPage] = useState(1);

    const callSearch = async () => {
        setLoading(true);
        setState([])

        let formData = new FormData();

        formData.append('status', selectedOption)
        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('page', page)


        await axiosInstance.post(
            "admin_dash/search_cancle",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response.result)
            setState(response.data.response.result)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })

            setLoading(false)

        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    useEffect(() => {
        callSearch()
    }, [selectedOption, page])

    // delete
    let [statusloading, setStatusLoading] = useState(false);

    const deleteCancellationReason = async (event) => {
        setLoading(true);
        const element = event.target
        const CancellationReasonId = element.getAttribute('data-id')
        console.log(CancellationReasonId);
        let arrCancellationReason = new Array(CancellationReasonId);
        console.log('array', arrCancellationReason);

        let formData = new FormData();
        formData.append('id', arrCancellationReason)

        console.log("delete");

        await axiosInstance.delete(
            "admin_dash/add_cancle", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                console.log(response.data.response.data)
                alertToggle()
                callSearch()
            }
            setLoading(false);
            return
        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    // pause unpause

    const [pauseStatus, setPauseStatus] = useState(null);

    const pauseUnpauseCancellationReason = async (e) => {

        setLoading(true);
        const element = e.target
        const CancellationReasonId = element.getAttribute('data-id');
        const CancellationReasonStatus = element.getAttribute('data-status');
        console.log("id", CancellationReasonId);

        setPauseStatus(CancellationReasonStatus)
        console.log("pause status", pauseStatus);

        await axiosInstance.post(
            "admin_dash/edit_get_cancle-".concat(CancellationReasonId),

        ).then((response) => {
            console.log(response.data)
            pauseToggle()
            callSearch()
            setLoading(false);
        }).catch((err) => {
            console.log("got an error", err)
        })
    }




    return (

        <div className="col-md-12 p-0 mb-5">
            <AddModal ref={cancelCreateCancellation} heading="">
                <div>
                    <div className=" Headingstyle d-flex justify-content-center">
                        <h5>Add A Cancellation Reason</h5>
                    </div>
                    <div className=" text-secondary d-flex justify-content-center">
                        <h5>Help users to find appropriate reason to cancle the booking.</h5>
                    </div>


                    <div className="d-flex mt-5">
                        <div className="col-md-12">
                            <div className={_(["", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_([" ", css.font1])}>
                                        Write A Reason
                                    </label>
                                    <input
                                        type='input'
                                        className={_(['input_box', css.input_box_style, css.font2])}
                                        placeholder=''
                                        name='reason_name'
                                        value={reason.reason_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <div className='checkanimate d-flex justify-content-center mt-5'>
                            <div className='d-flex flex-row mt-2'>
                                <div className="col-4 align-self-center">
                                    <div className="d-flex position-relative">
                                        <h6>Active</h6>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className='d-flex justify-content-end'>
                                        <CheckAnimate value={checkbox} onClick={Changetoggle} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={_(["d-flex mt-5 flex-row justify-content-center", css2.input_font, css.font_18])}>
                            <button type='button' onClick={openCreateCancellation}
                                className={_([" d-flex justify-content-center align-items-center mr-5 p-2 px-5", css2.inside_button, css.inside_button1, css.font1])}>
                                cancel
                            </button> &nbsp;&nbsp;
                            <button type='button' onClick={Save}
                                className={_([" d-flex justify-content-center align-items-center ml-5 p-2 px-5 ", css2.inside_button, css.inside_button1, css.font1])}>
                                Save
                            </button>
                        </div>

                    </div>
                </div>
            </AddModal>
            <div className="d-flex justify-content-end" >
                <button
                    className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                    onClick={openCreateCancellation}
                >
                    Add New
                </button>
            </div>



            <div className={_(["w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                        name=''
                        onClick={toggleButtonSearch}
                    >
                        All
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Pouse' ? css2.selected_search_button : '')])}
                        name='Pouse'
                        onClick={toggleButtonSearch}
                    >
                        On Hold
                    </button>

                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img src={searchIcon} alt="search">
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            placeholder="Search by name or location"
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                {selectedOption === 'All' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                            <label className={_(["", css.font_18, css.font2])}>4 Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'On_Hold' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>6 Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}

            </div>
            <div className={_(["flex-grow-1", css2.table])}>
                <table className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                    <tr className={_(["", css2.search_header])}>
                        <td className={_(["p-4 text-start"])}>#</td>
                        <td className={_(["text-start"])}>Name</td>
                        <td>Created By</td>
                        <td>Cancellations<img alt='' src={arrowUpDown} className={_(["ml-2"])}></img></td>
                        <td>Actions</td>
                    </tr>

                    {state.map((spot, index) => {

                        return (
                            <>
                                <tr className={_([""])} key={index}>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <label className={_(["", css.checkbox_group])} htmlFor={"check" + index}>
                                                <input data-id={spot.id} type="checkbox" className={_(["mr-2", css.checkbox_size])} id={"check" + index}></input>
                                                <span className={_(["mr-1", css.box])}></span>
                                            </label>
                                            <div className="index d-flex align-items-center mt-3"><p>{index}</p></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-start align-items-start"])}>
                                            <label className={_(["row", css.font4])}>{spot.reason_name ? spot.reason_name : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.created_by ? spot.created_by : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.cancellation ? spot.cancellation : "-"}</label>
                                        </div>
                                    </td>

                                    <td>
                                        {selectedOption === '' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#">Edit</Link>
                                                    <Link className="dropdown-item" to="#" data-status={spot.is_pause} data-id={spot.id} onClick={pauseUnpauseCancellationReason} >{spot.is_pause === true ? "Pause" : "Unpause"}</Link>
                                                    <Link className="dropdown-item" to="#" data-id={spot.id} onClick={deleteCancellationReason}>Delete</Link>
                                                </div>
                                            </div> : null}
                                        {selectedOption === 'Pouse' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#" data-status={spot.is_pause} data-id={spot.id} onClick={pauseUnpauseCancellationReason} >{spot.is_pause === true ? "Pause" : "Unpause"}</Link>
                                                    <Link className="dropdown-item" to="#" data-id={spot.id} onClick={deleteCancellationReason}>Delete</Link>

                                                </div>
                                            </div> : null}



                                    </td>
                                </tr>
                            </>
                        )
                    })}

                </table>
                {loading ?
                    (
                        <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                            <Loader loading={loading} />
                        </div>
                    ) :


                    (state.length === 0 ?
                        (
                            <>
                                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                    <img src={noParkings} alt="No Parkings"></img>
                                    <span className={_(["mt-4", css.font2, css.font_18])}>No Cancellation Reason available right now. :(</span>
                                </div>
                            </>
                        )
                        :
                        null

                    )
                }
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                    <PaginationComponent
                        count={pagination.noOfPages}
                        page={page}
                        setPage={setPage}
                    />
                    :
                    null
                }
            </div>
            <SavedPopup isOpen={save} heading={'Cancellation Reasons'} Subheading={'Saved Successfully'} buttontitle={'Okay'} toggle={SaveToggle} />
            <SavedPopup isOpen={alert} heading={'Cancellation Reasons Deleted !'} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={alertToggle} />
            <SavedPopup isOpen={pause} heading={pauseStatus === true ? "Cancellation Reasons Unpause" : "Cancellation Reasons Pause"} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={pauseToggle} />
        </div>




    )
}


export default CancellationReasons;