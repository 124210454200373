import React, {useState} from 'react';
import { ClipLoader } from 'react-spinners';


function Loader(props) {
        const [color, setColor] = useState("#52a484");
        const css = {
                width: '50px',
                height: '50px'
        }
        return (

                <ClipLoader color={color} loading={props.loading} css={css} size={150} />

        )
}

 
export default  Loader;