import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router';
import { Link,useNavigate } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './terms.css'




const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const CompanyProfile = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [selected, setSelected] = useState("Amenities");

    const handleClick = (e) => {
        setSelected(e.target.name)
        let name = e.target.name;
        if (name === "TermsConditions") {
            setTitle("Terms & Conditions")
        } else if (name === "MonthlyParkingTerms") {
            setTitle("Monthly Parking Terms")
        } else if (name === "HourlyParkingTerms") {
            setTitle("Hourly Parking Terms")
        } else if (name === "DocumenationTerms") {
            setTitle("Documenation Terms")
        } else if (name === "ProviderTerms") {
            setTitle("Provider Terms")
        } else {
            setTitle("Extension Terms")
        }
    }

    const url = new URL(window.location.href);
    useEffect(()=>{
    
    if (url.pathname.split('/')[3]!=='TermsConditions'){
        setSelected(url.pathname.split('/')[3])
        setTitle(url.pathname.split('/')[3].replace(/([A-Z])/g, ' $1').trim())}
    else{
        setSelected(url.pathname.split('/')[3])
        setTitle("Terms & Conditions")
    }
    },[])




    return (
        <div className='container bg-white mt-5'>
            <div className='container mt-4'>
                <p className={_(["align-self-start mb-4 mt-4 font-family-regular"])} onClick={()=>navigate('/Dashboard/details')}>Profile {'>'} <span className='link-font'>Terms</span></p>
                <h4 className={_(["linkstyle fw-bold",])}>{title} </h4>
            </div>
            <div className='container mt-4'>
                <div className='col-md-12 p-0'>
                    <ul className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}>
                        <Link to="/Dashboard/Terms/TermsConditions" name="TermsConditions" className={selected === "TermsConditions" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick}>Terms & Conditions
                        </Link>
                        <Link to="/Dashboard/Terms/MonthlyParkingTerms" name="MonthlyParkingTerms" className={selected === "MonthlyParkingTerms" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick}>Monthly Parking Terms
                        </Link>
                        <Link to="/Dashboard/Terms/HourlyParkingTerms" name="HourlyParkingTerms" className={selected === "HourlyParkingTerms" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick} >Hourly Parking Terms
                        </Link>
                        <Link to="/Dashboard/Terms/DocumentationTerms" name="DocumentationTerms" className={selected === "DocumentationTerms" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick} >Documenation Terms
                        </Link>
                        <Link to="/Dashboard/Terms/ProviderTerms" name="ProviderTerms" className={selected === "ProviderTerms" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick}>Provider Terms
                        </Link>
                        <Link to="/Dashboard/Terms/ExtensionTerms" name="ExtensionTerms" className={selected === "ExtensionTerms" ? _(["py-2", css2.active_link3, css2.link_text2]) : _(["py-2",  css2.link_text2])} onClick={handleClick}>Extension Terms
                        </Link>
                    </ul>
                </div>
                <div className='col-md-12 p-0 m-0 contnt-term d-flex flex-column align-items-center'>
                    <Outlet />
                </div>
            </div>
        </div>
    )

}

export default CompanyProfile;