import React, { useState, useEffect, useContext } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import editIcon from "../../assets/Icons/Icon material-edit.svg"
import { Link, useNavigate } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import defaultSPotImg from "../../assets/Default_spot_img.svg";
import eyeGradient from '../../assets/useicon/eye-gradient.svg';
import eyeGray from '../../assets/useicon/eye-gray.svg';
import starIcon from "../../assets/Icons/Icon awesome-star.svg";
import noParkings from "../../assets/Icons/Group 26816.svg";
import "../../styles.css"
import './Companyprofile.css'
//
import ReviewDetailPopup from "../popups/CompanyProfile/ReviewDetailPopup";
import AddReviewPopup from "../popups/CompanyProfile/AddReviewPopup";
import Loader from "../Reusable Popup/Loader";
import axiosInstance from "../../axios";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



const RESULT_LIMIT_IN_PAGE = 6

const options = [
    {
        label: "Users",
        value: "User",
    },
    {
        label: "Providers",
        value: "Provider",
    },]

function UserReviews() {

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    //popups
    const [ReviewDetail, setReviewDetail] = useState(false);
    const ReviewDetailToggle = () => {
        if (ReviewDetail) {
            setReviewDetail(false)
        }
        else {
            setReviewDetail(true)
        }
    }

    const [AddReview, setAddReview] = useState(false);
    const AddReviewToggle = () => {
        if (AddReview) {
            setAddReview(false)
        }
        else {
            setAddReview(true)
        }
    }

    const [selectedOption, setSelectedOption] = useState('active');
    const [providertype, setProviderType] = useState('User');
    const [viewtype, setViewType] = useState('is_web');

    const toggleButtonSearch = (e) => {
        setSelectedOption(e.target.name);
        
    }

    const toggleProviderSearch = (e) => {
        setProviderType(e.target.value);
        console.log(providertype);
        // console.log(viewtype);
    }

    const toggleViewSearch = (e) => {
        setViewType(e.target.value);
        console.log(viewtype);
    }

    
    // console.log(providertype)
   

    const [search, setSearch] = useState("")
    const [state, setState] = useState([])

    const [page, setPage] = useState(1)

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })

    const callUserSearch = async () => {
        setLoading(true);
        setColor('#5c5b5b');
        setState([])

        let formData = new FormData();

        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)
        formData.append('review', providertype)
        formData.append('type', viewtype )



        await axiosInstance.post(

            "admin_dash/get_search_review",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response.result)
            setState(response.data.response.result)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })

            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })
    }

    useEffect(() => {


        callUserSearch()
    }, [selectedOption,viewtype, providertype, page])

    // delete

    // const deleteParkingSpot = async (event) => {
    //     setStatusLoading(true);
    //     const element = event.target
    //     const parkingSpotId = element.getAttribute('data-id')
    //     console.log(parkingSpotId);
    //     let arrparkingspot = new Array(parkingSpotId);
    //     console.log('array', arrparkingspot);
    //     let formData = new FormData();


    //     formData.append('id', arrparkingspot)

    //     console.log("delete")
    //     await axiosInstance.post(
    //         "admin_dash/del_parking_spot", formData,
    //         { headers: { 'content-type': 'multipart/form-data' } }
    //     ).then((response) => {

    //         console.log(response.data.response.data)
    //         alertToggle()
    //         callSearch()
    //         setStatusLoading(false);


    //     }).catch((err) => {
    //         console.log("got an error", err)
    //     })
    // }



    return (

        <div className="col-md-12 p-0 mb-5">
            <div className="d-flex justify-content-end" >
                <button
                    className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                    onClick={AddReviewToggle}
                >
                    Add A Review
                </button>
            </div>



            <div className={_([" w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'active' ? css2.selected_search_button : '')])}
                        name='active'
                        onClick={toggleButtonSearch}
                    >
                        Active
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'deleted' ? css2.selected_search_button : '')])}
                        name='deleted'
                        onClick={toggleButtonSearch}
                    >
                        Deleted
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img src={searchIcon} alt="search">
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            placeholder="Search here"
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                
                {selectedOption === 'active' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>{state.length} Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'deleted' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Deleted</label>
                            <label className={_(["", css.font_18, css.font2])}>{state.length} Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                        <select value={providertype}
                            onChange={toggleProviderSearch} className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                            <option value='User'>
                                Users
                            </option>
                            <option value='Provider'>Providers</option>
                        </select>
                        <select value={viewtype}
                            onChange={toggleViewSearch} className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                            <option value='is_web'>
                                In Web
                            </option>
                            <option value='is_app'>In App</option>
                        </select>
                        <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])}>
                            <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                            Delete
                        </button>
                    </div>

                </div>

            </div>
            <div className={_(["flex-grow-1", css2.table])}>
                <table className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                    <tr className={_(["", css2.search_header])}>
                        <td>#</td>
                        <td>Name</td>
                        <td>Contact Info</td>
                        <td>Address</td>
                        <td>Review</td>
                        <td>Rating</td>
                        <td>Visible On Website</td>
                        <td>Action</td>
                    </tr>
                    {state.map((spot, index) => {
                        return (
                            <>
                                <tr className={_([""])}>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <label className={_(["", css.checkbox_group])} htmlFor={"check" + index}>
                                                <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id={"check" + index}></input>
                                                <span className={_(["mr-1", css.box])}></span>
                                            </label>
                                            <div className="index d-flex align-items-center mt-3"><p>{index}</p></div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className={_(["d-flex flex-row justify-content-start align-items-center"])}>
                                            {/* <img src={ spot.review_for === 'Users' ? (spot.user.profile_pic ? spot.user.profile_pic : defaultSPotImg) : } className={_(["", css2.search_spot_img])}></img> */}
                                            <div className={_([" d-flex flex-column justify-content-start align-items-start"])}>
                                                <label className={_(["", css.font4])}>{spot.review_for === 'User' ? (spot.user.first_name ? spot.user.first_name + spot.user.last_name : "-") : spot.provider.first_name + spot.provider.last_name}</label>
                                                <label className={_(["", css.font_14])}>{spot.review_for === 'Provider' ? 'provider' : "users"}</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td >
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["", css.font4])}>{spot.review_for === 'User' ? (spot.user.email ? spot.user.email : "-") : spot.provider.email}</label>
                                            <label className={_(["", css.font4])}>{spot.review_for === 'User' ? (spot.user.phone_number ? spot.user.phone_number : "-") : spot.provider.phone_number}</label>
                                        </div>

                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["", css.font4])}>{spot.review_for === 'User' ? (spot.user.address_line_1 ? spot.user.address_line_1 : "-") : spot.provider.address_line_1}</label>
                                            <label className={_(["", css.font4])}>{spot.review_for === 'User' ? (spot.user.address_line_2 ? spot.user.address_line_2 : "-") : spot.provider.address_line_2}</label>
                                        </div>
                                    </td>
                                    <td >
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.user_comment ? spot.user_comment : "-"}</label>
                                        </div>
                                    </td>
                                    <td >
                                        <div className={_([" d-flex justify-content-center align-items-center"])}>
                                            <img src={starIcon} alt="..." className={_(["ml-2"])} onClick={ReviewDetailToggle}></img>&nbsp;
                                            <label className={_(["row", css.font4])}>{spot.user_rating ? spot.user_rating : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.is_visible_on_web ? <p><img src={eyeGradient} alt="..." className={_(["ml-2"])}></img></p> : <p><img src={eyeGray} alt="..." className={_(["ml-2"])}></img></p>}</label>
                                        </div>
                                    </td>
                                    <td>
                                        {selectedOption === 'active' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#">Share</Link>
                                                    <Link className="dropdown-item" to="#" /*onClick={deleteParkingSpot}*/ data-id={spot.id} >Delete</Link>
                                                </div>
                                            </div> : null}
                                        {selectedOption === 'deleted' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#" /*onClick={deleteParkingSpot}*/ data-id={spot.id}>Delete</Link>
                                                </div>
                                            </div> : null}


                                    </td>
                                </tr>
                            </>
                        )
                    })}

                </table>
                {loading ?
                            (
                                <div className="w-100">
                                    <div className="d-flex justify-content-center spinner w-100">
                                        <div className="spinner-border text-muted mt-4 mb-4"></div>
                                    </div>
                                </div>
                            ) :


                            (state.length === 0 ?
                                (
                                    <>
                                        <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                            <img src={noParkings} alt="No Parkings"></img>
                                            <span className={_(["mt-4", css.font2, css.font_18])}>No parking spots available right now. :(</span>
                                        </div>
                                    </>
                                )
                                :
                                null

                            )
                        }
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                    <PaginationComponent
                        count={pagination.noOfPages}
                        page={page}
                        setPage={setPage}
                    />
                    :
                    null
                }
            </div>
            <ReviewDetailPopup isOpen={ReviewDetail} toggle={ReviewDetailToggle} />
            <AddReviewPopup isOpen={AddReview} toggle={AddReviewToggle} />


        </div>



    )
}


export default UserReviews;