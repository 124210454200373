import React, { useEffect ,useState} from 'react';
import editIcon from "../../assets/Icons/Icon material-edit.svg"
/* csss */
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import deleteIcon from "../../assets/Icons/Icon material-delete.svg"
import axiosInstance from '../../axios';
import DeletePopup from '../Reusable Popup/DeletePopup';
import DeleteSuccess from '../Reusable Popup/DeleteSucess';
import SavedPopup from '../Reusable Popup/SavedPopup';
import { SettingsOverscanRounded } from '@material-ui/icons';



const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


var currency={'USD':'$','EUR':'€','GBP':'£','JYP':'¥','INR':'₹','CNY':'¥ /元'}

const ServiceCharges = () => {
    const [State,setState]=useState([])
    const [deletion, setDelete] = useState(false);

    async function makerequest() {

        await axiosInstance.get(
            "admin_dash/service_charge-<1>"
        ).then((response) => {
            console.log(response.data)
    
            setState(response.data.response.result)
    
        }).catch((err) => {
            console.log(err.response.data);
    
        })
    
    }
    
    useEffect(()=>{
        makerequest()
    
    },[])
    
    const [confirmation, setconfirmation]=useState(false)
    const [getid,setid]=useState(-1)
    const [save,setsave]=useState(false)
    const deletevalue=(e)=>{
        setid(e.target.id)
        setDelete(true)

        }
    const DeleteToggle = (e) => {
        
        if(e.target.value==='Cancel'){
            setDelete(false)
        }
        else{
                setDelete(false)
             
                Deleted()
                
            }
        }

        async function Deleted() {

            await axiosInstance.delete(
                "admin_dash/service_charge-"+getid
            ).then((response) => {
                makerequest()
                setconfirmation(true)
                console.log(response)
            }).catch((err) => {
                makerequest()
                console.log(err.response.data);
        
            })
        
        }
        const [valueState,setValue]=useState({currency_type:'$', booking_type:'Monthly', ammount:null, service_charge:null})
    
        async function Save() {
            let formData = new FormData();
            formData.append('currency_type', valueState.currency_type);
            formData.append('booking_type', valueState.booking_type);
            formData.append('ammount', valueState.ammount);
            formData.append('service_charge', valueState.service_charge);

            await axiosInstance.post(
                "admin_dash/service_charge-<id>",
                formData
            ).then((response) => {
                setsave(true)
                makerequest()
            }).catch((err) => {
                makerequest()
                console.log(err.response.data);
        
            })
        
        }

        const addvalues=(e)=>{
            console.log(e.target.name,e.target.value)
            setValue({ ...valueState, [e.target.name]: e.target.value })

        }

        console.log(
            Object.entries(currency)
          )


    return (
        <div className="col-md-12 mb-5 w-100">
            <div className="row">
                <div className="col-md-12">
                    <div className='SCtitle p-4'>
                        <p>Indicate the srervice charge applicable to providers.</p>
                    </div>
                    <div className='d-flex Addbox justify-content-center'>
                        <div className={_(["col-md-9  d-flex flex-wrap d-flex justify-content-center mt-3"])}>
                            <p className='RITxt mt-1 mr-2'>For</p>
                            <select className={_(["mr-2", css.font_18, css.font4, css2.select_box_style1])} name='booking_type'
                                        value={valueState.booking_type}
                                        onChange={addvalues}>
                                <option value='Monthly'>Monthly</option>
                                <option value='Hourly'>Hourly</option>
                            </select>
                            <p className='RITxt mt-1 mr-2'>Over</p>
                            <select className={_(["mr-2", css.font_18, css.font4, css2.select_box_style1])} name='currency_type'
                                        value={valueState.currency_type}
                                        onChange={addvalues}>
                                {Object.entries(currency).map(([keys,values])=>(<option value={values}>{keys}</option>))}
                                
                            </select>
                            <input placeholder='Amount' className={_(["mr-2 ", css.font_18, css.font4, css2.select_box_style])} type="text" name='ammount'
                                        value={valueState.ammount}
                                        onChange={addvalues}/>
                            <p className='RITxt mt-1 mr-2'>Service charge</p>
                            <input placeholder='0'  className={_(["mr-2", css.font_18, css.font4, css2.select_box_style1])} type="text" name='service_charge'
                                        value={valueState.service_charge}
                                        onChange={addvalues} />
                            <p className='RITxt mt-1 mr-2'>%</p>

                        </div>
                        <div className='mb-3 col-md-3 justify-content-center'>
                                <button className="font_18 inside_button1 add_new_button" onClick={Save}
                                >Add
                                </button>
                        </div>

                    </div>

                </div>
                <div className="col-md-12 p-0 mt-3 mb-5">
                    <div className={_(["flex-grow-1", css2.table])}>
                        <div className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                            <div className={_(["row", css2.search_header])}>
                                <div className='col-md-1'>#</div>
                                <div className='col-md-1'>Over</div>
                                <div className='col-md-2'>Type</div>
                                <div className='col-md-2'>Service Charge</div>
                                <div className='col-md-4'></div>
                                <div className='col-md-2'>Actions</div>
                            </div>




                            {State.map((state ,index)=>(
                            <div className={_(["row mb-2 mt-2", css.border_bottom])} >
                                <div className={_(["col-md-1 mb-2 mt-2"])}>
                                    <label className={_(["flex-grow-1 d-flex justify-content-center align-items-center mb-0", css.checkbox_group])} htmlFor="check1">{index+1}
                                    </label>
                                </div>
                                <div className={_(["col-md-1 mb-2 mt-2"])}>

                                    <div className={_([" d-flex flex-column justify-content-start align-items-center flex-grow-1"])}>
                                    <label className={_([""])}>{state.ammount}{state.currency_type}</label>
                                    </div>
                                </div>
                                <div className={_(["col-md-2 mb-2 mt-2"])}>
                                <label className={_([""])}>{state.booking_type}</label>
                                </div>
                                <div className={_(["col-md-2 mb-2 mt-2"])}>
                                    {state.service_charge}%

                                </div >
                                <div className={_(["col-md-4 mb-2 mt-2"])}>

                                </div >
                                <div  className={_(["col-md-2 mb-2 mt-2"])}>
                                    <button className={_([" ", css.deletebutton])} >
                                        <img src={deleteIcon} alt="..." className={_(["mr-2"])} id={state.id} onClick={deletevalue}></img>
                                    </button>
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>
            </div>
            <DeletePopup  isOpen={deletion} heading={'Alert!'} subheading={'Are you sure you want to delete'} subheading2={'Sevice Charge Data?'} buttontitle={'Cancel'} buttontitle2={'Yes'} toggle={DeleteToggle}/>
            <DeleteSuccess  isOpen={confirmation} heading={'Great!'} subheading={'Successfully Deleted'} subheading2={'Sevice Charge Data'} buttontitle={'Okey'} toggle={()=>{setconfirmation(false)}}/>
            <SavedPopup isOpen={save} heading={'New service charges'} Subheading={'Added Successfully'} buttontitle={'Okay'} toggle={()=>{setsave(false)}} />
        </div>

    )

}


export default ServiceCharges;