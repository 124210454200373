import React, { useEffect, useState } from "react";
import css from "../../../main.module.css";
import css2 from "./../../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import searchIcon from "./../../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "./../../../assets/Icons/Icon material-delete1.svg";
import editIcon from "./../../../assets/Icons/Icon material-edit.svg";
import { Link } from "react-router-dom";
import actionIcon from "./../../../assets/Icons/Repeat Grid 15.svg";
import noParkings from "../../../assets/Icons/Group 26816.svg";

import "../../../styles.css";
import {
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
} from "../../../store/actions/providerActions";
import axiosInstance from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Reusable Popup/Loader";
import PaginationComponent from "../../Pagination";
import SavedPopup from "../../Reusable Popup/SavedPopup";

import { DownloadExcel } from "../../algo/DownloadExcel";
import { makeBookingData } from "../../algo/madeArrayData";
import { downloadParkingCSV } from "../../algo/DownloadCSV";
import { generatePDF } from "../../Parking_spot/parkingspots/parkingSpotPDF";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const RESULT_LIMIT_IN_PAGE = 6;

const ProviderParkingSpot = () => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("providerId");
  const [state, setState] = useState([]);

  const [selectedOption, setSelectedOption] = useState("All");
  console.log(selectedOption, "selectedOptioncsdc");
  const [parkingSpotData, setParkingSpotData] = useState({});
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState(false);
  const [pause, setPause] = useState(false);
  const [approve, setApprove] = useState(false);
  const [deny, setDeny] = useState(false);
  const [pauseStatus, setPauseStatus] = useState(null);
  const [exportInfo, setExport] = useState("");

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
  };

  const [editPricePopup, setEditPricePopup] = useState({
    popup: false,
    spotId: "",
  });

  const toggleEditPricePopup = () => {
    if (editPricePopup.popup) {
      setEditPricePopup({
        ...editPricePopup,
        popup: false,
      });
    } else {
      setEditPricePopup({
        ...editPricePopup,
        popup: true,
      });
    }
  };

  // const userBooking = async (id) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `/provider_dash/edit_and_view_parking_spot_api-${id}`
  //     );
  //     const result = response?.data?.response?.parking_spot;
  //     setParkingSpotData(result);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   userBooking(id);
  // }, [id]);

  const callSearch = async (id) => {
    setLoadingData(true);
    setColor("#5c5b5b");

    let formData = new FormData();

    formData.append("type", selectedOption);
    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("page", page);
    formData.append("filter", "");

    await axiosInstance
      .post(`/provider_dash/edit_and_view_parking_spot_api-${id}`, formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        const result = response?.data?.response?.parking_spot;
        setParkingSpotData(result);
        setPagination({
          count: RESULT_LIMIT_IN_PAGE,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
        console.log("got an error", err);
      });
  };

  useEffect(() => {
    callSearch(id);
  }, [selectedOption, page, id]);

  const download = (e) => {
    const value = e.target.value;
    setExport(e.target.value);
    const data = makeBookingData(state);
    // var bookingData=data.map(({data, ...keepAttrs}) => keepAttrs);
    console.log("download", data);
    if (value === "exportAsExcel") {
      DownloadExcel(data, "parkingspot.xlsx");
    }
    if (value === "exportAsCSV") {
      downloadParkingCSV(data, "parkingspot.csv");
    }
    if (value === "exportAsPDF") {
      generatePDF(data, selectedOption);
    }
    setExport("");
  };

  // popup
  const alertToggle = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  };

  const pauseToggle = () => {
    if (pause) {
      setPause(false);
    } else {
      setPause(true);
    }
  };

  const approveToggle = () => {
    if (approve) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  };

  const denyToggle = () => {
    if (deny) {
      setDeny(false);
    } else {
      setDeny(true);
    }
  };

  console.log("parkingSpotData", parkingSpotData);
  return (
    <div className={_(["col-md-12 bg-white p-0 mb-5 shadow mb-5 bg-body rounded-3",css.br_18])}>
      <div className={_(["d-flex justify-content-start p-4",css.font1])}>
        <h5> Parking Spott</h5>
      </div>
      <div className={_([" w-100", css2.search_row])}>
        <div>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "All" ? css2.selected_search_button : "",
            ])}
            name="All"
            onClick={toggleButtonSearch}
          >
            All
          </button>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "Active" ? css2.selected_search_button : "",
            ])}
            name="Active"
            onClick={toggleButtonSearch}
          >
            Active
          </button>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "Pending_Approval"
                ? css2.selected_search_button
                : "",
            ])}
            name="Pending_Approval"
            onClick={toggleButtonSearch}
          >
            Pending Approval
          </button>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "Denied" ? css2.selected_search_button : "",
            ])}
            name="Denied"
            onClick={toggleButtonSearch}
          >
            Denied
          </button>
        </div>
        <div className={_(["d-flex justify-content-end"])}>
          <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
            <img alt="parking item" src={searchIcon}></img>

            <input
              className={_(["ml-2 w-100", css.font_18, css.font2])}
              type="input"
              name="search"
              placeholder="Search here"
              style={{ border: "0px", background: "none" }}
            ></input>
          </div>
        </div>
      </div>
      <div
        className={_([
          "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
          css2.search_row_2,
        ])}
      >
        {selectedOption === "All" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
              <label className={_(["", css.font_18, css.font2])}>4 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        {selectedOption === "Active" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>
                Active
              </label>
              <label className={_(["", css.font_18, css.font2])}>6 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        {selectedOption === "Pending_Approval" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>
                Deactivated
              </label>
              <label className={_(["", css.font_18, css.font2])}>6 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        <div
          className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
        >
          <div
            className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
          >
            <select
                        placeholder="Export As PDF"
                        value={exportInfo}
                        onChange={(e) => {
                          setExport(e.target.value);
                          download(e);
                        }}
                        className={_([
                          "mr-3",
                          css.font_18,
                          css.font1,
                          css2.select_box_style,
                        ])}
                      >
                        <option value="" disabled>
                          Select Export
                        </option>
                        <option value="exportAsPDF">Export As PDF</option>
                        <option value="exportAsCSV">Export As CSV</option>
                        <option value="exportAsExcel">Export As Excel</option>
                      </select>
            <button
              className={_([
                " pl-2 pr-2 d-flex align-items-center",
                css.font_18,
                css.inside_button4,
              ])}
            >
              <img
                alt="parking item"
                src={deleteIcon}
                className={_(["mr-2"])}
              ></img>
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className={_(["flex-grow-1", css2.table])}>
        <table
          className={_([
            "mt-2 w-100",
            css.font_18,
            css.font2,
            css2.search_list,
          ])}
        >
          <tr className={_(["", css2.search_header])}>
            <td>#</td>
            <td>Parking Name</td>
            <td>Address</td>
            <td>Created By</td>
            <td>Price<br></br> (Hourly)</td>
            <td>Price<br></br> (Monthly)</td>
            <td>Bookings</td>
            <td>Action</td>
          </tr>

          {parkingSpotData?.length > 0 &&
            parkingSpotData?.map((item, index) => {
              const continuousIndex = index + 1 + (page - 1) * pagination.count;

              return (
                <>
                  <tr className={_([""])}>
                    <td>
                      <label
                        className={_([
                          "flex-grow-1 d-flex justify-content-center align-items-center mb-0",
                          css.checkbox_group,
                        ])}
                        htmlFor="check1"
                      >
                        <input
                          type="checkbox"
                          className={_(["mr-2", css.checkbox_size])}
                          id="check1"
                        ></input>
                        <span className={_(["mr-1", css.box])}></span>
                      </label>
                    </td>

                    <td>{item?.parking_name}</td>
                    <td>{item?.parking_spot_address1}</td>
                    <td>{item?.created_at}</td>
                    <td>{item?.hourly_rate}</td>
                    <td>{item?.monthly_rate}</td>
                    <td>{item?.total_count_booked}</td>
                    <td>
                      {selectedOption === "All" ? (
                        <div className="dropdown">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img alt="parking item" src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={
                                "/Dashboard/ViewParkingSpot?search=" +
                                parkingSpotData?.id
                              }
                            >
                              Edit
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Deactivate
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Active" ? (
                        <div className="dropdown">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img alt="parking item" src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={
                                "/Dashboard/ViewParkingSpot?search=" +
                                parkingSpotData?.id
                              }
                            >
                              Edit
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Deactivate
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Pending_Approval" ? (
                        <div className="dropdown">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img alt="parking item" src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={
                                "/Dashboard/ViewParkingSpot?search=" +
                                parkingSpotData?.id
                              }
                            >
                              Edit
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Deactivate
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Denied" ? (
                        <div className="dropdown">
                          <p
                            className="mb-0 dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img alt="parking item" src={actionIcon}></img>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={
                                "/Dashboard/ViewParkingSpot?search=" +
                                parkingSpotData?.id
                              }
                            >
                              Edit
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Deactivate
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Delete
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </>
              );
            })}
        </table>
        {loadingData ? (
          <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
            <Loader color={color} loading={loadingData} />
          </div>
        ) : state?.length === 0 ? (
          <>
            <div className="mt-5 w-100 d-flex flex-column align-items-center">
              <img src={noParkings} alt="No Parkings"></img>
              <span className={_(["mt-4", css.font2, css.font_18])}>
                No parking spots available right now. :({" "}
              </span>
            </div>
          </>
        ) : null}
      </div>
      <div className={_(["", css.font_18, css.font2, css2.pagination])}>
        {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
          <PaginationComponent
            count={pagination.noOfPages}
            page={page}
            setPage={setPage}
          />
        ) : null}
      </div>
      <div className="mt-5"></div>
      {/* <UpdatePrice
          isOpen={editPricePopup.popup}
          toggle={toggleEditPricePopup}
        ></UpdatePrice> */}
        <SavedPopup
          isOpen={alert}
          heading="Parking Spot Deleted!"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={alertToggle}
        />
        <SavedPopup
          isOpen={pause}
          heading={`Parking Spot ${
            pauseStatus === "Pause" ? "Unpaused" : "Paused"
          }`}
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={pauseToggle}
        />
        <SavedPopup
          isOpen={approve}
          heading="Parking Spot Approved"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={approveToggle}
        />
        <SavedPopup
          isOpen={deny}
          heading="Parking Spot Application Denied"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={denyToggle}
        />
    </div>
  );
};

export default ProviderParkingSpot;
