import css from "./../../main.module.css";
import cssx from "./../common.module.css";
import css2 from "./Bookings.module.css";
import img1 from "./../../assets/Icons/Group 26139.svg";
import img2 from "./../../assets/Icons/Group 23923.svg";
import img3 from "./../../assets/Icons/Mask Group 144.svg";
import img4 from "./../../assets/Icons/Group 26152.svg";
import img5 from "./../../assets/Icons/Time Extended.svg";
import img6 from "./../../assets/Icons/Group 133.svg";
import img7 from "./../../assets/Icons/Group 26727.svg";
import passIcon from "./../../assets/Icons/Group 25037.svg";

// import CircularProgressBar from "../CircularProgressWithLabel.js"
import GoogleMap from "../Parking_spot/GoogleMap";
import { useNavigate, useSearchParams } from "react-router-dom";

import starIcon from "./../../assets/Icons/Icon awesome-star.svg";
import heartIcon from "./../../assets/Icons/Icon awesome-heart.svg";
import savedIcon from "./../../assets/Icons/Group 26073.svg";
import dropIcon from "./../../assets/Icons/Mask Group 146.svg";
import phoneIcon from "./../../assets/Icons/Icon material-phone.svg";

import { MdContentCopy } from "react-icons/md";

import defaultSPotImg from "./../../assets/Default_spot_img.svg";
import ViewInvoice from "./ViewInvoice";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import {
  convertTimeToHM,
  formatDate,
  formatDateString,
  getTotalTime,
} from "../../utils";
import BarcodePopup from "../popups/barcodePopup";
import Loader from "../Reusable Popup/Loader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};
// function addMarker(props) {
//   console.log("props.location", props.location);

//   const markerContent = document.createElement("div");
//   markerContent.style.display = "flex";
//   markerContent.style.alignItems = "center";
//   markerContent.style.justifyContent = "center";
//   markerContent.style.color = props.color || "black";
//   markerContent.style.fontSize = props.fontSize || "18px";
//   markerContent.style.fontFamily = props.font || "ProximaNova-Regular";
//   markerContent.innerHTML = props.label || "";

//   if (props.svg) {
//     const icon = document.createElement("img");
//     icon.src = props.svg;
//     icon.style.width = "30px";
//     icon.style.height = "30px";
//     markerContent.appendChild(icon);
//   }

//   if (window.google) {
//     return new window.google.maps.marker.AdvancedMarkerElement({
//       position: props.location,
//       map: props.map,
//       content: markerContent,
//     });
//   }
//   return null;
// }

function ViewBooking() {
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("search");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewBooking, setViewBooking] = useState({});
  const [openPassPopup, setPassPopup] = useState(false);
  const [map, setMap] = useState(null);

  useEffect(() => {
    let isMounted = true; // to handle unmounting
    let marker = null;

    const userBooking = async () => {
      let apiUrl = `bookingId=${query}`;
      setLoading(true);

      try {
        const response = await axiosInstance.get(
          `/admin_dash/get_provider_bookings?${apiUrl}`
        );
        const result = response?.data?.response?.bookings?.[0];
        console.log("result?.parking?.loc_lat", result?.parking?.loc_lat);
        if (isMounted) {
          setViewBooking(result);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching booking data:", error);
      }
    };

    userBooking();

    return () => {
      isMounted = false;
      if (marker) {
        marker.setMap(null); // Cleanup marker on unmount
      }
    };
  }, [map]);

  const handleCopy = () => {
    if (viewBooking?.booking_pass_code) {
      navigator.clipboard.writeText(viewBooking.booking_pass_code).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="  mt-5 mb-5 d-flex justify-content-center align-items-center">
          <Loader color={"#5c5b5b"} loading={loading} />
        </div>
      ) : (
        <>
          <div
            className={_([
              "d-flex flex-column align-items-center",
              cssx.container,
              css.shade_background,
            ])}
          >
            <div
              className={_([
                "d-flex flex-column align-items-center",
                css2.center_container,
              ])}
            >
              <div
                className={_(["d-flex flex-row justify-content-between w-100"])}
              >
                <p className={_(["", css.font_18, css.font5])}>
                  <span  className={_(["", css.cursor_pointer,css.font4])}  onClick={() => navigate(`/Dashboard/Bookings`)}>
                    Bookings &gt; 
                  </span>{" "}
                  {viewBooking?.parking_name} : #
                  {viewBooking?.booking_pass_code}
                  <button
                    className={_(["",css.cursor_pointer,css2.copy_button_custom])}
                    onClick={handleCopy}
                  >
                    <MdContentCopy/>
                  </button>
                </p>
                <label
                  className={_([
                    "",
                    css.font_18,
                    css.font1,
                    css2.current_text_style,
                  ])}
                >
                  Current
                </label>
              </div>

              <div
                className={_([
                  "d-flex flex-row justify-content-between w-100",
                  css2.progress_group,
                ])}
              >
                <span className={_(["mt-3", css2.progress_line])}></span>
                <div
                  className={_([
                    "d-flex flex-column justify-content-start align-items-center",
                    css2.selected_step,
                  ])}
                >
                  <div
                    className={_([
                      "d-flex flex-column justify-content-center align-items-center",
                    ])}
                  >
                    <img src={img1} alt="..."></img>
                  </div>
                  <p className={_(["mt-3", css.font_24, css.font1])}>
                    Booking Confirmed
                  </p>
                </div>
                <div
                  className={_([
                    "d-flex flex-column justify-content-start align-items-center",
                    css2.selected_step,
                  ])}
                >
                  <div
                    className={_([
                      "d-flex flex-column justify-content-center align-items-center",
                    ])}
                  >
                    <img src={img2} alt="..."></img>
                  </div>
                  <p className={_(["mt-3", css.font_24, css.font1])}>
                    Checked In
                  </p>
                </div>
                <div
                  className={_([
                    "d-flex flex-column justify-content-start align-items-center",
                    css2.not_selected_step,
                  ])}
                >
                  <div
                    className={_([
                      "d-flex flex-column justify-content-center align-items-center",
                    ])}
                  >
                    <img src={img3} alt="..."></img>
                  </div>
                  <p className={_(["mt-3", css.font_24, css.font1])}>
                    Checked Out
                  </p>
                </div>
                {/* <div className={_(["d-flex flex-column justify-content-start align-items-center", css2.not_selected_step])}>
              <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                  <img src={img4} alt="...">
                  </img>
              </div>
              <p className={_(["mt-3",css.font_24, css.font1])}>Booking Cancelled</p>
              
          </div>
          <div className={_(["d-flex flex-column justify-content-start align-items-center", css2.not_selected_step])}>
              <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                  <img src={img5} alt="...">
                  </img>
              </div>
              <p className={_(["mt-3",css.font_24, css.font1])}>Time Extended</p>
              
          </div> */}
                <div
                  className={_([
                    "d-flex flex-column justify-content-start align-items-center",
                    css2.not_selected_step,
                  ])}
                >
                  <div
                    className={_([
                      "d-flex flex-column justify-content-center align-items-center",
                    ])}
                  >
                    <img src={img6} alt="..."></img>
                  </div>
                  <p className={_(["mt-3", css.font_24, css.font1])}>
                    Reviews & Ratings
                  </p>
                </div>
              </div>

              <div
                className={_([
                  "d-flex flex-wrap justify-content-start align-items-center w-100 p-3",
                  css2.card_border,
                ])}
              >
                <div
                  className={_([
                    "d-flex flex-column justify-content-center align-items-center mr-4 mt-2",
                    css2.first_panel_img_div,
                  ])}
                >
                  <img src={img7} alt="..."></img>
                </div>
                <div className={_(["mt-2", css.mr_3rem])}>
                  <label className={_(["m-0", css.font_18, css.font2])}>
                    Request: Extend for {viewBooking?.extended_time || 0}
                  </label>
                  <br></br>
                  <label className={_(["m-0", css.font_14, css.font2])}>
                    Just now
                  </label>
                </div>
                <div className={_(["mt-2", css2.item, css.mr_3rem])}>
                  <label className={_(["m-0", css.font_18, css.font1])}>
                    Arrival:
                  </label>
                  <br></br>
                  <label className={_(["m-0", css.font_14, css.font2])}>
                    {formatDateString(viewBooking?.checkin)}
                  </label>
                </div>
                <div
                  className={_([
                    "mt-2 d-flex",
                    css2.item,
                    css.items_center,
                    css.mr_3rem,
                  ])}
                >
                  <div>
                    <hr className={_(["", css.vertical_line])}></hr>
                  </div>
                  <div className={_([""])}>
                    <label className={_(["m-0", css.font_18, css.font1])}>
                      Checkout:
                    </label>
                    <br></br>
                    <label className={_(["m-0", css.font_14, css.font2])}>
                      {formatDateString(viewBooking?.checkout)}
                    </label>
                  </div>
                </div>
                <div
                  className={_([
                    "mt-2 d-flex",
                    css2.item,
                    css.items_center,
                    css.mr_3rem,
                  ])}
                >
                  <div>
                    <hr className={_(["", css.vertical_line])}></hr>
                  </div>
                  <div>
                    <label className={_(["m-0", css.font_18, css.font1])}>
                      Total Time:
                    </label>
                    <br></br>
                    <label className={_(["m-0", css.font_14, css.font2])}>
                      {viewBooking?.total_time}
                    </label>
                  </div>
                </div>
                <div
                  className={_([
                    "mt-2 d-flex",
                    css2.item,
                    css.items_center,
                    css.mr_3rem,
                  ])}
                >
                  <div>
                    <hr className={_(["", css.vertical_line])}></hr>
                  </div>
                  <div>
                    <label className={_(["m-0", css.font_18, css.font1])}>
                      Time Left:
                    </label>
                    <br></br>
                    <label
                      className={_([
                        "m-0",
                        css.font_14,
                        css.font2,
                        css2.text_green,
                      ])}
                    >
                      {getTotalTime(
                        viewBooking?.start_time,
                        viewBooking?.end_time
                      )}
                    </label>
                  </div>
                </div>
                <div
                  className={_([
                    "mt-2 flex-grow-1 d-flex flex-column justify-content-end align-items-center",
                    css2.item,
                  ])}
                >
                  <button
                    className={_([
                      "",
                      css.font_18,
                      css.inside_button3,
                      css2.pass_button_style,
                    ])}
                    onClick={() => setPassPopup(true)}
                  >
                    <img src={passIcon} className={_(["mr-3"])}></img>Pass
                  </button>
                </div>
              </div>
              <div
                className={_([
                  "mt-3 d-flex flex-row justify-content-center align-items-center w-100 p-3",
                  css2.first_panel,
                ])}
              >
                <img
                  src={viewBooking?.parking_img || defaultSPotImg}
                  className={_(["", css2.spot_img])}
                ></img>
                <div
                  className={_([
                    "align-self-start flex-grow-1 d-flex flex-column justify-content-center align-items-center",
                  ])}
                >
                  <div
                    className={_([
                      "d-flex flex-row justify-content-between  w-100",
                    ])}
                  >
                    <div className={_(["flex-grow-1 d-flex flex-column ml-3"])}>
                      <label className={_(["", css.font1, css.font_24])}>
                        {viewBooking?.parking_name}
                      </label>
                      <div className={_(["d-flex flex-row"])}>
                        <div className={_(["d-flex align-items-center mr-2"])}>
                          <img
                            src={starIcon}
                            className={_(["", css.icons])}
                          ></img>
                          <label
                            className={_(["ml-2 mb-0", css.font2, css.font_18])}
                          >
                            {viewBooking?.user_rating}
                          </label>
                        </div>
                        <div className={_(["d-flex align-items-center mr-2"])}>
                          <img
                            src={heartIcon}
                            className={_(["", css.icons])}
                          ></img>
                          <label
                            className={_(["ml-2 mb-0", css.font2, css.font_18])}
                          >
                            {viewBooking?.parking?.likes}
                          </label>
                        </div>
                        <div className={_(["d-flex align-items-center mr-2"])}>
                          <img
                            src={savedIcon}
                            className={_(["", css.icons])}
                          ></img>
                          <label
                            className={_(["ml-2 mb-0", css.font2, css.font_18])}
                          >
                            {viewBooking?.parking?.bookmarked_number}
                          </label>
                        </div>
                      </div>
                      <label className={_(["", css.font2, css.font_18])}>
                        <img
                          src={dropIcon}
                          className={_(["mr-2", css.icons])}
                        ></img>
                        {viewBooking?.parking?.parking_spot_address1}
                      </label>
                      <label className={_(["", css.font2, css.font_18])}>
                        <img
                          src={phoneIcon}
                          className={_(["mr-2", css.icons])}
                        ></img>
                        +{viewBooking?.parking?.landlord_country_code}{" "}
                        {viewBooking?.parking?.parking_phone_number}
                      </label>
                    </div>
                    <div>
                      <label
                        className={_([
                          " m-0",
                          css.font_18,
                          css.font1,
                          css2.button2,
                        ])}
                      >
                        ${viewBooking?.parking?.hourly_rate}/hour
                      </label>
                    </div>
                  </div>
                  <p
                    className={_([
                      "align-self-start ml-3",
                      css.font_18,
                      css.font2,
                    ])}
                  >
                    {viewBooking?.cancelation_discription || ""}
                  </p>
                </div>
              </div>
              <div
                className={_([
                  "mt-3 d-flex flex-wrap justify-content-between align-items-start w-100",
                  css2.last_panel_height,
                ])}
              >
                <div
                  className={_([
                    " d-flex flex-column justify-content-start align-items-start p-3",
                    css2.last_panels,
                  ])}
                >
                  <label className={_(["", css.font_24, css.font1])}>
                    Car Details & Personal Information
                  </label>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Name
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.booked_by?.first_name +
                        " " +
                        viewBooking?.booked_by?.last_name}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Vehicle Type
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.vehicle_type}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Year
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.year}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Make
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.make}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Model
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.model}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      State / Province
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.sate_province}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Vehicle Tag
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      {viewBooking?.vehicle_tag_id}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                </div>
                <div
                  className={_([
                    " d-flex flex-column justify-content-start align-items-start p-3",
                    css2.last_panels,
                  ])}
                >
                  <label className={_(["", css.font_24, css.font1])}>
                    Payment Summary
                  </label>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Sub Total :
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      ${viewBooking?.amount || 0}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Service Charge :
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      ${viewBooking?.service_charge}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Discount :
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      ${viewBooking?.discount_amount}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Tax :
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      ${viewBooking?.tax_amount}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font2])}>
                      Tip :
                    </label>
                    <label className={_(["m-0", css.font_18, css.font4])}>
                      ${viewBooking?.net_amount}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <div
                    className={_([
                      " d-flex flex-row justify-content-between align-items-center w-100",
                    ])}
                  >
                    <label className={_(["m-0", css.font_18, css.font1])}>
                      Grand Total
                    </label>
                    <label className={_(["m-0", css.font_18, css.font3])}>
                      ${viewBooking?.total_amount}
                    </label>
                  </div>
                  <hr style={{ width: "100%" }}></hr>
                  <button
                    className={_([
                      "mt-3 align-self-center mb-2",
                      css.font_18,
                      css.inside_button1,
                    ])}
                    onClick={() => {
                      navigate(`/Dashboard/Bookings/invoice?search=${query}`, {
                        state: { bookingData: viewBooking },
                      });
                    }}
                  >
                    Get Invoice
                  </button>
                </div>
                <div
                  className={_([
                    " d-flex flex-row justify-content-center align-items-center p-0",
                    css2.last_panels_map,
                  ])}
                >
                  <GoogleMap
                    setMap={setMap}
                    lat={viewBooking?.parking?.loc_lat}
                    lng={viewBooking?.parking?.loc_lon}
                    byLocation={false}
                    locationName={viewBooking?.parking?.parking_name}
                  />
                </div>
              </div>
            </div>
          </div>
          <BarcodePopup
            isOpen={openPassPopup}
            toggle={() => setPassPopup(!openPassPopup)}
            state={viewBooking}
          />
        </>
      )}
    </>
  );
}

export default ViewBooking;
