import React, { useState} from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import editIcon from "../../assets/Icons/Icon material-edit.svg"
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"

import defaultSPotImg from "../../assets/Default_spot_img.svg"
import noParkings from '../../assets/Icons/Group 26816.svg'
import axiosInstance from "../../axios";

import "../../styles.css"
import Moment from 'moment';
import { useEffect } from "react";
import { DATETOWORDS , UTCTimeconvert} from "../../Tools";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};
const RESULT_LIMIT_IN_PAGE = 6

function ContactUsQueries() {
    //Initialization section
    const [selectedOption, setSelectedOption] = useState('Active')
    const [search, setsearch]=useState('')
    const [datewise, setdatewise]=useState('')
    const [usertype, setusertype]=useState('')
    const [daywise, setdaywise]=useState('')
    const [state, setstate]=useState([])
    const [Candelete, setcandelete]=useState('')
    const [editPricePopup, setEditPricePopup] = useState({
        popup: false,
        spotId: "",
    })
    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })
    const [page, setPage] = useState(1)
    const[loading,setLoading]=useState(false)



    //function and methods section
    const toggleButtonSearch = (e) => {
        setSelectedOption(e.target.name);
    }

   

    const Setdays = (e) => {
        if (e.target.value==='last07Days'){
            setdaywise(7)
        }
        if (e.target.value==='last30Days'){
            setdaywise(30)
        }
        if (e.target.value==='last3months'){
            setdaywise(91)
        }
        if (e.target.value==='last6months'){
            setdaywise(183)
        }
        if (e.target.value==='last1year'){
            setdaywise(365)
        }
        if (e.target.name==='date'){
            
            setdatewise(Moment(e.target.value).format('MM-DD-YYYY'))
        }
    }   

    const toggleEditPricePopup = () => {
        if (editPricePopup.popup) {
            setEditPricePopup({
                ...editPricePopup,
                popup: false,
            })
        }
        else {
            setEditPricePopup({
                ...editPricePopup,
                popup: true,
            })
        }
    }



    //API calling section start

    async function ContactUsQueries(){
        setLoading(true)
        let formData = new FormData();
        formData.append('search',search)
        formData.append('status',selectedOption)
        formData.append('date',datewise)  //it taken the value in form of mm-dd-yyyy
        formData.append('day',daywise)    //it taken the value in numeric form like 1,2,3,4 
        formData.append('type',usertype)  //it has three type Visitor, User, Provider
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')

        await axiosInstance.post(
            'admin_dash/search_contact_us',formData
        ).then((response) => {
            setLoading(false)
            setstate(response.data.response.result)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })
    
        }).catch((err) => {
            console.log(err.response);
            setLoading(false)
    
        })
    
        }
        async function DeleteOrUndelete(e){
            let formData = new FormData();
            formData.append('id',e.target.id)
            formData.append('want_to',e.target.name)
    
            await axiosInstance.put(
                'admin_dash/send_contactus_query',formData
            ).then((response) => {
                ContactUsQueries()
              
        
            }).catch((err) => {
                console.log(err.response);
        
            })
        
            }

    useEffect(()=>{
        ContactUsQueries()
    },[datewise,search,daywise,usertype,selectedOption])


    return (

        <div className="col-md-12 p-0 mb-5">

            <div className={_([" w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Active' ? css2.selected_search_button : '')])}
                        name='Active'
                        onClick={toggleButtonSearch}
                    >
                        Active
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Deleted' ? css2.selected_search_button : '')])}
                        name='Deleted'
                        onClick={toggleButtonSearch}
                    >
                        Deleted
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img alt="chat inquiries" src={searchIcon}>
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            value={search}
                            onChange={(e)=>{setsearch(e.target.value)}}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            ContactUsQueries()
                                        }
                                    }}
                            placeholder="Search here"
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                {selectedOption === 'Active' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'Deleted' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Deleted</label>
                            <label className={_(["", css.font_18, css.font2])}>{pagination.count} items</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                        <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}
                        onChange={(e)=>{setusertype(e.target.value)}}>
                            <option value=''>All</option>
                            <option value='User'>Users</option>
                            <option value='Provider'>Providers</option>
                            <option value='Visitor'>Visitors/Website</option>
                        </select>
                        <input type="date" className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])} 
                        name='date' onChange={Setdays}></input>
                        <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}
                        onChange={Setdays}>
                            <option value=''>---</option>
                            <option value='last07Days'>Last 07 Days</option>
                            <option value='last30Days'>Last 30 Days</option>
                            <option value='last3months'>Last 3 Months</option>
                            <option value='last6months'>Last 6 Months</option>
                            <option value='last1year'>Last 1 Year</option>
                        </select>
                    </div>

                </div>

            </div>
            <div className={_(["flex-grow-1", css2.table])}>
           
                <div className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                    
                    <div className={_(["row mt-2 w-100", css2.search_header])}>
                                    <div className="col-md-1 mt-2">#</div>
                                    <div className="col-md-2 mt-2">Name</div>
                                    <div className="col-md-3 mt-2">Email</div>
                                    <div className="col-md-2 mt-2">Phone No.</div>
                                    <div className="col-md-1 mt-2">Message</div>
                                    <div className="col-md-2 mt-2">Date</div>
                                    <div className="col-md-1 mt-2">Actions</div>
                                    
                                
                    </div>




                    
                        <div className={_(["row mt-2 w-100",css2.bottomline])} >
                            {loading?<div className="w-100">
                            <div className="d-flex justify-content-center spinner w-100 align-items-center">
                            <div className="spinner-border text-muted mt-4 mb-4"></div>
                            </div> 
                            </div>:(pagination.count===0?<>
                                    <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                        <img src={noParkings} alt="No Parkings"></img>
                                        <span className={_(["mt-4", css.font2, css.font_18])}>No Queries available right now. :(</span>
                                    </div></>:null)
                                }
                            {state.map((state,index)=>(<><div className="col-md-1 mt-3 mb-2">
                                <label className={_(["flex-grow-1 d-flex justify-content-center align-items-center mb-0", css.checkbox_group])} htmlFor="check1">
                                {index+1}
                                </label>
                            </div>
                            <div  className={_(["col-md-2 d-flex flex-row mb-2"])}>
                                <img alt="chat inquiries" src={defaultSPotImg} className={_(["", css2.search_spot_img])}></img>
                                <label className={_([""])}>{state.first_name} {state.last_name}</label>
                                
                            </div>
                            <div className="col-md-3 mb-2 mt-3 justify-content-center align-items-center ">
                                {state.email}
                            </div>
                        <div className="col-md-2 mb-2 mt-3 justify-content-center align-items-center">
                            {state.country_code!=='' & state.phone_number!==''?'+'+state.country_code+state.phone_number:'-'}
                        </div>
                        <div className="col-md-1 mb-2 mt-3">
                            {state.mesg!==''?state.mesg.slice(0,5)+'..':'-'}
                        </div>
                        <div className="col-md-2 mb-2 mt-3">
                            {DATETOWORDS(state.date)}<br></br>
                            {UTCTimeconvert(state.date)}
                        </div>
                        <div className="col-md-1 mb-2 mt-3">
                            {selectedOption === 'Active' ?
                                <div className="dropdown">
                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="chat inquiries" src={actionIcon}></img>
                                    </p>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="#" >Send Email</Link>
                                        <Link className="dropdown-item" to="#" id={state.id} name='Delete' onClick={DeleteOrUndelete}>Delete</Link>
                                    </div>
                                </div> : null}
                            {selectedOption === 'Deleted' ?
                                <div className="dropdown">
                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="chat inquiries" src={actionIcon}></img>
                                    </p>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="#" id={state.id} name='Active' onClick={DeleteOrUndelete}>Active</Link>
                                    </div>
                                </div> : null}


                        </div></>))}
                    </div>
                    
                   

                </div>
                
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
            {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                            <PaginationComponent
                                count={pagination.noOfPages}
                                page={page}
                                setPage={setPage}
                            />
                            :
                            null
                        }
            </div>


        </div>



    )
}


export default ContactUsQueries;