
import mainCss from "./../../main.module.css";
import css2 from "../notification/Notifications.module.css";
import css from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import { useNavigate } from "react-router-dom";
import User from '../../assets/Icons/Icon-user.svg';
import React, { useState, useEffect, useContext } from 'react';
import PaginationComponent from "../../components/Pagination";
import Loader from "../Reusable Popup/Loader";
import NotificationContext from '../notification/NotificationContext';
import { UTCTimeconvert ,DATETOWORDS} from "../../Tools";


import "../../styles.css"
import ParButton from "../../usable/ParButton";
import AccessSuccessConfirPopup from "../popups/AccessSuccessConfirPopup";
import axiosInstance from "../../axios";
import AccessSuccessPopup from "../popups/AccessSuccessPopup"; 

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const RESULT_LIMIT_IN_PAGE = 6

function AdminAccess() {
    const date = new Date()

    const profiledata=useContext(NotificationContext)
    const history = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");    
    const notifications=profiledata.adminaccessnotify===undefined? []:profiledata.adminaccessnotify
    const count=profiledata.adminaccesscount===undefined? 0:profiledata.adminaccesscount
    const [ID, setID] = useState(-1)
    const [permis,setpermis]=useState(false)
    const [Accessrequest,setAccessrequest]=useState(false)
    const [AccessSuccess,setAccessSuccess]=useState(false)
    const [requestname,setrequestname]=useState('')
    const [userName,setUserName]=useState('')
    const [permission_name,setpermission_name]=useState('')

    const accesstoggle=(e)=>{
        setUserName(e.target.getAttribute('username'))
        setrequestname(e.target.name)
        setID(e.target.id)
        setAccessrequest(true)
    }
    const allowpermission=(e)=>{
        console.log(e.target.value,permis)
        setAccessrequest(false)
        if(e.target.value==='Yes'){
            AllowAccess()
            setpermission_name('allow')
        }

    }
    const Denypermission=(e)=>{
        console.log(e.target.id)
        setpermission_name('deny')
        setID(e.target.id)
        AllowAccess()
    }

    const AllowAccess = async () => {
        let formData = new FormData();
        
        console.log(permission_name)
        if (permission_name==='allow'){
            formData.append("id",ID )
            formData.append("permission_name", permission_name )
            formData.append("allow_24hr_only", permis )}
        else{
            formData.append("id",ID )
            formData.append("permission_name", permission_name )

        }
        
        await axiosInstance.put(
            "admin_dash/Allow_access", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (permission_name==='allow'){
            setAccessSuccess(true)
            setpermis(false)
            setID(-1)}
            else{
                setpermis(false)
                setID(-1)
            }
        }).catch((err) => {
            console.log('Get an error',err.response)
        })
    }
    
    return (


        <div className="col-md-12 p-0 mb-5">

            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div className="row pt-4 pb-4 m-0">
                        <div className="col-md-6 p-0">
                            <p className="text-secondary">Total {count} Requests</p>

                        </div>
                        <div className="col-md-6 d-flex justify-content-end p-0">
                            <ParButton name="Mark all as read" className="pt-2 pb-2 pl-4 pr-4" />
                        </div>
                    </div>

                </div>
                <div className={_(["row", css2.loader])}>
                        {count===0?<Loader color={color}  loading={true}/>:null}
                    </div>
                
                    {
                       notifications.map((item,index) =>
                        (<><div className={item.notification_status?_(['col-md-12',css2.bottomline]):_(["col-md-12", css2.inside_body])}>
                            <div className="row p-3">
                            <div className={_(["col-md-12", css2.notification_row, css2.display1])}>
                                <div className={_(["col-md-2",css.profile_img])}>
                                <img src={item.nofti_pic!==null?item.nofti_pic:User} className={_(["", css2.notification_img])} />
                    
                                </div>
                                <div className={_(["col-md-7 ml-3", css2.message])} >
                                    <label className={_(["d-flex flex-row"])}><p className={_(["mb-0", mainCss.font5, mainCss.font_18])}>{item.fullname}</p><p className={_(["mb-0 ml-1", mainCss.font2, mainCss.font_18])}>{item.title}</p><p className={_(["mb-0 ml-1", mainCss.font4, mainCss.font_18])}>{item.body_message}</p></label>
                                    {item.date_created.split(':')[0]===date.toISOString().split(':')[0] && item.date_created.split(':')[1]===date.toISOString().split(':')[1]?<label className={_(["mb-0", mainCss.font2, mainCss.font_14])}>Just Now</label>:
                                    <label className={_(["mb-0", mainCss.font2, mainCss.font_14])}>{DATETOWORDS(item.date_created)} {UTCTimeconvert(item.date_created)}</label>}
                                </div>
                
                                {
                                        item.allow === false && item.deny===false && item.path!==""?
                                        <div className={_(["ml-4 col-md-3 d-flex flex-column", css2.inside_button_section, mainCss.font_14])}>
                                        <button className={_(["", mainCss.font1, mainCss.inside_button1])} onClick={accesstoggle} id={item.id} name={item.body_message} username={item.fullname}>
                                            Allow
                                        </button>
                                        <ParButton onClick={()=>{history(item.path+`?id=${item.id}`)}} className="white-button justify-content-center align-items-center mt-3 d-flex" name="View" />
                                    </div>
                                    :item.path==="" && item.allow===false && item.deny===false ? <div className={_(["ml-4 col-md-3 d-flex flex-column", css2.inside_button_section, mainCss.font_14])}>
                                    <button className={_(["", mainCss.font1, mainCss.inside_button1])} onClick={accesstoggle} id={item.id} name={item.body_message} username={item.fullname}>
                                        Allow
                                    </button>
                                    <button className={_(["  mt-3", mainCss.font1, mainCss.inside_button2])} onClick={Denypermission} id={item.id} name={item.body_message} username={item.fullname}>
                                            Deny
                                        </button>
                                    </div>:
                                    <div className={_(["ml-4 mt-3 col-md-3 d-flex flex-column justify-content-center align-items-center", mainCss.inside_button_section, mainCss.font_14])}>
                                    <button className={_(["", mainCss.font1, mainCss.inside_button])}>
                                        {item.allow === true && item.deny === false?'Allowed':'Denied'}
                                    </button>
                                    </div>
                                    
                                    }
                    
                
                    
                            </div>
                    
                        </div></div></>
                        ))
                    }
                </div>
                <AccessSuccessConfirPopup  isOpen={Accessrequest} heading={'Are you sure you want to allow access to'} subheading={requestname+'?'} buttontitle={'Cancel'} buttontitle2={'Yes'} checktoggle={(e)=>setpermis(e.target.checked)} toggle={allowpermission}/>
                <AccessSuccessPopup  isOpen={AccessSuccess} heading={'Access Granted to'} user={userName} toggle={()=>(setAccessSuccess(false))}/>
                
            
        </div>

    )

}


export default AdminAccess;