import React, { useState, useContext } from "react";
import mainCss from "./../../main.module.css";
import css2 from "../notification/Notifications.module.css";
import { useNavigate } from "react-router-dom";
import User from '../../assets/Icons/Icon-user.svg';
import css from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import Loader from "../Reusable Popup/Loader";

import "../../styles.css"
import ParButton from "../../usable/ParButton";
import NotificationContext from '../notification/NotificationContext';
import { UTCTimeconvert ,DATETOWORDS} from "../../Tools";


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function AdminActivities() {
    

    const profiledata=useContext(NotificationContext)

    const history = useNavigate();
    let [color, setColor] = useState("#ffffff");
    const [page, setPage] = useState(1)
   
   
    const notifications=profiledata.activenotification===undefined? []:profiledata.activenotification
    const count=profiledata.activecount===undefined? 0:profiledata.activecount
    

    return (


        <div className="col-md-12 p-0 mb-5">

            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div className="row pt-4 pb-4 m-0">
                        <div className="col-md-6 p-0">
                            <p className="text-secondary">{count!=0?count+' New Requests':null}</p>

                        </div>
                        <div className="col-md-6 d-flex justify-content-end p-0">
                            <ParButton name="Mark all as read" className="pt-2 pb-2 pl-4 pr-4" />
                        </div>
                    </div>

                </div>
                <div className={_(["", css2.loader])}>
                        {count===0?<Loader color={color}  loading={true}/>:null}
                    </div>
                
                    {
                        notifications.map((item, index) =>
                        (<><div className={item.notification_status?_(['col-md-12',css2.bottomline]):_(["col-md-12", css2.inside_body])}>
                            <div className="row p-3">
                            <div className={_(["col-md-12", css2.notification_row, css2.display1])}>
                                <div className={_(["col-md-2",css.profile_img])}>
                                    <img src={item.nofti_pic===null?User:item.nofti_pic} className={_(["", css2.notification_img])} />
                                </div>
                                <div className={_(["col-md-7 ml-4", css2.message])} >
                                    <p className={_(["mb-0", mainCss.font4, mainCss.font_18])}><b>{item.fullname} </b>{item.title} <b>{item.body_message}</b></p>
                                    <label className={_(["mb-0", mainCss.font2, mainCss.font_14])}>{item.created_date}</label>
                                </div>

                                <div className={_(["ml-4 col-md-3 d-flex flex-column", css2.inside_button_section, mainCss.font_18])}>
                                    <button className={_(["", mainCss.font1, mainCss.inside_button1])}>
                                        View
                                    </button>
                                </div>
                            </div>
                            </div>
                            </div></>
                        ))
                    }
                
            </div>
            
        </div>

    )

}


export default AdminActivities;