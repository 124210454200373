import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Link,useNavigate } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './aboutus.css'


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const AboutUs = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("Who are we");
    const [selected, setSelected] = useState("Who_are_we");

    const handleClick = (e) => {
        setSelected(e.target.name)
        let name = e.target.name;
        if (name === "WhoWeAre") {
            setTitle("Who we are?")
        } else if (name === "Our_Vision") {
            setTitle("Our Vision")
        } else if (name === "HelpFAQs") {
            setTitle("Help & FAQs")
        } else if (name === "AboutParkingBud") {
            setTitle("About ParkingBud")
        } else {
            setTitle("Privacy Policy")
        }
    }


    const url = new URL(window.location.href);
    useEffect(()=>{
    if (url.pathname.split('/')[3]!=='HelpFAQs'){
        setSelected(url.pathname.split('/')[3])
        setTitle(url.pathname.split('/')[3].replace(/([A-Z])/g, ' $1').trim())}
    else{
        setTitle('Help FAQs')
        setSelected('Help FAQs')
    }
    },[])



    return (
        <div className='container bg-white mt-5'>
            <div className='container mt-4'>
                <p className={_(["align-self-start mb-4 mt-4 font-family-regular"])} onClick={()=>navigate('/Dashboard/details')}>Profile {'>'} <span className='link-font'>About Us</span></p>
                <h4 className={_(["linkstyle fw-bold",])}>{title} </h4>
            </div>
            <div className='container mt-4'>

                <div className='col-md-12 p-0'>
                    <ul className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}>
                        <Link to="/Dashboard/AboutUs/WhoWeAre" name="WhoWeAre" className={selected === "WhoWeAre" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Who we are?
                        </Link>
                        <Link to="/Dashboard/AboutUs/OurVision" name="OurVision" className={selected === "OurVision" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Our Vision
                        </Link>
                        <Link to="/Dashboard/AboutUs/HelpFAQs" name="HelpFAQs" className={selected === "HelpFAQs" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >Help &amp; FAQs
                        </Link>
                        <Link to="/Dashboard/AboutUs/AboutParkingBud" name="AboutParkingBud" className={selected === "AboutParkingBud" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >About ParkingBud
                        </Link>
                        <Link to="/Dashboard/AboutUs/PrivacyPolicy" name="PrivacyPolicy" className={selected === "PrivacyPolicy" ? _(["py-2 bg-white", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Privacy Policy
                        </Link>
                    </ul>
                </div>
                <div className='col-md-12 border m-0 contnt-aboutus d-flex flex-column align-items-center'>
                    <Outlet />
                </div>
            </div>
        </div>
    )

}

export default AboutUs;