import React from 'react';
import { Outlet } from 'react-router';
import DasboardNav from '../../components/dashboard_nav/DashboardNavBar2';


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container">
                <DasboardNav />
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <Outlet/>
                    </div>
                </div>
            </div>

        )
    }
}

export default Dashboard;