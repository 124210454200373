import React,{useState} from 'react';
import Chart from 'react-apexcharts';
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


 function Bookingtrend () {
   

        return (<>
            <div className='col-md-12 mb-4 position-relative' style={{boxShadow:'0px 0px 5px rgba(0,0,0,0.5)',borderRadius:'15px'}}>
            <div className='row mb-4'>
                <div className='col-md-3 pt-2 pb-2'>
                    <h5 className='text-blue'>Bookings Trend</h5>
                    <p className='text-secondary'>0</p>
                </div>
                <div className='col-md-9 pt-2 pb-2'>
                    <div className='row d-flex justify-content-end'>
                    <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''}  className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="monthly">monthly</option>
                                <option value="hourly">hourly</option>
                                
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''}  className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="7">07 Days</option>
                                <option value="30">30 days</option>
                                <option value="90">Last 3 Months</option>
                                <option value="180">Last 6 Months</option>
                                <option value="360" >Last 1 Year</option>
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''}  className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="7">Tribeca parking</option>
                                <option value="7">Tribeca parking1</option>
                            </select>
                        </div>
                        
                    </div>

                </div>
            </div>
            <div className='row'>
                    <Chart 
                    type="line"
                    width={950}
                    height={400}
                    
                    
                    series={[
                        {
                          name: "series-1",
                          data: [30, 40, 45, 50, 49, 60, 70, 91]
                        }]
                      }
                      
                    options= {{
                        chart:{id: "basic-bar"},
                        yaxis: {
                          categories: [200, 400, 600, 800, 1000, 1200, 1400]
                        }}
                      }
                     
                    />

            </div>
            </div>

            <div className='col-md-12 mb-4 position-relative' style={{boxShadow:'0px 0px 5px rgba(0,0,0,0.5)',borderRadius:'15px'}}>
            <div className='row mb-4'>
                <div className='col-md-3 pt-2 pb-2'>
                    <h5 className='text-blue'>Sales Trend</h5>
                    <p className='text-secondary'>0</p>
                </div>
                <div className='col-md-9 pt-2 pb-2'>
                    <div className='row d-flex justify-content-end'>
                    <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="monthly">monthly</option>
                                <option value="hourly">hourly</option>
                                
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="7">07 Days</option>
                                <option value="30">30 days</option>
                                <option value="90">Last 3 Months</option>
                                <option value="180">Last 6 Months</option>
                                <option value="360" >Last 1 Year</option>
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="7">Tribeca parking</option>
                                <option value="7">Tribeca parking1</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div className='row'>
                    <Chart 
                    type="line"
                    width={950}
                    height={400}
                    
                    
                    series={[
                        {
                          name: "series-1",
                          data: [30, 40, 45, 50, 49, 60, 70, 91]
                        }]
                      }
                      
                    options= {{
                        chart:{id: "basic-bar"},
                        yaxis: {
                          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                        }}
                      }
                     
                    />

            </div>
            </div>
            <div className='col-md-12 mb-4 position-relative' style={{boxShadow:'0px 0px 5px rgba(0,0,0,0.5)',borderRadius:'15px'}}>
            <div className='row mb-4'>
                <div className='col-md-3 pt-2 pb-2'>
                    <h5 className='text-blue'>Cancellation</h5>
                    <p className='text-secondary'>000</p>
                </div>
                <div className='col-md-9 pt-2 pb-2'>
                    <div className='row d-flex justify-content-end'>
                    <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="monthly">monthly</option>
                                <option value="hourly">hourly</option>
                                
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="7">07 Days</option>
                                <option value="30">30 days</option>
                                <option value="90">Last 3 Months</option>
                                <option value="180">Last 6 Months</option>
                                <option value="360" >Last 1 Year</option>
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className='form-control'>
                                <option value="7">Tribeca parking</option>
                                <option value="7">Tribeca parking1</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div className='row'>
                    <Chart 
                    type="line"
                    width={950}
                    height={400}
                    
                    
                    series={[
                        {
                          name: "series-1",
                          data: [30, 40, 45, 50, 49, 60, 70, 91]
                        }]
                      }
                      
                    options= {{
                        chart:{id: "basic-bar"},
                        yaxis: {
                          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                        }}
                      }
                     
                    />

            </div>
            </div>
            </>

        )
    }


export default Bookingtrend