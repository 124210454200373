import React from "react";
import css from '../main.module.css'

const CountryCodes = ({ handleContryCodeChange, value, name, id, cs }) => {

	const _ = (classes) => {
		let s = "";
		classes.map((i) => (s += i + " "));
		return s;
	  };
	return( 
		<select
			id={id}
			name={name}
			className={_(["",cs,css.w_fit_content,])}
			value={value}
			onChange={handleContryCodeChange}>
			
			<option data='US' value='1'>
				{" "}
				+1
			</option>

			<option data='IN' value='91'>
				{" "}
				+91
			</option>

			<option data='AF' value='93'>
				+93
			</option>
			<option data='AL' value='355'>
				{" "}
				+355
			</option>
			<option data='DZ' value='213'>
				+213
			</option>
			<option data='AS' value='1684'>
				+1684
			</option>
			<option data='AD' value='376'>
				+376
			</option>
			<option data='AO' value='244'>
				+244
			</option>
			<option data='AI' value='1264'>
				+1264
			</option>
			<option data='AQ' value='672'>
				+672
			</option>
			<option data='AG' value='1268'>
				{" "}
				+1268
			</option>
			<option data='AR' value='54'>
				{" "}
				+54
			</option>
			<option data='AM' value='374'>
				+374
			</option>
			<option data='AW' value='297'>
				+297
			</option>
			<option data='AU' value='61'>
				{" "}
				+61
			</option>
			<option data='AT' value='43'>
				+43
			</option>
			<option data='AZ' value='994'>
				{" "}
				+994
			</option>
			<option data='BS' value='1242'>
				+1242
			</option>
			<option data='BH' value='973'>
				{" "}
				+973
			</option>
			<option data='BD' value='880'>
				{" "}
				+880
			</option>
			<option data='BB' value='1246'>
				{" "}
				+1246
			</option>
			<option data='BY' value='375'>
				{" "}
				+375
			</option>
			<option data='BE' value='32'>
				{" "}
				+32
			</option>
			<option data='BZ' value='501'>
				{" "}
				+501
			</option>
			<option data='BJ' value='229'>
				{" "}
				+229
			</option>
			<option data='BM' value='1441'>
				{" "}
				+1441
			</option>
			<option data='BT' value='975'>
				{" "}
				+975
			</option>
			<option data='BO' value='591'>
				{" "}
				+591
			</option>
			<option data='BA' value='387'>
				{" "}
				+387
			</option>
			<option data='BW' value='267'>
				{" "}
				+267
			</option>
			<option data='BR' value='55'>
				{" "}
				+55
			</option>
			<option data='IO' value='246'>
				{" "}
				+246
			</option>
			<option data='VG' value='1284'>
				{" "}
				+1284
			</option>
			<option data='BN' value='673'>
				{" "}
				+673
			</option>
			<option data='BG' value='359'>
				{" "}
				+359
			</option>
			<option data='BF' value='226'>
				{" "}
				+226
			</option>
			<option data='BI' value='257'>
				{" "}
				+257
			</option>
			<option data='KH' value='855'>
				{" "}
				+855
			</option>
			<option data='CM' value='237'>
				{" "}
				+237
			</option>

			<option data='CV' value='238'>
				{" "}
				+238
			</option>
			<option data='KY' value='1345'>
				{" "}
				+1345
			</option>
			<option data='CF' value='236'>
				{" "}
				+236
			</option>
			<option data='TD' value='235'>
				{" "}
				+235
			</option>
			<option data='CL' value='56'>
				{" "}
				+56
			</option>
			<option data='CN' value='86'>
				{" "}
				+86
			</option>
			<option data='CX' value='61'>
				{" "}
				+61
			</option>
			<option data='CC' value='61'>
				{" "}
				+61
			</option>
			<option data='CO' value='57'>
				{" "}
				+57
			</option>
			<option data='KM' value='269'>
				{" "}
				+269
			</option>
			<option data='CK' value='682'>
				{" "}
				+682
			</option>
			<option data='CR' value='506'>
				{" "}
				+506
			</option>
			<option data='HR' value='385'>
				{" "}
				+385
			</option>
			<option data='CU' value='53'>
				{" "}
				+53
			</option>
			<option data='CW' value='599'>
				{" "}
				+599
			</option>
			<option data='CY' value='90'>
				{" "}
				+90
			</option>
			<option data='CY' value='357'>
				{" "}
				+357
			</option>
			<option data='CZ' value='420'>
				{" "}
				+420
			</option>
			<option data='CD' value='243'>
				{" "}
				+243
			</option>
			<option data='DK' value='45'>
				{" "}
				+45
			</option>
			<option data='DJ' value='253'>
				{" "}
				+253
			</option>
			<option data='DM' value='1809'>
				{" "}
				+1809
			</option>
			<option data='DO' value='1809'>
				{" "}
				+1809
			</option>
			<option data='TL' value='670'>
				{" "}
				+670
			</option>
			<option data='EC' value='593'>
				{" "}
				+593
			</option>
			<option data='EG' value='20'>
				{" "}
				+20
			</option>
			<option data='SV' value='503'>
				{" "}
				+503
			</option>
			<option data='GQ' value='240'>
				{" "}
				+240
			</option>
			<option data='ER' value='291'>
				{" "}
				+291
			</option>
			<option data='EE' value='372'>
				{" "}
				+372
			</option>
			<option data='ET' value='251'>
				{" "}
				+251
			</option>
			<option data='FK' value='500'>
				{" "}
				+500
			</option>
			<option data='FO' value='298'>
				{" "}
				+298
			</option>
			<option data='FJ' value='679'>
				{" "}
				+679
			</option>
			<option data='FI' value='358'>
				{" "}
				+358
			</option>
			<option data='FR' value='33'>
				{" "}
				+33
			</option>
			<option data='GF' value='594'>
				{" "}
				+594
			</option>
			<option data='PF' value='689'>
				{" "}
				+689
			</option>
			<option data='GA' value='241'>
				{" "}
				+241
			</option>
			<option data='GM' value='220'>
				{" "}
				+220
			</option>
			<option data='GE' value='7880'>
				{" "}
				+7880
			</option>
			<option data='DE' value='49'>
				{" "}
				+49
			</option>
			<option data='GH' value='233'>
				{" "}
				+233
			</option>
			<option data='GI' value='350'>
				{" "}
				+350
			</option>
			<option data='GR' value='30'>
				{" "}
				+30
			</option>
			<option data='GL' value='299'>
				{" "}
				+299
			</option>
			<option data='GD' value='1473'>
				{" "}
				+1473
			</option>
			<option data='GP' value='590'>
				{" "}
				+590
			</option>
			<option data='GU' value='671'>
				{" "}
				+671
			</option>
			<option data='GT' value='502'>
				{" "}
				+502
			</option>
			<option data='GG' value='44'>
				{" "}
				+44
			</option>
			<option data='GN' value='224'>
				{" "}
				+224
			</option>
			<option data='GW' value='245'>
				{" "}
				+245
			</option>
			<option data='GY' value='592'>
				{" "}
				+592
			</option>
			<option data='HT' value='509'>
				{" "}
				+509
			</option>
			<option data='HN' value='504'>
				{" "}
				+504
			</option>
			<option data='HK' value='852'>
				{" "}
				+852
			</option>
			<option data='HU' value='36'>
				{" "}
				+36
			</option>
			<option data='IS' value='354'>
				{" "}
				+354
			</option>

			<option data='ID' value='62'>
				{" "}
				+62
			</option>
			<option data='IR' value='98'>
				{" "}
				+98
			</option>
			<option data='IQ' value='964'>
				{" "}
				+964
			</option>
			<option data='IE' value='353'>
				{" "}
				+353
			</option>
			<option data='IM' value='44'>
				{" "}
				+44
			</option>
			<option data='IL' value='972'>
				{" "}
				+972
			</option>
			<option data='IT' value='39'>
				{" "}
				+39
			</option>
			<option data='CI' value='225'>
				{" "}
				+225
			</option>
			<option data='JM' value='1876'>
				{" "}
				+1876
			</option>
			<option data='JP' value='81'>
				{" "}
				+81
			</option>
			<option data='JE' value='44'>
				{" "}
				+44
			</option>
			<option data='JO' value='962'>
				{" "}
				+962
			</option>
			<option data='KZ' value='7'>
				{" "}
				+7
			</option>
			<option data='KE' value='254'>
				{" "}
				+254
			</option>
			<option data='KI' value='686'>
				{" "}
				+686
			</option>
			<option data='XK' value='383'>
				{" "}
				+383
			</option>
			<option data='KW' value='965'>
				{" "}
				+965
			</option>
			<option data='KG' value='996'>
				{" "}
				+996
			</option>
			<option data='LA' value='856'>
				{" "}
				+856
			</option>
			<option data='LV' value='371'>
				{" "}
				+371
			</option>
			<option data='LB' value='961'>
				{" "}
				+961
			</option>
			<option data='LS' value='266'>
				{" "}
				+266
			</option>
			<option data='LR' value='231'>
				{" "}
				+231
			</option>
			<option data='LY' value='218'>
				{" "}
				+218
			</option>
			<option data='LI' value='417'>
				{" "}
				+417
			</option>
			<option data='LT' value='370'>
				{" "}
				+370
			</option>
			<option data='LU' value='352'>
				{" "}
				+352
			</option>
			<option data='MO' value='853'>
				{" "}
				+853
			</option>
			<option data='MK' value='389'>
				{" "}
				+389
			</option>
			<option data='MG' value='261'>
				{" "}
				+261
			</option>
			<option data='MW' value='265'>
				{" "}
				+265
			</option>
			<option data='MY' value='60'>
				{" "}
				+60
			</option>
			<option data='MV' value='960'>
				{" "}
				+960
			</option>
			<option data='ML' value='223'>
				{" "}
				+223
			</option>
			<option data='MT' value='356'>
				{" "}
				+356
			</option>
			<option data='MH' value='692'>
				{" "}
				+692
			</option>
			<option data='MQ' value='596'>
				{" "}
				+596
			</option>
			<option data='MR' value='222'>
				{" "}
				+222
			</option>
			<option data='YT' value='269'>
				{" "}
				+269
			</option>
			<option data='MX' value='52'>
				{" "}
				+52
			</option>
			<option data='FM' value='691'>
				{" "}
				+691
			</option>
			<option data='MD' value='373'>
				{" "}
				+373
			</option>
			<option data='MC' value='377'>
				{" "}
				+377
			</option>
			<option data='MN' value='976'>
				{" "}
				+976
			</option>
			<option data='ME' value='382'>
				{" "}
				+382
			</option>
			<option data='MS' value='1664'>
				{" "}
				+1664
			</option>
			<option data='MA' value='212'>
				{" "}
				+212
			</option>
			<option data='MZ' value='258'>
				{" "}
				+258
			</option>
			<option data='MN' value='95'>
				{" "}
				+95
			</option>
			<option data='NA' value='264'>
				{" "}
				+264
			</option>
			<option data='NR' value='674'>
				{" "}
				+674
			</option>
			<option data='NP' value='977'>
				{" "}
				+977
			</option>
			<option data='NL' value='31'>
				{" "}
				+31
			</option>
			<option data='AN' value='599'>
				{" "}
				+599
			</option>
			<option data='NC' value='687'>
				{" "}
				+687
			</option>
			<option data='NZ' value='64'>
				{" "}
				+64
			</option>
			<option data='NI' value='505'>
				{" "}
				+505
			</option>
			<option data='NE' value='227'>
				{" "}
				+227
			</option>
			<option data='NG' value='234'>
				{" "}
				+234
			</option>
			<option data='NU' value='683'>
				{" "}
				+683
			</option>
			<option data='KP' value='850'>
				{" "}
				+850
			</option>
			<option data='NF' value='672'>
				{" "}
				+672
			</option>
			<option data='NP' value='670'>
				{" "}
				+670
			</option>
			<option data='NO' value='47'>
				{" "}
				+47
			</option>
			<option data='OM' value='968'>
				{" "}
				+968
			</option>
			<option data='PK' value='92'>
				{" "}
				+92
			</option>
			<option data='PW' value='680'>
				{" "}
				+680
			</option>
			<option data='PS' value='970'>
				{" "}
				+970
			</option>
			<option data='PA' value='507'>
				{" "}
				+507
			</option>
			<option data='PG' value='675'>
				{" "}
				+675
			</option>
			<option data='PY' value='595'>
				{" "}
				+595
			</option>
			<option data='PE' value='51'>
				{" "}
				+51
			</option>
			<option data='PH' value='63'>
				{" "}
				+63
			</option>
			<option data='PN' value='64'>
				{" "}
				+64
			</option>
			<option data='PL' value='48'>
				{" "}
				+48
			</option>
			<option data='PT' value='351'>
				{" "}
				+351
			</option>
			<option data='PR' value='1787'>
				{" "}
				+1787
			</option>
			<option data='QA' value='974'>
				{" "}
				+974
			</option>
			<option data='CG' value='242'>
				{" "}
				+242
			</option>
			<option data='RE' value='262'>
				{" "}
				+262
			</option>
			<option data='RO' value='40'>
				{" "}
				+40
			</option>
			<option data='RU' value='7'>
				{" "}
				+7
			</option>
			<option data='RW' value='250'>
				{" "}
				+250
			</option>
			<option data='BL' value='590'>
				{" "}
				+590
			</option>
			<option data='SH' value='290'>
				{" "}
				+290
			</option>
			<option data='KN' value='1869'>
				{" "}
				+1869
			</option>
			<option data='SC' value='1758'>
				{" "}
				+1758
			</option>
			<option data='SR' value='597'>
				{" "}
				+597
			</option>
			<option data='MF' value='590'>
				+590
			</option>
			<option data='PM' value='508'>
				{" "}
				+508
			</option>
			<option data='VC' value='1784'>
				{" "}
				+1784
			</option>
			<option data='WS' value='685'>
				+685
			</option>
			<option data='SM' value='378'>
				{" "}
				+378
			</option>
			<option data='ST' value='239'>
				+239
			</option>
			<option data='SA' value='966'>
				{" "}
				+966
			</option>
			<option data='SN' value='221'>
				{" "}
				+221
			</option>
			<option data='CS' value='381'>
				{" "}
				+381
			</option>
			<option data='SC' value='248'>
				{" "}
				+248
			</option>
			<option data='SL' value='232'>
				{" "}
				+232
			</option>
			<option data='SG' value='65'>
				{" "}
				+65
			</option>
			<option data='SX' value='1721'>
				+1721
			</option>
			<option data='SK' value='421'>
				{" "}
				+421
			</option>
			<option data='SI' value='386'>
				+386
			</option>
			<option data='SB' value='677'>
				{" "}
				+677
			</option>
			<option data='SO' value='252'>
				{" "}
				+252
			</option>
			<option data='ZA' value='27'>
				+27
			</option>
			<option data='KR' value='82'>
				+82
			</option>
			<option data='SS' value='211'>
				{" "}
				+211
			</option>
			<option data='ES' value='34'>
				+34
			</option>
			<option data='LK' value='94'>
				+94
			</option>
			<option data='SD' value='249'>
				{" "}
				+249
			</option>
			<option data='SR' value='597'>
				{" "}
				+597
			</option>
			<option data='SJ' value='47'>
				+47
			</option>
			<option data='SZ' value='268'>
				+268
			</option>
			<option data='SE' value='46'>
				{" "}
				+46
			</option>
			<option data='CH' value='41'>
				{" "}
				+41
			</option>
			<option data='SY' value='963'>
				+963
			</option>
			<option data='TW' value='886'>
				+886
			</option>
			<option data='TJ' value='992'>
				+992
			</option>
			<option data='TZ' value='255'>
				{" "}
				+255
			</option>
			<option data='TH' value='66'>
				{" "}
				+66
			</option>
			<option data='TG' value='228'>
				{" "}
				+228
			</option>
			<option data='TO' value='676'>
				{" "}
				+676
			</option>
			<option data='TT' value='1868'>
				+1868
			</option>
			<option data='TN' value='216'>
				{" "}
				+216
			</option>
			<option data='TR' value='90'>
				{" "}
				+90
			</option>
			<option data='TM' value='993'>
				{" "}
				+993
			</option>
			<option data='TC' value='1649'>
				+1649
			</option>
			<option data='TV' value='688'>
				{" "}
				+688
			</option>
			<option data='UG' value='256'>
				{" "}
				+256
			</option>
			<option data='UA' value='380'>
				{" "}
				+380
			</option>
			<option data='AE' value='971'>
				{" "}
				+971
			</option>
			<option data='GB' value='44'>
				{" "}
				+44
			</option>
			<option data='CA' value='1'>
				+1
			</option>
			<option data='UY' value='598'>
				{" "}
				+598
			</option>
			<option data='UZ' value='998'>
				{" "}
				+998
			</option>
			<option data='VU' value='678'>
				{" "}
				+678
			</option>
			<option data='VA' value='379'>
				{" "}
				+379
			</option>
			<option data='VE' value='58'>
				{" "}
				+58
			</option>
			<option data='VN' value='84'>
				{" "}
				+84
			</option>
			<option data='WF' value='681'>
				{" "}
				+681
			</option>
			<option data='YE' value='969'>
				{" "}
				+969
			</option>
			<option data='YE' value='967'>
				+967
			</option>
			<option data='ZM' value='260'>
				{" "}
				+260
			</option>
			<option data='ZW' value='263'>
				{" "}
				+263
			</option>
		</select>
    );
};

export default CountryCodes;