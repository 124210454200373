export const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

export const convertTimeToHM = (timeStr) => {
  // Split the time string into parts
  const [hours, minutes, seconds] = timeStr?.split(":").map(Number);

  // Calculate total minutes
  const totalMinutes = hours * 60 + minutes + Math.floor(seconds / 60);

  // Convert total minutes into hours and minutes
  const formattedHours = Math.floor(totalMinutes / 60);
  const formattedMinutes = totalMinutes % 60;

  // Check if both hours and minutes are 0
  if (timeStr == 0) {
    return "0h";
  }

  // Return the formatted string
  return `${formattedHours}h ${formattedMinutes}m`;
};

export const formatDateString = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

export const getTotalTime = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const diffMs = end - start; // Difference in milliseconds

  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  let result = "";

  if (diffDays > 0) result += `${diffDays} day${diffDays > 1 ? "s" : ""}`;
  if (diffHours > 0) {
    if (result) result += ", ";
    result += `${diffHours} hour${diffHours > 1 ? "s" : ""}`;
  }
  if (diffMinutes > 0) {
    if (result) result += ", ";
    result += `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""}`;
  }

  return result || "0 minutes";
};
