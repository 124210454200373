
import css from './../../../main.module.css'
import css2 from "../../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import css3 from "../../../components/Profile/ProfilePage.module.css";
import React, { useState, } from "react";
import { Modal } from "reactstrap";
import visibleIcon from "../../../assets/Icons/Icon awesome-eye.svg"
import emptyProfileIcon from "./../../../assets/Icons/Icon feather-user.svg";
import iconstar from "../../../assets/Icons/Icon awesome-star-1.svg"

import "./../../../styles.css"

import './../popup.css'

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};




function AddReviewPopup(props) {

    const [imageLoading, setImageLoading] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);

    const [state, setState] = useState({
        profilePic: null,
        country: "",
        state: ""
    })


    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div className='container p-5'>
                    <div className="d-flex justify-content-center">
                        <h1 className={_(["mb-0", css.font_18, css.font1])}>Add A Review</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p className={_(["mb-0", css.font_18, css.font2])}>Let everyone know what your user's thinks</p>
                    </div>
                    <div className=" col-md-12 d-flex justify-content-center">
                        <img alt="add review"     src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                        <img alt="add review"     src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                        <img alt="add review"     src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                        <img alt="add review"     src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                        <img alt="add review"     src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                    </div>
                    <div className='row mt-4'>
                        <div className="col-md-8">
                            <div className={_(["", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_([" ", css.font1])}>
                                        Name
                                    </label>


                                    <input
                                        type='input'
                                        className={_(['input_box', css.input_box_style, css.font2])}
                                        placeholder='First Name'
                                        name='firstName'
                                        //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                                        //}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={_([" ", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_([" ", css.font1])}>
                                        Email
                                    </label>


                                    <input
                                        type='input'
                                        className={_(['input_box', css.input_box_style, css.font2])}
                                        placeholder='Email address'
                                        name='email'
                                        //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                                        //}}
                                        required
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="d-flex flex-row w-100 align-items-center ">
                                {imageLoading ? (
                                    <div className='spinner-border text-muted mt-4 mb-4'>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className="col-md-12 ml-5">
                                            <img
                                                src={state.profile_pic ? (state.profile_preview ? state.profile_preview : state.profile_pic) : (emptyProfileIcon)}
                                                alt="loading..."
                                                className={_(["", css3.profile_img3])}>

                                            </img>
                                        </div>
                                        <div className='col-md-12 d-flex justify-content-center'>
                                            <label htmlFor='uploadImage' className={_(["submitbtn p-2 mt-2",])}>
                                                Choose File
                                                <input type="file" name='' id='uploadImage' accept='image/*' hidden />
                                            </label>
                                        </div>



                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={_(["", css2.input_font, css.font_18])}>
                        <div className={_(["", css2.column_width3])}>
                            <label className={_([" ", css.font1])}>
                                Review
                            </label>


                            <textarea rows="4" cols="50"
                                className={_(['input_box', css.input_box_style, css.font2])}
                                placeholder='Add Review'
                                name='email'
                                //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                                //}}
                                required
                            >
                            </textarea>

                        </div>
                    </div>
                    <div className="d-flex justify-content-start">
                        <img alt="add review"     src={visibleIcon} className={_(["ml-3",])}></img>&nbsp;
                        <h6 className={_(['mt-2', css.font1])}>Visible on Website</h6>
                    </div>

                    <div className={_(["d-flex justify-content-around mx-5 mt-5", css.font1])}
                    >

                        <button
                            onClick={props.toggle}
                            // type='submit'
                            className={_(["btn btn_B d-flex justify-content-center align-items-center w-25", css2.inside_button, css.inside_button2, css.font_18])}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                            }}
                            // type='submit'
                            className={_(["btn btn_B d-flex justify-content-center align-items-center w-25", css2.inside_button, css.inside_button1, css.font_18])}
                        >
                            Publish Now
                        </button>
                    </div>





                </div>

            </Modal>

        </>
    );
}

export default AddReviewPopup;