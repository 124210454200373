import css from "./DashboardNavBar.module.css";
import mainCss from "./../../main.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import parkingSpotIcon from "./../../assets/parking-bud-provider-assets/statics/Mask Group 133.svg";
import parkingSpotIconBlue from "./../../assets/parking-bud-provider-assets/statics/Mask Group 133-blue.svg";
import bookingIcon from "./../../assets/parking-bud-provider-assets/statics/Group 28.svg";
import bookingIconBlue from "./../../assets/parking-bud-provider-assets/statics/Group 28-blue.svg";
import UserIcon from "./../../assets/parking-bud-provider-assets/statics/Group 27298.svg";
import UserIconBlue from "./../../assets/parking-bud-provider-assets/statics/Group 27298-blue.svg";
import notificationIcon from "./../../assets/parking-bud-provider-assets/statics/Icon ionic-md-notifications-outline.svg";
import notificationIconBlue from "./../../assets/parking-bud-provider-assets/statics/Icon ionic-md-notifications-outline-blue.svg";
import svgIcon from "./../../assets/svgs/Component.svg";
import Logo from "./../../assets/parking-bud-provider-assets/rent-your-parking/add-parking.png";
import emptyProfileIcon from "./../../assets/Icons/Icon feather-user.svg";
/* popups */
import LogoutPopUp from "../popups/LogoutPopUp";
import axiosInstance from "../../axios";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const DasboardNav = () => {
  const location = useLocation();
  const storedValue = localStorage.getItem("selected");
  const [selected, setSelected] = useState(
    storedValue ? storedValue : "ParkingSpots"
  );
  const [profileImage, setProfileImage] = useState(null);
  const [userdata, setuserdata] = useState({});
  const [Logout, setLogout] = useState(false);
  const [indicator, setindicator] = useState(0);
  const [idstate, setidstate] = useState();

  const navigate = useNavigate();

  const handleLogOut = (message) => {
    if (message === "Unauthorized") {
      localStorage.removeItem("selected");
      navigate("/");
    }
  };
  const LogoutToggle = () => {
    if (Logout) {
      setLogout(false);
    } else {
      setLogout(true);
    }
  };

  const handleClick = (name) => {
    setSelected(name);
  };

  async function makerequest() {
    await axiosInstance
      .get("admin_dash/edit_admin_profile")
      .then((response) => {
        setProfileImage(response.data.response.user.profile_pic);
        setuserdata(response.data.response.user);
        setidstate(response.data.response.user.id);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          handleLogOut(err.response.statusText);
        }
        console.log(err.response.data);
      });
  }

  useEffect(() => {
    makerequest();
    localStorage.setItem("selected", selected);
  }, [selected]);

  useEffect(() => {
    var token =
      (localStorage.getItem("user__token")
        ? localStorage.getItem("user__token")
        : sessionStorage.getItem("user__token")) || "";
    let io = new WebSocket(
      process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET +
        "ws/admin_notifications/" +
        idstate +
        "/"
    );

    io.onopen = (e) => {
      if (io.readyState === WebSocket.OPEN) {
        io.send(JSON.stringify({ token: token }));
      }
      //io.send(JSON.stringify({'token':token}))
    };
    io.onclose = (e) => {};
    io.onmessage = (e) => {
      let data = JSON.parse(e.data);
      setindicator(data.notifications_unread);
    };
    return () => {
      io.close();
    };
  }, [idstate]);

  // Check if the current path is /Dashboard/ViewParkingSpot
  const hideMiddleSection = location.pathname === "/Dashboard/ViewParkingSpot";

  return (
    <>
      <nav
        className={_([
          "navbar navbar-expand-lg navbar-light fixed-top",
          css.nav,
        ])}
      >
        <Link
          className={_(["navbar-brand", css.nav_header])}
          to="/Dashboard/ParkingSpots"
          onClick={() => {
            setSelected("ParkingSpots");
          }}
        >
          <img src={Logo} className={_(["", css.logo_img])}></img>
          <div className={_(["ml-3", css.logo])}>Parking Bud</div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle-navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={_([
            "collapse navbar-collapse",
            css.nav_items,
            mainCss.font_24,
          ])}
          id="navbarSupportedContent"
        >
          <ul className={_(["navbar-nav w-100"])}>
            {!hideMiddleSection && (
              <div className={_(["w-100 d-flex"])}>
                <li id="ParkingSpots" className={_(["nav-item"])}>
                  <Link
                    name="ParkingSpots"
                    to="/Dashboard/ParkingSpots"
                    className={
                      selected === "ParkingSpots"
                        ? _(["", css.item, css.selected_focused])
                        : _(["", css.item])
                    }
                    onClick={() => handleClick("ParkingSpots")}
                  >
                    <label className={_([" ", css.label])}>Parking Spots</label>
                    <div
                      className={_([
                        " ",
                        css.rounded__Icon,
                        css.display_on_dropdown,
                      ])}
                    >
                      <img
                        src={svgIcon}
                        className={_(["", css.background_svg])}
                      ></img>
                      <div className={_(["", css.img_div])}>
                        <img
                          className={_(["", css.img_icon])}
                          src={parkingSpotIcon}
                        ></img>
                        <img
                          className={_(["", css.img_icon_blue])}
                          src={parkingSpotIconBlue}
                        ></img>
                      </div>
                    </div>
                  </Link>
                </li>
                <li id="Bookings" className="nav-item">
                  <Link
                    name="Bookings"
                    to="/Dashboard/Bookings"
                    className={
                      selected === "Bookings"
                        ? _(["", css.item, css.selected_focused])
                        : _(["", css.item])
                    }
                    onClick={() => handleClick("Bookings")}
                  >
                    <label className={_([" ", css.label])}>Booking</label>
                    <div
                      className={_([
                        " ",
                        css.rounded__Icon,
                        css.display_on_dropdown,
                      ])}
                    >
                      <img
                        src={svgIcon}
                        className={_(["", css.background_svg])}
                      ></img>
                      <div className={_(["", css.img_div])}>
                        <img
                          className={_(["", css.img_icon])}
                          src={bookingIcon}
                        ></img>
                        <img
                          className={_(["", css.img_icon_blue])}
                          src={bookingIconBlue}
                        ></img>
                      </div>
                    </div>
                  </Link>
                </li>
                <li id="Users" className="nav-item">
                  <Link
                    name="Users"
                    to="/Dashboard/Users"
                    className={
                      selected === "Users"
                        ? _(["", css.item, css.selected_focused])
                        : _(["", css.item])
                    }
                    onClick={() => handleClick("Users")}
                  >
                    <label className={_([" ", css.label])}>Users</label>
                    <div
                      className={_([
                        " ",
                        css.rounded__Icon,
                        css.display_on_dropdown,
                      ])}
                    >
                      <img
                        src={svgIcon}
                        className={_(["", css.background_svg])}
                      ></img>
                      <div className={_(["", css.img_div])}>
                        <img
                          className={_(["", css.img_icon])}
                          src={UserIcon}
                        ></img>
                        <img
                          className={_(["", css.img_icon_blue])}
                          src={UserIconBlue}
                        ></img>
                      </div>
                    </div>
                  </Link>
                </li>
                <li id="Notification" className="nav-item">
                  <Link
                    name="Notification"
                    to="/Dashboard/Notification"
                    className={
                      selected === "Notification"
                        ? _(["", css.item, css.selected_focused])
                        : _(["", css.item])
                    }
                    onClick={() => handleClick("Notification")}
                  >
                    <label className={_([" ", css.label])}>
                      Notifications{" "}
                      {indicator > 0 ? (
                        <label
                          className={_(["", css.notifydot, mainCss.font_14])}
                        ></label>
                      ) : null}
                    </label>
                    <div
                      className={_([
                        " ",
                        css.rounded__Icon,
                        css.display_on_dropdown,
                      ])}
                    >
                      <img
                        src={svgIcon}
                        className={_(["", css.background_svg])}
                      ></img>
                      <div className={_(["", css.img_div])}>
                        <img
                          className={_(["", css.img_icon])}
                          src={notificationIcon}
                        ></img>
                        <img
                          className={_(["", css.img_icon_blue])}
                          src={notificationIconBlue}
                        ></img>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
            )}
            <li id="" className={_(["nav-item", css.display_none_on_dropdown])}>
              <Link
                name="profile"
                to="/Dashboard/MyProfile/MyAccount"
                className={
                  selected === "profile"
                    ? _(["", css.item, css.selected_focused])
                    : _(["", css.item])
                }
              >
                <label className={_([" ", css.label])}>Profile</label>
              </Link>
            </li>
            <li id="" className={_(["nav-item", css.display_none_on_dropdown])}>
              <Link
                to="#"
                onClick={LogoutToggle}
                className={
                  selected === "logout"
                    ? _(["", css.item, css.selected_focused])
                    : _(["", css.item])
                }
              >
                <label className={_([" ", css.label])}>Logout</label>
              </Link>
            </li>

            <img
              src={svgIcon}
              id="bubble"
              className={_([
                "",
                css.svg_img,
                css.animation,
                selected === "" ? css.none_opacity : "",
                css.display_on_dropdown,
              ])}
            ></img>
          </ul>
        </div>

        {/* nav-right dropdown */}

        <div
          className={_([
            "navbar-brand",
            css.nav_header,
            mainCss.font_18,
            css.display_on_dropdown,
          ])}
        >
          {/* <Link to="/Dashboard/MyProfile/MyAccount" className={_(["mr-3 d-flex flex-row justify-content-between align-items-center border", css.profile_img ])}> */}
          <div
            className={_([
              "mr-3 d-flex flex-row justify-content-between align-items-center border",
              css.profile_img,
              mainCss.cursor_pointer,
            ])}
            // data-toggle="dropdown"
            onClick={() => {
              setSelected("");
              navigate('/Dashboard/details')
            }}
          >
            <img
              src={profileImage != null ? profileImage : emptyProfileIcon}
            ></img>
          </div>
          {/* </Link> */}

          <div className="dropdown">
            <Link
              className="dropdown-toggle d-flex flex-row justify-content-between align-items-center"
              to="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              // onClick={() => {
              //   setSelected("");
              // }}
            ></Link>
            <div
              className={_([" dropdown-menu", css.prfiledropdown])}
              aria-labelledby="navbarDropdown"
            >
              {/* inside dropdown */}
              <div className={_(["dropdown-item", css.dropdown_item])}>
                <Link to={"/Dashboard/MyProfile/MyAccount"}>
                  <div className="mt-3">
                    <div
                      className={_([
                        " ",
                        profileImage != null ? css.dropdownprofile : null,
                      ])}
                    >
                      <img
                        src={
                          profileImage != null ? profileImage : emptyProfileIcon
                        }
                      ></img>
                    </div>
                  </div>
                </Link>
                <p className={_(["mt-0 mb-0", mainCss.font_24, css.headding])}>
                  {userdata.first_name} {userdata.last_name}
                </p>
                <p
                  className={_([
                    "mt-0 mb-0",
                    mainCss.font2,
                    css.headding,
                    css.textwrap,
                  ])}
                >
                  {userdata.email}
                </p>

                <button
                  onClick={LogoutToggle}
                  // type='submit'
                  className={_(["dropdown-item", css.logoutbtn])}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
        <LogoutPopUp isOpen={Logout} toggle={LogoutToggle} />
      </nav>
    </>
  );
};

export default DasboardNav;
