import css2 from '../../components/common.module.css'
import css from './../../main.module.css'
import React, { useState,useEffect } from "react";
//import { useNavigate } from "react-router-dom";

import ReactCountryFlag from "react-country-flag";
import { Modal } from "reactstrap";
import FileImage from '../../assets/file-upload.png';

import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "./../../config/ImageFileConfig"
import axiosInstance from '../../axios';
import validator from "validator";


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function AddAmenities(props) {

    //const history = useNavigate();
    const [imagefilename,setimagefilename]=useState('')
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);
    const[state,setstate]=useState({name:'',icon_img:'',active_status:false})
    
    const[errstate,seterrstate]=useState({name:'',icon_img:null,active_status:false})


    const handleImage = async(e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            setProfilePhoto(null);

            if (file) {
                setImageLoading(true);
                //compressing image
                // console.log("Options", options);
                const compressedFile = await imageCompression(file, options);
                // console.log(
                //     `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
                // );
                if (compressedFile.size / 1024 / 1024 > 2) {
                    // throw {response:{data:"Image size is too large"}};
                    throw "Image size is too large";
                }

                const fileEE = new File([compressedFile], file.name);
                setProfilePhoto(fileEE);

                let base64Image = await new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (err) => {
                        reject(err);
                    };
                });
                console.log(file)

                if (base64Image !== undefined) {
                    setstate({
                        ...state,
                        icon_img: file,
                        profile_preview: base64Image,
                    });
                    setImageLoading(false);
                }
                

            } else {
                // setImageError (false)
                setstate({
                    ...state,
                    icon_img: null,
                });
                setProfilePhoto(null);
                setImageLoading(false);
            }
        } catch (err) {
            console.log("Errror in image upload ", err);
        }
    };

    

    const onChange=(e)=>{
        console.log(e.target.name,e.target.value)
        setstate({...state,[e.target.name]:e.target.value})
        if(e.target.checked){
            setcolor('')
        }
        else{
        setcolor('grey')}

    }

    const [color,setcolor]=useState('grey')
   
    
    const amentiescreated=async()=>{
        console.log(state)
        let formData = new FormData();

        formData.append('name',state.name )
        formData.append('icon_img',state.icon_img )
        formData.append('active_status',state.active_status )

        await axiosInstance.post(
            "amenties_info",formData
        ).then((response) => {
            props.toggle()
            alert('Successfully created')
            setstate({name:'',icon_img:'',active_status:false})
            
            

        }).catch((err) => {
            props.toggle()
            alert('Try again')
            console.log(err.response.request.status);

        })
    }



    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_(["mt-3 mb-4", css2.center_container])}
                >
                    <div className={_(["col-md-12 mt-3 mb-4", css.font1])}>
                       <h5 className="text-center">Create An Amenity</h5>
                   </div>
                    <div className="col-md-12 mt-4">
                        <div className="row">
                            <div className="col-md-8 form-group">
                                <label for="title" className={_(["", css.font1,css.font_16])}>Name</label>
                                <input type="input" id="title" name="name" className={_(['form-control', css.input_box_style_sm, css.font2])} value={state.name} onChange={onChange}/>
                                {errstate.name &&
                                <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{errstate.name}</span>

                            </div>
                        }
                            </div>
                            <div className="col-md-4 form-group d-flex flex-column justify-content-center align-items-center">
                                {imageLoading?<div className='spinner-border text-muted mt-4 mb-4'>
                                                </div>:<><p className="mb-0 parking-file-container">
                                    <img src={state.icon_img !== '' ? state.profile_preview : FileImage} alt="Upload file" />
                                    <input type="file" onChange={handleImage} className="parking-file-upload" name="" />
                                </p>
                                <p className="text-center mb-0 color-blue font-family-bold">
                                    Drag and drop or <span className="color1">browser</span> to choose a icon
                                    <input type="file" onChange={handleImage} className="parking-file-upload" name=''  />
                                </p></>}
                            </div>
                            <div className={_(["col-md-12 d-flex justify-content-center mb-4"])}>
                                <p className={_(["row",css.activelabel])}>Active
                                    <input className={_(["", css.react_switch_checkbox])} id={`react-switch-new`} type="checkbox" name='active_status' value={color==='grey'?true:false} onClick={onChange}/>
                                    <label className={_(["", css.react_switch_label])} htmlFor={`react-switch-new`} style={{ background: color }}>
                                        <span className={_(["", css.react_switch_button])}/>
                                    </label>
                                </p> 
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                                <button className={_(['',css.cancel])} onClick={props.toggle}>Cancel</button>
                                <button className={_(['',css.submit])} onClick={amentiescreated}>Submit</button>
                            </div>

                 </div>

                </div>
                    
                </div>


            </Modal >

        </>
    );
}

export default AddAmenities;

