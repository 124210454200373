import React, { useEffect, useState } from "react";
import css from "./../../main.module.css";
import css2 from "./Bookings.module.css";
import printIcon from "./../../assets/Icons/icons8-print-96.svg";
import Logo from "./../../assets/Icons/Group 23830.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import axiosInstance from "../../axios";

const _ = (classes) => classes.join(" ");

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function formatDateTime(dateString) {
  const date = new Date(dateString);
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${dateFormatter.format(date)} <br> ${timeFormatter.format(date)}`;
}

function formatTime(totalTime) {
  const [hours, minutes, seconds] = totalTime.split(":");
  const formattedHours = `${hours.padStart(2, "0")}h`;
  const formattedMinutes = `${minutes.padStart(2, "0")}m`;
  const formattedSeconds =
    seconds !== "00" ? `${seconds.padStart(2, "0")}s` : "";
  return formattedSeconds
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedHours}:${formattedMinutes}`;
}

function InvoiceRow({ label, value }) {
  return (
    <div
      className={_(["d-flex flex-row justify-content-end align-items-center"])}
    >
      <p className={_(["mt-2 mb-0", css.font_24, css.font1])}>{label} :</p>
      <p className={_(["mt-2 ml-2 mb-0", css.font_24, css.font2])}>${value}</p>
    </div>
  );
}

function ViewInvoice() {
  const location = useLocation();
  const navigate = useNavigate();

  const bookingData = location.state?.bookingData;

  const [searchParams] = useSearchParams();
  const query = searchParams.get("search");
  const [invoicePrint, setInvoicePrint] = useState({});
  const [loading, setLoading] = useState(false);

  const formattedDate = bookingData ? formatDateTime(bookingData.checkin) : "";
  const formattedCheckout = bookingData
    ? formatDateTime(bookingData.checkout)
    : "";
  const formattedTime = bookingData?.total_time
    ? formatTime(bookingData.total_time)
    : "00h:00m";

  const handlePrint = () => {
    const input = document.getElementById("invoice-container");

    // Temporarily hide the elements you don't want to include in the PDF
    const elementsToHide = document.querySelectorAll(".hide-in-pdf");
    elementsToHide.forEach((el) => (el.style.display = "none"));

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add additional pages if needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");

      // Restore the visibility of the elements
      elementsToHide.forEach((el) => (el.style.display = ""));
    });
  };

  const currentDate = formatDate(new Date());

  const userBooking = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/provider_dash/print__invoice/${query}`
      );
      const result = response?.data?.response;
      setInvoicePrint(result);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching booking data:", error);
    }
  };

  useEffect(() => {
    userBooking();
  }, []);

  return (
    <div
      id="invoice-container"
      className={_([
        "d-flex flex-column align-items-center",
        css2.container_custom,
        css.shade_background,
      ])}
    >
      <div
        className={_([
          "d-flex flex-column align-items-center",
          css2.center_container,
        ])}
      >
        <div className={_(["d-flex flex-row justify-content-between w-100"])}>
          <label className={_([" hide-in-pdf", css.font_32, css.font1])}>
            Invoice
          </label>
          <button
            className={_([
              " hide-in-pdf",
              css.inside_button1,
              css2.print_button,
            ])}
            onClick={handlePrint}
          >
            <img src={printIcon} alt="Print" className={_(["mr-1"])} />
            Print
          </button>
        </div>
        <p
          className={_([
            "mt-2 align-self-start hide-in-pdf",
            css.font_18,
            css.font2,
          ])}
        >
          <span  className={_(["", css.cursor_pointer])} onClick={() => navigate(`/Dashboard/Bookings`)}>Bookings</span>{" "}
          &gt;{" "}
          <span  className={_(["", css.cursor_pointer])}
            onClick={() => navigate(`/Dashboard/ViewBooking?search=${query}`)}
          >
            Tribeca Parking : #{bookingData?.booking_pass_code}
          </span>{" "}
          &gt; Print parking
        </p>

        <div className={_(["w-100 d-flex flex-column", css2.invoice])}>
          <div className={_(["w-100", css.nav_header, css.align_left])}>
            <img src={Logo} alt="Logo" className={_(["", css.logo_img])} />
            <div className={_(["ml-3", css.logo])}>Parking Bud</div>
          </div>
          <p className={_(["mt-2 mb-0", css.font_18, css.font4])}>
            {bookingData?.parking?.state}, {bookingData?.parking?.country}
          </p>
          <p className={_(["mt-1 mb-0", css.font_18, css.font2])}>
            (+{bookingData?.parking?.parking_country_code}){" "}
            {bookingData?.parking?.parking_phone_number}
          </p>

          <hr className={_(["", css.horizontal_line])} />

          <div className={_(["d-flex flex-row justify-content-between w-100"])}>
            <p className={_(["mt-2 mb-0", css.font_24, css.font4])}>
              #{bookingData?.booking_code}
            </p>
            <div className={_(["d-flex flex-row align-items-center"])}>
              <p className={_(["mt-2 mb-0", css.font_24, css.font1])}>Date :</p>
              <p className={_(["mt-2 ml-2 mb-0", css.font_24, css.font2])}>
                {currentDate}
              </p>
            </div>
          </div>

          <div className={_(["", css2.invoice_to])}>
            <p className={_(["mt-5 mb-0", css.font_24, css.font3])}>
              Invoice to
            </p>
            <p className={_(["mt-2 mb-0", css.font_18, css.font1])}>
              {bookingData?.booked_by?.first_name +
                " " +
                bookingData?.booked_by?.last_name}
            </p>
            <p className={_(["mb-0", css.font_18, css.font2, css2.address])}>
              {bookingData?.parking?.parking_spot_address1}
            </p>
            <p className={_(["mb-0", css.font_18, css.font2, css2.address])}>
              {bookingData?.parking?.city}, {bookingData?.parking?.state}{" "}
              {bookingData?.parking?.zip_code}
            </p>
          </div>

          <div className={_(["mt-3", css2.table])}>
            <table
              className={_(["w-100", css.font_18, css.font2, css2.search_list])}
            >
              <thead>
                <tr className={_(["", css2.search_header])}>
                  <th>#</th>
                  <th>Parking Name (Booking ID)</th>
                  <th>Type</th>
                  <th>Arrival</th>
                  <th>Checkout</th>
                  <th>Total Time</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className={_([""])}>
                  <td>1</td>
                  <td>
                    <span className={_(["", css.font4])}>
                      {bookingData?.parking_name}{" "}
                    </span>{" "}
                    <br /> #{bookingData?.booking_pass_code}
                  </td>
                  <td className={_(["", css.font4])}>
                    {bookingData?.booking_type}
                  </td>
                  <td
                    className={_(["", css.font4])}
                    dangerouslySetInnerHTML={{ __html: formattedDate }}
                  />
                  <td
                    className={_(["", css.font4])}
                    dangerouslySetInnerHTML={{ __html: formattedCheckout }}
                  />
                  <td className={_(["", css.font4])}>{formattedTime}</td>
                  <td className={_(["", css.font4])}>${bookingData?.amount}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={_(["align-self-end", css2.invoice_summary])}>
            <InvoiceRow
              label="Subtotal"
              value={invoicePrint?.invoice?.[0]?.subtotal}
            />
            <InvoiceRow
              label="Service Charge"
              value={bookingData?.service_charge}
            />
            <InvoiceRow
              label="Discount"
              value={invoicePrint?.invoice?.[0]?.discount}
            />
            <InvoiceRow label="Tax" value={invoicePrint?.invoice?.[0]?.tax} />
            <InvoiceRow
              label="Tip"
              value={invoicePrint?.invoice?.[0]?.tip || "0"}
            />
            <InvoiceRow
              label="Additional Charges"
              value={invoicePrint?.invoice?.[0]?.additional_charges || "0"}
            />
          </div>

          <hr className={_(["", css.horizontal_line])} />

          <p className={_(["mb-2 align-self-end", css.font_32, css.font3])}>
            ${invoicePrint?.invoice?.[0]?.total || "0"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ViewInvoice;
