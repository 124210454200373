import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavLink,  } from "react-router-dom";
/* css */
import './providerdetails.css'
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import { useSelector } from 'react-redux';


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const ProviderDetail = () => {

    
    const { data } = useSelector((state) => state.provider);
    const userData = data?.data?.response?.provider_data
    const user_type = userData?.user_type
    console.log(user_type,'user_typebukvytvg')
console.log("user_type",data)
    const history=useNavigate()

    const [activeLink, setActiveLink] = useState("/Dashboard/ProviderDetail/Profile");

    const handleLinkClick = (to) => {
        setActiveLink(to);
    };
    
    const [state, setState] = useState({
        first_name:'',
        last_name:'',
    })

    // const links = [
    //     { to: "/Dashboard/ProviderDetail/Profile", text: "Profile" },
    //     { to: "/Dashboard/ProviderDetail/Documents", text: "Documents" },
    //     { to: "/Dashboard/ProviderDetail/ParkingSpot", text: "Parking Spots" },
    //     { to: "/Dashboard/ProviderDetail/Bookings", text: "Bookings" },
    //     { to: "/Dashboard/ProviderDetail/Coupons", text: "Coupons" },
    //     { to: "/Dashboard/ProviderDetail/Earnings", text: "Earnings" },
    //     { to: "/Dashboard/ProviderDetail/Inquiries", text: "Inquiries" }
    // ];

    const businessProviderLinks = [
        { to: "/Dashboard/ProviderDetail/Profile", text: "Profile" },
        { to: "/Dashboard/ProviderDetail/Documents", text: "Documents" },
        { to: "/Dashboard/ProviderDetail/ParkingSpot", text: "Parking Spots" },
        { to: "/Dashboard/ProviderDetail/Bookings", text: "Bookings" },
        { to: "/Dashboard/ProviderDetail/Coupons", text: "Coupons" },
        { to: "/Dashboard/ProviderDetail/Earnings", text: "Earnings" },
        { to: "/Dashboard/ProviderDetail/Inquiries", text: "Inquiries" }
    ];

    const consumerLinks = [
        { to: "/Dashboard/ProviderDetail/Profile", text: "Profile" },
        { to: "/Dashboard/ProviderDetail/Bookings", text: "Bookings" },
        { to: "/Dashboard/ProviderDetail/Inquiries", text: "Inquiries" }
    ];

    // Determine the links to display based on the user_type //provider = users
    const links = user_type === 'business_provider'
        ? businessProviderLinks
        : consumerLinks;

    return (
        <div className="container mt-5">
            <div className="row">
                <div className='col-md-12 d-flex'>
                    <p className={_(["",mainCss.cursor_pointer])}  onClick={() =>
                    history("/Dashboard/Users")}
                    >Users</p><p className='ml-1 mr-1'>&gt;</p><p className=''style={{color:'#233874',fontWeight:'500'}}>Park Plus</p>
                </div>
                <div className={_(["col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 p-3 mb-5 bg-body rounded-3 sticky-sidebar",])}>
                    <div className="w-100">
                        <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                            {links.map((link, index) => (
                                <li key={index}>
                                    <NavLink 
                                        to={link.to} 
                                        className={_(["mb-2 px-2 py-2", css2.link_text, activeLink === link.to ? css2.active_link : ""])}
                                        onClick={() => handleLinkClick(link.to)}
                                    >
                                        <p>{link.text}</p>
                                        <p>{'>'}</p>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col-md-9 mx-4">
                    <Outlet/>
                </div>
            </div>
        </div>

    )


}


export default ProviderDetail;