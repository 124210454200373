import React, { useState, useEffect,useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css";
import mainCss from "../../main.module.css";
import './myprofile.css';

/* icons */
import emailIcon from "../../assets/Icons/Icon material-email.svg";
import Iconuser from "../../assets/Icons/Icon-user.svg";
import phoneIcon from "../../assets/Icons/Icon awesome-phone-alt.svg"
import pointerIcon from "../../assets/Icons/Icon material-location-on.svg";
import timezoneIcon from "../../assets/Icons/Mask Group 145.svg";
import emptyProfileIcon from "../../assets/Icons/Icon feather-user.svg";
//
import axiosInstance from "./../../axios";
import { DATA_PROCESSOR } from "./../../Tools";
import Loader from '../Reusable Popup/Loader';
import NotificationContext from '../notification/NotificationContext';
import BarLoaders from '../Reusable Popup/Barloaders';





const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const MyAccount = () => {
    const history = useNavigate();
    const [changePassword, setChangePassword] = useState(false);
    const [logout, setLogout] = useState(false);
    const [imageLoading, setImageLoading] = useState(null);
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    
    const profiledata=useContext(NotificationContext)



    const ChangedPasswordToggle = () => {
        if (changePassword) {
            setChangePassword(false)
        }
        else {
            setChangePassword(true)
        }
    }
    // profile

    const state = profiledata === undefined || profiledata.profile.first_name===''? [] : profiledata.profile
    
   console.log(state)

    const [stateError, setStateError] = useState({
        profile_pic: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        country: null,
        country_code: null,
        city: null,
        state: null,
        zip_code: null,
        timezone: null,
        website_url: null,
        user_type: null,
    })

    // async function makerequest() {
    //     let data = ""
    //     await axiosInstance.get(
    //         "admin_dash/edit_admin_profile"
    //     ).then((response) => {
    //         //let formData = new FormData();
    //         //console.log(this.confirmPassword);


    //         //console.log(response.data);
    //         data = response.data.response.user;
    //         DATA_PROCESSOR(data, state, setState)


    //         // console.log(state.email)

    //     }).catch((err) => {
    //         console.log(err.response.data);

    //     })

    // }

    // useEffect(() => {
    //     makerequest()

    // }, [])

    function logoutpage() {
        sessionStorage.removeItem('__admin__token&');
        localStorage.removeItem('__admin__token&');
        history("/", { replace: true });
        window.location.reload();


    }




    return (

        <div className={_(["col-md-12", mainCss.font2, mainCss.font_18])}>
            <div className="col-md-12 px-4 pt-4 d-flex justify-content-end">
                <Link className='linkstyle' to="/Dashboard/MyAccount/Edit">Edit</Link>
            </div>
            <div className='row p-3 mb-5'>
                <div className='col-md-3 col-sm-5' >
                    <div className=''>
                        {imageLoading ? (
                            <div className='spinner-border text-muted mt-4 mb-4'>
                                <Loader color={color} loading={loading} />
                            </div>
                        ) : (
                            <img className={_(["", css2.profile_img1])}
                                src={state.profile_pic ? state.profile_pic : emptyProfileIcon }
                                alt='avatar'
                            />
                        )}
                    </div>

                </div>
                <div className={_(["col-md-8", css2.account_inside_details])}>
                    <div>
                        <label className='headtitle'><strong>{state.length!==0?state.first_name + " " + state.last_name:'-'}</strong></label>
                    </div>
                    <div>
                        <img src={Iconuser} alt="loading..." className="mr-2"></img>
                        <label>{state.length!==0?'Admin':<BarLoaders color={color} loading={true} />}</label>
                    </div>
                    <div>
                        <img src={emailIcon} alt="loading..." className="mr-2"></img>
                        <label>{state.length!==0?state.email:<BarLoaders color={color} loading={true} />}</label>
                    </div>
                    <div>
                        <img src={phoneIcon} alt="loading..." className="mr-2"></img>
                        <label>{state.length!==0?"+" + state.country_code + " " + state.phone_number:<BarLoaders color={color} loading={true} />}</label>
                    </div>
                    <div>
                        <img src={pointerIcon} alt="loading..." className="mr-2"></img>
                        {console.log(state.length)}
                        <label>{state.length!==0?state.address_line_1 + ", "+
                            state.address_line_2 + ", "+
                            state.city + ", "+
                            state.state + ", "+
                            state.country:<BarLoaders color={color} loading={true} />}</label>
                    </div>
                    <div>
                        <img src={timezoneIcon} alt="loading..." className="mr-2"></img>
                        <label>{state.length!==0? state.timezone : <BarLoaders color={color} loading={true} />}</label>
                    </div>

                    <div className="mt-2">
                        <Link className='linkstyle' to="#" onClick={ChangedPasswordToggle}>Change Password</Link>
                    </div>
                    <div className="">
                        <Link className='linkstyle' to="#" onClick={logoutpage}>Logout</Link>
                    </div>

                </div>
            </div>

        </div>





    )

}


export default MyAccount;