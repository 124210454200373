import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import noParkings from "../../assets/Icons/Group 26816.svg";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"

import aicon from "../../assets/svgs/Group 27296.svg"
import sicon from "../../assets/svgs/Group 28767.svg"

import { Link, useNavigate ,useLocation} from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"
import defaultSPotImg from "../../assets/Default_spot_img.svg"
import AddAdminPopup from "../popups/CompanyProfile/AddAdminPopup";
import "../../styles.css"
import DeletePopup from "../Reusable Popup/DeletePopup";
import DeleteSuccess from "../Reusable Popup/DeleteSucess";
import Loader from "../Reusable Popup/Loader";
import Badge from 'react-bootstrap/Badge';
import AccessRequestPopup from "../Reusable Popup/AccessRequest";

//
import axiosInstance from "../../axios";
// import CreateProviderPopup from "../popups/CreateProviderPopup";
import AdminEditPopup from "../popups/CompanyProfile/AdminEditPopup"

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};




var Data_Format = {
    id: null,
    email: null,
    username: null,
    date_joined: null,
    last_login: null,
    is_admin: null,
    is_active: null,
    is_staff: null,
    is_superuser: null,
    first_name: null,
    last_name: null,
    user_type: null,
    address_line_1: null,
    address_line_2: null,
    profile_pic: null,
    country_code: null,
    phone_number: null,
    country: null,
    city: null,
    state: null,
    zip_code: null,
    is_user_blocked: null,
    timezone: null,
}


const RESULT_LIMIT_IN_PAGE = 6

const DATA_PROCESSOR = (data, setState) => {
    let temp = [];

    for (const spot in data) {
        var obj = {}
        console.log(data[spot])
        for (const key in Data_Format) {
            if (key in data[spot]) {
                obj = { ...obj, [key]: data[spot][key] }
            }
            else {
                obj = { ...obj, [key]: null }
            }
        }
        temp.push(obj)

    }

    setState(temp)
}

function Admins(props) {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    let location=useLocation()
    
    //popup
    const [AddAdmin, setAddAdmin] = useState(false);
    const [createProvider, setCreateProvider] = useState(false);
    const AddAdminToggle = () => {
        if (AddAdmin) {
            setAddAdmin(false)
        }
        else {
            setAddAdmin(true)
        }
    }

    const [AdminDetail, setAdminDetail] = useState(false);
    const AdminDetailToggle = () => {
        if (AdminDetail) {
            setAdminDetail(false)
        }
        else {
            setAdminDetail(true)
        }
    }
    const [DeleteAdmin, setDeleteAdmin] = useState(false);
    const [Email, setEmail] = useState(null);

    const [confirmation, setconfirmation]=useState(false)
    const [deletion, setDelete] = useState(false);

    const DeleteToggle = (e) => {
        
        if(e.target.value==='Cancel'){
            setDelete(false)
        }
        else{
            console.log(e.target.value,arr.length===0)
            
            if(e.target.value==='Yes' && arr.length!==0){
                setDelete(false)
                setarr([])
                DeletedArr()
            }
            else{
                console.log(e.target.value,arr)
                setDelete(false)
                Deleted()
            }
        }
        
    }
    const [arr,setarr]=useState([])
    const DeleteArr=(e)=>{
        
            if(e.target.checked){
                arr.push(e.target.name)
                console.log(arr)
            }
            else{
                arr.splice(arr.indexOf(e.target.name),1)
                console.log(arr)
            }
    }

    const[valu,setvalue]=useState()
    const deletetoggle=(e)=>{
        const element = e.target
        setvalue(element.getAttribute('data-id'))
        setDelete(true)

        
    }
    const Deleted = async (e) => {
        // setLoading(true);
        // setColor('#5c5b5b');
        console.log('id', valu)
        
        setDelete(false)

        let formData = new FormData();

        formData.append('id',valu)

        await axiosInstance.post(
            "admin_dash/delete_subadmin",formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            setconfirmation(true)
            callSearch()

        }).catch((err) => {
            console.log("got an error", err.response)
            if(err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            setLoading(false);
        })


    }

    const DeletedArr = async (event) => {
        // setLoading(true);
        // setColor('#5c5b5b');

        let formData = new FormData();

        
        formData.append("id", arr)


        await axiosInstance.post(
            "admin_dash/delete_subadmin", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            callSearch()
            setconfirmation(true)
            setarr([])

        }).catch((err) => {
            console.log("got an error", err.response.data.error_msg)
            if(err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            setLoading(false);
            setarr([])
        })
    }

    const ActiveToggle = async (event) => {
        const element = event.target
        const adminId = await element.getAttribute('data-email')

        let formData = new FormData();
        formData.append('email', adminId)
        await axiosInstance.post(

            "admin_dash/is_active_subadmin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            callSearch()
        }).catch((err) => {
            
            setLoading(false);
            if(err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            else{
                alert('Failed !')
            }
            
        })
    }

    const BlockToggle = async (event) => {
        setLoading(true)
        const element = event.target
        const adminId = await element.getAttribute('data-id')

        let formData = new FormData();
        formData.append('id', adminId)
        await axiosInstance
        .post("admin_dash/block_admin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            setLoading(false)
            callSearch()
        }).catch((err) => {
            if(err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            else{
            alert('Failed !')}
            setLoading(false);
            
        })
    }





    //


    const [selectedOption, setSelectedOption] = useState('active')
    const [Accessrequest, setAccessrequest]=useState(false)

    const toggleButtonSearch = (e) => {
        setSearch("")
        setSelectedOption(e.target.name);
    }


    const [search, setSearch] = useState("")
    const [state, setState] = useState([])

    const [loadingData, setLoadingData] = useState(false)
    const [page, setPage] = useState(1)

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })
    const [sort,setsort] = useState('')

    const filter=(e)=>{
        console.log(e.target.value)
        setsort(e.target.value)
    }


    const callSearch = async () => {
        setColor('#5c5b5b');

        setLoadingData(true)
        setState([])

        let formData = new FormData();


        formData.append('search', search)
        formData.append('admin_type', sort)
        // formData.append('state', '')
        // formData.append('job_task', '')
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)


        await axiosInstance.post(

            "admin_dash/search_subadmin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data)
            DATA_PROCESSOR(response.data.response.result, setState)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })
            setLoadingData(false)
            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    useEffect(() => {
        callSearch()
        console.log(props)
    }, [selectedOption, page,sort])

    // delete

    const handleDelete = async () => {



        let formData = new FormData();


        formData.append('email', Email)


        await axiosInstance.post(
            "admin_dash/delete_subadmin", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {


            console.log(response.data.response)





        }).catch((err) => {
            console.log("got an error--", err)
        })


    }





    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader color={color} loading={loading} />
                </div>
            ) :

                <div className="col-md-12 p-0">


                    <div className="d-flex justify-content-end" >
                        <button
                            className={_(["", css.font_18, css.inside_button1, css2.add_new_button])}
                            onClick={AddAdminToggle}
                        >
                            Add New Admin
                        </button>
                    </div>
                    <div className={_([" w-100", css2.search_row])}>
                        <div>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                                name=''
                                onClick={toggleButtonSearch}
                            >
                                All
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'active' ? css2.selected_search_button : '')])}
                                name='active'
                                onClick={toggleButtonSearch}
                            >
                                Active
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'deactive' ? css2.selected_search_button : '')])}
                                name='deactive'
                                onClick={toggleButtonSearch}
                            >
                                Deactivated
                            </button>
                        </div>
                        <div className={_(["d-flex justify-content-end"])}>
                            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                                <img src={searchIcon} alt="search">
                                </img>

                                <input
                                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                                    type="input"
                                    name="search"
                                    placeholder="Search here"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            callSearch()
                                        }
                                    }}
                                    style={{ border: '0px', background: 'none' }}
                                >

                                </input>
                            </div>


                        </div>
                    </div>
                    <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                        {selectedOption === '' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} Users</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'active' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} Users</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'deactive' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} Users</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                            <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                                <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])} onClick={filter}>
                                    <option value=''>All</option>
                                    <option value='admin'>
                                        Admin
                                    </option>
                                    <option value='sub admin'>Sub Admin</option>
                                </select>
                                <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])} onClick={()=>{setDelete(true)}}>
                                    <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                                    Delete
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className={_(["flex-grow-1 ", css2.table])}>
                        <table
                            className={_([
                            "mt-2 w-100",
                            css.font_18,
                            css.font2,
                            css2.search_list,
                            ])}
                        >
                            <tr className={_(["", css2.search_header])}>
                            <td>#</td>
                            <td>Name</td>
                            <td>Contact Info</td>
                            <td>Address</td>
                            <td>Role</td>
                            <td>reated By</td>
                            <td>Status</td>
                            <td>Actions</td>
                            </tr>

                            {state?.length > 0 &&
                            state?.map((spot, index) => {
                                const continuousIndex =
                                index + 1 + (page - 1) * pagination.count;

                                return (
                                <>
                                    <tr className={_([""])} key={index}>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                        <label
                                            className={_(["", css.checkbox_group])}
                                            htmlFor={"check" + index}
                                        >
                                            <input
                                            type="checkbox"
                                            className={_(["mr-2", css.checkbox_size])}
                                            id={"check" + index}
                                            name={spot.id} onChange={DeleteArr}
                                            ></input>
                                            <span className={_(["mr-1", css.box,css.cursor_pointer])}></span>
                                        </label>
                                        <div className="index d-flex align-items-center mt-3">
                                            <p>{continuousIndex}</p>
                                        </div>
                                        </div>
                                    </td>
                                    <td
                                        className={_([
                                        "d-flex align-items-center p-2",
                                        css.cursor_pointer,
                                        ])}
                                    >
                                        <div
                                        className={_([
                                            "d-flex flex-row justify-content-start align-items-center",
                                            css.gap_10,
                                        ])}
                                        >
                                        <img src={spot.profile_pic ? spot.profile_pic : defaultSPotImg} className={_(["", css2.search_spot_img1])}></img>
                                                                <div className={_(["ml-2 d-flex flex-column"])}>
                                                                    <label className={_(["", css.font4])}>{spot.first_name ? spot.first_name : "-"}</label>
                                                                    {/* <label className={_(["", css.font_14])}>{spot.user_type ? spot.user_type : "-"}</label> */}
                                                                    <label className={_(["d-flex align-items-start", css.font_14])}>{ 
                                                                        spot.user_type === "admin" ? (
                                                                            <img src={aicon} alt="admin" />
                                                                        ) : spot.user_type === "sub admin" ? (
                                                                            <img src={sicon} alt="sub admin" />
                                                                        ) : (
                                                                            "-"
                                                                        )
                                                                        }</label>
                                                                    {/* {console.log(spot.user_type,'gyjuser_type')} */}
                                                                </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        <label className={_(["", css.font4])}>{spot.email ? (spot.email.length>23? spot.email.slice(0,23)+'..':spot.email): "-"}</label>
                                        <label className={_(["", css.font_14])}>{spot.phone_number ? spot.phone_number : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        <label className={_(["", css.font4])}>{spot.address_line_1 ? spot.address_line_1:'-' }<br/>{spot.address_line_2 ? spot.address_line_2:'' }</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        <label className={_(["", css.font4])}>{spot.user_type ? spot.user_type : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        <label className={_(["", css.font4])}>{spot.create_by ? spot.create_by : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        <label className={_([" d-flex", css.font4])}>{spot.is_active ? <Badge bg='success'>Active</Badge>:<Badge bg='danger'>Deactive</Badge>}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                        className={_([
                                            " d-flex flex-column justify-content-center align-items-center",
                                        ])}
                                        >
                                        {selectedOption === '' ?
                                                                <div className="dropdown">
                                                                    <p className="mb-0 " to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <img src={actionIcon}></img>
                                                                    </p>
                                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        <Link className="dropdown-item" to="" onClick={()=>setCreateProvider(!createProvider)}>Edit</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={deletetoggle} data-id={spot.id}>Delete</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-email={spot.email}>{spot.is_active?'Deactivate':'Active'}</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={BlockToggle} data-id={spot.id}>{spot.is_user_blocked?'Unblock':'Block'}</Link>
                                                                    </div>
                                                                </div> : null}
                                                                {selectedOption === 'active' ?
                                                                <div className="dropdown">
                                                                    <p className="mb-0 " to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <img src={actionIcon}></img>
                                                                    </p>
                                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        <Link className="dropdown-item" to="" onClick={()=>setCreateProvider(!createProvider)} >Edit</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={deletetoggle} data-id={spot.id}>Delete</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-email={spot.email}>Deactivate</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={BlockToggle} data-id={spot.id}>{spot.is_user_blocked?'Unblock':'Block'}</Link>
                                                                    </div>
                                                                </div> : null}
                                                                {selectedOption === 'deactive' ?
                                                                <div className="dropdown">
                                                                    <p className="mb-0" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <img src={actionIcon}></img>
                                                                    </p>
                                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-email={spot.email}>Active</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={deletetoggle} data-id={spot.id}>Delete</Link>
                                                                        <Link className="dropdown-item" to="#" onClick={BlockToggle} data-id={spot.id}>{spot.is_user_blocked?'Unblock':'Block'}</Link>
                                                                    </div>
                                                                </div> : null}
                                        </div>
                                    </td>
                                    </tr>
                                </>
                                );
                            })}
                        </table>
                        {loadingData ?
                            (
                                <div className="w-100">
                                    <div className="d-flex justify-content-center spinner w-100">
                                        <div className="spinner-border text-muted mt-4 mb-4"></div>
                                    </div>
                                </div>
                            ) :


                            (state.length === 0 ?
                                (
                                    <>
                                        <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                            <img src={noParkings} alt="No Parkings"></img>
                                            <span className={_(["mt-4", css.font2, css.font_18])}>No parking spots available right now. :(</span>
                                        </div>
                                    </>
                                )
                                :
                                null

                            )
                        }
                    </div>
                    <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                        {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                            <PaginationComponent
                                count={pagination.noOfPages}
                                page={page}
                                setPage={setPage}
                            />
                            :
                            null
                        }
                    </div>

                    <AddAdminPopup isOpen={AddAdmin} toggle={AddAdminToggle} />
                    <DeletePopup  isOpen={deletion} heading={'Alert!'} subheading={'Are you sure you want to delete'} subheading2={'Admin?'} buttontitle={'Cancel'} buttontitle2={'Yes'} toggle={DeleteToggle}/>
                    <DeleteSuccess  isOpen={confirmation} heading={'Great!'} subheading={'Successfully Deleted'} subheading2={'Admin'} buttontitle={'Okey'} toggle={()=>{setconfirmation(false)}}/>
                    <AccessRequestPopup  isOpen={Accessrequest} heading={'Alert!'} subheading={'edit the Sub Admin'} title={'Request to edit'} msg={'Manage the SubAdmin'} permissionname={'manage_subadmin'} path={''}  toggle={()=>{setAccessrequest(false)}}/>
                    <AdminEditPopup isOpen={createProvider} toggle={()=>setCreateProvider(!createProvider)}
        />
                </div>
            }
        </>




    )
}


export default Admins;

