import css2 from './../common.module.css'
import css from './../../main.module.css'
import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, } from "reactstrap";
import Icon1 from '../../assets/GIF2/Delete_Success.json'
import LottieGIF from './../lottieComponent'
import "./../../styles.css"

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function DeleteSuccess(props) {




    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_([" shadow d-flex flex-column justify-content-center align-items-center p-4", css2.center_container])}
                >

                    <div className={_(["d-flex flex-column justify-content-center align-items-center", css2.body_header])}>


                    <LottieGIF json={Icon1} loop={true} height="8rem" width="8rem"></LottieGIF>


                    </div>
                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >
                        <div className={_(["d-flex flex-column justify-content-center align-items-center mt-0 mb-4", css.font1])}
                        >
                            <p className={_(["mb-0 fs-4 mb-4", css.font_18, css.font1])}>{props.heading}</p>
                            <p className={_(["mb-0 fs-5", css.font_18, css.font2])}>{props.subheading}</p>
                            <p className={_(["mb-0", ])}>{props.subheading2}</p>

                        </div>

                        <div className={_(["d-flex flex-row justify-content-around align-items-center mt-3 mb-5", css.font1])}
                        >


                            <button
                                onClick={props.toggle}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])}
                            >
                                {props.buttontitle}
                            </button>
                        </div>

                    </div>




                </div>

            </Modal>

        </>
    );
}

export default DeleteSuccess;