// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangePassword_inside_body__1dB5W{\n    width: 60%;\n}\n.ChangePassword_inside_button__ArALA{\n    width: 60%;\n}\n\n@media screen and (max-width: 768px) {\n    .ChangePassword_inside_body__1dB5W{\n        width: 80%;\n    }\n    .ChangePassword_inside_button__ArALA{\n        width: 60%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/popups/ChangePassword.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".inside_body{\n    width: 60%;\n}\n.inside_button{\n    width: 60%;\n}\n\n@media screen and (max-width: 768px) {\n    .inside_body{\n        width: 80%;\n    }\n    .inside_button{\n        width: 60%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inside_body": "ChangePassword_inside_body__1dB5W",
	"inside_button": "ChangePassword_inside_button__ArALA"
};
export default ___CSS_LOADER_EXPORT___;
