import React, { useState } from 'react';

/* csss */
import './../Companyprofile.css';
import css from "../../../main.module.css";
import css3 from './../../common.module.css';







const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const TermsRole = (props) => {
    const [checkedbox, setCheckedBox] = useState(false);
    const handleOnAllChange = () => {
        setCheckedBox((prevCheckedBox ) => {
            if (prevCheckedBox){
                setCheckedBox(false)
            }else{
                setCheckedBox(true) 
            }
        });
    }

    const handleOnChange = (e) => {
        if (e.target.checked){
            props.submitdata(e.target.name)
    
        }
       else{
        props.setremove(e.target.name)
       }
    }
    const done=()=>{
        props.done(true)
    }
    return (
        <div className='container mb-5'>
            <div className='col-md-6 mt-5 d-flex justify-content-start'>
                <label className={_(["", css.checkbox_group2])} htmlFor="check1">
                    <input type="checkbox" checked={checkedbox} onChange={handleOnAllChange} className={_(["mr-2", css.checkbox_size])} id="check1"></input>
                    <span className={_(["mr-1", css.box])}></span>
                </label>
                <p className='ml-1'>Select All</p>
            </div>
            <label className='col-md-12 p-0 Headingstyle'>Team & Conditions :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check2">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='view_terms_and_conditions' className={_(["mr-2", css.checkbox_size])} id="check2"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Term & Conditions</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='manage_terms_and_conditions' className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Terms & Conditions</p>
                </div>

            </div>

            <label className='col-md-12 p-0 Headingstyle'>Monthly Parking Terms :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check4">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="view_monthly_parking_terms" className={_(["mr-2", css.checkbox_size])} id="check4"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Monthly Parking Terms</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check5">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="manage_monthly_parking_terms" className={_(["mr-2", css.checkbox_size])} id="check5"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Monthly Parking Terms</p>
                </div>

            </div>

            <label className='col-md-12 p-0 Headingstyle'>Hourly Parking Terms :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check6">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="view_hourly_parking_terms" className={_(["mr-2", css.checkbox_size])} id="check6"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Hourly Parking Terms</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check7">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="manage_hourly_parking_terms" className={_(["mr-2", css.checkbox_size])} id="check7"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Hourly Parking Terms</p>
                </div>

            </div>

            <label className='col-md-12 p-0 Headingstyle'>Documentation Terms :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check8">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='view_documentations_terms' className={_(["mr-2", css.checkbox_size])} id="check8"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Documentation Terms</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check9">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='manage_documentations_terms' className={_(["mr-2", css.checkbox_size])} id="check9"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Documentation Terms</p>
                </div>

            </div>

            <label className='col-md-12 p-0 Headingstyle'>Provider Terms :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check10">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='view_provider_terms' className={_(["mr-2", css.checkbox_size])} id="check10"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Provider Terms</p>
                </div>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check11">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="manage_provider_terms" className={_(["mr-2", css.checkbox_size])} id="check11"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Provider Terms</p>
                </div>

            </div>
            <label className='col-md-12 p-0 Headingstyle'>Extension Terms :</label>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check10">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name='view_extensions_terms' className={_(["mr-2", css.checkbox_size])} id="check10"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>View Extension Terms</p>
                </div>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check11">
                        <input type="checkbox" checked={checkedbox?true:null} onChange={handleOnChange} name="manage_extensions_terms" className={_(["mr-2", css.checkbox_size])} id="check11"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-1'>Manage Extension Terms</p>
                </div>

            </div>
            <div className={_(["d-flex flex-row justify-content-around align-items-center mt-5", css.font1])}
            >
                <button
                    onClick={done
                    }
                    // type='submit'
                    className={_(["btn btn_B d-flex justify-content-center align-items-center w-25 ", css3.inside_button, css.inside_button1, css.font_18])}
                >
                    Create a Role
                </button>
            </div>

        </div>
    )
}


export default TermsRole