import css from "../../../main.module.css";
import cssx from "../../../components/common.module.css"
import css2 from "./SearchParkingSpot.module.css";
import css3 from "../AddParkingSpot.module.css"
import img1 from "../../../assets/Icons/Group 26744.svg"
import img2 from "../../../assets/Icons/Group 26152.svg"
import img3 from "../../../assets/Icons/Group 26713.svg"
import bgImg1 from "../../../assets/Icons/Group 25426.svg"

import starIcon from "../../../assets/Icons/Icon awesome-star.svg"
import heartIcon from "../../../assets/Icons/Icon awesome-heart.svg"
import savedIcon from "../../../assets/Icons/Group 26073.svg"
import dropIcon from "../../../assets/Icons/Mask Group 146.svg"
import phoneIcon from "../../../assets/Icons/Icon material-phone.svg"
import editIcon from "../../../assets/Icons/Icon material-edit.svg"
import shareIcon from "../../../assets/Icons/Icon feather-share.svg"
import actionIcon from "../../../assets/Icons/Repeat Grid 15.svg"
import dotIcon from "../../../assets/Icons/Ellipse 451.svg"
import { Link, useNavigate , useSearchParams} from "react-router-dom";
import { useEffect, useState} from "react";
import validator from 'validator'
import GoogleMap from "./../GoogleMap"
import { getBase64 } from "../../algo/getBase64";
import { setDefinition } from "./SingleParkingPDF";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import defaultSPotImg from "../../../assets/Default_spot_img.svg"
import profile from '../../../assets/profile.jpg';
import axiosInstance from '../../../axios'
import {DATA_PROCESSOR} from '../../../Tools'
import DeleteParkingPopup from "../../popups/DeleteParkingPopup";
import DeleteParkingSuccPopup from "../../popups/DeleteParkingSuccPopup";
import { PulseLoader } from "react-spinners";
import { set } from "react-hook-form";

import axios from 'axios';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function addMarker(props) {
    const markerContent = document.createElement('div');
    markerContent.style.display = 'flex';
    markerContent.style.alignItems = 'center';
    markerContent.style.justifyContent = 'center';
    markerContent.style.color = props.color || 'black';
    markerContent.style.fontSize = props.fontSize || '18px';
    markerContent.style.fontFamily = props.font || 'ProximaNova-Regular';
    markerContent.innerHTML = props.label || '';

    if (props.svg) {
        const icon = document.createElement('img');
        icon.src = props.svg;
        icon.style.width = '30px';
        icon.style.height = '30px';
        markerContent.appendChild(icon);
    }

    if (window.google) {
        return new window.google.maps.marker.AdvancedMarkerElement({
            position: props.location,
            map: props.map,
            content: markerContent,
        });
    }
    return null;
}
function ViewParkingSpot(props) {
    const history=useNavigate()
    
    // const [searchParams, setSearchParams] = useSearchParams();
    // const query = searchParams.get('search')

    const [searchParams] = useSearchParams();
    const query = searchParams.get('search');
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [totalEarning, setTotalEarning] = useState(0);
    const [totalBooking, setTotalBooking] = useState(0);
    const [total_cancellation, setTotalCancellation] = useState(0);
    const [myspot, setMySpot] = useState({
        lat: "",
        lng: "",
    })
    const [loader, setLoader] = useState(false);
    const [parking_instruction, setInstruction] = useState([]);
    console.log(parking_instruction,"parking_instruction")
    const [deleteParkingPopup, setDeleteParking] = useState(false)
    const [deleteParkingPopupSuccessfull, setDeleteParkingSuccessfull] = useState(false)
      const [referenceSpotId, setReferenceSpotId] = useState({
        name: null,
        id: null
    })
    const [map, setMap] = useState(null);

    const pro_detail=()=>{
        history("/Dashboard/ProviderDetail/Profile")
    }
console.log("myspot",myspot)
    const[provider,setprovider]=useState({
        profile_pic:null,
        first_name:'',
        last_name:'',
        email:'',
        phone_number:'',
        is_active:false,
        country_code:'',
    })
    
    const [state, setState] = useState({
        parking_name: null,
        total_count_booked: null,
        total_cancele_booked: null,
        total_earned: null,
        total_ratings: null,
        likes: null,
        parking_country_code: null,
        parking_phone_number: null,
        parking_brief_discription: null,
        monthly: false,
        hourly: false,
        monthly_rate: null,
        hourly_rate: null,
        mon_hourly: null,
        tus_hourly: null,
        wed_hourly: null,
        thu_hourly: null,
        fri_hourly: null,
        sat_hourly: null,
        sun_hourly: null,
        is_your_parking_can_list_at_least_3_months: false,
        is_parking_unser_cctv: false,
        is_parking_on_deed_restricted_community: false,
        are_u_owner_of_parking: false,
        gated_core_required: false,
        add_gate_code: null,
        is_active: false,
        pic_1: null,
        pic_2: null,
        pic_3: null,
        pic_4: null,
        BD_pic_1: null,
        BD_pic_2: null,
        BD_pic_3: null,
        BD_pic_4: null,
        amenities: [],
        email: '',
        loc_lat: '',
        loc_lon: '',
        parking_spot_address1: '',
        parking_spot_address2: '',
        status:'',
    });
console.log("staDasdte",state?.mon_hourly)
const deleteParkingSpot = async (id) => {
    if (!id) {
        return;
    }

    console.log("delete");
    try {
        await axiosInstance.post(
            "/provider_dash/delete_parking_spot" + id,
            { headers: { 'content-type': 'multipart/form-data' } }
        );
        setDeleteParking(false);
        setDeleteParkingSuccessfull(true);
        navigate("/Dashboard/ParkingSpots");
    } catch (err) {
        console.log("got an error", err);
    }
};

    const download = async () => {
        const { BD_pic_1, 
            BD_pic_2, 
            BD_pic_3, 
            BD_pic_4, 
            email, 
            total_ratings, 
            parking_name, 
            parking_brief_discription, 
            parking_country_code, 
            parking_phone_number, 
            parking_spot_address1, 
            parking_spot_address2, 
            amenities 
        } = state;
        var list = amenities.map(({ id, active_status, created_by, icon_img, ...keepAttrs }) => keepAttrs);
        var pdfList = []
        for (let i = 0; i < list.length; i++) {
            pdfList.push(list[i].name)
        }


        let image = '';
        if (BD_pic_1) {
            image = await getBase64(BD_pic_1)
        }

        let arr = [];
        if (BD_pic_2) {
            arr.push({
                image: await getBase64(BD_pic_2),
                fit: [60, 60]
            }
            )
        }
        if (BD_pic_3) {
            arr.push({
                image: await getBase64(BD_pic_3),
                fit: [60, 60]
            }
            )
        }
        if (BD_pic_4) {
            arr.push({
                image: await getBase64(BD_pic_4),
                fit: [60, 60]
            }
            )
        }

        console.log(arr);
        let obj = {
            "operationList": ["One", "Two", "List"],
            "ammenityList": pdfList,
            "instructionList": ['1', '1'],
            "name": parking_name,
            "phoneNumber": parking_country_code + parking_phone_number,
            "address": parking_spot_address1 + " " + parking_spot_address2,
            "description": parking_brief_discription,
            "image": image,
            "rating": total_ratings,
            "email": email,
            "otherImages": arr
        }

        let dd = setDefinition(obj);
        pdfMake.createPdf(dd).download();

    }

    const days = [
        { day: "Mon", hours: state.mon_hourly },
        { day: "Tue", hours: state.tus_hourly },
        { day: "Wed", hours: state.wed_hourly },
        { day: "Thu", hours: state.thu_hourly },
        { day: "Fri", hours: state.fri_hourly },
        { day: "Sat", hours: state.sat_hourly },
        { day: "Sun", hours: state.sun_hourly },
      ].filter(day => day.hours !== "" && day.hours !== "\"\"" && day.hours !== null);

      // const aproveparkingSpot = async (id) => {
    //     console.log(query,"query")
    //     setLoader(true);
    //     let formData = new FormData();

    //     formData.append('id',query )
    //     await axiosInstance.post(
    //         "admin_dash/aproove_deny_parking_spot",
    //         formData,
    //         { headers: { 'content-type': 'multipart/form-data' } }
    //     ).then((response) => {
    //         setLoader(false);
    //         history('Dashboard/ParkingSpots')

    //     }).catch((err) => {
    //         setLoader(false);
    //         console.log("got an error", err)
    //     })
    // }

    const aproveparkingSpot = async (id) => {
        console.log("approveParkingSpot called with id:", id);
        setLoader(true);
        let formData = new FormData();

        formData.append('id', query);
        try {
            await axiosInstance.post(
                `admin_dash/approve_deny_parking_spot-${id}`,
                formData,
                { headers: { 'content-type': 'multipart/form-data' } }
            );
            setLoader(false);
            navigate('/Dashboard/ParkingSpots');
        } catch (err) {
            setLoader(false);
            console.log("got an error", err);
        }
    };

    const denyparkingSpot = async (id) => {
        console.log(query, "query");
        setLoader(true);
        let formData = new FormData();

        formData.append('id', query);
        try {
            await axiosInstance.post(
                "admin_dash/deny_parking_spot",
                formData,
                { headers: { 'content-type': 'multipart/form-data' } }
            );
            setLoader(false);
            navigate('/Dashboard/ParkingSpots');
        } catch (err) {
            setLoader(false);
            console.log("got an error", err);
        }
    };



    useEffect(() => {
        let isMounted = true; // to handle unmounting
        let marker = null; // to store marker instance for cleanup

        const fetchParkingData = async () => {
            if (!query || !validator.isNumeric(query)) {
                navigate("/notfound");
            } else {
                setLoader(true);
                try {
                    const response = await axiosInstance.get(
                        "/provider_dash/edit_and_view_parking_spot_api-" + query,
                    );

                    if (isMounted) {
                        const parkingSpot = response.data.response.parking_spot;
                        const providerData = response.data.response.provider;

                        if (parkingSpot.parking_instruction) {
                            setInstruction(parkingSpot.parking_instruction.split(","));
                        } else {
                            setInstruction([]);
                        }

                        setMySpot({ lat: parkingSpot.loc_lat, lng: parkingSpot.loc_lon });
                        setReferenceSpotId({ id: parkingSpot.id, name: parkingSpot.parking_name });

                        await DATA_PROCESSOR(parkingSpot, state, setState);
                        await DATA_PROCESSOR(providerData, provider, setprovider);
                        console.log(parkingSpot);

                        // Add marker to the map
                        if (map) {
                            marker = addMarker({
                                location: { lat: parkingSpot.loc_lat, lng: parkingSpot.loc_lon },
                                label: parkingSpot.parking_name,
                                map: map,
                                draggable: false
                            });
                        }

                        setLoader(false);
                    }
                } catch (err) {
                    setLoader(false);
                    if (err) {
                        alert(err);
                    }
                }
            }
        };

        fetchParkingData();

        // Cleanup function to run on unmount
        return () => {
            isMounted = false;
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [query, map]);


    return (
        <>
        <PulseLoader color="#752E8E" size="30" loading={loading} />
        <div className={_(["d-flex flex-column align-items-center", cssx.container, css.shade_background])}>
            <div className={_(["d-flex flex-column align-items-center", css2.center_container])}>
                {loader ? (
                    <div className="d-flex justify-content-center spinner">
                        <div className="spinner-border text-muted mt-4 mb-4"></div>
                    </div>
                ) :
                    (
                        <>
                            <p className={_(["align-self-start mb-4 mt-4", css.font_18, css.font2])}>
                                <Link className={_([css.custom_sublink])} to="/Dashboard/ParkingSpots"> Parkings Spots </Link> &gt; <span className={ css.dark_blue_color }>{state.parking_name}</span></p>
                            <div className={_([" w-100", css2.card_row])}>
                                <div className={_(["", css2.cards2])}>
                                    <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                        <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Bookings</label>
                                        <img src={img1} alt="..."></img>
                                        {/* <div className={css.image_container}>
                                            <img src={bgImg1} alt="Background" className={css.background_image} />
                                            <img src={img1} alt="Total Bookings" className={css.card_image} />
                                        </div> */}
                                    </div>
                                    <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>{totalBooking}</label>

                                </div>
                                <div className={_(["", css2.cards2])}>
                                    <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                        <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Cancelled Bookings</label>
                                        <img src={img2} alt="..."></img>
                                    </div>
                                    <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>{total_cancellation}</label>
                                </div>
                                <div className={_(["", css2.cards2])}>
                                    <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                        <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Earnings</label>
                                        <img src={img3} alt="..."></img>
                                    </div>
                                    <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>{totalEarning}</label>
                                </div>

                            </div>
                            <div className={_(["mt-3 w-100", css2.panel1 ,css2.border])}>
                                <div className={_(["col-md-12 align-items-center d-flex row", ])}>
                                    <div className={_(["col-md-1", css2.profile_img])}>
                                            {provider.profile_pic==null?<img src={profile}></img>:<img src={provider.profile_pic}></img>}

                                    </div>
                                    <div className={_(["col-md-2", css2.profnam])}>
                                        <div className={_(["column d-flex flex-column", css.gap_5])}>
                                            <div className={_(["d-flex",css.font_14])}>by{" "}
                                                <label className={_([" ml-1",css.font_18,css.font4])}>{provider.first_name} {provider.last_name}</label>
                                            </div>
                                            <div className={_(["", css2.icons])}>
                                                <div className={_(["", css2.P,css.font8,css.font_14])}>P</div>
                                                <div className={_(["", css2.U,css.font6,css.font_14])}>U</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={_(["col-md-3", css2.profdetail])}>
                                        <div className={_(["column"])}>
                                        <label className={_(["",css.font1,css.font_14,css.fontb_700])}>Email:</label>
                                        <div className={_(["",css.font_14,css.font9])}>{provider.email}</div></div>
                                    </div>
                                    <div className={_(["col-md-2 align-items-center", css2.profdetail])}>
                                        <hr className={_(["", css.vertical_line])}></hr>
                                        <div className={_(["column"])}>
                                            <label className={_(["",css.font1,css.font_14])}>Phone:</label>
                                            <div className={_(["",css.font_14,css.font9])}>+{provider.country_code}{provider.phone_number}</div>
                                        </div>
                                    </div>
                                    <div className={_(["col-md-2 align-items-center", css2.profdetail])}>
                                        <hr className={_(["", css.vertical_line])}></hr>
                                        <div className={_(["column"])}>
                                            <label className={_(["",css.font1,css.font_14])}>Status:</label>
                                            {provider.is_active?
                                            <div className={_(["",css.font6,css.font6a,css.font_14])}>Active</div>:<div className={_(["",css.font7,css.font_14])}>Deactive</div>}
                                        </div>
                                    </div>
                                    <div className={_(["col-md-2", css2.profbuttn])}>
                                        <div className={_(["column"])}>
                                            <button type="button" className={_(["btn",css.font_14,css.font4, css2.viewbtn])} onClick={pro_detail}>View profile</button> 
                                            <button type="button" className={_(["btn", css.font_14, css.font4, css2.contactbtn])}>Contact Provider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={_(["mt-3 w-100", css2.panel1])}>
                                <div className={_(["", css2.spot_img])} id="spot_images">

                                    <img className={_([" "])} src={state.pic_1 ? state.pic_1 : defaultSPotImg}></img>
                                    <img className={_([" "])} src={state.pic_2 ? state.pic_2 : defaultSPotImg}></img>
                                    <img className={_([" "])} src={state.pic_3 ? state.pic_3 : defaultSPotImg}></img>
                                    <img className={_([" "])} src={state.pic_4 ? state.pic_4 : defaultSPotImg}></img>
                                    <img className={_([" "])} src={defaultSPotImg}></img>
                                </div>
                                <div className={_(["ml-3 d-flex flex-column", css2.spot_details])}>
                                    <div className={_(["d-flex flex-row flex-grow-1"])}>
                                        <div className={_(["flex-grow-1 d-flex flex-column"])}>
                                            <label className={_(["", css.font1, css.font_24])}>
                                                {state.parking_name}
                                            </label>
                                            <div className={_(["d-flex flex-row"])}>
                                                <div className={_(["d-flex align-items-center mr-2"])}>
                                                    <img src={starIcon}></img>
                                                    <label className={_(["ml-2 mb-0", css.font4, css.font_18])}>
                                                        {state.total_ratings}
                                                    </label>
                                                </div>
                                                <div className={_(["d-flex align-items-center mr-2"])}>
                                                    <img src={heartIcon}></img>
                                                    <label className={_(["ml-2 mb-0", css.font4, css.font_18])}>
                                                        {state.likes}
                                                    </label>
                                                </div>
                                                <div className={_(["d-flex align-items-center mr-2"])}>
                                                    <img src={savedIcon}></img>
                                                    <label className={_(["ml-2 mb-0", css.font4, css.font_18])}>
                                                        0
                                                    </label>
                                                </div>

                                            </div>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                <img src={dropIcon} className={_(["mr-2"])}></img>
                                                {state.parking_spot_address1}
                                            </label>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                <img src={phoneIcon} className={_(["mr-2"])}></img>
                                                {"+" + state.parking_country_code + " " + state.parking_phone_number}
                                            </label>
                                            <p className={_(["", css.font2, css.font_18])}>
                                                {state.parking_brief_discription}
                                            </p>
                                        </div>

                                        <div className={_(["d-flex flex-row edit-container align-items-baseline mt-2 mr-3", css.gap_14])}>
                                            {console.log(state,'state')}
                                            <label className={_(["", css.font4])}>
                                                {state.status === 'A' ? <p className={_([css.badgevp])}>Active</p> : (state.status === 'D') ? <label className={_([css.badgevp2])}>Denied</label> : (state.status === 'P') ? <p className={_([css.badgevp_orange])}>Under Review</p> : <p className={_([css.badgevp_orange])}>Pause</p>}
                                            </label>
                                            <img src={shareIcon} alt="Download" onClick={download} className={_(["download-button"])}></img>
                                            <div className="dropdown">
                                                <p className="mb-0 d-flex flex-row justify-content-between align-items-center" 
                                                to="#" 
                                                id="navbarDropdown" 
                                                role="button" 
                                                data-toggle="dropdown" 
                                                aria-haspopup="true" 
                                                aria-expanded="false">
                                                    <img src={actionIcon} alt="dropdown icon" ></img>
                                                </p>
                                                <div className={_(["dropdown-menu dropdown-menu-right", css.custom_dropdown])} aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#" 
                                                        onClick={() => {aproveparkingSpot(query);}} >Approve</Link>
                                                    <Link className="dropdown-item" to="#" onClick={()=>denyparkingSpot()} >Deny</Link>
                                                    <Link className="dropdown-item" to="#"
                                                        onClick={(e) => {
                                                            setDeleteParking(true)
                                                        }}>Delete</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={_([" d-flex flex-row justify-content-end w-100", css.font_18, css.font1])}>
                                        {state.monthly && <button className={_(["", css2.button2])}>
                                            {"$" + state.monthly_rate} / month
                                        </button>}
                                        {state.hourly && <button className={_(["", css2.button2])}>
                                            {"$" + state.hourly_rate} / hour
                                        </button>}
                                    </div>

                                </div>
                            </div>

                            <div className={_(["mt-3 w-100", css2.panel2_cards])}>

                                <div className={_(["position-relative", css2.cards])}>
                                    <div className={_(["d-flex flex-row edit-parking-container"])}>
                                    </div>
                                    <div>
                                        <label className={_(["", css.font1, css.font_24])}>Hours Of Operation</label>

                                        {days.length > 0 ?  days.map((day, index) => {
                                            return(
                                            <>
                                            <ul key={index} className="d-flex flex-row list list-unstyled mb-1">
                                                <>
                                                <li><img src={dotIcon} className={_(["mr-2"])}></img></li>
                                                <li className={_(["mr-2"])}>{day.day}:</li>
                                                <li>{day.hours}</li>
                                                </>
                                                
                                            </ul>
                                            </>
                                            )}) : <p>No dates available </p>}
                                    </div>
                                    <hr className={_(["", css.horizontal_line])}></hr>
                                    <div>
                                        <label className={_(["", css.font1, css.font_24])}>
                                            Parking spot Selling options:
                                        </label>
                                        <div className={_(["d-flex flex-row"])}>
                                            {state.hourly && <label className={_(["mr-3", css.font4, css.font_18])}>
                                                <img src={dotIcon} className={_(["mr-2"])}></img>
                                                Hourly
                                            </label>}
                                            {state.monthly && <label className={_(["", css.font4, css.font_18])}>
                                                <img src={dotIcon} className={_(["mr-2"])}></img>
                                                Monthly
                                            </label>}
                                        </div>
                                    </div>
                                </div>
                                <div className={_(["position-relative", css2.cards])}>
                                    <div className={_(["d-flex flex-row edit-parking-container"])}>
                                    </div>
                                    <label className={_(["", css.font1, css.font_24])}>
                                        Amenities
                                    </label>
                                    {/* {state.amenities &&
                                        state.amenities.map((item, index) => {

                                            return (
                                                <div key={item.id} className={_(["d-flex flex-row "])}>
                                                    <img src={dotIcon} className={_(["mt-2 mr-2 align-self-start"])}></img>
                                                    <label className={_(["", css.font2, css.font_18])}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            )

                                        })} */}
                                        {state.amenities && state.amenities.length > 0 ? (
                                            state.amenities.map((item, index) => (
                                                <div key={item.id} className="d-flex flex-row align-items-center mb-2">
                                                    <img src={item?.icon_img} className={_(["ml-0 mr-1",])} style={{height:'16px', width:'16px'}} alt="amenities Icon" />
                                                    <label className={_(["mb-0 ml-1",css.font2,css.font_18])}>
                                                        {item?.name}
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No amenities found</p>
                                        )}
                                </div>
                                <div className={_(["p-0", css2.cards])}>
                                    <div className={_([css2.map_container])}>
                                        <div className={_(["w-100 h-100"])}>
                                            {!loader && myspot.lat && myspot.lng && <GoogleMap setMap={setMap} lat={myspot.lat} lng={myspot.lng} byLocation={false}></GoogleMap>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={_(["mt-3 w-100", css2.panel3_cards])}>
                                <div className={_(["position-relative", css2.cards])}>
                                    <div className={_(["d-flex flex-row edit-parking-container"])}>
                                        
                                    </div>
                                    <label className={_(["", css.font1, css.font_24])}>
                                        Instructions
                                    </label>
                                    {/* {parking_instruction && parking_instruction.length > 0 ?
                                        parking_instruction.map((value, index) => {

                                            return (
                                                <div className={_(["d-flex flex-row "])}>
                                                    <img src={dotIcon} className={_(["mt-2 mr-2 align-self-start"])}></img>
                                                    <label className={_(["", css.font2, css.font_18])}>
                                                        {value}
                                                    </label>
                                                </div>
                                            )

                                        })
                                        : <p>Not any instruction</p>
                                    } */}
                                    {parking_instruction && parking_instruction?.length > 0 && parking_instruction?.[0] !== '[]'  ? (
                                        parking_instruction.map((value, index) => (
                                            <div key={index} className="d-flex flex-row">
                                                <img src={dotIcon} className="mt-2 mr-2 align-self-start" alt="Dot Icon" />
                                                <label className={`${css.font2} ${css.font_18}`}>
                                                    {value}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Not any instruction</p>
                                    )}

                                </div>
                                <div className={_(["position-relative", css2.cards])}>
                                    <div className={_(["d-flex flex-row edit-parking-container"])}>
                                        
                                    </div>
                                    <label className={_(["", css.font1, css.font_24])}>
                                        About Parking Area
                                    </label>
                                    <div>
                                        <div className={_(["d-flex flex-row justify-content-between mt-1"])}>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                Is your parking spot available to list for at least 3 consecutive months?
                                            </label>
                                            <label className={_(["ml-4", css.font2, css.font_18])}>
                                                {state.is_your_parking_can_list_at_least_3_months ? "Yes" : "No"}
                                            </label>
                                        </div>
                                        <div className={_(["d-flex flex-row justify-content-between mt-1"])}>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                Are you the owner of the parking spots you want to list?
                                            </label>
                                            <label className={_(["ml-4", css.font2, css.font_18])}>
                                                {state.are_u_owner_of_parking ? "Yes" : "No"}
                                            </label>
                                        </div>
                                        <div className={_(["d-flex flex-row justify-content-between mt-1"])}>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                Is the parking spot located in a deed restricted or HOA community?
                                            </label>
                                            <label className={_(["ml-4", css.font2, css.font_18])}>
                                                {state.is_parking_on_deed_restricted_community ? "Yes" : "No"}
                                            </label>
                                        </div>
                                        <div className={_(["d-flex flex-row justify-content-between mt-1"])}>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                Is the parking spot under the surveillance of a security camera?
                                            </label>
                                            <label className={_(["ml-4", css.font2, css.font_18])}>
                                                {state.is_parking_unser_cctv ? "Yes" : "No"}
                                            </label>
                                        </div>
                                        <div className={_(["d-flex flex-row justify-content-between mt-1"])}>
                                            <label className={_(["", css.font4, css.font_18])}>
                                                Do you need a gate code to reach the parking spot?
                                            </label>
                                            <label className={_(["ml-4", css.font2, css.font_18])}>
                                                {state.gated_core_required ? "Yes" : "No"}
                                            </label>
                                        </div>

                                    </div>
                                    {state.gated_core_required &&
                                        <div className="d-flex flex-row align-items-center">
                                            <label className={_(["mb-0 ", css.font1, css.font_18])}>
                                                Gate Code:
                                            </label>
                                            <p className={_(["ml-3 mb-0", css.font4])}>{state.add_gate_code}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={_(["mt-3 mb-3 w-100", css2.last_panel])}>
                                <label className={_([" ", css.font1, css.font_24])}>
                                    Parking Best Using Directions:
                                </label>
                                <div className={_(["w-100 d-flex flex-wrap justify-content-between"])}>
                                    <img src={state.BD_pic_1 ? state.BD_pic_1 : state.BD_pic_2 ? state.BD_pic_2 : state.BD_pic_3 ? state.BD_pic_3 : state.BD_pic_4 ? state.BD_pic_4 : defaultSPotImg}></img>
                                    <img src={state.BD_pic_2 ? state.BD_pic_2 : state.BD_pic_3 ? state.BD_pic_3 : state.BD_pic_4 ? state.BD_pic_4 : defaultSPotImg}></img>
                                    <img src={state.BD_pic_3 ? state.BD_pic_3 : state.BD_pic_4 ? state.BD_pic_4 : defaultSPotImg}></img>
                                    <img src={state.BD_pic_4 ? state.BD_pic_4 : defaultSPotImg}></img>

                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <DeleteParkingPopup isOpen={deleteParkingPopup} toggle={() => { setDeleteParking(!deleteParkingPopup) }} onConfirmation={deleteParkingSpot} spot={referenceSpotId} />
            <DeleteParkingSuccPopup isOpen={deleteParkingPopupSuccessfull} toggle={() => { setDeleteParkingSuccessfull(!deleteParkingPopupSuccessfull) }} />
        </div>
    </>
    )
}

export default ViewParkingSpot;