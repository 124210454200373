import React, { useState , useEffect} from 'react';
import { Outlet } from 'react-router';
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './reportinquiries.css'
import { Link,useNavigate } from "react-router-dom";




const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const ReportInquries = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [selected, setSelected] = useState("Statistics");

    const handleClick = (e) => {
        setSelected(e.target.name)
        let name = e.target.name;
        if (name === "Statistics") {
            setTitle("Statistics")
        } else if (name === "ServiceChanges") {
            setTitle("Service Changes")
        } else if (name === "ChatInquiries") {
            setTitle("Chat Inquiries")
        } else {
            setTitle("Contact Us Queries")
        }
    }


    const url = new URL(window.location.href);
    useEffect(()=>{
    setSelected(url.pathname.split('/')[3])
    setTitle(url.pathname.split('/')[3].replace(/([A-Z])/g, ' $1').trim())
    },[])



    return (
        <div className='container bg-white mt-5'>
            <div className='container mt-4'>
                <p  className={_(["align-self-start mb-4 mt-4 font-family-regular"])} onClick={()=>navigate('/Dashboard/details')}>Profile {'>'} <span className='link-font'>Report &amp; Inquries</span></p>
                <h4 className={_(["linkstyle fw-bold",])}>{title} </h4>
            </div>
            <div className='container mt-4'>

                <div className='col-md-12 p-0'>
                    <ul className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}>
                        <Link to="/Dashboard/ReportInquries/Statistics" name="Statistics" className={selected === "Statistics" ? _(["py-2", css2.active_link5, css2.link_text5]) : _(["py-2", css2.link_text5])} onClick={handleClick}>Statistics
                        </Link>
                        <Link to="/Dashboard/ReportInquries/ServiceCharges" name="ServiceCharges" className={selected === "ServiceCharges" ? _(["py-2", css2.active_link5, css2.link_text5]) : _(["py-2", css2.link_text5])} onClick={handleClick}>Service Charges
                        </Link>
                        <Link to="/Dashboard/ReportInquries/ChatInquiries" name="ChatInquiries" className={selected === "ChatInquiries" ? _(["py-2", css2.active_link5, css2.link_text5]) : _(["py-2", css2.link_text5])} onClick={handleClick}>Chat Inquiries
                        </Link>
                        <Link to="/Dashboard/ReportInquries/ContactUsQueries" name="ContactUsQueries" className={selected === "ContactUsQueries" ? _(["py-2", css2.active_link5, css2.link_text5]) : _(["py-2", css2.link_text5])} onClick={handleClick}>Contact Us Queries
                        </Link>
                    </ul>
                </div>
                <div className='col-md-12 border p-0 Contnt d-flex flex-column align-items-center'>
                    <Outlet />
                </div>
            </div>
        </div>
    )

}

export default ReportInquries;