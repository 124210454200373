import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import PaginationComponent from "../../components/Pagination";

import noParkings from "../../assets/Icons/Group 26816.svg";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import { Link } from "react-router-dom";
import eyeGradient from '../../assets/useicon/eye-gradient.svg';
import eyeGray from '../../assets/useicon/eye-gray.svg';
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import defaultSPotImg from "../../assets/Default_spot_img.svg"
import "../../styles.css"
import AddBannerPopup from "../popups/CompanyProfile/AddBannerPopup";
import axiosInstance from "../../axios";
import Loader from "../Reusable Popup/Loader";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const RESULT_LIMIT_IN_PAGE = 6

function ManageBanners() {

    const [AddBanner, setAddBanner] = useState(false);
    const AddBannerToggle = () => {
        if (AddBanner) {
            setAddBanner(false)
        }
        else {
            setAddBanner(true)
        }
    }
    // 
    const [selectedOption, setSelectedOption] = useState('active')

    const toggleButtonSearch = (e) => {
        setSearch("")
        setSelectedOption(e.target.name);
    }

    const [visibleOption, setVisibleOption] = useState('Website')

    const toggleVisibleSearch = (e) => {
        setVisibleOption(e.target.value);
    }

    const [search, setSearch] = useState("")
    const [state, setState] = useState([])

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })


    const callSearch = async () => {
        setLoading(true);
        setState([])

        let formData = new FormData();


        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)
        formData.append('visible_for', visibleOption)



        await axiosInstance.post(

            "admin_dash/search_banner_from_admin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            //console.log(response.data.response.result)
            setState(response.data.response.result)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })
            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    useEffect(() => {
        callSearch()
    }, [selectedOption,visibleOption, page])



    return (

        <div className="col-md-12 p-0 mb-5">
            <div className="d-flex justify-content-end" >
                <button
                    className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                    onClick={AddBannerToggle}
                >
                    Add A Banner
                </button>
            </div>



            <div className={_([" w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                        name=''
                        onClick={toggleButtonSearch}
                    >
                        All
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'active' ? css2.selected_search_button : '')])}
                        name='active'
                        onClick={toggleButtonSearch}
                    >
                        Active
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'deactive' ? css2.selected_search_button : '')])}
                        name='deactive'
                        onClick={toggleButtonSearch}
                    >
                        Deactivated
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img src={searchIcon} alt="search">
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            placeholder="Search here"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    callSearch()
                                }
                            }}
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                {selectedOption === '' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                            <label className={_(["", css.font_18, css.font2])}>4 Banner</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'active' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>{state.length} Banner</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'deactive' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                            <label className={_(["", css.font_18, css.font2])}>{state.length} Banner</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                        <select value={visibleOption} onChange={toggleVisibleSearch} className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                            <option value='Website'>
                                Website
                            </option>
                            <option value='Application'>Application</option>
                        </select>
                        <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])}>
                            <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                            Delete
                        </button>
                    </div>

                </div>

            </div>
            <div className={_(["flex-grow-1", css2.table])}>
                <table className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                    <tr className={_(["", css2.search_header])}>
                        <td>#</td>
                        <td>Image</td>
                        <td>Clickable</td>
                        <td>Link</td>
                        <td>Created By</td>
                        <td>Visible On Application</td>
                        <td>Visible On Website</td>
                        <td>Status</td>
                        <td>Action</td>
                    </tr>


                    {state.map((spot, index) => {

                        return (
                            <>

                                <tr className={_([""])}>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <label className={_(["", css.checkbox_group])} htmlFor={"check" + index}>
                                                <input data-id={spot.id} type="checkbox" className={_(["mr-2", css.checkbox_size])} id={"check" + index}></input>
                                                <span className={_(["mr-1", css.box])}></span>
                                            </label>
                                            <div className="index d-flex align-items-center mt-3"><p>{index}</p></div>
                                        </div>
                                    </td>

                                    <td className={_(["d-flex flex-row justify-content-center align-items-center"])}>
                                        <div className={_(["d-flex flex-row justify-content-start align-items-center"])}>
                                            <img src={spot.banner_pic ? spot.banner_pic : defaultSPotImg} className={_(["", css2.search_spot_img])}></img>

                                        </div>
                                    </td>
                                    <td>
                                        <label className={_(["flex-grow-1 d-flex justify-content-center align-items-center mb-0", css.checkbox_group])} htmlFor="check1">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check1"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.link_url ? spot.link_url : "-"}</label>
                                        </div>
                                    </td>
                                    <td >
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.create_by ? spot.create_by : "-"}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.visible_app ? <p><img src={eyeGradient} alt="..." className={_(["ml-2"])}></img></p> : <p><img src={eyeGray} alt="..." className={_(["ml-2"])}></img></p>}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={_([" d-flex flex-column justify-content-center align-items-center"])}>
                                            <label className={_(["row", css.font4])}>{spot.visible_web ? <p><img src={eyeGradient} alt="..." className={_(["ml-2"])}></img></p> : <p><img src={eyeGray} alt="..." className={_(["ml-2"])}></img></p>}</label>
                                        </div>
                                    </td>
                                    <td >
                                        <div className={_([""])}>
                                            <label className={_(["", css.font4])}>{spot.active_status ? <p className="badge">Active</p> : <p className="badge2">Deactivated</p>}</label>
                                        </div>
                                    </td>
                                    <td>
                                        {selectedOption === '' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#">Edit</Link>
                                                    <Link className="dropdown-item" to="#">Deactivate</Link>
                                                    <Link className="dropdown-item" to="#" >Delete</Link>
                                                </div>
                                            </div> : null}
                                        {selectedOption === 'active' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#">Edit</Link>
                                                    <Link className="dropdown-item" to="#">Deactivate</Link>
                                                    <Link className="dropdown-item" to="#" >Delete</Link>
                                                </div>
                                            </div> : null}
                                        {selectedOption === 'deactive' ?
                                            <div className="dropdown">
                                                <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src={actionIcon}></img>
                                                </p>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#">Deactivate</Link>
                                                    <Link className="dropdown-item" to="#" >Delete</Link>
                                                </div>
                                            </div> : null}


                                    </td>
                                </tr>

                            </>
                        )
                    })}


                </table>
                {loading ?
                            (
                                <div className="w-100">
                                    <div className="d-flex justify-content-center spinner w-100">
                                        <div className="spinner-border text-muted mt-4 mb-4"></div>
                                    </div>
                                </div>
                            ) :


                            (state.length === 0 ?
                                (
                                    <>
                                        <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                            <img src={noParkings} alt="No Parkings"></img>
                                            <span className={_(["mt-4", css.font2, css.font_18])}>No parking spots available right now. :(</span>
                                        </div>
                                    </>
                                )
                                :
                                null

                            )
                        }
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                    <PaginationComponent
                        count={pagination.noOfPages}
                        page={page}
                        setPage={setPage}
                    />
                    :
                    null
                }
            </div>
            <AddBannerPopup isOpen={AddBanner} toggle={AddBannerToggle} />
        </div>




    )
}


export default ManageBanners;