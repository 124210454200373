import css from "../../../main.module.css";
import cssx from "../../../components/common.module.css";
import css2 from "./SearchParkingSpot.module.css";
import img1 from "../../../assets/Icons/Group 26701.svg";
import img2 from "../../../assets/Icons/Group 26700.svg";
import img3 from "../../../assets/Icons/Group 26718.svg";
import img4 from "../../../assets/Icons/Group 26713.svg";
import React, { useState, useEffect, useRef } from "react";
import PaginationComponent from "../../../components/Pagination";
import UpdatePrice from "../../../components/popups/UpdatePrice1";
import searchIcon from "../../../assets/Icons/Icon feather-search.svg";
import arrowUpDown from "../../../assets/useicon/Arrow Up Down.svg";
import deleteIcon from "../../../assets/Icons/Icon material-delete1.svg";
import { Link, useNavigate } from "react-router-dom";
import actionIcon from "../../../assets/Icons/Repeat Grid 15.svg";
import defaultSPotImg from "../../../assets/Default_spot_img.svg";
import noParkings from "../../../assets/Icons/Group 26816.svg";
import "../../../styles.css";
//
import axiosInstance from "../../../axios";
import SavedPopup from "../../Reusable Popup/SavedPopup";
import { DownloadExcel } from "../../algo/DownloadExcel";
import { makeBookingData } from "../../algo/madeArrayData";
import { downloadParkingCSV } from "../../algo/DownloadCSV";
import { generatePDF } from "../parkingspots/parkingSpotPDF";
import toast from "react-hot-toast";

import Loader from "../../Reusable Popup/Loader";
import axios from "axios";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const RESULT_LIMIT_IN_PAGE = 6;

function ParkingSpots() {
  const history = useNavigate();

  const [loadingData, setLoadingData] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [alert, setAlert] = useState(false);
  const [pause, setPause] = useState(false);
  const [approve, setApprove] = useState(false);
  const [deny, setDeny] = useState(false);
  const [editPricePopup, setEditPricePopup] = useState({
    popup: false,
    spotId: "",
    oldPrice: "",
    parking_name: "",
    spotLocationLat: null,
    spotLocationLng: null,
  });
  const [state, setState] = useState([]);
  const [exportInfo, setExport] = useState("");
  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [statusloading, setStatusLoading] = useState(false);
  const [parkingstats, setParkingStats] = useState({});
  const [pauseStatus, setPauseStatus] = useState(null);
  const [deleteList, setDeleteList] = useState([]);

  const handleRowClick = (spotId) => {
    history(`/Dashboard/ViewParkingSpot?search=${spotId}`);
  };
  // popup
  const alertToggle = () => {
    if (alert) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  };

  const pauseToggle = () => {
    if (pause) {
      setPause(false);
    } else {
      setPause(true);
    }
  };

  const approveToggle = () => {
    if (approve) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  };

  const denyToggle = () => {
    if (deny) {
      setDeny(false);
    } else {
      setDeny(true);
    }
  };

  // end

  const toggleEditPricePopup = (e) => {
    if (editPricePopup.popup) {
      setEditPricePopup({
        ...editPricePopup,
        popup: false,
      });
    } else {
      let idx = e.target.name;
      console.log("Index here", state[idx]);
      setEditPricePopup({
        ...editPricePopup,
        spotId: state[idx].id,
        oldPrice: state[idx].hourly_rate,
        parking_name: state[idx].parking_name,
        spotLocationLat: state[idx].loc_lat,
        spotLocationLng: state[idx].loc_lon,
        popup: true,
      });
    }
  };

  // download pdf

  const download = (e) => {
    const value = e.target.value;
    setExport(e.target.value);
    const data = makeBookingData(state);
    // var bookingData=data.map(({data, ...keepAttrs}) => keepAttrs);
    console.log("download", data);
    if (value === "exportAsExcel") {
      DownloadExcel(data, "parkingspot.xlsx");
    }
    if (value === "exportAsCSV") {
      downloadParkingCSV(data, "parkingspot.csv");
    }
    if (value === "exportAsPDF") {
      generatePDF(data, selectedOption);
    }
    setExport("");
  };

  // search parking spot

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
  };

  const callSearch = async () => {
    setLoadingData(true);
    setColor("#5c5b5b");
    setState([]);

    let formData = new FormData();

    formData.append("type", selectedOption);
    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("page", page);
    formData.append("filter", "");

    await axiosInstance
      .post("admin_dash/search_parking_spot_api_by_admin", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response.data);
        setState(response.data.response.data);
        setPagination({
          count: RESULT_LIMIT_IN_PAGE,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoadingData(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  useEffect(() => {
    callSearch();
  }, [selectedOption, page]);

  // approve

  const approveParkingSpot = async (e) => {
    e.preventDefault();
    setStatusLoading(true);

    const element = e.target;
    const parkingSpotId = element.getAttribute("data-id");
    const parkingSpotStatus = element.getAttribute("data-status");

    setPauseStatus(parkingSpotStatus);

    try {
      const response = await axiosInstance.post(
        `admin_dash/aproove_deny_parking_spot-${parkingSpotId}`
      );
      console.log(response.data);
      approveToggle();
      callSearch();
    } catch (err) {
      console.log("got an error", err);
    } finally {
      setStatusLoading(false);
    }
  };

  // deny

  const denyParkingSpot = async (e) => {
    setStatusLoading(true);
    const element = e.target;
    const parkingSpotId = element.getAttribute("data-id");
    const parkingSpotStatus = element.getAttribute("data-status");
    setPauseStatus(parkingSpotStatus);
    console.log("deny", parkingSpotId);

    let formData = new FormData();

    formData.append("id", parkingSpotId);

    await axiosInstance
      .post("admin_dash/deny_parking_spot", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data);
        denyToggle();
        callSearch();
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  // pause unpause

  const pauseUnpauseParkingSpot = async (e) => {
    setStatusLoading(true);
    const element = e.target;
    const parkingSpotId = element.getAttribute("data-id");
    const parkingSpotStatus = element.getAttribute("data-status");
    setPauseStatus(parkingSpotStatus);
    console.log("pause unpause", parkingSpotId);

    await axiosInstance
      .post("admin_dash/pause_parking_spot-".concat(parkingSpotId))
      .then((response) => {
        console.log(response.data);
        pauseToggle();
        callSearch();
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  // delete

  const deleteParkingSpot = async (event) => {
    setStatusLoading(true);
    const element = event.target;
    const parkingSpotId = element.getAttribute("data-id");
    console.log(parkingSpotId);
    let arrparkingspot = new Array(parkingSpotId);
    console.log("array", arrparkingspot);
    let formData = new FormData();

    formData.append("id", arrparkingspot);

    await axiosInstance
      .post("admin_dash/del_parking_spot", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response.data);
        alertToggle();
        callSearch();
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  // delete

  const addIntoDeleteList = (event) => {
    let id = event.target.getAttribute("data-id");

    if (event.target.checked) {
      setDeleteList([...deleteList, id]);
    } else {
      var index = deleteList.indexOf(id);
      if (index > -1) {
        deleteList.splice(index, 1);
      }
    }
  };
  const handleDelete = async () => {
    if (deleteList?.length > 0) {
      setStatusLoading(true);

      let formData = new FormData();
      formData.append("id", deleteList);

      try {
        const response = await axiosInstance.post(
          "admin_dash/del_parking_spot",
          formData,
          { headers: { "content-type": "multipart/form-data" } }
        );

        console.log(response.data.response);
        alertToggle();
        callSearch();
      } catch (err) {
        console.log("got an error", err);
        toast.error("Failed to delete items. Please try again.");
      } finally {
        setStatusLoading(false);
      }
    } else {
      toast.error("Please select spots to delete.");
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "admin_dash/parking-spot-stats",
          {
            cancelToken: source.token,
          }
        );
        setParkingStats(response.data.response || []);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.error("Failed to fetch parking stats:", err);
          // Optionally, show a message to the user or set an error state
        }
      }
    };

    fetchData();

    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  console.log("parkingstats", parkingstats);

  useEffect(() => {
    let mapInstance = null;
    if (editPricePopup.spotLocationLat && editPricePopup.spotLocationLng) {
      mapInstance = new window.google.maps.Map(document.getElementById("map"), {
        center: {
          lat: editPricePopup.spotLocationLat,
          lng: editPricePopup.spotLocationLng,
        },
        zoom: 15,
      });

      const marker = new window.google.maps.Marker({
        position: {
          lat: editPricePopup.spotLocationLat,
          lng: editPricePopup.spotLocationLng,
        },
        map: mapInstance,
      });
    }

    return () => {
      if (mapInstance) {
        mapInstance = null; // Properly cleanup map instance
      }
    };
  }, [editPricePopup.spotLocationLat, editPricePopup.spotLocationLng]);

  
  return (
    <>
      <div
        className={_([
          "d-flex flex-column align-items-center",
          cssx.container,
          css.shade_background,
        ])}
      >
        <div
          className={_([
            "d-flex flex-column align-items-center",
            css2.center_container,
          ])}
        >
          <p className={_(["align-self-start", css.font1, css.font_32])}>
            Parking Spots
          </p>
          <div className={_([" w-100", css2.card_row])}>
            <div className={_(["", css2.cards])}>
              <div
                className={_([
                  "d-flex flex-row justify-content-between align-items-start w-100",
                ])}
              >
                <label
                  className={_([
                    "align-self-start flex-grow-1",
                    css.font1,
                    css.font_24,
                  ])}
                >
                  Active Parking
                </label>
                <img src={img1} alt="..."></img>
              </div>
              <label
                className={_(["mb-0 align-self-start", css.font2, css.font_32])}
              >
                {parkingstats?.active_parking_spot
                  ? parkingstats?.active_parking_spot
                  : 0}
              </label>
            </div>
            <div className={_(["", css2.cards])}>
              <div
                className={_([
                  "d-flex flex-row justify-content-between align-items-start w-100",
                ])}
              >
                <label
                  className={_([
                    "align-self-start flex-grow-1",
                    css.font1,
                    css.font_24,
                  ])}
                >
                  Pending Parking
                </label>
                <img src={img2} alt="..."></img>
              </div>
              <label
                className={_(["mb-0 align-self-start", css.font2, css.font_32])}
              >
                {parkingstats?.pending_parking_spot
                  ? parkingstats?.pending_parking_spot
                  : 0}
              </label>
            </div>
            <div className={_(["", css2.cards])}>
              <div
                className={_([
                  "d-flex flex-row justify-content-between align-items-start w-100",
                ])}
              >
                <label
                  className={_([
                    "align-self-start flex-grow-1",
                    css.font1,
                    css.font_24,
                  ])}
                >
                  Available Parking
                </label>
                <img src={img3} alt="..."></img>
              </div>
              <label
                className={_(["mb-0 align-self-start", css.font2, css.font_32])}
              >
                {parkingstats?.available_parking
                  ? parkingstats?.available_parking
                  : 0}
              </label>
            </div>
            <div className={_(["", css2.cards])}>
              <div
                className={_([
                  "d-flex flex-row justify-content-between align-items-start w-100",
                ])}
              >
                <label
                  className={_([
                    "align-self-start flex-grow-1",
                    css.font1,
                    css.font_24,
                  ])}
                >
                  Total Earnings
                </label>
                <img src={img4} alt="..."></img>
              </div>
              <label
                className={_(["mb-0 align-self-start", css.font2, css.font_32])}
              >
                {parkingstats?.total_earning
                  ? `$ ${parkingstats?.total_earning}`
                  : `$ ${0}`}
              </label>
            </div>
          </div>

          <div
            className={_([
              "mt-5 d-flex flex-column",
              css2.center_container,
              css2.parking_search,
            ])}
          >
            <div className="d-flex justify-content-end">
              {selectedOption === "" ||
              selectedOption === "pending_approval" ||
              selectedOption === "denied" ? (
                <button
                  className={_([
                    "align-self-end mt-8 mr-8 mb-0 ms-0",
                    css.font_18,
                    css.inside_button1,
                    css2.add_new_button,
                  ])}
                  onClick={() =>
                    history(
                      "/Dashboard/ParkingSpots/AddParkingSpot/BasicDetails",
                      { replace: true }
                    )
                  }
                >
                  Add New Parking
                </button>
              ) : null}

              {selectedOption === "active" && (
                <>
                  <button
                    className={_([
                      "align-self-end m-0",
                      css.font_18,
                      css.inside_button_custom,
                      css2.add_new_button,
                    ])}
                    onClick={() =>
                      history("/Dashboard/Mapview", { replace: true })
                    }
                  >
                    Map View
                  </button>
                  <button
                    className={_([
                      "align-self-end m-0",
                      css.font_18,
                      css.inside_button1,
                      css2.add_new_button,
                    ])}
                    onClick={() =>
                      history(
                        "/Dashboard/ParkingSpots/AddParkingSpot/BasicDetails",
                        { replace: true }
                      )
                    }
                  >
                    Add New Parking
                  </button>
                </>
              )}
            </div>
            <div className={_([" w-100", css2.search_row])}>
              <div className="d-flex justify-content-around">
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "" ? css2.selected_search_button : "",
                  ])}
                  name=""
                  onClick={toggleButtonSearch}
                >
                  All
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "active"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="active"
                  onClick={toggleButtonSearch}
                >
                  Active
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "pending_approval"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="pending_approval"
                  onClick={toggleButtonSearch}
                >
                  Pending Approval
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "denied"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="denied"
                  onClick={toggleButtonSearch}
                >
                  Denied
                </button>
              </div>
              <div className={_(["d-flex justify-content-end"])}>
                <div
                  className={_(["", css.font_18, css.font2, css2.search_bar])}
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    style={{ height: "15px" }}
                  ></img>

                  <input
                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                    type="input"
                    name="search"
                    placeholder="Search by Name or Location"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        callSearch();
                      }
                    }}
                    style={{ border: "0px", background: "none" }}
                  />
                </div>
              </div>
            </div>
            <div
              className={_([
                "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
                css2.search_row_2,
              ])}
            >
              {["", "active", "pending_approval", "denied"].includes(
                selectedOption
              ) && (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      {selectedOption === "active"
                        ? "Active"
                        : "Pending Approval"}
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Spots
                    </label>
                  </div>

                  {(selectedOption === "active" ||
                    selectedOption === "pending_approval" ||
                    selectedOption === "denied") && (
                    <div
                      className={_([
                        "flex-grow-1 d-flex flex-wrap justify-content-end",
                      ])}
                    >
                      <select
                        placeholder="Export As PDF"
                        value={exportInfo}
                        onChange={(e) => {
                          setExport(e.target.value);
                          download(e);
                        }}
                        className={_([
                          "mr-3",
                          css.font_18,
                          css.font1,
                          css2.select_box_style,
                        ])}
                      >
                        <option value="" disabled>
                          Select Export
                        </option>
                        <option value="exportAsPDF">Export As PDF</option>
                        <option value="exportAsCSV">Export As CSV</option>
                        <option value="exportAsExcel">Export As Excel</option>
                      </select>
                      <button
                        onClick={() => handleDelete()}
                        className={_([
                          "pl-2 pr-2 d-flex align-items-center",
                          css.font_18,
                          css.inside_button4,
                        ])}
                      >
                        <img
                          src={deleteIcon}
                          alt="..."
                          className={_(["mr-2"])}
                        ></img>
                        Delete
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={_(["flex-grow-1", css2.table])}>
              <table
                className={_([
                  "mt-2 w-100",
                  css.font_18,
                  css.font2,
                  css2.search_list,
                ])}
              >
                <tr className={_(["", css2.search_header])}>
                  <td>#</td>
                  <td>Parking Name</td>
                  <td>Address</td>
                  <td>Provider Name</td>
                  <td className="d-flex justify-content-center align-items-center">
                    <div
                      className={_([
                        "d-flex flex-row justify-content-center align-items-center",
                      ])}
                    >
                      <div
                        className={_([
                          " d-flex flex-column justify-content-center align-items-center",
                        ])}
                      >
                        <label>Price</label>
                        <label>(hourly)</label>
                      </div>
                      <img
                        alt=""
                        src={arrowUpDown}
                        className={_([
                          "ml-1",
                          css.cursor_pointer,
                          css2.search_list_img1,
                        ])}
                        onClick={toggleEditPricePopup}
                      ></img>
                    </div>
                  </td>
                  <td>
                    Price <br></br>(monthly)
                  </td>
                  <td>Bookings</td>
                  <td className="">
                    <div
                      className={_([
                        "d-flex flex-row justify-content-center align-items-center",
                      ])}
                    >
                      <div
                        className={_([
                          " d-flex flex-column justify-content-center align-items-center",
                        ])}
                      >
                        <label>Earnings</label>
                      </div>
                      <img
                        alt=""
                        src={arrowUpDown}
                        className={_([
                          "ml-1",
                          css.cursor_pointer,
                          css2.search_list_img1,
                        ])}
                        onClick={toggleEditPricePopup}
                      ></img>
                    </div>
                  </td>
                  <td>Status</td>
                  <td>Actions</td>
                </tr>

                {state?.length > 0 &&
                  state?.map((spot, index) => {
                    const continuousIndex =
                      index + 1 + (page - 1) * pagination.count;

                    return (
                      <>
                        <tr className={_([""])} key={index}>
                          <td>
                            <div className="d-flex align-items-center p-2">
                              <label
                                className={_(["", css.checkbox_group])}
                                htmlFor={"check" + index}
                              >
                                <input
                                  onChange={(event) => addIntoDeleteList(event)}
                                  data-id={spot.id}
                                  type="checkbox"
                                  className={_(["mr-2", css.checkbox_size])}
                                  id={"check" + index}
                                ></input>
                                <span className={_(["mr-1", css.box])}></span>
                              </label>
                              <div className="index d-flex align-items-center mt-3">
                                <p>{continuousIndex}</p>
                              </div>
                            </div>
                          </td>
                          <td
                            className={_([
                              "d-flex align-items-center p-2",
                              css.cursor_pointer,
                            ])}
                            onClick={() => handleRowClick(spot?.id)}
                          >
                            <div
                              className={_([
                                "d-flex flex-row justify-content-start align-items-center",
                                css.gap_10,
                              ])}
                            >
                              <img
                                src={spot.pic_1 ? spot.pic_1 : defaultSPotImg}
                                className={_(["", css2.custom_search_spot_img])}
                              ></img>
                              <div
                                className={_([
                                  " d-flex flex-column justify-content-start align-items-center",
                                ])}
                              >
                                <label
                                  className={_([
                                    "",
                                    css.font4,
                                    css.cursor_pointer,
                                  ])}
                                >
                                  {spot.parking_name ? spot.parking_name : "-"}
                                </label>
                                <label
                                  className={_([
                                    "",
                                    css.font_14,
                                    css.cursor_pointer,
                                  ])}
                                >
                                  {spot.parking_spot_number
                                    ? "Spot no. " + spot.parking_spot_number
                                    : "-"}
                                </label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              <label className={_(["", css.font4])}>
                                {spot.parking_spot_address1
                                  ? spot.parking_spot_address1
                                  : "-"}
                              </label>
                              <label className={_(["", css.font4])}>
                                {spot.parking_spot_address2
                                  ? spot.parking_spot_address2
                                  : "-"}
                              </label>
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              <label className={_(["row", css.font4])}>
                                {spot.parking_name ? spot.parking_name : "-"}
                              </label>
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              {spot?.hourly_rate
                                ? "$" + spot?.hourly_rate
                                : "-"}
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              {spot?.monthly_rate
                                ? "$" + spot?.monthly_rate
                                : "-"}
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              {!spot.total_count_booked
                                ? spot.total_count_booked
                                : "-"}
                            </div>
                          </td>
                          <td>
                            <div
                              className={_([
                                " d-flex flex-column justify-content-center align-items-center",
                              ])}
                            >
                              {!spot.total_earned
                                ? "$" + spot.total_earned
                                : "-"}
                            </div>
                          </td>
                          <td>
                            {/* {statusloading ? (
                                                        <div className='spinner-border text-muted mt-4 mb-4'>
                                                        </div>
                                                    ) : ( */}
                            <>
                              {selectedOption === "pending_approval" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot.status === "P" ? (
                                      <p className="badge-red mb-0">
                                        Under Review
                                      </p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "denied" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot.status === "D" ? (
                                      <p className="badge2 mb-0">Denied</p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "active" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot.status === "A" ? (
                                      <p className="badge mb-0">Active</p>
                                    ) : (
                                      <p className="badge-red mb-0">Pause</p>
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot.status === "A" ? (
                                      <p className="badge mb-0">Active</p>
                                    ) : spot.status === "D" ? (
                                      <p className="badge2 mb-0">Denied</p>
                                    ) : spot.status === "P" ? (
                                      <p className="badge-red mb-0">
                                        Under Review
                                      </p>
                                    ) : (
                                      <p className="badge-red mb-0">Pause</p>
                                    )}
                                  </label>
                                </div>
                              ) : null}
                            </>
                            {/* )} */}
                          </td>
                          <td>
                            {(selectedOption === "" ||
                              selectedOption === "active" ||
                              selectedOption === "pending_approval" ||
                              selectedOption === "denied") && (
                              <div className="dropdown">
                                <p
                                  className="mb-0 dropdown-toggle"
                                  id="navbarDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img
                                    className={_(["", css2.search_list_img2])}
                                    alt=""
                                    src={actionIcon}
                                  />
                                </p>
                                <div
                                  className={_([
                                    "dropdown-menu",
                                    css.custom_dropdown1,
                                  ])}
                                  aria-labelledby="navbarDropdown"
                                >
                                  {spot.status === "D" && (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={approveParkingSpot}
                                      >
                                        Approve
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={deleteParkingSpot}
                                        data-id={spot.id}
                                      >
                                        Delete
                                      </Link>
                                    </>
                                  )}
                                  {spot.status === "P" && (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={approveParkingSpot}
                                      >
                                        Approve
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={denyParkingSpot}
                                      >
                                        Deny
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={deleteParkingSpot}
                                        data-id={spot.id}
                                      >
                                        Delete
                                      </Link>
                                    </>
                                  )}
                                  {(spot.status === "A" ||
                                    selectedOption === "active") && (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        to={
                                          "/Dashboard/ViewParkingSpot?search=" +
                                          spot.id
                                        }
                                      >
                                        Edit
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        Share
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={deleteParkingSpot}
                                        data-id={spot.id}
                                      >
                                        Delete
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={pauseUnpauseParkingSpot}
                                      >
                                        {spot.status === "PO"
                                          ? "Unpause"
                                          : "Pause"}
                                      </Link>
                                    </>
                                  )}
                                  {spot.status === "pending_approval" && (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={approveParkingSpot}
                                      >
                                        Approve
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        data-status={spot.status}
                                        onClick={denyParkingSpot}
                                      >
                                        Deny
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={deleteParkingSpot}
                                        data-id={spot.id}
                                      >
                                        Delete
                                      </Link>
                                    </>
                                  )}
                                  {spot.status === "denied" && (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-id={spot.id}
                                        onClick={approveParkingSpot}
                                      >
                                        Approve
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={deleteParkingSpot}
                                        data-id={spot.id}
                                      >
                                        Delete
                                      </Link>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
              {loadingData ? (
                <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                  <Loader color={color} loading={loadingData} />
                </div>
              ) : state?.length === 0 ? (
                <>
                  <div className="mt-5 w-100 d-flex flex-column align-items-center">
                    <img src={noParkings} alt="No Parkings"></img>
                    <span className={_(["mt-4", css.font2, css.font_18])}>
                      No parking spots available right now. :({" "}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
              {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
                <PaginationComponent
                  count={pagination.noOfPages}
                  page={page}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
        <UpdatePrice
          isOpen={editPricePopup.popup}
          toggle={toggleEditPricePopup}
        ></UpdatePrice>
        <SavedPopup
          isOpen={alert}
          heading="Parking Spot Deleted!"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={alertToggle}
        />
        <SavedPopup
          isOpen={pause}
          heading={`Parking Spot ${
            pauseStatus === "Pause" ? "Unpaused" : "Paused"
          }`}
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={pauseToggle}
        />
        <SavedPopup
          isOpen={approve}
          heading="Parking Spot Approved"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={approveToggle}
        />
        <SavedPopup
          isOpen={deny}
          heading="Parking Spot Application Denied"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={denyToggle}
        />
      </div>
    </>
  );
}

export default ParkingSpots;
