import css from './../../../main.module.css'
import css3 from './../../common.module.css'
import css2 from './../ChangePassword.module.css'
import React, { useState, } from "react";
import {  useNavigate } from "react-router-dom";

import { Modal,  } from "reactstrap";
import visibleIcon from "./../../../assets/parking-bud-provider-assets/login-screens/Icon awesome-eye.png"
import "./../../../styles.css"
import axiosInstance from '../../../axios';

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function ChangePassword(props) {

    const history = useNavigate();
    
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false);

    const togglePassword = (e) => {
        if (e.target.name === 'currentPassword') {
            if (currentPasswordVisible) {
                setCurrentPasswordVisible(false)
            }
            else {
                setCurrentPasswordVisible(true)
            }
        }
        else if (e.target.name === 'newPassword') {
            if (newPasswordVisible) {
                setNewPasswordVisible(false)
            }
            else {
                setNewPasswordVisible(true)
            }
        }
        else if (e.target.name === 'confirmNewPassword') {
            if (confirmNewPasswordVisible) {
                setConfirmNewPasswordVisible(false)
            }
            else {
                setConfirmNewPasswordVisible(true)
            }
        }


    }

    const [errMsg, setErrMsg] = useState({
        oldpassword: null,
        password: null,
        //passwordmismatch: null,
        confirm_password: null,

    });

    const handleChange = (e) => {

        setState({
            ...state,
            [e.target.name]: e.target.value
        });
        setErrMsg({
            ...errMsg,
            [e.target.name]: null
        });

    }

    const [state, setState] = useState({
        oldpassword: "",
        password: "",
        confirm_password: "",



    })

    async function changepass() {

        if (state.oldpassword === "") {
            setErrMsg({ ...errMsg, oldpassword: "Password cannot be empty" });

            return
        }
        if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(state.oldpassword)) {
            setErrMsg({ ...errMsg, oldpassword: "Password length must be between 8-16 characters with atleast one special character and numeric digit" })
            return
        }
        if (state.password === "") {
            setErrMsg({ ...errMsg, password: "Password cannot be empty" });
            return
        }

        if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(state.password)) {
            setErrMsg({ ...errMsg, password: "Password length must be between 8-16 characters with atleast one special character and numeric digit" })
            return
        }

        if (state.password !== state.confirm_password) {
            setErrMsg({ ...errMsg, confirmPassword: "Password do not match" })
            return
        }

        let formData = new FormData();
        formData.append('oldpassword', state.oldpassword);
        formData.append('password', state.password);
        formData.append('confirm_password', state.confirm_password);



        await axiosInstance.post(
            "admin_dash/change_admin_password", formData
        ).then((response) => {
            if (response.data.success) {

                console.data(response.data)
                sessionStorage.removeItem('__admin__token&');
                localStorage.removeItem('__admin__token&');
                history("/", { replace: true });
                window.location.reload();


            }


            return

        }).catch((err) => {
            
            console.log(err.response)

            if (err.response.errors.oldpassword) {
                setErrMsg({ ...errMsg, oldpassword: "Old Password is incorrect" });

            }
            else {
                setErrMsg({ ...errMsg, oldpassword: "Old Password is incorrect" });
            }

        });



    }

    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column  align-items-center h-100", css3.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_([" shadow d-flex flex-column justify-content-center align-items-center", css3.center_container])}
                >
                    <div className={_(["d-flex flex-column justify-content-center align-items-center mt-5", css3.body_header])}>

                        <p className={_(["mb-1", css.font1, css.font_32])}>
                            Change Password
                        </p>
                        <p className={_(["", css.font2, css.font_18])}>
                            It's good to use a strong password.
                        </p>

                    </div>
                    <div className={_(["", css2.inside_body, css.font_18])}
                    >

                        <div className={_([" mb-3 mt-3"])}>
                            <label className={_([" ", css.font1])}>
                                Current Password <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>*</sup>
                            </label>
                            <div className={_(["d-flex flex-row align-items-center", css.password_input])}>
                                <input
                                    type={currentPasswordVisible ? 'input' : 'password'}
                                    className={_(['input_box', css.input_box_style, css.font2])}
                                    placeholder='Password'
                                    name='oldpassword'
                                    onChange={handleChange}
                                    value={state.oldpassword}
                                    id='password'
                                />
                                <img
                                    src={visibleIcon}
                                    alt='eyeicon'
                                    name='currentPassword'
                                    className={_(["", css.visible_icon])}
                                    onClick={togglePassword}
                                ></img>
                            </div>
                            {errMsg.oldpassword &&
                                <div className="input__err__group1 d-flex justify-content-center align-items-start">
                                    <img alt='error' className=""></img>
                                    <span >{errMsg.oldpassword}</span>

                                </div>
                            }
                        </div>
                        <div className={_([" mb-3 mt-3"])}>
                            <label className={_([" ", css.font1])}>
                                New Password <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>*</sup>
                            </label>
                            <div className={_(["d-flex flex-row align-items-center", css.password_input])}>
                                <input
                                    type={newPasswordVisible ? 'input' : 'password'}
                                    className={_(['input_box', css.input_box_style, css.font2])}
                                    placeholder='Password'
                                    name='password'
                                    value={state.password}
                                    onChange={handleChange}
                                    id='password'
                                />
                                <img
                                    src={visibleIcon}
                                    alt='eyeicon'
                                    name='newPassword'
                                    className={_(["", css.visible_icon])}
                                    onClick={togglePassword}
                                ></img>
                            </div>
                            {errMsg.password &&
                                <div className="input__err__group1 d-flex justify-content-center align-items-start">
                                    <img alt='error' className=""></img>
                                    <span>{errMsg.password}</span>

                                </div>
                            }
                        </div>
                        <div className={_([" mb-3 mt-3"])}>
                            <label className={_([" ", css.font1])}>
                                Confirm New Password <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>*</sup>
                            </label>
                            <div className={_(["d-flex flex-row align-items-center", css.password_input])}>
                                <input
                                    type={confirmNewPasswordVisible ? 'input' : 'password'}
                                    className={_(['input_box', css.input_box_style, css.font2])}
                                    placeholder='Password'
                                    name='confirm_password'
                                    onChange={handleChange}
                                    value={state.confirm_password}
                                    id='password'
                                //onChange={(event)=>{setStateFunc(event,setState,state,'password')}}
                                // ref={password}
                                />
                                <img
                                    src={visibleIcon}
                                    alt='eyeicon'
                                    name='confirmNewPassword'
                                    className={_(["", css.visible_icon])}
                                    onClick={togglePassword}
                                ></img>
                            </div>
                            {errMsg.confirm_password &&
                                <div className="input__err__group1 d-flex justify-content-center align-items-start">
                                    <img alt='error' className=""></img>
                                    <span>{errMsg.confirm_password}</span>

                                </div>
                            }
                        </div>

                        <div className={_(["d-flex flex-row justify-content-around align-items-center mt-5 mb-5", css.font1])}
                        >

                            <button
                                type='submit'
                                onClick={changepass}
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button_shadow, css.font_18])}
                            >
                                Update Password
                            </button>
                        </div>

                    </div>
                </div>




            </Modal>

        </>
    );
}

export default ChangePassword;