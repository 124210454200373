import React, { useState, useEffect, useContext } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import editIcon from "../../assets/Icons/Icon material-edit.svg"
import noParkings from "../../assets/JSON-Gifs/GIF/15.Empty list.json";
import { Link, useNavigate } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"
import axiosInstance from "../../axios";
import DeletePopup from "../Reusable Popup/DeletePopup";
import DeleteSuccess from "../Reusable Popup/DeleteSucess";
import Loader from "../Reusable Popup/Loader";
import LottieGIF from "../lottieComponent";
import AccessRequestPopup from "../Reusable Popup/AccessRequest";

import "../../styles.css"

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const RESULT_LIMIT_IN_PAGE=6

function Roles() {

    const history = useNavigate();
    


    const [selectedOption, setSelectedOption] = useState('')
    const [deletion, setDelete] = useState(false);
    


    const [search, setSearch] = useState("")
    const [state, setState] = useState([])

    const [loadingData, setLoadingData] = useState(false)
    const [page, setPage] = useState(1)
    const [valu,setvalue]=useState()
    const [arr,setarr]=useState([])
    const [confirmation, setconfirmation]=useState(false)
    const [Accessrequest, setAccessrequest]=useState(false)
    const deletetoggle=(e)=>{
        const element = e.target
        setvalue(element.getAttribute('data-id'))
        setDelete(true)
    }
    const DeleteToggle = (e) => {
        
        if(e.target.value==='Cancel'){
            setDelete(false)
        }
        else{
            console.log(e.target.value,arr.length===0)
            
            if(e.target.value==='Yes' && arr.length!==0){
                setDelete(false)
                setarr([])
                DeletedArr()
                
                
            }
            else{
                console.log(e.target.value,arr)
                setDelete(false)
                Deleted()
            }
        }
        
    }

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })
    const [sort,setsort] = useState('')

    const filter=(e)=>{
        console.log(e.target.value)
        setsort(e.target.value)
    }

    const Deleted = async (e) => {
        // setLoading(true);
        // setColor('#5c5b5b');
        console.log('id', valu)
        
        setDelete(false)

        let formData = new FormData();

        formData.append('id',valu)

        await axiosInstance.post(
            "admin_dash/delete_role",formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.error_msg)
            if (response.data.success==='false'){
                alert(response.data.error_msg)}
            else{
            setconfirmation(true)}
            // setLoading(false);
            callSearch()

        }).catch((err) => {
            console.log("got an error", err.response.data.error_msg)
            if (err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            setLoadingData(false);
        })


    }
    const DeletedArr = async (event) => {


        let formData = new FormData();

        
        formData.append("id", arr)


        await axiosInstance.post(
            "admin_dash/delete_role", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            if (response.data.success==='false'){
                alert(response.data.error_msg)}
            else{
            setconfirmation(true)}
            callSearch()
            setarr([])

        }).catch((err) => {
            console.log("got an error", err.response)
            if (err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            setLoadingData(false);
            setarr([])
        })
    }

    const callSearch = async () => {

        setLoadingData(true)
        setarr([])
        console.log(selectedOption,search,page)

        let formData = new FormData();


        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)


        await axiosInstance.post(

            "admin_dash/search_role",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            console.log(response.data.response.result)
            setState(response.data.response.result)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })
            setLoadingData(false)

        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    useEffect(() => {
        callSearch()
    }, [selectedOption, page,sort])


    const toggleButtonSearch = (e) => {
        setSelectedOption(e.target.name);
    }

    const [editPricePopup, setEditPricePopup] = useState({
        popup: false,
        spotId: "",
    })

    const toggleEditPricePopup = () => {
        if (editPricePopup.popup) {
            setEditPricePopup({
                ...editPricePopup,
                popup: false,
            })
        }
        else {
            setEditPricePopup({
                ...editPricePopup,
                popup: true,
            })
        }
    }
    const Operner=()=>{
        if (arr.length!==0){
            setDelete(true)

        }
        else{
            alert('Please select the Roles')
        }
        
        
    }

    const DeleteArr=(e)=>{
        
        if(e.target.checked){
            arr.push(e.target.name)
            console.log(arr)
        }
        else{
            arr.splice(arr.indexOf(e.target.name),1)
            console.log(arr)
        }}
        
    const [change, setChange] = useState(false);
    const changeToggle = () => {
        if (change) {
            setChange(false)
        }
        else {
            setChange(true)
        }
    }

    const ActiveToggle = async (event) => {
    const element = event.target
    const vehicleId = await element.getAttribute('data-id')
    const vehicleActive = await element.getAttribute('data-active')


    if (vehicleActive==='true') {
        var st='false'
    } else {
        var st='true'
    }

    let formData = new FormData();
    formData.append('id', vehicleId)
    formData.append('role_status', st)

    await axiosInstance.post(

        "admin_dash/deactive_role",
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
    ).then((response) => {
        changeToggle()
        callSearch()
    }).catch((err) => {
        
        console.log("got an error", err)
        if (err.response.data.error_msg==='you have no access'){
            setAccessrequest(true)
        }
        else{
        alert('Failed !')}
        
        setLoadingData(false);
        
    })
}


    return (

        <div className="col-md-12 p-0 mb-5">
            <div className="d-flex justify-content-end" >
                <button
                    className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                    onClick={() => history("/Dashboard/CreateARole/CompanyProfile", { replace: true })}
                >
                    Create A Role
                </button>
            </div>
            <div className={_([" w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                        name=''
                        onClick={toggleButtonSearch}
                    >
                        All
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Active' ? css2.selected_search_button : '')])}
                        name='Active'
                        onClick={toggleButtonSearch}
                    >
                        Active
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Deactivated' ? css2.selected_search_button : '')])}
                        name='Deactivated'
                        onClick={toggleButtonSearch}
                    >
                        Deactivated
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <button style={{border:'None',background:'transparent'}} onClick={callSearch}><img src={searchIcon} alt="search">
                        </img></button>

                        <input
                                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                                    type="input"
                                    name="search"
                                    placeholder="Search here"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            callSearch()
                                        }
                                    }}
                                    style={{ border: '0px', background: 'none' }}
                                >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                {selectedOption === '' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                            <label className={_(["", css.font_18, css.font2])}>{pagination.totalResults} Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'Active' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>{pagination.totalResults} Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'Deactivated' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                            <label className={_(["", css.font_18, css.font2])}>{pagination.totalResults} Users</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])} onClick={Operner}>
                            <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                            Delete
                        </button>
                    </div>

                </div>

            </div>
            <div className={_([" col-md-12 flex-grow-1", css2.table])}>
                <div  className={_(["mt-2", css.font_18, css.font2, css2.search_list])}>
                    <div  className={_(["row", css2.search_header])}>
                        <div className='col-md-1 mt-3'>#</div >
                        <div className='col-md-3 mt-3'>Role</div >
                        <div className='col-md-3 mt-3'>Description</div >
                        <div className='col-md-3 mt-3'>Created By</div >
                        <div className='col-md-2 mt-3'>Actions</div >
                    </div >




                    {loadingData?<div className="row justify-content-center mt-5"><Loader   loading={loadingData} /></div>:
                    state.map((state, index) => {
                                return (<>
                                    <div className={_(["row"])}>
                                        <div className="col-md-1 mt-3">
                                            <div className="d-flex justify-content-center align-items-center ml-3">
                                                <label className={_(["mb-3", css.checkbox_group])} htmlFor={"check" + index}>
                                                    <input type="checkbox" className={_(["", css.checkbox_size])} name={state.id} id={"check" + index} onChange={DeleteArr}></input>
                                                    <span className={_(["mr-1", css.box])}></span>
                                                </label>
                                                <div className="index d-flex align-items-center"><p>{index+1}</p></div>
                                            </div>
                                        </div>
                                        <div className={_(["col-md-3 mt-3"])}>

                                            <div className={_(["justify-content-center align-items-center"])}>
                                                <label className={_(["", css.font4])}>{state.job_task}</label>

                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <div className={_(["justify-content-center align-items-center"])}>
                                                <label className={_(["", css.font4])}>{state.description}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-3" >
                                        <div className={_(["justify-content-center align-items-center"])}>
                                                <label className={_(["", css.font4])}>{state.created_by===null?'-':state.created_by}</label>
                                            </div>
                                           
                                        </div>
                                        <div className="mt-3 col-md-2">
                                            {selectedOption === '' ?
                                                <div className="dropdown ">
                                                    <p className="mb-0" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" data-id={state.id} >Edit</Link>
                                                        <Link className="dropdown-item" to="#"  data-id={state.id} data-active={state.role_status} onClick={ActiveToggle}>{state.role_status ? "Deactivate" : "Active"}</Link>
                                                        <Link className="dropdown-item" to="#"  data-id={state.id} onClick={deletetoggle}  >Delete</Link>

                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'Active' ?
                                                <div className="dropdown ">
                                                    <p className="mb-0" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item" to="#" data-id={state.id} >Edit</Link>
                                                        <Link className="dropdown-item" to="#"  data-id={state.id} data-active={state.role_status} onClick={ActiveToggle}>Deactivate</Link>
                                                        <Link className="dropdown-item" to="#"  data-id={state.id}  onClick={deletetoggle} >Delete</Link>

                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'Deactivated' ?
                                                <div className="dropdown ">
                                                    <p className="mb-0" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" data-id={state.id} >Edit</Link>
                                                        <Link className="dropdown-item" to="#" data-id={state.id} data-active={state.role_status} onClick={ActiveToggle}>Active</Link>
                                                        <Link className="dropdown-item" to="#" data-id={state.id}  onClick={deletetoggle} >Delete</Link>

                                                    </div>
                                                </div> : null}


                                        </div>
                                    </div></>)
                            })}
                    {state.length === 0 ?
                        (
                            <>
                                {loadingData?null:<div className="mt-5 w-100 d-flex flex-column align-items-center">
                                <LottieGIF json={noParkings} loop={true} height="15rem" width="15rem"></LottieGIF>
                                    <span className={_(["mt-4", css.font2, css.font_18])}>No Roles available right now! :(</span>
                                </div>}
                            </>
                        )
                        :
                        null

                        }

                </div>
                <DeletePopup  isOpen={deletion} heading={'Alert!'} subheading={'Are you sure you want to delete'} subheading2={'Admin Roles?'} buttontitle={'Cancel'} buttontitle2={'Yes'} toggle={DeleteToggle}/>
                <DeleteSuccess  isOpen={confirmation} heading={'Great!'} subheading={'Successfully Deleted'} subheading2={'Admin Roles'} buttontitle={'Okey'} toggle={()=>{setconfirmation(false)}}/>
                
                <AccessRequestPopup  isOpen={Accessrequest} heading={'Alert!'} subheading={'edit the roles'} title={'Request to edit'} msg={'Manage the Roles'} permissionname={'manage_role'} path={''}  toggle={()=>{setAccessrequest(false)}}/>
                

            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
            {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                            <PaginationComponent
                                count={pagination.noOfPages}
                                page={page}
                                setPage={setPage}
                            />
                            :
                            null
                        }
            </div>


        </div>



    )
}


export default Roles;