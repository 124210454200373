import React, { memo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navz from '../components/Navbar1';
import Loadable from "react-loadable";
import TestComponent from "./../components/lottieComponent";




/* My profile*/

import MyProfileEdit from "../components/myprofile/myprofilecomponent/MyProfileEdit";

/*terms*/


/* About us*/



/*Report & inquries*/



/* provider details*/
import ProviderDetail from "../components/providerdetail/ProviderDetail";
import ProviderProfile from "../components/providerdetail/profile/Profile";
import ProviderBookings from "../components/providerdetail/bookings/Bookings";
import ProviderCoupons from "../components/providerdetail/coupons/Coupons";
import ProviderDocuments from "../components/providerdetail/documents/Documents";
import ProviderEarnings from "../components/providerdetail/earnings/Earnings";
import ProviderInquries from "../components/providerdetail/inquiries/Inquries"
import ProviderParkingSpot from "../components/providerdetail/parkingspots/ParkingSpot";


import UsersDetail from "../components/usersdetails/UsersDetails";
import UsersBookings from "../components/usersdetails/bookings/UsersBookings";
import UsersProfile from "../components/usersdetails/profile/Profile";


/* dashboard*/
import Dashboard from "../Dashboard_page/auth/Dashboard";
import ParkingSpots from '../components/Parking_spot/parkingspots/ParkingSpots';
import Bookings from '../components/Bookings/Bookings';
import NotificationComponent from '../components/notification/Notifications';
import Users from '../components/Users/Users'
import AddParkingSpot from '../components/Parking_spot/AddParkingSpot';
import BasicDetails from '../components/Parking_spot/BasicDetails';
import ParkingSpotAddress from '../components/Parking_spot/ParkingSpotAddress';
import OtherDetails from '../components/Parking_spot/OtherDetails';
import AppliedForApproval from '../components/Parking_spot/AppliedForApproval';
import ViewParkingSpot from "../components/Parking_spot/parkingspots/ViewParkingSpot";
import AdminDashbord from '../components/admindashbord';
import MapviewDashboard from '../components/Parking_spot/googlemap/MapViewDashboard';

import DashboardContext from "../Dashboard_page/auth/DashboardContext";


import ParkingDataRouters from "./authrouts/ParkingDataRouters";
import CompanyProfileRouters from "./authrouts/CompanyProfileRouters";
import MyProfileRouters from "./authrouts/MyProfileRouters";
import TermsRouters from "./authrouts/TermsRouters";
import AboutusRouters from "./authrouts/AboutusRouters";
import ReportInquiryRouters from "./authrouts/ReportInquiryRouters";
import ViewBooking from "../components/Bookings/ViewBookings";
import ViewInvoice from "../components/Bookings/ViewInvoice";






const LoginPage = Loadable({
    loader: () =>
        import("../Dashboard_page/public/admin/Login"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ForgotPasswordPage = Loadable({
    loader: () =>
        import("../Dashboard_page/public/admin/ForgotPassword"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ForgotPassword2Page = Loadable({
    loader: () =>
        import("../Dashboard_page/public/admin/ForgotPassword2"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const OtpVerficationPage = Loadable({
    loader: () =>
        import("../Dashboard_page/public/admin/OTPVerification"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ResetPasswordPage = Loadable({
    loader: () =>
        import("../Dashboard_page/public/admin/ResetPassword"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});











function PublicRoute() {

    return (
        <Router>
            <Navz />
            <DashboardContext>
            <Routes>
                <Route exact path='/' element={<LoginPage />} />
                <Route exact path='/login' element={<LoginPage />} />
                <Route exact path='/forgotpassword' element={<ForgotPasswordPage />} />
                <Route path='/forgotpassword/email' element={<ForgotPassword2Page />} />
                <Route path='/forgotpassword/phone' element={<ForgotPassword2Page />} />
                <Route path='/forgotpassword/otpverification' element={<OtpVerficationPage />} />
                <Route path='/forgotpassword/resetpassword' element={<ResetPasswordPage />} />
                <Route path='/test' element={<TestComponent />} />


                

                <Route path='/Dashboard/Mapview' element={<MapviewDashboard />} />
                <Route path='/Dashboard' element={<Dashboard/>} >
                
                    <Route path='/Dashboard/details' element={<AdminDashbord />} />
                    {/* company profile */}
                    {/* my profile */}
                    {/* <Route path='/Dashboard/ViewDetails' element={<AdminAccessDetail />} /> */}
                    {/* <Route path='/Dashboard/MyProfile' element={<MyProfile />}>
                        <Route path='/Dashboard/MyProfile/MyAccount' element={<MyAccount />} />
                        <Route path='/Dashboard/MyProfile/EmailNotification' element={<EmailNotification />} />
                        <Route path='/Dashboard/MyProfile/SendNotification' element={<SendNotification />} />
                        <Route path='/Dashboard/MyProfile/AdminActivities' element={<AdminActivities />} />
                        <Route path='/Dashboard/MyProfile/AdminAccess' element={<AdminAccess />} />
                        
                    </Route> */}
                    <Route path='/Dashboard/MyAccount/Edit' element={<MyProfileEdit />} />
                    {/* terms */}
                    {/* <Route path='/Dashboard/Terms' element={<Terms />}>
                        <Route path='/Dashboard/Terms/DocumentationTerms' element={<DocumentationTerms />} />
                        <Route path='/Dashboard/Terms/ExtensionTerms' element={<ExtensionTerms />} />
                        <Route path='/Dashboard/Terms/HourlyParkingTerms' element={<HourlyParkingTerms />} />
                        <Route path='/Dashboard/Terms/MonthlyParkingTerms' element={<MonthlyParkingTerms />} />
                        <Route path='/Dashboard/Terms/ProviderTerms' element={<ProviderTerms />} />
                        <Route path='/Dashboard/Terms/TermsConditions' element={<TermsConditions />} />

                    </Route> */}
                    {/* about us */}
                    {/* <Route path='/Dashboard/AboutUs' element={<AboutUs />}>
                        <Route path='/Dashboard/AboutUs/AboutParkingBud' element={<AboutParkingBud />} />
                        <Route path='/Dashboard/AboutUs/HelpFAQs' element={<HelpFAQs />} />
                        <Route path='/Dashboard/AboutUs/PrivacyPolicy' element={<PrivacyPolicy />} />
                        <Route path='/Dashboard/AboutUs/WhoWeAre' element={<WhoAreWe />} />
                        <Route path='/Dashboard/AboutUs/OurVision' element={<OurVision />} />

                    </Route> */}
                    {/* report inquries */}
                    {/* <Route path='/Dashboard/ReportInquries' element={<ReportInquries />}>
                        <Route path='/Dashboard/ReportInquries/ChatInquiries' element={<ChatInquries />} />
                        <Route path='/Dashboard/ReportInquries/ContactUsQueries' element={<ContactUsQueries />} />
                        <Route path='/Dashboard/ReportInquries/ServiceCharges' element={<ServiceCharges />} />
                        <Route path='/Dashboard/ReportInquries/Statistics' element={<AdminStats />} />

                    </Route> */}
                    {/* provider details */}
                    <Route path='/Dashboard/ProviderDetail' element={<ProviderDetail />}>
                        <Route path='/Dashboard/ProviderDetail/Profile' element={<ProviderProfile />} />
                        <Route path='/Dashboard/ProviderDetail/Bookings' element={<ProviderBookings />} />
                        <Route path='/Dashboard/ProviderDetail/Coupons' element={<ProviderCoupons />} />
                        <Route path='/Dashboard/ProviderDetail/Documents' element={<ProviderDocuments />} />
                        <Route path='/Dashboard/ProviderDetail/Earnings' element={<ProviderEarnings />} />
                        <Route path='/Dashboard/ProviderDetail/Inquiries' element={<ProviderInquries />} />
                        <Route path='/Dashboard/ProviderDetail/ParkingSpot' element={<ProviderParkingSpot />} />


                    </Route>





                    {/* Users details */}
                    <Route path='/Dashboard/UsersDetail' element={<UsersDetail />}>
                        <Route path='/Dashboard/UsersDetail/UsersBookings' element={<UsersBookings />} />
                        <Route path='/Dashboard/UsersDetail/UsersProfile' element={<UsersProfile />} />

                    </Route>


                    {/* parking spot */}
                    <Route path="/Dashboard/ParkingSpots" element={<ParkingSpots />} ></Route>
                    <Route path="/Dashboard/ParkingSpots/AddParkingSpot" element={<AddParkingSpot />}>
                        <Route path="/Dashboard/ParkingSpots/AddParkingSpot/BasicDetails" element={<BasicDetails />} />
                        <Route path="/Dashboard/ParkingSpots/AddParkingSpot/ParkingSpotAddress" element={<ParkingSpotAddress />} />
                        <Route path="/Dashboard/ParkingSpots/AddParkingSpot/OtherDetails" element={<OtherDetails />} />
                        <Route path="/Dashboard/ParkingSpots/AddParkingSpot/AppliedForApproval" element={<AppliedForApproval />} />
                    </Route>
                    <Route path="/Dashboard/ViewParkingSpot" element={<ViewParkingSpot />} />



                    <Route path="/Dashboard/Bookings" element={<Bookings />} ></Route>
                    <Route path="/Dashboard/ViewBooking" element={<ViewBooking />} />
                    <Route path="/Dashboard/Bookings/invoice" element={<ViewInvoice />} />

                    <Route path="/Dashboard/Notification" element={<NotificationComponent />} ></Route>
                    
                    <Route path="/Dashboard/Users" element={<Users />} ></Route>



                </Route>
            </Routes>
            
            <ParkingDataRouters/>
            <CompanyProfileRouters/>
            <MyProfileRouters/>
            <TermsRouters/>
            <AboutusRouters/>
            <ReportInquiryRouters/>

            </DashboardContext>
            
        </Router>
    );
}

export default memo(PublicRoute);
