// import css2 from "./../common.module.css";
// import css from "./../../main.module.css";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { Modal } from "reactstrap";
// import Icon1 from "../../assets/svgs/Group 29118.svg";
// import "./../../styles.css";

// const _ = (classes) => {
//   let s = "";
//   classes.map((i) => (s += i + " "));
//   return s;
// };

// function BarcodePopup(props) {
//   const history = useNavigate();

//   return (
//     <>
//       <Modal
//         isOpen={props.isOpen}
//         className={_([
//           "d-flex flex-column align-items-center mt-5 h-100",
//           css2.container,
//         ])}
//         backdrop={true}
//         toggle={props.toggle}
//       >
//         <div
//           className={_([
//             " shadow d-flex flex-column justify-content-center align-items-center p-4",
//             css2.center_container,
//           ])}
//         >
//           <div
//             className={_([
//               "d-flex flex-column justify-content-center align-items-center",
//               css2.body_header,
//             ])}
//           >
//             <img src={Icon1}></img>
//           </div>
//           <div
//             className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
//           >
//             <div
//               className={_([
//                 "d-flex flex-column justify-content-center align-items-center mt-0 mb-4",
//                 css.font1,
//               ])}
//             >
//               <p className={_(["mb-0", css.font_18, css.font1])}>User Query</p>
//               <p className={_(["mb-0", css.font_18, css.font2])}>
//                 A query / message from users. Get back to then as soon as
//                 possible.
//               </p>
//               <p className={_(["mb-0", css.font_18, css.font2])}></p>
//             </div>
//             <div className="row">
//               <div className={_(["", css2.input_font, css.font_18])}>
//                 <div className={_(["row", css2.column_width3])}>
//                   <label
//                     className={_([
//                       "col-md-2 d-flex flex-row-reverse align-items-center",
//                     ])}
//                   >
//                     From:
//                   </label>

//                   <input
//                     type="input"
//                     className={_([
//                       "col-md-10 bg-light",
//                       css.input_box_style,
//                       css.font2,
//                     ])}
//                     placeholder="First Name"
//                     name="firstName"
//                     //onChange={(event)=>{setStateFunc(event,setState,state,'username')

//                     //}}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className={_([" mt-2", css2.input_font, css.font_18])}>
//                 <div className={_(["row", css2.column_width3])}>
//                   <label
//                     className={_([
//                       "col-md-2 d-flex flex-row-reverse align-items-center",
//                     ])}
//                   >
//                     Email:
//                   </label>

//                   <input
//                     type="email"
//                     className={_([
//                       "col-md-10 bg-light",
//                       css.input_box_style,
//                       css.font2,
//                     ])}
//                     placeholder="First Name"
//                     name="firstName"
//                     //onChange={(event)=>{setStateFunc(event,setState,state,'username')

//                     //}}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className={_([" mt-2", css2.input_font, css.font_18])}>
//                 <div className={_(["row", css2.column_width3])}>
//                   <label
//                     className={_([
//                       "col-md-2 d-flex flex-row-reverse align-items-center",
//                     ])}
//                   >
//                     Phone:
//                   </label>

//                   <input
//                     type="tel"
//                     className={_([
//                       "col-md-10 bg-light",
//                       css.input_box_style,
//                       css.font2,
//                     ])}
//                     placeholder="First Name"
//                     name="firstName"
//                     //onChange={(event)=>{setStateFunc(event,setState,state,'username')

//                     //}}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className={_(["mt-2", css2.input_font, css.font_18])}>
//                 <div className={_(["row", css2.column_width3])}>
//                   <label
//                     className={_([
//                       "col-md-2 d-flex flex-row-reverse align-items-center",
//                     ])}
//                   >
//                     Message:
//                   </label>

//                   <textarea
//                     type="input"
//                     className={_([
//                       "col-md-10 bg-light",
//                       css.input_box_style,
//                       css.font2,
//                     ])}
//                     placeholder="First Name"
//                     name="firstName"
//                     //onChange={(event)=>{setStateFunc(event,setState,state,'username')

//                     //}}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className={_([" mt-2", css2.input_font, css.font_18])}>
//                 <div className={_(["row", css2.column_width3])}>
//                   <label
//                     className={_([
//                       "col-md-2 d-flex flex-row-reverse align-items-center",
//                     ])}
//                   >
//                     About:
//                   </label>

//                   <textarea
//                     type="input"
//                     className={_([
//                       "col-md-10 bg-light",
//                       css.input_box_style,
//                       css.font2,
//                     ])}
//                     placeholder="First Name"
//                     name="firstName"
//                     //onChange={(event)=>{setStateFunc(event,setState,state,'username')

//                     //}}
//                     required
//                   />
//                 </div>
//               </div>
//             </div>

//             <div
//               className={_([
//                 "d-flex flex-row justify-content-around align-items-center mt-5 mb-5",
//                 css.font1,
//               ])}
//             >
//               <button
//                 onClick={props.toggle}
//                 // type='submit'
//                 className={_([
//                   "btn btn_B d-flex justify-content-center align-items-center ",
//                   css2.inside_button,
//                   css.inside_button2,
//                   css.font_18,
//                 ])}
//               >
//                 Cancel
//               </button>
//               <button
//                 onClick={() => {}}
//                 // type='submit'
//                 className={_([
//                   "btn btn_B d-flex justify-content-center align-items-center ",
//                   css2.inside_button,
//                   css.inside_button1,
//                   css.font_18,
//                 ])}
//               >
//                 Send An Email
//               </button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// }

// export default BarcodePopup;

import css2 from "./../common.module.css";
import css from "./../../main.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import Icon1 from "../../assets/svgs/Group 29118.svg";
import phone from "../../assets/svgs/phone-alt.svg";
import car from "../../assets/svgs/sedan-car-model.svg";
import parklogo from "../../assets/svgs/Group 23627.svg";
import gate from "../../assets/svgs/Mask-Group-156.svg";
import download from "../../assets/Icons/Icon feather-share.svg";
import { QRCodeCanvas } from "qrcode.react";

import "./../../styles.css";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function BarcodePopup({ isOpen, toggle, state }) {
  const history = useNavigate();

  return (
    <>
      <Modal
        isOpen={isOpen}
        className={_([
          "d-flex flex-column align-items-center mt-5 h-100",
          css2.container,
        ])}
        backdrop={true}
        toggle={toggle}
      >
        <div
          className={_([
            "d-flex flex-column justify-content-center align-items-center p-4",
          ])}
        >
          <div
            className={_([
              "d-flex flex-row justify-content-center align-items-center",
              css.w_full,
              css2.font1,
              css.font_24,
            ])}
          >
            Tribeca Parking
          </div>
          <div className={_(["", css2.border_bottom_container])} />
          <div
            className={_([
              "d-flex justify-content-center align-items-center mt-2",
              css.w_full,
              css.font3,
            ])}
          >
            Spot No. {state?.parking?.parking_spot_number}
          </div>

          <div
            className={_([
              "d-flex flex-row justify-content-center p-2",
              css.w_full,
              css.mt_28,
            ])}
          >
            <div
              className={_([
                "d-flex flex-column justify-content-center align-items-center",
                css.w_half,
              ])}
            >
              <div className={_(["mb-1", css.font10])}>
                Barcode to Check in / Check out
              </div>
              <div
                style={{ position: "relative", display: "inline-block" }}
                className={_([
                  " d-flex flex-column justify-content-center align-items-center",
                  css.barcodebg_lighthblue,
                ])}
              >
                {/* QR Code */}
                <div
                  className={_([
                    "d-flex flex-column justify-content-center align-items-center ",
                    css.barcodebg_blue,
                    css.w_full,
                  ])}
                >
                  <div className={_(["p-1 mt-2 rounded-lg", css.bg_white])}>
                    <QRCodeCanvas
                      value={state?.booking_pass_url}
                      style={{ height: "100%", width: "128px" }}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"H"}
                    />
                    {/* Logo Overlay */}
                    <img
                      src={parklogo}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        top: "35%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </div>
                  <div className={_(["", css.font_white])}>29 DAYS</div>
                  <div className={_(["", css.font_white])}>Wed 30 Dec</div>
                </div>
                <div className={_(["", css.pass_code])}>
                  #{state?.booking_pass_code}
                </div>
              </div>
            </div>
            <div
              className={_([
                "d-flex flex-column justify-content-center align-items-center",
                css.w_half,
              ])}
            >
              <div
                className={_([
                  "d-flex flex-row justify-start align-items-center p-1",
                  css.w_full,
                ])}
              >
                <div
                  className={_([
                    "d-flex justify-content-center align-items-center",
                  ])}
                >
                  <img
                    style={{ width: "30px", height: "20px" }}
                    src={phone}
                    alt="phone"
                  />
                </div>
                <div
                  className={_([
                    "pl-4 d-flex justify-content-center align-items-center",
                  ])}
                >
                  (+{state?.parking?.parking_country_code}){" "}
                  {state?.parking?.parking_phone_number}
                </div>
              </div>
              <hr className={_(["", css.hr_barcode])} />

              <div
                className={_([
                  "d-flex flex-row justify-start align-items-center p-1",
                  css.w_full,
                ])}
              >
                <div
                  className={_([
                    "d-flex justify-content-center align-items-center",
                  ])}
                >
                  <img
                    style={{ width: "30px", height: "20px" }}
                    src={car}
                    alt="phone"
                  />
                </div>
                <div
                  className={_([
                    "pl-4 d-flex justify-content-center align-items-center",
                  ])}
                >
                  {state?.vehicle_tag_id}
                </div>
              </div>
              <hr className={_(["", css.hr_barcode])} />
              <div
                className={_([
                  "d-flex flex-row justify-start align-items-center p-1",
                  css.w_full,
                ])}
              >
                <div
                  className={_([
                    "d-flex justify-content-center align-items-center",
                  ])}
                >
                  <img
                    style={{ width: "30px", height: "20px" }}
                    src={parklogo}
                    alt="phone"
                  />
                </div>
                <div
                  className={_([
                    "pl-4 d-flex justify-content-center align-items-center",
                  ])}
                >
                  {state?.parking?.parking_spot_address1}
                </div>
              </div>
              <hr className={_(["", css.hr_barcode])} />
              <div
                className={_([
                  "d-flex flex-row justify-start align-items-center p-1",
                  css.w_full,
                ])}
              >
                <div
                  className={_([
                    "d-flex justify-content-center align-items-center",
                  ])}
                >
                  <img
                    style={{ width: "30px", height: "20px" }}
                    src={gate}
                    alt="phone"
                  />
                </div>
                <div
                  className={_([
                    "pl-4 d-flex justify-content-center align-items-center",
                  ])}
                >
                  HOA Community, GATE Code: ABC123
                </div>
              </div>

              <button
                className={_([
                  "d-flex flex-row justify-content-center align-items-center mt-4",
                  css.downloadbtn,
                  css.font5,
                ])}
              >
                <img className={_(["mr-2"])} src={download} alt="download" />
                Download Pass
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BarcodePopup;
