import css from "../../main.module.css";
import css3 from "./../common.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg";
import editIcon from "../../assets/Icons/Icon material-edit.svg";
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import defaultSPotImg from "../../assets/Default_spot_img.svg";
import "../../styles.css";
import "./myprofile.css";
import React, { useState, useEffect } from "react";
import FileImage from "../../assets/file-upload.png";
import axiosInstance from "../../axios";
import ScheduleAnotification from "../popups/ScheduleNotifyPopup";
import SendAnotification from "../popups/SendNotifyPopup";
import noParkings from "../../assets/Icons/Group 26816.svg";

const hour = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const minute = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
];

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

var Data_Format = {
  id: null,
  email: null,
  username: null,
  date_joined: null,
  last_login: null,
  is_admin: null,
  is_active: null,
  is_staff: null,
  is_superuser: null,
  first_name: null,
  last_name: null,
  user_type: null,
  address_line_1: null,
  address_line_2: null,
  profile_pic: null,
  country_code: null,
  phone_number: null,
  country: null,
  city: null,
  state: null,
  zip_code: null,
  is_user_blocked: null,
  timezone: null,
};

const RESULT_LIMIT_IN_PAGE = 6;

function SendNotification(props) {
  const toggleButtonSearch = (e) => {
    setselectedOption(e.target.name);
  };

  const handleImage = (e) => {
    // const file = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //     this.setState({ notificationFile: file })
    //     this.setState({ notificationFileName: [reader.result] });
    // }
  };

  const deletearr = (e) => {
    if (e.target.checked) {
      arr.push(e.target.name);
      console.log(arr);
    } else {
      arr.splice(arr.indexOf(e.target.name), 1);
      console.log(arr);
    }
  };

  const deleteval = async () => {
    let formData = new FormData();

    formData.append("id_list", arr);
    await axiosInstance
      .post("admin_dash/delete_send_notification", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        callSearch();
        setarr([]);

        console.log(response, "adfadfaf");
        setLoadingData(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err.response);
      });
  };

  const openSendModal = () => {
    if (sendModal) {
      setsendModal(false);
    } else {
      setsendModal(true);
    }
  };
  const openSchduleModal = () => {
    if (scheduleModal) {
      setscheduleModal(false);
    } else {
      setscheduleModal(true);
    }
  };

  const [arr, setarr] = useState([]);
  const [selectedOption, setselectedOption] = useState("");
  const [imagefilename, setimagefilename] = useState("");
  const [sendModal, setsendModal] = useState(false);
  const [scheduleModal, setscheduleModal] = useState(false);
  const [state, setState] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setsort] = useState("");
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });

  const callSearch = async () => {
    setColor("#5c5b5b");

    setLoadingData(true);
    setState([]);

    let formData = new FormData();

    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("page", page);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("status", selectedOption);

    await axiosInstance
      .post("admin_dash/search_nofti_data", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        setState(response.data.response.result);
        setPagination({
          count: response.data.pagination.count,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });
        setLoadingData(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  useEffect(() => {
    callSearch();
    console.log(props);
  }, [selectedOption, page, sort]);
  return (
    <>
      <div className="col-md-12 p-0 mb-5">
        <div className="d-flex justify-content-end">
          <button
            className={_([
              "align-self-end",
              css.font_18,
              css.inside_button2,
              css2.add_new_button,
            ])}
            onClick={openSchduleModal}
          >
            Scheduled A Notification
          </button>
          <button
            className={_([
              "align-self-end",
              css.font_18,
              css.inside_button1,
              css2.add_new_button,
            ])}
            onClick={openSendModal}
          >
            Send A Notification
          </button>
        </div>

        <div className={_([" w-100", css2.search_row])}>
          <div>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "" ? css2.selected_search_button : "",
              ])}
              name=""
              onClick={toggleButtonSearch}
            >
              All
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "sent" ? css2.selected_search_button : "",
              ])}
              name="sent"
              onClick={toggleButtonSearch}
            >
              Sent
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "scheduled"
                  ? css2.selected_search_button
                  : "",
              ])}
              name="scheduled"
              onClick={toggleButtonSearch}
            >
              Scheduled
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "draft" ? css2.selected_search_button : "",
              ])}
              name="draft"
              onClick={toggleButtonSearch}
            >
              Drafts
            </button>
          </div>
          <div className={_(["d-flex justify-content-end"])}>
            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
              <img src={searchIcon} alt="search"></img>

              <input
                className={_(["ml-1 w-100", css.font_18, css.font2])}
                type="input"
                name="search"
                placeholder="Search here"
                style={{ border: "0px", background: "none" }}
              ></input>
            </div>
          </div>
        </div>
        <div
          className={_([
            "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
            css2.search_row_2,
          ])}
        >
          {selectedOption === "" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  All
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  4 Notification
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "sent" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Sent
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  6 Notification
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "scheduled" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Scheduled
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  6 Notification
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}

          {selectedOption === "draft" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Drafts
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  6 Notification
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          <div
            className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
          >
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            >
              <button
                className={_([
                  " pl-2 pr-2 d-flex align-items-center",
                  css.font_18,
                  css.inside_button7,
                ])}
                onClick={deleteval}
              >
                <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className={_(["flex-grow-1", css2.table])}>
          <div
            className={_(["mt-2", css.font_18, css.font2, css2.search_list])}
          >
            <div className={_(["row w-100 mt-2", css2.search_header])}>
              <div className="col-md-1 mt-2">#</div>
              <div className="col-md-1 mt-2"></div>
              <div className="col-md-2 mt-2">Title</div>
              <div className="col-md-4 mt-2">Body Message</div>
              <div className="col-md-2 mt-2">Created By</div>
              <div className="col-md-1 mt-2"></div>
              <div className="col-md-1 mt-2">Actions</div>
            </div>

            {state.length > 0 ? (
              state.map((state, index) => {
                return (
                  <>
                    {console.log(state)}
                    <div className={_(["row mt-2"])}>
                      <div className="col-md-1 mt-3 mb-2">
                        <label
                          className={_([
                            "flex-grow-1 d-flex justify-content-center",
                            css.checkbox_group,
                          ])}
                          htmlFor={"check" + index}
                        >
                          <input
                            type="checkbox"
                            className={_(["mr-2", css.checkbox_size])}
                            name={state.id}
                            id={"check" + index}
                            onChange={deletearr}
                          ></input>
                          <span className={_(["mr-1", css.box])}></span>
                        </label>
                      </div>
                      <div className={_(["col-md-1"])}>
                        <img
                          src={
                            state.notifi_image !== null
                              ? state.notifi_image
                              : defaultSPotImg
                          }
                          className={_(["", css2.search_spot_img])}
                        ></img>
                      </div>
                      <div className={_(["col-md-2"])}>
                        <label className={_(["", css.font4])}>
                          {state.title}
                        </label>
                      </div>
                      <div className={_(["col-md-4 "])}>
                        {state.body_message}
                      </div>
                      <div className="col-md-2 mb-2 mt-3">
                        {state.created_by}
                      </div>
                      <div className="col-md-1 mb-2 mt-3">
                        <label className={_(["", css.font4])}>
                          {state.status_nofti}
                        </label>
                      </div>

                      <div className="col-md-1 mb-2 mt-3">
                        {selectedOption === "" ? (
                          <div className="dropdown">
                            <p
                              className="mb-0 dropdown-toggle"
                              to="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={actionIcon}></img>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <Link className="dropdown-item" to="#">
                                Edit
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Send
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete
                              </Link>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "sent" ? (
                          <div className="dropdown">
                            <p
                              className="mb-0 dropdown-toggle"
                              to="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={actionIcon}></img>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <Link className="dropdown-item" to="#">
                                Edit
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Send
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete
                              </Link>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "scheduled" ? (
                          <div className="dropdown">
                            <p
                              className="mb-0 dropdown-toggle"
                              to="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={actionIcon}></img>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <Link className="dropdown-item" to="#">
                                Edit
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Send
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete
                              </Link>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "drafts" ? (
                          <div className="dropdown">
                            <p
                              className="mb-0 dropdown-toggle"
                              to="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={actionIcon}></img>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <Link className="dropdown-item" to="#">
                                Edit
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Send
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete
                              </Link>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                  <img src={noParkings} alt="No Parkings"></img>
                  <span className={_(["mt-4", css.font2, css.font_18])}>
                    No parking spots available right now. :(
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={_(["", css.font_18, css.font2, css2.pagination])}>
          <PaginationComponent
            count={pagination.noOfPages}
            page={page}
            setPage={setPage}
          />
        </div>

        <ScheduleAnotification
          isOpen={scheduleModal}
          toggle={openSchduleModal}
        />
        <SendAnotification isOpen={sendModal} toggle={openSendModal} />
      </div>
    </>
  );
}

export default SendNotification;
