import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router';
import { NavLink } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './parkingdata.css'
import { useNavigate } from "react-router-dom";




const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const ParkingData = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [selected, setSelected] = useState("Amenities");

    const handleClick = (e) => {
        setSelected(e.target.name)
        let name = e.target.name;
        if (name === "Amenities") {
            setTitle("Amenities")
        } else if (name === "Vehicles") {
            setTitle("Vehicles")
        } else if (name === "Coupons") {
            setTitle("Coupons")
        } else if (name === "ApplicationMessages") {
            setTitle("Application Messages")
        } else if (name === "TimeExtension") {
            setTitle("Time Extension")
        } else {
            setTitle("Cancellation Reason")
        }
    }

    const url = new URL(window.location.href);
    useEffect(()=>{
    setSelected(url.pathname.split('/')[3])
    setTitle(url.pathname.split('/')[3].replace(/([A-Z])/g, ' $1').trim())
    },[])





    return (
        <div className='container bg-white mt-5'>
            <div className='container mt-4'>
                <p className={_(["align-self-start mb-4 mt-4 font-family-regular"])} onClick={()=>navigate('/Dashboard/details')}>Profile {'>'} <span className='link-font'>Parking Data</span></p>
                <h4 className={_(["linkstyle fw-bold",])}>{title} </h4>
            </div>
            <div className='container mt-4'>

                <div className='col-md-12 p-0'>
                    <ul className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}>
                        <NavLink to="/Dashboard/ParkingData/Amenities" name="Amenities" className={selected === "Amenities" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Amenities
                        </NavLink>
                        <NavLink to="/Dashboard/ParkingData/Vehicles" name="Vehicles" className={selected === "Vehicles" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Vehicles
                        </NavLink>
                        <NavLink to="/Dashboard/ParkingData/Coupons" name="Coupons" className={selected === "Coupons" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >Coupons
                        </NavLink>
                        <NavLink to="/Dashboard/ParkingData/ApplicationMessages" name="ApplicationMessages" className={selected === "ApplicationMessages" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >Application Messages
                        </NavLink>
                        <NavLink to="/Dashboard/ParkingData/CancellationReasons" name="CancellationReasons" className={selected === "CancellationReasons" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Cancellation Reasons
                        </NavLink>
                    </ul>
                </div>
                <div className='col-md-12 border p-0 Contnt d-flex flex-column align-items-center'>
                    <Outlet />
                </div>
            </div>
        </div>
    )

}

export default ParkingData;