import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import css from "./../main.module.css";

const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

const useStyles = makeStyles(() => ({
    ul: {
        "& .Mui-selected": {
            backgroundImage: "linear-gradient(#1cc8c0,#5ceb68)",
            color: "white"
        }
    }
}))

const PaginationComponent = ({count, page, setPage}) =>{
    const classes = useStyles();
    return (
        <>
        <Pagination 
            classes={{ul: classes.ul}}
            shape="rounded"
            count={count}
            defaultPage={page}
            onChange={(e, value)=>setPage(value)}
        />
        </>
    )
}

export default PaginationComponent;