import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NoPermission from '../../Dashboard_page/NoPermission';
import NotificationContext from '../../components/notification/NotificationContext';

import Terms from "../../components/terms/Terms";
import DocumentationTerms from "../../components/terms/DocumentationTerms";
import ExtensionTerms from "../../components/terms/ExtensionTerms";
import HourlyParkingTerms from "../../components/terms/HourlyParkingTerms";
import MonthlyParkingTerms from "../../components/terms/MonthlyParkingTerms";
import ProviderTerms from "../../components/terms/ProviderTerms";
import TermsConditions from "../../components/terms/Terms_Conditions";

export default function TermsRouters() {
    const Dashpermis = useContext(NotificationContext)
    // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
    const permissions = Object.keys(Dashpermis.permis)?.length === 0 ? {} : Dashpermis?.permis;
  return (<Routes>
    <Route path='/Dashboard/Terms' element={<Terms />}>
                        <Route path='/Dashboard/Terms/DocumentationTerms' element={permissions.manage_documentations_terms?<DocumentationTerms />:<NoPermission/>} />
                        <Route path='/Dashboard/Terms/ExtensionTerms' element={permissions.manage_extensions_terms?<ExtensionTerms />:<NoPermission/>} />
                        <Route path='/Dashboard/Terms/HourlyParkingTerms' element={permissions.manage_hourly_parking_terms?<HourlyParkingTerms />:<NoPermission/>} />
                        <Route path='/Dashboard/Terms/MonthlyParkingTerms' element={permissions.manage_monthly_parking_terms?<MonthlyParkingTerms />:<NoPermission/>} />
                        <Route path='/Dashboard/Terms/ProviderTerms' element={permissions.manage_provider_terms?<ProviderTerms />:<NoPermission/>} />
                        <Route path='/Dashboard/Terms/TermsConditions' element={permissions.manage_terms_and_conditions?<TermsConditions />:<NoPermission/>} />

                    </Route>
  </Routes>
  )
}
