import React,{useState} from 'react';
import Chart from 'react-apexcharts';
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


 function UserTrendGraph () {
    
    const [spaceList,setspaceList]=useState([{'id':1,"parking_name":"one"},{"id":2,"parking_name":"two"}])
    const val=[0,200,400,600,800,1000,1200,1400]
    

        return (
            <div className='col-md-12 mb-4 position-relative' style={{boxShadow:'0px 0px 5px rgba(0,0,0,0.5)',borderRadius:'15px'}}>
            <div className='row mb-4'>
                <div className='col-md-3 pt-2 pb-2'>
                    
                    <h5 className={_([css.font_18,css.font1])}>Users Trend</h5>
                    <p className='text-secondary'>0</p>
                </div>
                <div className='col-md-9 pt-2 pb-2'>
                    <div className='row d-flex justify-content-end'>
                    <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="all">All</option>
                                <option value="provider">Providers</option>
                                <option value="user">Users</option>
                            
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="state">State</option>
                                <option value="new york">New york</option>
                            
                            </select>
                        </div>
                        <div className='col-md-4 form-group'>
                            <select name="filter" onChange={''} className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="7">07 Days</option>
                                <option value="30">30 days</option>
                                <option value="90">Last 3 Months</option>
                                <option value="180">Last 6 Months</option>
                                <option value="360" >Last 1 Year</option>
                            </select>
                        </div>
                        
                    </div>

                </div>
            </div>
            <div className='row'>
                    <Chart 
                    type="bar"
                    width={650}
                    
                    
                    series={[
                        {
                          name: "series-1",
                          data: [300, 400, 450, 500, 490, 600, 700, 910, 450, 500, 490, 600]
                        }]
                      }
                      
                    options= {{
                        chart:{id: "basic-bar"},
                        xaxis: {
                          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug','Sep',"Oct","Nov","Dec"]
                        },
                        
                        fill: {
                            type: "gradient",
                            gradient: {
                              type: "vertical",
                              shadeIntensity: 1,
                              opacityFrom: 0.7,
                              opacityTo: 0.9,
                              stops: [0, 100],
                              colorStops: [{
                                offset: 0,
                                color: "#2ec4a4",
                                opacity: 1
                              },
                              
                              {
                                offset: 100,
                                color: '#74c557',
                                opacity: 1
                              },]
                            }
                          },
                        dataLabels:{
                            enabled:false,
                        }
                    
                        }
                      }
                     
                    />

            </div>
            </div>

        )
    }


export default UserTrendGraph