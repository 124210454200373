import css2 from '../../common.module.css'
import css from './../../../main.module.css'
import css3 from "../../../components/Profile/ProfilePage.module.css";
import React, { useState,useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import CountryCodes from "./../../../Dashboard_page/countryCode";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "reactstrap";
import "./../../../styles.css";
import "./../popup.css"
import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "./../../../config/ImageFileConfig"
import axiosInstance from '../../../axios';
import validator from "validator";
import {  ERROR__PROCESSOR } from "../../../Tools";
import emptyProfileIcon from "./../../../assets/Icons/Icon feather-user.svg";
import defaultpic from "./../../../../src/assets/Default_user_img.jpg";


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function AddAdminPopup(props) {

    //const history = useNavigate();

    const [editMyAccount, setEditMyAccount] = useState({
        countryCode: "1",
        countryCodeData: "US",

    })

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);

  const [state, setState] = useState({
        profile_pic: null,
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        country_code: "1",
        password: "",
        role:"select",
        is_active:false,
    })

    const [stateError, setStateError] = useState({
        profile_pic: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        country_code: null,
        password: null,
        role:null,
        is_active:null,
    })
    const [roleoptions,setroleoption]=useState([])
    const handleChange = (e) => {
        
            setState({ ...state, [e.target.name]: e.target.value })
            setStateError({ ...stateError, [e.target.name]: null })
        
    }
    const tr_agree=(e)=>{
        if(e.target.checked){
            setState({ ...state, [e.target.name]: true })
        }
        else{
        setState({ ...state, [e.target.name]: false })
        }
    }

    const rolesdetail=async()=>{
        await axiosInstance.get(
            'admin_dash/roles_name',
            {headers:{"content-type":"multipart/form-data"} }
        ).then((response)=>{
            setroleoption(response.data.response)
        }).catch((err)=>{console.log("error",err)})
    }

    useEffect(()=>{rolesdetail()},[])
    const handleImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            setProfilePhoto(null);

            if (file) {
                setImageLoading(true);
                //compressing image
                // console.log("Options", options);
                const compressedFile = await imageCompression(file, options);
                // console.log(
                //     `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
                // );
                if (compressedFile.size / 1024 / 1024 > 2) {
                    // throw {response:{data:"Image size is too large"}};
                    throw "Image size is too large";
                }

                const fileEE = new File([compressedFile], file.name);
                setProfilePhoto(fileEE);

                let base64Image = await new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (err) => {
                        reject(err);
                    };
                });

                if (base64Image !== undefined) {
                    setState({
                        ...state,
                        profile_pic: file,
                        profile_preview: base64Image,
                    });
                    setImageLoading(false);
                }
                

            } else {
                // setImageError (false)
                setState({
                    ...state,
                    profile_pic: null,
                });
                setProfilePhoto(null);
                setImageLoading(false);
            }
        } catch (err) {
            console.log("Errror in image upload ", err);
        }
    };

    async function Save() {

        if (validator.isEmpty(state.first_name)) {
            setStateError({ ...stateError, first_name: "Cannot be empty" })
            return
        }
        if (validator.isEmpty(state.last_name)) {
            setStateError({ ...stateError, last_name: "Cannot be empty" })
            return
        }
        if (!validator.isEmail(state.email)) {
            setStateError({ ...stateError, email: "Invalid Email" })
            return
        }

        if (validator.isEmpty(state.phone_number)) {
            setStateError({ ...stateError, phone_number: "Cannot be empty" })
            return
        }
        if (validator.isEmpty(state.password)) {
            setStateError({ ...stateError, password: "Cannot be empty" })
            return
        }
        if (state.role==='select') {
            setStateError({ ...stateError, role: "Please choose an option" })
            return
        }
        
        


        let formData = new FormData();
        formData.append('first_name', state.first_name);
        formData.append('last_name', state.last_name);
        formData.append('email', state.email);
        formData.append('phone_number', state.phone_number);
        formData.append('country_code', state.country_code);
        formData.append('password', state.password);
        formData.append('Role', state.role);
        formData.append('is_active', state.is_active);
  
        if (state.profile_pic.type) {
            formData.append("profile_pic", state.profile_pic);
        }
        await axiosInstance.post(
            "admin_dash/add_subadmin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            props.toggle()
            setState({
                profile_pic: null,
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                country_code: "1",
                password: "",
                role:"select",
                is_active:false,
            })
        }).catch((err) => {
            console.log("error in saving",err.response.data.error_msg);

            if (err.response.data.error_msg==='This email already exist') {
                setStateError({ ...stateError, email: "Email already exist" })
                return
            }
            if (err.response.data.error_msg==='This phone number already exist') {
                setStateError({ ...stateError, phone_number: "Phone number  already exist" })
                return
            }
            if (err.response && err.response.data.errors) {
                ERROR__PROCESSOR(err.response.data.errors, stateError, setStateError)
            }

        })
    };
    

    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_([" shadow d-flex flex-column justify-content-center align-items-center p-4", css2.center_container])}
                >

                    <div className={_(["d-flex flex-column justify-content-center align-items-center", css2.body_header])}>
                        <h1 className={_(["mb-0", css.font_18, css.font1])}>Create An Admin</h1>
                        <p className={_(["mb-0", css.font_18, css.font2])}>Create a new administrative role.</p>
                    </div>
                    <div className="container p-3">
                        <div className='row'>
                            <div className={_(["col d-flex flex-column", css2.inside_body, css.font_18])}
                            >
                                <div className={_(["row", css2.input_font, css.font_18])}>
                                    <div className={_(["col", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            First Name
                                        </label>
                                        <input
                                            type='input'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='First Name'
                                            name='first_name'
                                            value={state.first_name}
                                            onChange={handleChange}
                                            required
                                        />
                                      {stateError.first_name &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.first_name}</span>

                                            </div>
                                        } 
                                    </div>
                                    <div className={_(["col", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Last Name
                                        </label>
                                        <input
                                            type='input'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='Last Name'
                                            name='last_name'
                                            value={state.last_name}
                                            onChange={handleChange}
                                            required
                                        />
                                        {stateError.last_name &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.last_name}</span>

                                            </div>
                                        } 
                                    </div>
                                </div>
                                <div className={_(["", css2.input_font, css.font_18])}>
                                    <div className={_(["", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Email
                                        </label>
                                        <input
                                            type='email'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='ex. username@gmail.com'
                                            name='email'
                                            value={state.email}
                                            onChange={handleChange}
                                        />
                                        {stateError.email &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.email}</span>
                                            </div>
                                        } 
                                    </div>
                                </div>
                                <div className={_(["", css2.input_font, css.font_18])}>
                                    <div className={_(["", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Password
                                        </label>
                                        <input
                                            type='password'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='Password'
                                            name='password'
                                            value={state.password}
                                            onChange={handleChange}
                                        />
                                        {stateError.password &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.password}</span>

                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className={_(["b-0", css2.column_width3])}>
                                    <label className={_(["", css.font1])}>
                                        Phone Number
                                    </label>

                                    <div className={_(["d-flex flex-row align-items-center"])}>
                                        <ReactCountryFlag
                                            className="mr-2"
                                            countryCode={editMyAccount.countryCodeData}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            style={{
                                                width: '1.5em',
                                                height: '1.5em',
                                            }}
                                        />

                                        <CountryCodes cs={_(['input_box ml-1 mr-2 w-25', css.input_box_style, css.font2])}
                                            name="countryCode"
                                            value={editMyAccount.countryCode}
                                            handleContryCodeChange={(e) => {

                                                setEditMyAccount({
                                                    ...editMyAccount,
                                                    countryCodeData: e.target[e.target.selectedIndex].getAttribute('data'),
                                                    countryCode: e.target.value
                                                });
                                            }}
                                        />

                                        <input
                                            type='input'
                                            className={_(['input_box ml-1', css.input_box_style])}
                                            placeholder='1234567890'
                                            name='phone_number'
                                            value={state.phone_number}
                                            onChange={handleChange}
                                            id='number'
                                            required
                                        />
                                    </div>
                                </div>
                                {stateError.phone_number &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.phone_number}</span>

                                            </div>
                                        }
                                <div className={_(["", css2.input_font, css.font_18])}>
                                    <div className={_(["", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Role
                                        </label>
                                        <select  className={_(["input_box mt-1", css.font_18, css.font1, css.input_box_style])} name='role' value={state.role} onChange={handleChange}>
                                                <option value='select'>select</option>
                                            {roleoptions.map((rol)=>{
                                                return (<><option value={rol.job_task}>{rol.job_task}</option>
                                                
                                                </>)})}
                                                </select>
                                        {stateError.role &&
                                            <div className="input__err__group">
                                                <img className="mr-2"></img>
                                                <span>{stateError.role}</span>

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={_(['row',])}>
                                    <div className={_(["col mt-4",])}>
                                        <label className={_(["d-flex align-items-center container56"])}>
                                            <input type='checkbox' id='agreement' name="is_active" value={state.is_active} onChange={tr_agree}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="col-md-11 d-flex justify-content-start  mt-4 p-0">
                                        <p className="color1">Click this check box if you would like to  Activate admin account immediately. </p>  
                                        {/* Always ask for approval before publish */}
                                    </div>
                                </div>


                            </div>
                            <div className='col-md-4'>
                                <div className="d-flex flex-row w-100 align-items-center ">
                                    {imageLoading ? (
                                        <div className='spinner-border text-muted mt-4 mb-4'>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className="col-md-12 ml-5">
                                                <img
                                                    src={state.profile_pic?(state.profile_preview ? state.profile_preview : state.profile_pic) : (emptyProfileIcon)}
                                                    alt="loading..."
                                                    className={_(["", css3.profile_img3])}>

                                                </img>
                                            </div>
                                            <div className='col-md-12 d-flex justify-content-center'>
                                                <label htmlFor='uploadImage' className={_(["submitbtn p-2 mt-2",])}>
                                                    Choose File
                                                    <input type="file" name='' id='uploadImage' accept='image/*' onChange={handleImage} hidden />
                                                </label>
                                            </div>



                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >
                        <div className={_(["d-flex flex-row justify-content-around align-items-center mt-3 mb-5", css.font1])}
                        >

                            <button
                                onClick={props.toggle}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button2, css.font_18])}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={Save}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>


            </Modal >

        </>
    );
}

export default AddAdminPopup;