export const ERROR__PROCESSOR = (errors, errMsg, setErrMsg) => {
	let temp = errMsg;
    console.log(errors)
    for(var err in errors){
        
        
        if(err in errMsg){
            temp = {...temp, [err]: errors[err][0]}
        }
    }
    setErrMsg(temp)
	
}

export const DATA_PROCESSOR = (data, state, setState) => {
    let temp = state;
    for(const key in data){
        if(key in state){
            temp = {...temp, [key]: data[key]}
        }
    }
    setState(temp)
}


export const DATETOWORDS = (date) => {
    var dob = new Date(date);
    var dobArr = dob.toDateString().split(' ');
    return  dobArr[1] + ' ' + dobArr[2] + ', ' + dobArr[3];
}

export const UTCTimeconvert= (date) => {
    var dob = new Date(date);
    var dobArr = dob.toTimeString().split(':')
    if(dobArr[0]<=12){
        return dobArr[0]+':'+dobArr[1]+" "+'AM'}
    else{
        return  dobArr[0]-12 + ':' + dobArr[1] +" " +"PM"}
}

export const TIMETOWORDS = (time) => {
    const t = time.split(":")
    return t[0] + "h " + t[1] + "m"
}

export const GETTIMEFROMDATE = (date) => {
    var dob = new Date(date);
    return dob.toLocaleString('en-US', { hour: 'numeric', hour12: true })
}

export const DateConvert = (date) => {
    var dob = new Date(date);
    return dob
}


export const  TIMEDIFFERENCECALC = (date1, date2) => {
    let dateFuture = new Date(date2)
    let dateNow = new Date(date1)
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day ` : `${days} days `;
    }
    else{
        difference += (hours === 0 || hours === 1) ? `${hours} h ` : `${hours} h `;

        difference += (minutes === 0 || hours === 1) ? `${minutes} m` : `${minutes} m`; 
    }

    return difference;
}