import css2 from '../../components/common.module.css'
import css from './../../main.module.css'
import React, { useState,useEffect } from "react";
//import { useNavigate } from "react-router-dom";

import ReactCountryFlag from "react-country-flag";
import { Modal } from "reactstrap";
import FileImage from '../../assets/file-upload.png';

import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "./../../config/ImageFileConfig"
import axiosInstance from '../../axios';
import validator from "validator";

const hour = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const minute = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09","10","11", "12", "13", "14", "15",
"16", "17", "18", "19", "20","21", "22", "23", "24", "25", "26", "27", "28", "29", "30","31", "32", "33", 
"34", "35", "36", "37", "38", "39", "40","41","42", "43", "44", "45", "46", "47", "48", "49", "50","51", "52", "53", "54", "55", "56", "57", "58", "59"];


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function ScheduleAnotification(props) {

    //const history = useNavigate();
    const [imagefilename,setimagefilename]=useState('')


  const handleImage = (e) => {
    // const file = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(file);

    // reader.onloadend = () => {
    //     this.setState({ notificationFile: file })
    //     this.setState({ notificationFileName: [reader.result] });
    // }
 } 

    const icon = {
      width: '50px',
      height: '50px',
      margin: '0px auto',
      fontSize: '30px',
      marginBottom: '15px'
    }

    

    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_(["", css2.center_container])}
                >
                    <div className={_(["col-md-12 mt-3", css.font1])}>
                       <h5 className="text-center">Schedule A Notification</h5>
                   </div>
                   <div className={_(["col-md-12 position-relative", css.font5])}>
                        <p className="position-cancel mr-4" onClick={props.toggle}>Cancel</p>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label for="title" className={_(["", css.font1,css.font_16])}>Title</label>
                                <input type="text" id="title" name="title" className={_(['form-control', css.input_box_style_sm, css.font2])} placeholder="Title" />
                            </div>
                            <div className="col-md-8 form-group">
                                <label for="bodyMessage" className={_(["", css.font1,css.font_16])}>Body Message</label>
                                <textarea name="bodyMessage" rows="6" className={_(['form-control', css.input_textbox_style, css.font2])} id="bodyMessage"></textarea>
                            </div>
                            <div className="col-md-4 form-group d-flex flex-column justify-content-center align-items-center">
                                <label for="upload-file" className={_(["", css.font1,css.font_16])}>Choose an image or icon </label>
                                <p className="mb-0 parking-file-container">
                                    <img src={imagefilename !== '' ? imagefilename : FileImage} alt="Upload file" />
                                    <input type="file" onChange={handleImage} className="parking-file-upload" name="image" />
                                </p>
                                <p className="text-center mb-0 color-blue font-family-bold">
                                    Drag and drop or <span className="color1">browser</span> to choose a file png
                                    <input type="file" onChange={handleImage} className="parking-file-upload" name="image" />
                                </p>
                            </div>
                            <div className="col-md-8 form-group">
                                <label for="chooseDate" className={_(["", css.font1,css.font_16])}>Choose Date & Time</label>
                                <div className="col-md-12 d-flex">
                                    <input type="date" className={_(['form-control col-md-4', css.input_box_style_sm, css.font2])} />
                                    <label  className={_(["col-md-2 ml-3 mt-2", css.font2,css.font_16])}>Time</label>
                                    <div className="col-md-2">
                                        <select name="" className="form-control" style={{padding:'0px'}}>
                                            {
                                                hour.map((item, index) =>
                                                (
                                                    <option value={item} key={index}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select name="" className="form-control"style={{padding:'0px'}}>
                                            {
                                                minute.map((item, index) =>
                                                (
                                                    <option value={item} key={index}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="switch-box-popup">
                                            <input type="checkbox" />

                                            <span className="slider-popup"></span>
                                            <div className="am">AM</div>
                                            <div className="pm">PM</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <p className={_(["", css.font1,css.font_16])}>Choose Group To Send Notification</p>
                            </div>
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check2">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check2"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Select All</p>
                                    </div>
                                    <div className='col  d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Users</p>
                                    </div>
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Providers</p>
                                    </div>
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Admins</p>
                                    </div>
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Sub-Admin</p>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-5">
                                <div className="row">
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Via Email</p>
                                    </div>
                                    <div className='col d-flex justify-content-start'>
                                        <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                                            <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check3"></input>
                                            <span className={_(["mr-1", css.box])}></span>
                                        </label>
                                        <p className='ml-1'>Via Text</p>
                                    </div>
                                    
                                </div>

                            </div>
                            <div className="col-md-12 d-flex flex-row justify-content-around align-items-center mb-5">
                                <button className={_(['btn btn_B d-flex justify-content-center align-items-center ml-5',css.inside_button2])} style={{width:'15rem'}}>Save As a Draft</button>
                                <button className={_(['btn btn_B d-flex justify-content-center align-items-center mr-5',css.inside_button1])} style={{width:'15rem'}}>Schedule</button>
                            </div>

                 </div>

                </div>
                    
                </div>


            </Modal >

        </>
    );
}

export default ScheduleAnotification;
