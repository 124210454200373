import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css3 from "../../components/Profile/ProfilePage.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import emptyProfileIcon from "../../assets/Icons/Icon feather-user.svg";
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"
import AddModal from "../Reusable Popup/AddModel";
import CheckAnimate from "../Reusable Popup/CheckAnimate";
import "./parkingdata.css"
import "../../styles.css";
//
import Loader from "../Reusable Popup/Loader";
import axiosInstance from "../../axios";
import imageCompression from "browser-image-compression";
import { options } from "../../config/ImageFileConfig"
import SavedPopup from '../Reusable Popup/SavedPopup';
import AddAmenities from "../popups/addamenities";



const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

var Data_Format = {
    id: null,
    name: null,
    active_status: null,
    icon_img: null,
    created_by: null,
}

const RESULT_LIMIT_IN_PAGE = 6

const DATA_PROCESSOR = (data, setState) => {
    let temp = [];

    for (const spot in data) {
        var obj = {}
        console.log(data[spot])
        for (const key in Data_Format) {
            if (key in data[spot]) {
                obj = { ...obj, [key]: data[spot][key] }
            }
            else {
                obj = { ...obj, [key]: null }
            }
        }
        temp.push(obj)

    }

    setState(temp)
}

function Amenities() {



    let [loading, setLoading] = useState(false);
    const [create,setcreate]=useState(false)
    
    const cancelCreateAmenties = React.createRef();

    const openCreateAmenties = () => {if(create){
        setcreate(false)}
        else{
            setcreate(true)
        }
        
    }

    const [alert, setAlert] = useState(false);
    const alertToggle = () => {
        if (alert) {
            setAlert(false)
        }
        else {
            setAlert(true)
        }
    }

    const [change, setChange] = useState(false);
    const changeToggle = () => {
        if (change) {
            setChange(false)
        }
        else {
            setChange(true)
        }
    }

    const [save, setSave] = useState(false);
    const SaveToggle = () => {
        if (save) {
            setSave(false)
        }
        else {
            setSave(true)
        }
    }

    // search amenities

    const [selectedOption, setSelectedOption] = useState('')

    const toggleButtonSearch = (e) => {
        setSearch("")
        setSelectedOption(e.target.name);
    }

    const [search, setSearch] = useState("")
    const [state, setState] = useState([])


    const [page, setPage] = useState(1)

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })


    const callSearch = async () => {
        setLoading(true);
         
        setState([])

        let formData = new FormData();



        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)



        await axiosInstance.post(

            "amenties_search",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response.result)
            DATA_PROCESSOR(response.data.response.result, setState)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })

            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
        })
    }

    useEffect(() => {


        callSearch()
    }, [selectedOption, page])

    // active and deactivate

    const [amenitiesStatus, setAmenitiesStatus] = useState('');
    const [amenitiesStatusValue, setAmenitiesStatusValue] = useState('False');
    let [statusloading, setStatusLoading] = useState(false);

    const ActiveToggle = async (event) => {
        setStatusLoading(true);
        const element = event.target
        const amenitiesID = await element.getAttribute('data-id')
        const amenitiesActive = await element.getAttribute('data-active')
        console.log(amenitiesID);
        console.log(amenitiesActive);
        setAmenitiesStatus(amenitiesActive);

        if (amenitiesStatus) {
            setAmenitiesStatusValue('False');
            console.log('true', amenitiesStatusValue)
        } else {
            setAmenitiesStatusValue('True');
            console.log('false', amenitiesStatusValue)
        }


        let formData = new FormData();
        formData.append('id', amenitiesID)
        formData.append('active', amenitiesStatusValue)

        await axiosInstance.put(

            "amenties_info",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            changeToggle()
            callSearch()
            setStatusLoading(false);
            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
            setStatusLoading(false);
            setLoading(false);
        })
    }

    //Delete

    const handleDelete = async (event) => {
        // setLoading(true);
        //  
        const element = event.target
        const amenitiesId = element.getAttribute('data-id')
        console.log(amenitiesId)


        let formData = new FormData();


        formData.append('id', amenitiesId)

        await axiosInstance.delete(
            "delete_amenities", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            alertToggle()
            // setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })


    }

    //Add amenities

    const [pic, setPic] = useState({
        icon_img: null,
        name: "",
        active_status: false,
        icon_img: null,
        created_by: ""

    });

    const handleChange = (e) => {
        setPic({ ...pic, [e.target.name]: e.target.value })
    }

    const [AmentyIcon, setAmentyIcon] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);

    const handleImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            setAmentyIcon(null);

            if (file) {
                setImageLoading(true);
                //compressing image
                // console.log("Options", options);
                const compressedFile = await imageCompression(file, options);
                console.log(
                    `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
                );
                if (compressedFile.size / 1024 / 1024 > 2) {
                    // throw {response:{data:"Image size is too large"}};
                    throw "Image size is too large";
                }

                const fileEE = new File([compressedFile], file.name);
                setAmentyIcon(fileEE);

                let base64Image = await new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (err) => {
                        reject(err);
                    };
                });

                if (base64Image !== undefined) {
                    setPic({
                        ...pic,
                        icon_img: file,
                        profile_preview: base64Image,
                    });
                    setImageLoading(false);
                }
            } else {
                // setImageError (false)
                setPic({
                    ...pic,
                    icon_img: null,
                });
                setAmentyIcon(null);
                setImageLoading(false);
            }
        } catch (err) {
            console.log("Errror in image upload ", err);
        }
    };

    const [checkbox, setCheckbox] = useState(false);
    const Changetoggle = () => {
        if (checkbox) {
            setCheckbox(false)
            console.log(checkbox)
        } else {
            setCheckbox(true)
            console.log(checkbox)
        }
    }

    async function Save() {
        setLoading(true);
         

        let formData = new FormData();

        formData.append('name', pic.name);
        formData.append('icon_img', pic.icon_img);
        formData.append('active_status', checkbox);
        //formData.append('created_by', pic.created_by);

        await axiosInstance.post(
            "amenties_info",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                console.log(response.data.response)
                // {<AddModal toggleModal={false} />}              
                SaveToggle()
            }
            setLoading(false);
            return

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })

    }
    
   






    return (

        <div className="col-md-12 p-0 mb-5">
            

            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader loading={loading} />
                </div>
            ) :
                <>

                    <div className="d-flex justify-content-end" >
                        <button
                            className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                            onClick={openCreateAmenties}
                        >
                            Create An Amenities
                        </button>
                    </div>



                    <div className={_([" w-100", css2.search_row])}>
                        <div>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                                name=''
                                onClick={toggleButtonSearch}
                            >
                                All
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'active' ? css2.selected_search_button : '')])}
                                name='active'
                                onClick={toggleButtonSearch}
                            >
                                Active
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'deactive' ? css2.selected_search_button : '')])}
                                name='deactive'
                                onClick={toggleButtonSearch}
                            >
                                Deactivated
                            </button>
                        </div>
                        <div className={_(["d-flex justify-content-end"])}>
                            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                                <img src={searchIcon} alt="search">
                                </img>

                                <input
                                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                                    type="input"
                                    name="search"
                                    placeholder="Search here"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            callSearch()
                                        }
                                    }}
                                    style={{ border: '0px', background: 'none' }}
                                >

                                </input>
                            </div>


                        </div>
                    </div>
                    <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                        {selectedOption === '' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'active' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'deactive' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                            <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                                <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])}>
                                    <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                                    Delete
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className={_(["flex-grow-1", css2.table])}>
                        <table className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                            <tr className={_(["", css2.search_header])}>
                                <td>#</td>
                                <td className="d-flex justify-content-start align-items-center mt-3 ml-3">Name</td>
                                <td>Icon</td>
                                <td>Created By</td>
                                <td>Status</td>
                                <td>Actions</td>
                            </tr>



                            {state.map((spot, index) => {
                                return (
                                    <tr className={_([""])}>
                                        <td className="">
                                            <div className="d-flex justify-content-center align-items-center p-1">
                                                <label className={_(["", css.checkbox_group])} htmlFor={"check" + index}>
                                                    <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id={"check" + index}></input>
                                                    <span className={_(["mr-1", css.box])}></span>
                                                </label>
                                                <div className="index d-flex align-items-center mt-3"><p>{index}</p></div>
                                            </div>
                                        </td>
                                        <td className="d-flex justify-content-start align-items-center mt-3">
                                            <div className={_(["ml-2 row "])}>
                                                <label className={_(["", css.font4])}>{spot.name}</label>
                                            </div>
                                        </td>
                                        <td>

                                            <img src={spot.icon_img} alt="image" className={_(["ml-2"])}></img>

                                        </td>
                                        <td >
                                            <div className={_(["ml-2 row"])}>
                                                <label className={_(["", css.font4])}>{spot.created_by}</label>
                                            </div>

                                        </td>
                                        <td >
                                            {statusloading ? (
                                                <div className='spinner-border text-muted mt-4 mb-4'>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className={_([""])}>
                                                        <label className={_(["", css.font4])}>{spot.active_status ? <p className="badge">Active</p> : <p className="badge2">Deactivated</p>}</label>
                                                    </div>
                                                </>)}
                                        </td>


                                        <td>
                                            {selectedOption === '' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img alt="..." src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle}>{spot.active_status ? <p data-id={spot.id} data-active={spot.active_status}>Deactive</p> : <p data-id={spot.id} data-active={spot.active_status}>Active</p>}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id}>Delete</Link>

                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'active' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img alt="..." src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle}>{spot.active_status ? <p data-id={spot.id} data-active={spot.active_status}>Deactive</p> : <p data-id={spot.id} data-active={spot.active_status}>Active</p>}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id}>Delete</Link>
                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'deactive' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img alt="..." src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle}>{spot.active_status ? <p data-id={spot.id} data-active={spot.active_status}>Deactive</p> : <p data-id={spot.id} data-active={spot.active_status}>Active</p>}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id}>Delete</Link>
                                                    </div>
                                                </div> : null}


                                        </td>
                                    </tr>
                                )
                            })}

                        </table>
                    </div>
                    <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                        {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                            <PaginationComponent
                                count={pagination.noOfPages}
                                page={page}
                                setPage={setPage}
                            />
                            :
                            null
                        }
                    </div>
                    <SavedPopup isOpen={change} heading={(amenitiesStatusValue == 'True') ? 'Deactivated' : 'Activated'} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={changeToggle} />
                    <SavedPopup isOpen={save} heading={'Amenity'} Subheading={'Saved Successfully'} buttontitle={'Okay'} toggle={SaveToggle} />
                    <SavedPopup isOpen={alert} heading={'Amenity Deleted !'} Subheading={'Updated Successfully'} buttontitle={'Okay'} toggle={alertToggle} />
                </>
            }

            <AddAmenities isOpen={create} toggle={openCreateAmenties}/>

        </div>




    )
}


export default Amenities;