import React, { useState } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import PaginationComponent from "../../components/Pagination";

import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import editIcon from "../../assets/Icons/Icon material-edit.svg"
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"

import defaultSPotImg from "../../assets/Default_spot_img.svg"

import "../../styles.css"

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function ChatInquries() {


    const [selectedOption, setSelectedOption] = useState('All')

    const toggleButtonSearch = (e) => {
        setSelectedOption(e.target.name);
    }

    const [editPricePopup, setEditPricePopup] = useState({
        popup: false,
        spotId: "",
    })

    const toggleEditPricePopup = () => {
        if (editPricePopup.popup) {
            setEditPricePopup({
                ...editPricePopup,
                popup: false,
            })
        }
        else {
            setEditPricePopup({
                ...editPricePopup,
                popup: true,
            })
        }
    }

    return (

        <div className="col-md-12 p-0 mb-5">

            <div className={_([" w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'All' ? css2.selected_search_button : '')])}
                        name='All'
                        onClick={toggleButtonSearch}
                    >
                        All
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Active' ? css2.selected_search_button : '')])}
                        name='Active'
                        onClick={toggleButtonSearch}
                    >
                        Active
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'Resolved' ? css2.selected_search_button : '')])}
                        name='Resolved'
                        onClick={toggleButtonSearch}
                    >
                        Resolved
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img alt="chat inquiries" src={searchIcon}>
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            placeholder="Search by name or location"
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                {selectedOption === 'All' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                            <label className={_(["", css.font_18, css.font2])}>4 items</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'Active' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                            <label className={_(["", css.font_18, css.font2])}>6 items</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                {selectedOption === 'Resolved' ?
                    <div>
                        <div className={_([""])}>
                            <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                            <label className={_(["", css.font_18, css.font2])}>6 items</label>
                        </div>
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                        </div>
                    </div> : null}
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                        <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                            <option value='All'>All</option>
                            <option value='Hourly'>Users</option>
                            <option value='Monthly'>Providers</option>
                        </select>
                        <input type="date" className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}></input>
                        <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                            <option value='last07Days'>Last 07 Days</option>
                            <option value='last30Days'>Last 30 Days</option>
                            <option value='last3months'>Last 3 Months</option>
                            <option value='last6months'>Last 6 Months</option>
                            <option value='last1year'>Last 1 Year</option>
                        </select>
                    </div>

                </div>

            </div>
            <div className={_(["flex-grow-1", css2.table])}>
                <table className={_(["mt-2 w-100", css.font_18, css.font2, css2.search_list])}>
                    <tr className={_(["", css2.search_header])}>
                        <td>#</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Phone No.</td>
                        <td>Message</td>
                        <td>Date</td>
                        <td>Status</td>
                        <td>Actions</td>
                    </tr>




                    <tr className={_([""])}>
                        <td>
                            <label className={_(["flex-grow-1 d-flex justify-content-center align-items-center mb-0", css.checkbox_group])} htmlFor="check1">
                                <input type="checkbox" className={_(["mr-2", css.checkbox_size])} id="check1"></input>
                                <span className={_(["mr-1", css.box])}></span>
                            </label>
                        </td>
                        <td className={_(["d-flex flex-row justify-content-center align-items-center"])}>
                            <img alt="chat inquiries" src={defaultSPotImg} className={_(["", css2.search_spot_img])}></img>
                            <div className={_([" d-flex flex-column justify-content-start align-items-center flex-grow-1"])}>
                                <label className={_(["", css.font4])}>Tribeca Parking</label>
                                <label className={_(["", css.font_14])}>Spot No. 5</label>
                            </div>
                        </td>
                        <td>
                            $13
                        </td>
                        <td >
                            $5
                            <img alt="chat inquiries" src={editIcon} className={_(["ml-2"])} onClick={toggleEditPricePopup}></img>
                        </td>
                        <td >
                            25
                        </td>
                        <td>
                            5
                        </td>

                        <td>
                            $500
                        </td>
                        <td>
                            {selectedOption === 'All' ?
                                <div className="dropdown">
                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="chat inquiries" src={actionIcon}></img>
                                    </p>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/Dashboard/ViewParkingSpot">Resolve</Link>

                                    </div>
                                </div> : null}
                            {selectedOption === 'Active' ?
                                <div className="dropdown">
                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="chat inquiries" src={actionIcon}></img>
                                    </p>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/Dashboard/ViewParkingSpot">Resolve</Link>

                                    </div>
                                </div> : null}
                            {selectedOption === 'Deactivated' ?
                                <div className="dropdown">
                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="chat inquiries" src={actionIcon}></img>
                                    </p>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="#">Resolve</Link>

                                    </div>
                                </div> : null}


                        </td>
                    </tr>

                </table>
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                <PaginationComponent
                    count={10}
                />
            </div>

        </div>




    )
}


export default ChatInquries;