import React from 'react';
import './usable.css'

class ParButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <button 
            type={this.props.type ? this.props.type : 'button' } 
            className={`par-button ${this.props.className}`} 
            onClick={this.props.onClick ? this.props.onClick : (event) => { event.preventDefault() }}>
                {this.props.name}
            </button>
        )
    }
}

export default ParButton