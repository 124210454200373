import css2 from './../common.module.css'
import css from './../../main.module.css'
import React, { } from "react";
import {  useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import Gif from './../../assets/JSON-Gifs/GIF/22.logout.json'
import LottieGIF from './../lottieComponent'
import "./../../styles.css"

const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

function LogoutPopUp(props) {

    const history = useNavigate();

    
    function logoutpage() {
        sessionStorage.removeItem('__admin__token&');
        localStorage.removeItem('__admin__token&');
        history("/", { replace: true });
        window.location.reload();


    }
	
    return (
        <>
        <Modal  isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center h-100",css2.container])} backdrop={true} toggle={props.toggle}
        >
				<div
					className={_([" shadow d-flex flex-column justify-content-center align-items-center",css2.center_container])}
					>
						
                    <div className={_(["d-flex flex-column justify-content-center align-items-center", css2.body_header])}>
                        
                    <LottieGIF json={Gif} loop={true} height="8rem" width="8rem" autoload={true}/>
                        
                    </div>
                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >	
                        <div 	className={_(["d-flex flex-column justify-content-center align-items-center mt-0 mb-4", css.font1])}
                            >
                            <p className={_(["mb-0", css.font_18, css.font1])}>Oh no!</p>
                            <p className={_(["mb-0", css.font_18, css.font2])}>You're leaving... Are you sure?</p>
                        </div>

                        <div 	className={_(["d-flex flex-row justify-content-around align-items-center mt-3 mb-5", css.font1])}
                            >
                            
                            <button
                                onClick={props.toggle}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button2, css.font_18])	}
                                >
                                Cancel
                            </button>
                            <button
                                onClick={logoutpage}
                                // type='submit'
                                className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])	}
                                >
                                Logout
                            </button>
                        </div>

                    </div>
						
						
                        

				</div>
				
    </Modal>

        </>
    );
} 

export default LogoutPopUp;