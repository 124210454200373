import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NoPermission from '../../Dashboard_page/NoPermission';
import NotificationContext from '../../components/notification/NotificationContext';


import ReportInquries from "../../components/report&inquiries/Report&Inquiries";
import ChatInquries from "../../components/report&inquiries/ChatInquries";
import ContactUsQueries from "../../components/report&inquiries/ContactUsQueries";
import ServiceCharges from "../../components/report&inquiries/ServiceCharges";
import AdminStats from '../../components/statistics/AdminStats';

export default function ReportInquiryRouters() {

    const Dashpermis = useContext(NotificationContext)
    // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
    const permissions = Object.keys(Dashpermis.permis)?.length === 0 ? {} : Dashpermis?.permis;
    return (
        <Routes>
            <Route path='/Dashboard/ReportInquries' element={<ReportInquries />}>
                <Route path='/Dashboard/ReportInquries/ChatInquiries' element={permissions.manage_chat_inquiries ? <ChatInquries /> : <NoPermission />} />
                <Route path='/Dashboard/ReportInquries/ContactUsQueries' element={permissions.manage_contect_us_queries ? <ContactUsQueries /> : <NoPermission />} />
                <Route path='/Dashboard/ReportInquries/ServiceCharges' element={permissions.manage_service_charge ? <ServiceCharges /> : <NoPermission />} />
                <Route path='/Dashboard/ReportInquries/Statistics' element={permissions.manage_static ? <AdminStats /> : <NoPermission />} />
            </Route>
        </Routes>
    )
}
