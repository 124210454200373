import React from 'react';
import ReviewTrendGraph from './ReviewTrendGraph';
import UserTrendGraph from './UserTrendGraph';
import Bookingtrend from './bookingtrend';
import css from "./../../main.module.css";
import css2 from "./../../components/Parking_spot/parkingspots/SearchParkingSpot.module.css";
import totaluser from './../../assets/Icons/Group 26701.svg'
import totalparking from './../../assets/Icons/total_parking.png'
import totalbooking from './../../assets/Icons/Group 26718.svg'
import totalearning from './../../assets/Icons/Group 26713.svg'

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


class AdminStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='container'>
                <h3 className='text-blue mt-4 mb-4'>Statistics</h3>
                <div className={_(["mt-3 mb-3", css2.card_row])}>
                    <div className={_(["col-md-3", css2.cards])}>
                            <div className={_(["d-flex flex-row justify-content-between align-items-start w-100",css2.circle])}>
                                <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Users</label>
                                <img src={totaluser} alt="..."></img>
                            </div>
                            <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>10</label>

                    </div>
                    <div className={_(["col-md-3", css2.cards])}>
                            <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Parking</label>
                                <img src={totalparking} style={{width:'50px',height:'50px',backgroundColor: 'transparent'}} alt="..."></img>
                            </div>
                            <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>10</label>

                    </div>
         
                    <div className={_(["col-md-3", css2.cards])}>
                            <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Bookings</label>
                                <img src={totalbooking} alt="..."></img>
                            </div>
                            <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>10</label>

                    </div>
                    
                    <div className={_(["col-md-3", css2.cards])}>
                            <div className={_(["d-flex flex-row justify-content-between align-items-start w-100"])}>
                                <label className={_(["align-self-start flex-grow-1", css.font1, css.font_24])}>Total Earnings</label>
                                <img src={totalearning} alt="..."></img>
                            </div>
                            <label className={_(["mb-0 align-self-start", css.font2, css.font_32])}>10</label>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                    <UserTrendGraph />
                        </div>
                        <div className='col-md-4'>
                        <ReviewTrendGraph />
                            </div>
                    </div>
                    <Bookingtrend/>
            </div>

        )
    }
}


export default AdminStats


class TotalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='col-md-12' style={{boxShadow:'0px 0px 5px rgba(0,0,0,0.5)'}}>
                <div className='row m-0 p-3'>
                    <div className='col-md-8'>
                        <h4 className='text-blue h6'>{this.props.title}</h4>
                    </div>
                    <div className='col-md-4'>
                    </div>
                    <div className='col-md-12'>
                        <h4 className='text-secondary h6'>{this.props.number}</h4>
                    </div></div>
            </div>

        )

    }

}