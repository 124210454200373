
import css from './../../../main.module.css'
import css2 from "../../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import css3 from "../../../components/Profile/ProfilePage.module.css";
import React, { useState} from "react";
import { Modal } from "reactstrap";
import visibleIcon from "../../../assets/Icons/Icon awesome-eye.svg"
import iconstar from "../../../assets/Icons/Icon awesome-star-1.svg"
import emailIcon from "../../../assets/Icons/Icon material-email.svg"
import phoneIcon from "../../../assets/Icons/Icon awesome-phone-alt.svg"
import pointerIcon from "../../../assets/Icons/Icon material-location-on.svg"
import emptyProfileIcon from "./../../../assets/Icons/Icon feather-user.svg";

import "./../../../styles.css"

import './../popup.css'

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};




function ReviewDetailPopup(props) {
    const [imageLoading, setImageLoading] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);

    const [state, setState] = useState({
        profilePic: null,
        country: "",
        state: ""
    })


    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >

                <div className='container p-4'>
                    <div className='col-md-12 d-flex justify-content-end'>
                        <p>Edit</p>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className=" col-md-12 d-flex justify-content-center">
                            <h1 className={_(["mb-0", css.font_18, css.font1])}> Review Details</h1>
                        </div>
                        <div className=" col-md-12 d-flex justify-content-center">
                            <img alt="review detail popup"    src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                            <img alt="review detail popup"    src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                            <img alt="review detail popup"    src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                            <img alt="review detail popup"    src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                            <img alt="review detail popup"    src={iconstar} className={_(["ml-3",])}></img>&nbsp;
                        </div>
                    </div>
                    <div className='row d-flex mt-5'>
                        <div className='col-md-4'>
                            <div className="d-flex flex-row w-100 justify-content-center align-items-center ">
                                {imageLoading ? (
                                    <div className='spinner-border text-muted mt-4 mb-4'>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className="col-md-12 ml-5">
                                            <img
                                                src={state.profile_pic ? (state.profile_preview ? state.profile_preview : state.profile_pic) : (emptyProfileIcon)}
                                                alt="loading..."
                                                className={_(["", css3.profile_img3])}>

                                            </img>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className=' d-flex justify-content-center mt-2'>
                                <label style={{ color: "black" }}><h5>ParkingBud</h5></label>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className=''>
                                    <div className='d-flex'>
                                        <img alt="review detail popup"    src={emailIcon} className="mr-2"></img>
                                        <label>dassaysoy@gmail.com</label>
                                    </div>
                                    <div className='d-flex'>
                                        <img alt="review detail popup"    src={phoneIcon} className="mr-2"></img>
                                        <label>+1 123131313</label>
                                    </div>
                                    <div className='d-flex'>
                                        <img alt="review detail popup"    src={pointerIcon} className="mr-2"></img>
                                        <label>123 Street, United States</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-8'>

                            <div className="col-md-12">
                                <div className={_(["", css2.input_font, css.font_18])}>
                                    <div className={_(["", css2.column_width3])}>
                                        <label className={_([" ", css.font1])}>
                                            Title
                                        </label>


                                        <input
                                            type='input'
                                            className={_(['input_box', css.input_box_style, css.font2])}
                                            placeholder='First Name'
                                            name='firstName'
                                            //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                                            //}}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={_(["m-3 ", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_([" ", css.font1])}>
                                        Review
                                    </label>


                                    <textarea rows="4" cols="50"
                                        className={_(['input_box', css.input_box_style, css.font2])}
                                        placeholder='Add Review'
                                        name='email'
                                        //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                                        //}}
                                        required
                                    >
                                    </textarea>

                                </div>
                            </div>
                            <div className="d-flex justify-content-start">
                                <img alt="review detail popup"    src={visibleIcon} className={_(["ml-3",])}></img>&nbsp;
                                <h6 className={_(['mt-2', css.font1])}>Visible on Website</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </>
    );
}

export default ReviewDetailPopup;