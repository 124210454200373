import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axiosInstance from "../../axios";
import Loader from '../Reusable Popup/Loader';
import SavedPopup from '../Reusable Popup/SavedPopup';


/* csss */
import './aboutus.css'



const AboutParkingBud = () => {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const [content, setContent] = useState("")
   
    const [save, setSave] = useState(false);
    const SaveToggle = () => {
        if (save) {
            setSave(false)
        }
        else {
            setSave(true)
        }
    }




    async function makerequest() {
        setLoading(true);
        setColor('#5c5b5b');


        await axiosInstance.get(
            "admin_dash/about_us_catag-about_parking_bud"
        ).then((response) => {

            console.log(response.data.response.data);
            setContent(response.data.response.data.content);
            setLoading(false);


        }).catch((err) => {
            console.log(err.response.response.data);

        })

    }

    useEffect(() => {
        makerequest()

    }, [])

    async function Save() {
        setLoading(true);
        setColor('#5c5b5b');
        

        let formData = new FormData();

        formData.append('content', content);

        await axiosInstance.post(
            "admin_dash/about_us_catag-about_parking_bud",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                //alert('saved')
                //console.log(content)
                SaveToggle()

            }
            return

        }).catch((err) => {
            console.log("error in saving");
            console.log(err.response.response.data);


        })
    }










    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader color={color} loading={loading} />
                </div>
            ) :
                <div className="container">
                    <div className="row">
                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 border p-4 mb-5 mt-5 trmcontnt">
                                <Editor

                                   
                                    apiKey="y6ee112drn29hukuvhfknti1tccnqa9uk0t0amfwk59579v5"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                    }}

                                    value={content}
                                    onEditorChange={(newValue, editor) => setContent(newValue)}
                                />
                                <div class="col d-flex justify-content-center align-self-center">
                                    <button
                                        className="align-self-end font_18 inside_button0 add_new_button"
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="align-self-end font_18 inside_button add_new_button"
                                        onClick={Save}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <SavedPopup  isOpen={save} heading={'About ParkingBud'} Subheading={ 'Updated Successfully'} buttontitle={'Okay'} toggle={SaveToggle}/>
               
                </div >
            }
        </>

    )

}

export default AboutParkingBud;