import React,{useState} from 'react';
import Chart from 'react-apexcharts';
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function ReviewTrendGraph(){
    const [contryname, setCountryname]= useState([]);
    const [medal, setMedal]= useState([]);
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         loading: false,
    //         spaceList:[{'id':1,"parking_name":"one"},{"id":2,"parking_name":"two"}],
    //         total_review: 0,
    //         token: localStorage.getItem("provider__token") || sessionStorage.getItem("provider__token"),
    //         graphData: {
    //             labels: ['#2ec4bb', '#a3ef88', '#419224', '#d4dbe8'],
    //             datasets: [
    //                 {
    //                     label: 'Review',
    //                     data: [1, 4, 5, 1],
    //                     backgroundColor: [
    //                         '#2ec4bb',
    //                         '#a3ef88',
    //                         '#419224',
    //                         '#d4dbe8',
    //                     ],
    //                     borderColor: [
    //                         '#2ec4bb',
    //                         '#a3ef88',
    //                         '#419224',
    //                         '#d4dbe8',
    //                     ],
    //                     borderWidth: 2,
    //                     cutout: 130
    //                 },
    //             ],
    //         }
    //     }
    // }
        return (
            <div className='col-md-12 position-relative' style={{ boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',height:'530px',borderRadius:'15px'}}>
                <div className='row'>
                    <div className={_(['col-md-5 pt-2',css.font_18])}>
                    <h5 className={_([css.font_18,css.font1])}>Review Trend</h5>
                        <p className='text-secondary'>1</p>
                    </div>
                    <div className={_(['col-md-7 form-group pt-2 pb-2',])}>
                            <select name="filter" onChange={''} className={_(['form-control',css.font_18, css.font1, css2.select_box_style])}>
                                <option value="7">Tribeca parking</option>
                                <option value="7">Tribeca parking1</option>
                            </select>
                        </div>
                </div>
                <div className='row p-2 mt-4'>
                    <Chart 
                    type="donut"
                    width={300}
                    height={ 400}
                    
                    
                    series={[8, 3, 3, 2,1]}

                    options={{
                    labels:['5 Star','4 Star','3 Star','2 Star','1 Star'],
                    colors: ['#2ec4a4','#74c557','#a3ef88','#c1e295','#d4dbe8'],
                    plotOptions:{
                    pie:{
                        donut:{
                            size: '85%',
                            labels:{
                                show:true,
                                name: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    offsetY: 30,
                                    
                                  },
                                  value: {
                                    show: true,
                                    fontSize: '30px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    offsetY: -16,
                                    
                         
                                  },
                                total:{
                                    show:true,
                                    label: '',
                                    showAlways:false,
                                    fontSize:20,
                                    color: '#2c189c',
                                    formatter: () => '15%'
                                    
                                }
                            }
                        }
                    }

                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'start',
                        verticalAlign:'bottom', 
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        itemMargin: {
                            horizontal: 1.5,
                            vertical: 0
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                      },

                    dataLabels:{
                        enabled:false,
                        
                    }


                    }}
                    
                    />
            </div>
        </div>

        )
    }


export default ReviewTrendGraph