import React from "react";
import { useDropzone } from "react-dropzone";
import css2 from "./CompleteProfile.module.css";

const _ = (classes) => classes.join(" ");

function MyDropzone({ file, setFile, text, imgsrc, existingFiles }) {
  // Check if any files are already selected
  const isInputDisabled = file.length > 0;

  // Configure dropzone options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFile((prevFiles) => {
        const fileMap = new Map();
        [...prevFiles, ...newFiles].forEach((f) => {
          fileMap.set(f.preview, f);
        });
        return Array.from(fileMap.values());
      });
    },
    // Disable drag and drop if files are already present
    disabled: isInputDisabled,
  });

  return (
    <div
      {...getRootProps({
        className: "d-flex flex-column justify-content-center align-items-center w-100",
      })}
    >
      <input {...getInputProps()} disabled={isInputDisabled} />
      {file.length > 0 ? (
        <>
        {console.log(file,'file')}
          {file.map((f, index) => (
            // <div key={index} className="m-2">
              <img src={f.preview} alt={f.name} style={{ width: '100%', height: '228px', borderRadius:'10px' }} />
            // </div>
          ))}
        </>
      ) : (
        <>
          <img
            src={imgsrc}
            alt="..."
            className={_(["", css2.upload_box_img])}
          />
          <p className={_(["mb-0", css2.inside_text])}>
            {isDragActive ? "Drop the files here ..." : text}
          </p>
        </>
      )}
    </div>
  );
}

export default MyDropzone;
