import React from 'react';
import { Link } from 'react-router-dom';


/* css */
import css2 from "./../../Profile/ProfilePage.module.css"
import mainCss from "./../../../main.module.css"

/* icons */
import emailIcon from "./../../../assets/Icons/Icon material-email.svg"
import phoneIcon from "./../../../assets/Icons/Icon awesome-phone-alt.svg"
import webIcon from "./../../../assets/Icons/Group 155.svg"
import pointerIcon from "./../../../assets/Icons/Icon material-location-on.svg"
import timezoneIcon from "./../../../assets/Icons/Mask Group 145.svg"



const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const UsersProfile = () => {




    return (
        <div class="container">
            <div class="row">
                <div className={_(["bg-white", css2.account_details, mainCss.font2, mainCss.font_18])}>
                    <div >
                        <img alt="profile" className={_(["", css2.profile_img])}></img>
                    </div>
                    <div className={_(["", css2.account_inside_details])}>
                        <div>
                            <label style={{ color: "black" }}><b>Dassay Soy</b></label>
                        </div>
                        <div>
                            <img src={emailIcon} alt="loading..." className="mr-2"></img>
                            <label>dassaysoy@gmail.com</label>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="loading..." className="mr-2"></img>
                            <label>+1 123131313</label>
                        </div>
                        <div>
                            <img src={webIcon} alt="loading..." className="mr-2"></img>
                            <label>www.url.com</label>
                        </div>
                        <div>
                            <img src={pointerIcon} alt="loading..." className="mr-2"></img>
                            <label>123 Street, NJ 07307, United States</label>
                        </div>
                        <div>
                            <img src={timezoneIcon} alt="loading..." className="mr-2"></img>
                            <label>timezone</label>
                        </div>

                        <div className="mt-3">
                            <Link to="#">Change Password</Link>
                        </div>
                        <div className="">
                            <Link to="#">Logout</Link>
                        </div>
                    </div>
                    <div className=" ml-3">
                        <Link to="/dashboard/profile/myaccount/edit">Edit</Link>
                    </div>
                </div>


            </div>
        </div>



    );


}


export default UsersProfile;