// store/store.js
import { createStore, combineReducers } from 'redux';
import providerReducer from './reducers/providerReducer';

const rootReducer = combineReducers({
  provider: providerReducer, // Add your reducers here
});

const store = createStore(rootReducer);

export default store;
