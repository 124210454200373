import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg"
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg"
import { Link, useNavigate } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg"
import AddModal from "../Reusable Popup/AddModel";
import CheckAnimate from "../Reusable Popup/CheckAnimate";
import Badge from 'react-bootstrap/Badge';
//
import Loader from "../Reusable Popup/Loader";
import axiosInstance from "../../axios";
import SavedPopup from '../Reusable Popup/SavedPopup';
import AddVehicle from "../popups/addvehicle";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


var Data_Format = {
    id: null,
    car_name: null,
    is_active: null,
    created_by: null,
}

const RESULT_LIMIT_IN_PAGE = 6

const DATA_PROCESSOR = (data, setState) => {
    let temp = [];

    for (const spot in data) {
        var obj = {}
        console.log(data[spot])
        for (const key in Data_Format) {
            if (key in data[spot]) {
                obj = { ...obj, [key]: data[spot][key] }
            }
            else {
                obj = { ...obj, [key]: null }
            }
        }
        temp.push(obj)

    }

    setState(temp)
}

function Vehicles() {


    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    const [create, setcreate] = useState(false)

    const openCreateVehicles = (e) => {
        if(create){
            setcreate(false)}
            else{
                setcreate(true)
            }
            
        }
   

    const [change, setChange] = useState(false);
    const changeToggle = () => {
        if (change) {
            setChange(false)
        }
        else {
            setChange(true)
        }
    }

    // search vehicle

    const [selectedOption, setSelectedOption] = useState('');

    const toggleButtonSearch = (e) => {
        setSearch("")
        setSelectedOption(e.target.name);
    }

    const [search, setSearch] = useState("")
    const [state, setState] = useState([])


    const [page, setPage] = useState(1)

    const [pagination, setPagination] = useState({
        count: 0,
        previous: false,
        next: false,
        startIndex: 0,
        endIndex: 0,
        totalResults: 0,
        noOfPages: 0,
    })


    const callSearch = async () => {
        setLoading(true);
        setColor('#5c5b5b');
        setState([])

        let formData = new FormData();



        formData.append('search', search)
        formData.append('result_limit', RESULT_LIMIT_IN_PAGE)
        formData.append('page', page)
        formData.append('order_by', '')
        formData.append('order_by_type', '')
        formData.append('status', selectedOption)



        await axiosInstance.post(

            "admin_dash/search_vehicle_from_admin",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response.result)
            DATA_PROCESSOR(response.data.response.result, setState)
            setPagination({
                count: response.data.pagination.count,
                previous: response.data.pagination.previous,
                next: response.data.pagination.next,
                startIndex: response.data.pagination.startIndex,
                endIndex: response.data.pagination.endIndex,
                totalResults: response.data.pagination.totalResults,
                noOfPages: response.data.pagination.noOfPages
            })

            setLoading(false);

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })
    }

    useEffect(() => {
        callSearch()
    }, [selectedOption, page])

    // add vehice

    

    // active and deactivate
    let [statusloading, setStatusLoading] = useState(false);

    const ActiveToggle = async (event) => {
        setStatusLoading(true);
        const element = event.target
        const vehicleId = await element.getAttribute('data-id')
        const vehicleActive = await element.getAttribute('data-active')


        if (vehicleActive==='true') {
            var st='False'
        } else {
            var st='True'
        }

        let formData = new FormData();
        formData.append('id', vehicleId)
        formData.append('active', st)

        await axiosInstance.put(

            "admin_dash/add_vehicle_from_admin/",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            setStatusLoading(false);
            changeToggle()
            callSearch()
        }).catch((err) => {
            
            console.log("got an error", err)
            alert('Failed !')
            setStatusLoading(false);
            setLoading(false);
            
        })
    }

    // delete

    const handleDelete = async (event) => {
        // setLoading(true);
        // setColor('#5c5b5b');
        const element = event.target
        const amenitiesId = element.getAttribute('data-id')
        console.log('id', amenitiesId,arr)


        let formData = new FormData();

        
        formData.append("id", amenitiesId)


        await axiosInstance.post(
            "admin_dash/del_vehicle_from_admin", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            // alertToggle()
            // setLoading(false);
            callSearch()
            setarr([])

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })


    }
    const handleDeletearr = async (event) => {
        // setLoading(true);
        // setColor('#5c5b5b');

        let formData = new FormData();

        
        formData.append("id", arr)


        await axiosInstance.post(
            "admin_dash/del_vehicle_from_admin", formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {

            console.log(response.data.response)
            // alertToggle()
            callSearch()

        }).catch((err) => {
            console.log("got an error", err)
            setLoading(false);
        })
    }

    const [arr,setarr]=useState([])
    const DeleteArr=(e)=>{
        
            if(e.target.checked){
                arr.push(e.target.name)
                console.log(arr)
            }
            else{
                arr.splice(arr.indexOf(e.target.name),1)
                console.log(arr)
            }
    }





    return (

        <div className="col-md-12 p-0 mb-5">
           
                    <div className="d-flex justify-content-end" >
                        <button
                            className={_(["align-self-end", css.font_18, css.inside_button1, css2.add_new_button])}
                            onClick={openCreateVehicles}
                        >
                            Add a Vehicles
                        </button>
                    </div>

                    <div className={_([" w-100", css2.search_row])}>
                        <div>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === '' ? css2.selected_search_button : '')])}
                                name=''
                                onClick={toggleButtonSearch}
                            >
                                All
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'active' ? css2.selected_search_button : '')])}
                                name='active'
                                onClick={toggleButtonSearch}
                            >
                                Active
                            </button>
                            <button
                                className={_(["mr-4", css.font_18, css2.search_buttons, (selectedOption === 'deactive' ? css2.selected_search_button : '')])}
                                name='deactive'
                                onClick={toggleButtonSearch}
                            >
                                Deactivated
                            </button>
                        </div>
                        <div className={_(["d-flex justify-content-end"])}>
                            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                                <img src={searchIcon} alt="search">
                                </img>

                                <input
                                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                                    type="input"
                                    name="search"
                                    placeholder="Search here"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            callSearch()
                                        }
                                    }}
                                    style={{ border: '0px', background: 'none' }}
                                >

                                </input>
                            </div>


                        </div>
                    </div>
                    <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                        {selectedOption === '' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'active' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Active</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        {selectedOption === 'deactive' ?
                            <div>
                                <div className={_([""])}>
                                    <label className={_(["mr-3", css.font_24, css.font1])}>Deactivated</label>
                                    <label className={_(["", css.font_18, css.font2])}>{state.length} items</label>
                                </div>
                                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>

                                </div>
                            </div> : null}
                        <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                            <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                                <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])} onClick={handleDeletearr}>
                                    <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                                    Delete
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className={_(["flex-grow-1", css2.table])}>
                        <div className={_(["", css.font_18, css.font2, css2.search_list])}>
                            <div className={_(["row", css2.search_header])}>
                                <div className="col-md-1 mt-3">#</div>
                                <div className="col-md-3 mt-3">Name</div>
                                <div className="col-md-3 mt-3">Created By</div>
                                <div className="col-md-3 mt-3">Status</div>
                                <div className="col-md-2 mt-3">Actions</div>
                            </div>

                            <Loader color={color} loading={loading} />
                            {state.map((spot, index) => {
                                return (<>
                                    <div className={_(["row"])}>
                                        <div className="col-md-1 mt-3">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label className={_(["mb-3", css.checkbox_group])} htmlFor={"check" + index}>
                                                    <input type="checkbox" className={_(["", css.checkbox_size])} name={spot.id} id={"check" + index} onChange={DeleteArr}></input>
                                                    <span className={_(["mr-1", css.box])}></span>
                                                </label>
                                                <div className="index d-flex align-items-center"><p>{index}</p></div>
                                            </div>
                                        </div>
                                        <div className={_(["col-md-3 mt-3"])}>

                                            <div className={_([" d-flex flex-column justify-content-start align-items-center flex-grow-1"])}>
                                                <label className={_(["", css.font4])}>{spot.car_name}</label>

                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <div className={_(["d-flex flex-column justify-content-start align-items-center"])}>
                                                <label className={_(["", css.font4])}>{spot.created_by}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-3" >
                                            {statusloading ? (
                                                <div className='spinner-border text-muted mt-4 mb-4'>
                                                </div>
                                            ) : (<>
                                                <div className={_([""])}>
                                                <label className={_(["", css.font4 ,css.font_18])}>{spot.is_active ?<Badge bg='success'>Active</Badge>:<Badge bg='danger'>Deactive</Badge>}</label>
                                                </div>
                                            </>
                                            )}
                                        </div>
                                        <div className=" mt-3 col-md-2">
                                            {selectedOption === '' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-id={spot.id} data-active={spot.is_active}>{spot.is_active ? "Deactive" : "Active"}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id} >Delete</Link>

                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'active' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-id={spot.id} data-active={spot.is_active}>{spot.is_active ? "Deactive" : "Active"}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id}>Delete</Link>

                                                    </div>
                                                </div> : null}
                                            {selectedOption === 'deactive' ?
                                                <div className="dropdown">
                                                    <p className="mb-0 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={actionIcon}></img>
                                                    </p>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link className="dropdown-item" to="#" onClick={ActiveToggle} data-id={spot.id} data-active={spot.is_active}>{spot.is_active ? "Deactive" : "Active"}</Link>
                                                        <Link className="dropdown-item" to="#" onClick={handleDelete} data-id={spot.id} >Delete</Link>

                                                    </div>
                                                </div> : null}


                                        </div>
                                    </div></>)
                            })}

                        </div>
                    </div>
                    <div className={_(["", css.font_18, css.font2, css2.pagination])}>
                        {pagination.totalResults > RESULT_LIMIT_IN_PAGE ?
                            <PaginationComponent
                                count={pagination.noOfPages}
                                page={page}
                                setPage={setPage}
                            />
                            :
                            null
                        }
                    </div>
         
            
            <AddVehicle isOpen={create} toggle={openCreateVehicles}/>

        </div>




    )
}


export default Vehicles;