import css2 from './../../common.module.css'
import css from './../../../main.module.css'
import React, { useState } from "react";
import { Modal } from "reactstrap";
import "./../../../styles.css";
import axiosInstance from '../../../axios';
import Loader from '../../Reusable Popup/Loader';
import SavedPopup from '../../Reusable Popup/SavedPopup';

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function AddFAQPopup(props) {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const [save, setSave] = useState(false);
    const SaveToggle = () => {
        if (save) {
            setSave(false)            
        }
        else {
            setSave(true)
        }
    }

    const [state, setState] = useState({
        category_name: 'All',
        question: '',
        answer: ''
    })

    const toggleButtonSearch = (e) => {
        setState({ ...state, category_name: e.target.value });
    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        // setStateError({ ...stateError, [e.target.name]: null })
    }


    async function Save() {
        setLoading(true);
        setColor('#5c5b5b');

        let formData = new FormData();
        formData.append('category', state.category_name);
        formData.append('question', state.question);
        formData.append('answer', state.answer);

        await axiosInstance.post(
            "admin_dash/csm_read_create_FAQ_api",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                console.log('sucessfull')
                // window.location.reload();
                SaveToggle()
                setLoading(false);
            }
            return 

        }).catch((err) => {
            console.log("got an error", err.response)
            setLoading(false);
        })
    };


    return (

        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >

                <div
                    className={_([" shadow d-flex flex-column justify-content-center align-items-center p-4", css2.center_container])}
                >
                    <div className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
                    >
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <Loader color={color} loading={loading} />
                            </div>
                        ) : <>

                            <div className={_(["d-flex flex-column justify-content-center align-items-center mt-0 mb-4", css.font1])}
                            >
                                <p className={_(["mb-0", css.font_18, css.font1])}>Add FAQ</p>
                            </div>
                            <div className={_(["", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_(["mt-2", css.font1])}>
                                        Add A Question
                                    </label>
                                    <input
                                        type='text'
                                        className={_(['', css.input_box_style, css.font2])}
                                        placeholder='i.e. Can I extend parking time to 15 minutes?'
                                        name='question'
                                        value={state.question}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={_(["", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_(["mt-2", css.font1])}>
                                        For Whom
                                    </label>
                                    <select onChange={toggleButtonSearch} className={_(['', css.input_box_style, css.font2])}>
                                        <option selected value="All">All</option>
                                        <option value="User">User</option>
                                        <option value="Provider">Provider</option>
                                    </select>
                                </div>
                            </div>
                            <div className={_(["", css2.input_font, css.font_18])}>
                                <div className={_(["", css2.column_width3])}>
                                    <label className={_(["mt-2", css.font1])}>
                                        Add An Answer
                                    </label>
                                    <textarea
                                        type='text'
                                        className={_(['', css.input_box_style, css.font2])}
                                        placeholder='i.e. No, minimus time is 30 minutes.'
                                        name='answer'
                                        value={state.answer}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={_(["d-flex flex-row justify-content-around align-items-center mt-5 mb-5", css.font1])}
                            >

                                <button
                                    onClick={props.toggle}
                                    // type='submit'
                                    className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button2, css.font_18])}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={Save}
                                    className={_(["btn btn_B d-flex justify-content-center align-items-center ", css2.inside_button, css.inside_button1, css.font_18])}
                                >
                                    Add
                                </button>
                            </div>
                        </>
                        }
                    </div>
                </div>


                <SavedPopup isOpen={save} heading={'FAQs'} Subheading={'Added Successfully'} buttontitle={'Okay'} toggle={SaveToggle} />

            </Modal>

        </>
    );
}

export default AddFAQPopup;