import css2 from '../../components/common.module.css'
import css from './../../main.module.css'
import React, { useState,useEffect } from "react";
import { Modal } from "reactstrap";
import axiosInstance from '../../axios';
import validator from "validator";


const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function AddVehicle(props) {

    //const history = useNavigate();
    const[state,setstate]=useState({name:'',active_status:false})
    const[errstate,seterrstate]=useState({name:'',})

    const [color,setcolor]=useState('grey')
    const [inputcolor,setinputcolor]=useState('')

    const onChange=(e)=>{
        setstate({...state,[e.target.name]:e.target.value})
        seterrstate({...errstate,[e.target.name]:null})
        setinputcolor('')
        if(e.target.checked){
            setcolor('')
        }
        else{
        setcolor('grey')}

    }

    
    
    
    const vehiclecreated=async()=>{
        if (validator.isEmpty(state.name)) {
            seterrstate({ ...errstate, name: "Cannot be empty" })
            setinputcolor('0px 3px 1px red')
            return
        }
        let formData = new FormData();

        formData.append('car_name',state.name )
        formData.append('is_active',state.active_status )

        await axiosInstance.post(
            "admin_dash/add_vehicle_from_admin/",formData
        ).then((response) => {
            alert('Successfully Added')
            props.toggle()
            setstate({name:'',active_status:false})


        }).catch((err) => {
            console.log(err.response.request.status);

        })
    }



    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div
                    className={_(["mt-3 mb-4", css2.center_container])}
                >
                    <div className={_(["col-md-12 mt-3 mb-4", css.font1])}>
                       <h5 className="text-center">Create An Vehicle</h5>
                   </div>
                    <div className="col-md-12 mt-4">
                        <div className="row">
                            <div className="col-md-12 form-group mb-4">
                                <label for="title" className={_(["", css.font1,css.font_16])}>Name</label>
                                <input type="input" id="title" name="name" className={_(['form-control', css.input_box_style_sm, css.font2])} style={{boxShadow:inputcolor}} value={state.name} onChange={onChange}/>
                                {errstate.name &&
                                <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{errstate.name}</span>

                            </div>
                        }
                            </div>
                            <div className={_(["col-md-12 d-flex justify-content-center mb-4 mt-3"])}>
                                <p className={_(["row",css.activelabel])}>Active
                                    <input className={_(["", css.react_switch_checkbox])} id={`react-switch-new`} type="checkbox" name='active_status' value={color==='grey'?true:false} onClick={onChange}/>
                                    <label className={_(["", css.react_switch_label])} htmlFor={`react-switch-new`} style={{ background: color }}>
                                        <span className={_(["", css.react_switch_button])}/>
                                    </label>
                                </p> 
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                                <button className={_(['',css.cancel])} onClick={props.toggle}>Cancel</button>
                                <button className={_(['',css.submit])} onClick={vehiclecreated}>Submit</button>
                            </div>

                 </div>

                </div>
                    
                </div>


            </Modal >

        </>
    );
}

export default AddVehicle;

