import React, { useState, useEffect , useContext} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, NavLink } from "react-router-dom";
/* css */
import './admindasboard.css'
import css2 from "../components/Profile/ProfilePage.module.css"
import mainCss from "../main.module.css"
/* icons */
import emailIcon from "../assets/Icons/Icon material-email.svg"
import phoneIcon from "../assets/Icons/Icon awesome-phone-alt.svg"

import pointerIcon from "../assets/Icons/Icon material-location-on.svg"
import timezoneIcon from "../assets/Icons/Mask Group 145.svg"
import emptyProfileIcon from "../assets/Icons/Icon feather-user.svg";
//
import axiosInstance from "./../axios";
import { DATA_PROCESSOR } from "./../Tools";
import ChangePassword from './popups/Myprofile/ChangePassword';
import Loader from './Reusable Popup/Loader';
import BarLoaders from './Reusable Popup/Barloaders';
import NotificationComponent from './notification/Notifications';
import NotificationContext from './notification/NotificationContext';





const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};




const AdminDashbord = () => {
    const history = useNavigate();
    const profiledata=useContext(NotificationContext)
    const [loading, setLoading] = useState(false);
    
    const [color, setColor] = useState("#ffffff");
    const [changePassword, setChangePassword] = useState(false);
    const ChangedPasswordToggle = () => {
        if (changePassword) {
            setChangePassword(false)
        }
        else {
            setChangePassword(true)
        }
    }
    const profiledetails = profiledata === undefined || profiledata.profile.first_name===''? [] : profiledata.profile
    const count=profiledata.adminaccesscount===0? 0:profiledata.adminaccesscount
    const permis = Object.keys(profiledata.permis).length === 0 ? [] : profiledata.permis
    const count2=profiledata.activecount===0? 0:profiledata.activecount 

    console.log("profiledata",profiledata)
    

    useEffect(()=>{
        if (profiledetails.length===0){
            setLoading(true)
        }
        else{
            setLoading(false)
        }
    },[profiledetails.length===undefined])



    function logoutpage() {
        sessionStorage.removeItem('__admin__token&');
        localStorage.removeItem('__admin__token&');
        history("/", { replace: true });
        window.location.reload();


    }


    return (
        <div className='container p-0 bg-white mt-5'>
            <div className='row'>

                <div className='col-md-6 col-sm-12 col-lg-5 col-xl-4 col-xxl-4'>
                    <div className={_(["p-2", css2.account_details2, mainCss.font2, mainCss.font_18])}>
                        <div className={_(["mt-2", css2.profile_div])}>
                            <>
                                    <img className={_(["", css2.profile_img])}
                                        src={profiledetails.profile_pic ? profiledetails.profile_pic : emptyProfileIcon}
                                        alt='avatar'
                                    />
                                    <div className="mx-2 mt-2">
                                        <NavLink className='linkstyle' to="/Dashboard/MyAccount/Edit">Edit</NavLink>
                                    </div>
                                </>
                            
                        </div>
                        <div className={_(["px-2 mb-5", css2.account_inside_details2])}>
                            <div className={_(["", css2.account_inside_details1])}>
                                <div className='mt-3'>
                                    <label className='headheading-titlee1'><b>{loading?null:profiledetails.first_name + " " + profiledetails.last_name}</b></label>
                                </div>
                                <div className='d-flex'>
                                    <img src={emailIcon} alt="loading..." className="mr-2 mb-2"></img>
                                    {loading ? ( <BarLoaders color={color} loading={loading} />):<label>{profiledetails.email}</label>}
                                </div>
                                <div className='d-flex'>
                                    <img src={phoneIcon} alt="loading..." className="mr-2  mb-2"></img>
                                    {loading ? ( <BarLoaders color={color} loading={loading} />):<label>{"+" + profiledetails.country_code + " " + profiledetails.phone_number}</label>}
                                </div>
                                <div className='d-flex'>
                                    <img src={pointerIcon} alt="loading..." className="mr-2  mb-2"></img>
                                    {loading ? ( <BarLoaders color={color} loading={loading} />):<label>{profiledetails.address_line_1 !== "" ? profiledetails.address_line_1 + ", " : "-"}
                                        {profiledetails.address_line_2 !== "" ? profiledetails.address_line_2 + ", " : "-"}
                                        {profiledetails.city !== "" ? profiledetails.city + ", " : "-"}
                                        {profiledetails.state !== "" ? profiledetails.state + ", " : "-"}
                                        {profiledetails.country !== "" ? profiledetails.country : "-"}</label>}
                                </div>
                                <div className='d-flex'>
                                    <img src={timezoneIcon} alt="loading..." className="mr-2  mb-2"></img>
                                    {loading ? ( <BarLoaders color={color} loading={loading} />):<label>{profiledetails.timezone ? profiledetails.timezone : "-"}</label>}
                                </div>

                                <div className="mt-3">
                                    <NavLink className='linkstyle' to="#" onClick={ChangedPasswordToggle}>Change Password</NavLink>
                                </div>
                                <div className="">
                                    <NavLink className='linkstyle' to="#" onClick={logoutpage}>Logout</NavLink>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* <div className='col-md-6 col-sm-12 col-lg-7 col-xl-8 col-xxl-8 data-bs-spy="scroll" dash'>
                    <div className='w-25 compfl m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/CompanyProfile/CompanyInformation"><p>Company Profile</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                    {permis.manage_company_profile?<li>
                                        <NavLink to="/Dashboard/CompanyProfile/CompanyInformation" className={_([" mb-2 px-2", css2.link_text])}><p>Company Information</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_social_profile?<li>
                                        <NavLink to="/Dashboard/CompanyProfile/SocialProfile" className={_([" mb-2 px-2", css2.link_text])}><p>Social Profile</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_subadmin?<><li>
                                        <NavLink to={{pathname:"/Dashboard/CompanyProfile/Admins",state:{stage:'Admin'}} } className={_(["mb-2 px-2", css2.link_text])}><p>Admins</p>
                                            <p>{'>'}</p></NavLink>
                                    </li></>:null}
                                    {permis.manage_role?<><li>
                                        <NavLink to="/Dashboard/CompanyProfile/Roles" className={_(["mb-2 px-2", css2.link_text])}><p>Roles</p>
                                            <p>{'>'}</p></NavLink>
                                    </li></>:null}
                                   {permis.manage_review?<li>
                                        <NavLink to="/Dashboard/CompanyProfile/UserReviews" className={_(["mb-2 px-2", css2.link_text])}><p>Users Review</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    <li>
                                        <NavLink to="/Dashboard/CompanyProfile/ManageBanners" className={_(["mb-2 px-2", css2.link_text])}><p>Manage Banners</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className=' w-25 mypfl m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/MyProfile/MyAccount"><p>My Profile</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                    <li>
                                        <NavLink to="/Dashboard/MyProfile/MyAccount" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>My Account</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    {permis.email_notification?<li>
                                        <NavLink to="/Dashboard/MyProfile/EmailNotification" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Email Notificaton</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_noftification?<li>
                                        <NavLink to="/Dashboard/MyProfile/SendNotification" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Send Notificaton</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.admin_access?<li>
                                        <NavLink to="/Dashboard/MyProfile/AdminAccess" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Admin Access</p> <p>{count===0?null:<label className={_(['mr-2',css2.notifydot,mainCss.font_14])}>{count}</label>}
                                            {'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.admin_activities?<li>
                                        <NavLink to="/Dashboard/MyProfile/AdminActivities" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Admin Activities</p><p>{count2===0?null:<label className={_(['mr-2',css2.notifydot,mainCss.font_14])}>{count2}</label>}
                                            {'>'}</p></NavLink>
                                    </li>:null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    { permis.manage_amenities|| permis.manage_vehicles || permis.manage_coupan || permis.manage_message|| permis.manage_cancellations_reasons?<div className=' w-25 pkgdata m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/ParkingData/Amenities"><p>Parking Data</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   {permis.manage_amenities ? <li>
                                        <NavLink to="/Dashboard/ParkingData/Amenities" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Amenities</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_vehicles ? <li>
                                        <NavLink to="/Dashboard/ParkingData/Vehicles" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Vehicles</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_coupans ? <li>
                                        <NavLink to="/Dashboard/ParkingData/Coupons" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Coupons</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_message?<li>
                                        <NavLink to="/Dashboard/ParkingData/ApplicationMessages" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Application Messages</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_cancellations_reasons?<li>
                                        <NavLink to="/Dashboard/ParkingData/CancellationReasons" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Cancellation Reasons</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                </ul>
                            </div>
                        </div>
                    </div>:null
                    }
                     {permis.manage_terms_and_conditions || permis.manage_monthly_parking_terms || permis.manage_hourly_parking_terms|| 
                      permis.manage_documentations_terms || permis.manage_extensions_terms?<div className=' w-25 trms m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/Terms/TermsConditions"><p>Terms</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   {permis.manage_terms_and_conditions? <li>
                                        <NavLink to="/Dashboard/Terms/TermsConditions" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Terms &amp; Conditions</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_monthly_parking_terms ?<li>
                                        <NavLink to="/Dashboard/Terms/MonthlyParkingTerms" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Monthly Parking Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_hourly_parking_terms ?<li>
                                        <NavLink to="/Dashboard/Terms/HourlyParkingTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Hourly Parking Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_documentations_terms?<li>
                                        <NavLink to="/Dashboard/Terms/DocumentationTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Documentation Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_provider_terms?<li>
                                        <NavLink to="/Dashboard/Terms/ProviderTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Provider Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_extensions_terms?<li>
                                        <NavLink to="/Dashboard/Terms/ExtensionTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Extension Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                </ul>
                            </div>
                        </div>
                    </div>:null}
                    {permis.manage_who_we_are ||  permis.manage_our_vision || permis.manage_help_and_faq  
                    || permis.manage_about_parking_bud || permis.manage_privacy_and_policy?<div className=' w-25 abtus m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/AboutUs/WhoWeAte"><p>About Us</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                    {permis.manage_who_we_are?<li>
                                        <NavLink to="/Dashboard/AboutUs/WhoWeAre" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Who we are</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_our_vision ?<li>
                                        <NavLink to="/Dashboard/AboutUs/OurVision" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Our Vision</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_help_and_faq ?<li>
                                        <NavLink to="/Dashboard/AboutUs/HelpFAQs" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Help &amp; FAQs</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_about_parking_bud ?<li>
                                        <NavLink to="/Dashboard/AboutUs/AboutParkingBud" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>About ParkingBud</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                    {permis.manage_privacy_and_policy ?<li>
                                        <NavLink to="/Dashboard/AboutUs/PrivacyPolicy" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Privacy &amp; Policy</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                </ul>
                            </div>
                        </div>
                    </div>:null}
                    {permis.view_static ||permis.views_services_charge||permis.manage_service_charge||permis.view_chat_inquiries||permis.manage_chat_inquiries||permis.view_contect_us_queries||permis.manage_contect_us_queries?<div className=' w-25 repiqry m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/ReportInquries/Statistics"><p>Report &#38; Inquiries </p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   { permis.manage_static ? <li>
                                        <NavLink to="/Dashboard/ReportInquries/Statistics" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Statistics</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_service_charge? <li>
                                        <NavLink to="/Dashboard/ReportInquries/ServiceCharges" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Service Changes</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_chat_inquiries? <li>
                                        <NavLink to="/Dashboard/ReportInquries/ChatInquiries" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Chat Inquiries</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                   {permis.manage_contect_us_queries? <li>
                                        <NavLink to="/Dashboard/ReportInquries/ContactUsQueries" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Contact Us Queries</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>:null}
                                </ul>
                            </div>
                        </div>
                    </div>:null}

                </div> */}
                  <div className='col-md-6 col-sm-12 col-lg-7 col-xl-8 col-xxl-8 data-bs-spy="scroll" dash'>
                    <div className='w-25 compfl m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/CompanyProfile/CompanyInformation"><p>Company Profile</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   <li>
                                        <NavLink to="/Dashboard/CompanyProfile/CompanyInformation" className={_([" mb-2 px-2", css2.link_text])}><p>Company Information</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/CompanyProfile/SocialProfile" className={_([" mb-2 px-2", css2.link_text])}><p>Social Profile</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <><li>
                                        <NavLink to={{pathname:"/Dashboard/CompanyProfile/Admins",state:{stage:'Admin'}} } className={_(["mb-2 px-2", css2.link_text])}><p>Admins</p>
                                            <p>{'>'}</p></NavLink>
                                    </li></>
                                    <><li>
                                        <NavLink to="/Dashboard/CompanyProfile/Roles" className={_(["mb-2 px-2", css2.link_text])}><p>Roles</p>
                                            <p>{'>'}</p></NavLink>
                                    </li></>
                                   <li>
                                        <NavLink to="/Dashboard/CompanyProfile/UserReviews" className={_(["mb-2 px-2", css2.link_text])}><p>Users Review</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/CompanyProfile/ManageBanners" className={_(["mb-2 px-2", css2.link_text])}><p>Manage Banners</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className=' w-25 mypfl m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/MyProfile/MyAccount"><p>My Profile</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                    <li>
                                        <NavLink to="/Dashboard/MyProfile/MyAccount" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>My Account</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/MyProfile/EmailNotification" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Email Notificaton</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                 <li>
                                        <NavLink to="/Dashboard/MyProfile/SendNotification" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Send Notificaton</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                   <li>
                                        <NavLink to="/Dashboard/MyProfile/AdminAccess" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Admin Access</p> <p>{count===0?null:<label className={_(['mr-2',css2.notifydot,mainCss.font_14])}>{count}</label>}
                                            {'>'}</p></NavLink>
                                    </li>
                                   <li>
                                        <NavLink to="/Dashboard/MyProfile/AdminActivities" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Admin Activities</p><p>{count2===0?null:<label className={_(['mr-2',css2.notifydot,mainCss.font_14])}>{count2}</label>}
                                            {'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                 <div className=' w-25 pkgdata m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/ParkingData/Amenities"><p>Parking Data</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   <li>
                                        <NavLink to="/Dashboard/ParkingData/Amenities" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Amenities</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                  <li>
                                        <NavLink to="/Dashboard/ParkingData/Vehicles" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Vehicles</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                     <li>
                                        <NavLink to="/Dashboard/ParkingData/Coupons" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Coupons</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/ParkingData/ApplicationMessages" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Application Messages</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/ParkingData/CancellationReasons" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Cancellation Reasons</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                     <div className=' w-25 trms m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/Terms/TermsConditions"><p>Terms</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                  <li>
                                        <NavLink to="/Dashboard/Terms/TermsConditions" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Terms &amp; Conditions</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                 <li>
                                        <NavLink to="/Dashboard/Terms/MonthlyParkingTerms" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Monthly Parking Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                  <li>
                                        <NavLink to="/Dashboard/Terms/HourlyParkingTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Hourly Parking Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/Terms/DocumentationTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Documentation Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                   <li>
                                        <NavLink to="/Dashboard/Terms/ProviderTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Provider Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                  <li>
                                        <NavLink to="/Dashboard/Terms/ExtensionTerms" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Extension Terms</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className=' w-25 abtus m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/AboutUs/WhoWeAte"><p>About Us</p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                    <li>
                                        <NavLink to="/Dashboard/AboutUs/WhoWeAre" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Who we are</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                   <li>
                                        <NavLink to="/Dashboard/AboutUs/OurVision" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Our Vision</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/AboutUs/HelpFAQs" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Help &amp; FAQs</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Dashboard/AboutUs/AboutParkingBud" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>About ParkingBud</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                   <li>
                                        <NavLink to="/Dashboard/AboutUs/PrivacyPolicy" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Privacy &amp; Policy</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   <div className=' w-25 repiqry m-4'>
                        <div>
                            <Link className='heading-title' to="/Dashboard/ReportInquries/Statistics"><p>Report &#38; Inquiries </p></Link>
                            <div className="w-100">
                                <ul className={_(["w-100", css2.nav_style, mainCss.font_18])}>
                                   <li>
                                        <NavLink to="/Dashboard/ReportInquries/Statistics" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Statistics</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                  <li>
                                        <NavLink to="/Dashboard/ReportInquries/ServiceCharges" className={_([" mb-2 px-2", css2.link_text])}>
                                            <p>Service Changes</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                  <li>
                                        <NavLink to="/Dashboard/ReportInquries/ChatInquiries" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Chat Inquiries</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                              <li>
                                        <NavLink to="/Dashboard/ReportInquries/ContactUsQueries" className={_(["mb-2 px-2", css2.link_text])}>
                                            <p>Contact Us Queries</p>
                                            <p>{'>'}</p></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ChangePassword isOpen={changePassword} toggle={ChangedPasswordToggle} />


        </div>

    )

}

export default AdminDashbord;