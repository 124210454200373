import React from 'react';


class CheckAnimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <label className="switch">
                <input type="checkbox" value={this.props.value} onClick={this.props.onClick} />
                <span className="slider round"></span>
            </label>
        )
    }
}


export default CheckAnimate