

/* csss */
import './myprofile.css'
import css from "../../main.module.css";
import css3 from './../common.module.css'
import Loader from "../Reusable Popup/Loader";
import { useEffect, useState, useContext} from "react";
import axiosInstance from "./../../axios";

import NotificationContext from '../notification/NotificationContext';




const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
}
function EmailNotification(){
    
const [loading, setLoading] = useState(false);

const profiledata=useContext(NotificationContext)


const[pending_approvel, setpending_approvel]=useState(false)
const[admin_access_request, setadmin_access_request]=useState(false)
const[admin_forgot_password, setadmin_forgot_password]=useState(false)
const[new_provider_signup, setnew_provider_signup]=useState(false)
const[new_admin_added,setnew_admin_added]=useState(false)
const[new_admin_device_alert, setnew_admin_device_alert] =useState(false)
const[invoice_generated,setinvoice_generated]=useState(false)    
const[payment_initiated,setpayment_initiated]=useState(false)
const[payment_transferred,setpayment_transferred]=useState(false)
const[payment_pending,setpayment_pending]=useState(false)
const[payment_withdarwn,setpayment_withdarwn]=useState(false)
const[alert_sent_to_customers,setalert_sent_to_customers]=useState(false)
const[admin_activites,setadmin_activites]=useState(false)
const[admin_access_granted,setadmin_access_granted]=useState(false)
const[admin_access_requested,setadmin_access_requested]=useState(false)
const[compney_information_updated,setcompney_information_updated]=useState(false)
const[social_profile_updated,setsocial_profile_updated]=useState(false)
const[application_message_added,setapplication_message_added]=useState(false)
const[service_charge_edited,  setservice_charge_edited]=useState(false)
const[new_query,setnew_query]=useState(false)
const[inquiry_resolved,setinquiry_resolved]=useState(false)
const[faq_added,setfaq_added]=useState(false)
const[coupon_deactivated,setcoupon_deactivated]=useState(false)
const[banner_added,setbanner_added]=useState(false)
const[banner_published,setbanner_published]=useState(false)
const[banner_deactivated,  setbanner_deactivated]=useState(false)
const[coupon_created,setcoupon_created]=useState(false)
const[noftification_sent,setnoftification_sent]=useState(false)
const[terms_edited,setterms_edited]=useState(false)
const[privacy_policy_edited,  setprivacy_policy_edited]=useState(false)
const[about_us_edited,setabout_us_edited]=useState(false)



const [arr,setarr] = useState([])
const data=profiledata.email_data===undefined? {}:profiledata.email_data
console.log(profiledata)

// async function checknotiparmisn() {
//     setLoading(true)

//     let data = ""
//     await axiosInstance.get(
//         'admin_dash/email_permission'
//     ).then((response) => {
//         data = response.data.response.data;
//         setpending_approvel(data.pending_approvel);
//         setadmin_access_request(data.admin_access_request);
//         setnew_admin_device_alert(data.new_admin_device_alert);
//         setinvoice_generated(data.invoice_generated);
//         setpayment_initiated(data.payment_initiated);
//         setpayment_transferred(data.payment_transferred);
//         setpayment_pending(data.payment_pending);
//         setpayment_withdarwn(data.payment_withdarwn);
//         setalert_sent_to_customers(data.alert_sent_to_customers);
//         setadmin_activites(data.admin_activites);
//         setadmin_access_granted(data.admin_access_granted);
//         setadmin_access_requested(data.admin_access_requested);
//         setcompney_information_updated(data.compney_information_updated);
//         setsocial_profile_updated(data.social_profile_updated);
//         setapplication_message_added(data.application_message_added);
//         setservice_charge_edited(data.service_charge_edited);
//         setnew_query(data.new_query);
//         setinquiry_resolved(data.inquiry_resolved);
//         setfaq_added(data.faq_added);
//         setcoupon_deactivated(data.coupon_deactivated);
//         setbanner_added(data.banner_added)
//         setbanner_published(data.banner_published)
//         setbanner_deactivated(data.banner_deactivated)
//         setabout_us_edited(data.about_us_edited)
//         setnoftification_sent(data.noftification_sent)
//         setterms_edited(data.terms_edited)
//         setprivacy_policy_edited(data.privacy_policy_edited)
//         setcoupon_created(data.coupon_created)
//         setadmin_forgot_password(data.admin_forgot_password);
//         setnew_provider_signup(data.new_provider_signup);
//         setnew_admin_added(data.new_admin_added);
        
        
        
//         for(var i in data){
//             if (data[i]){
//                 arr.push(i)}
//         }
//         setLoading(false)

//     }).catch((err) => {
//         console.log(err.response.data);

//     })

//}

useEffect(() => {
        setpending_approvel(data.pending_approvel);
        setadmin_access_request(data.admin_access_request);
        setnew_admin_device_alert(data.new_admin_device_alert);
        setinvoice_generated(data.invoice_generated);
        setpayment_initiated(data.payment_initiated);
        setpayment_transferred(data.payment_transferred);
        setpayment_pending(data.payment_pending);
        setpayment_withdarwn(data.payment_withdarwn);
        setalert_sent_to_customers(data.alert_sent_to_customers);
        setadmin_activites(data.admin_activites);
        setadmin_access_granted(data.admin_access_granted);
        setadmin_access_requested(data.admin_access_requested);
        setcompney_information_updated(data.compney_information_updated);
        setsocial_profile_updated(data.social_profile_updated);
        setapplication_message_added(data.application_message_added);
        setservice_charge_edited(data.service_charge_edited);
        setnew_query(data.new_query);
        setinquiry_resolved(data.inquiry_resolved);
        setfaq_added(data.faq_added);
        setcoupon_deactivated(data.coupon_deactivated);
        setbanner_added(data.banner_added)
        setbanner_published(data.banner_published)
        setbanner_deactivated(data.banner_deactivated)
        setabout_us_edited(data.about_us_edited)
        setnoftification_sent(data.noftification_sent)
        setterms_edited(data.terms_edited)
        setprivacy_policy_edited(data.privacy_policy_edited)
        setcoupon_created(data.coupon_created)
        setadmin_forgot_password(data.admin_forgot_password);
        setnew_provider_signup(data.new_provider_signup);
        setnew_admin_added(data.new_admin_added);
        
        
        
        for(var i in data){
            if (data[i]){
                arr.push(i)}
        }

}, [data])


    const handleOnChange = (e) => {
        
        if (e.target.checked){
            if (e.target.name==='pending_approvel'){
                setpending_approvel(true)}
            if(e.target.name==='admin_access_request'){
                setadmin_access_request(true)}
            if (e.target.name==='new_admin_device_alert'){
                setnew_admin_device_alert(true)}
            if (e.target.name==='invoice_generated'){
                setinvoice_generated(true)}
            if (e.target.name==='payment_initiated'){
                setpayment_initiated(true)}
            if (e.target.name==='payment_transferred'){
                setpayment_transferred(true)}
            if (e.target.name==='payment_pending'){
                setpayment_pending(true)}
            if (e.target.name==='payment_withdarwn'){
                setpayment_withdarwn(true)}
            if (e.target.name==='alert_sent_to_customers'){
                setalert_sent_to_customers(true)}
            if (e.target.name==='admin_activites'){
                setadmin_activites(true)}
            if (e.target.name==='admin_access_granted'){
                setadmin_access_granted(true)}
            if (e.target.name==='admin_access_requested'){
                setadmin_access_requested(true)}
            if (e.target.name==='compney_information_updated'){
                setcompney_information_updated(true)}
            if (e.target.name==='social_profile_updated'){
                setsocial_profile_updated(true)}
            if (e.target.name==='application_message_added'){
                setapplication_message_added(true)}
            if (e.target.name==='service_charge_edited'){
                setservice_charge_edited(true)}
            if (e.target.name==='new_query'){
                setnew_query(true)}
            if (e.target.name==='inquiry_resolved'){
                setinquiry_resolved(true)}
            if (e.target.name==='faq_added'){
                setfaq_added(true)}
            if (e.target.name==='coupon_deactivated'){
                setcoupon_deactivated(true)}
            if (e.target.name==='banner_added'){
                setbanner_added(true)}
            if (e.target.name==='banner_published'){
                setbanner_published(true)}
            if (e.target.name==='banner_deactivated'){
                setbanner_deactivated(true)}
            if (e.target.name==='about_us_edited'){
                setabout_us_edited(true)}
            if (e.target.name==='noftification_sent'){
                setnoftification_sent(true)}
            if (e.target.name==='terms_edited'){
                setterms_edited(true)}
            if (e.target.name==='privacy_policy_edited'){
                setprivacy_policy_edited(true)}
            if (e.target.name==='coupon_created'){
                setcoupon_created(true)}
            if(e.target.name==='admin_forgot_password'){
                setadmin_forgot_password(true)}
            if(e.target.name==='new_provider_signup'){
                setnew_provider_signup(true)}
            if(e.target.name==='new_admin_added'){
                setnew_admin_added(true)
            }

            arr.push(e.target.name)
            
            
    
        }
       else{
            arr.splice(arr.indexOf(e.target.name),1)
            if (e.target.name==='pending_approvel'){
                setpending_approvel(false)}
            if (e.target.name==='admin_access_request'){
                setadmin_access_request(false)}
            if (e.target.name==='new_admin_device_alert'){
                setnew_admin_device_alert(false)}
            if (e.target.name==='invoice_generated'){
                setinvoice_generated(false)}
            if (e.target.name==='payment_initiated'){
                setpayment_initiated(false)}
            if (e.target.name==='payment_transferred'){
                setpayment_transferred(false)}
            if (e.target.name==='payment_pending'){
                setpayment_pending(false)}
            if (e.target.name==='payment_withdarwn'){
                setpayment_withdarwn(false)}
            if (e.target.name==='alert_sent_to_customers'){
                setalert_sent_to_customers(false)}
            if (e.target.name==='admin_activites'){
                setadmin_activites(false)}
            if (e.target.name==='admin_access_granted'){
                setadmin_access_granted(false)}
            if (e.target.name==='admin_access_requested'){
                setadmin_access_requested(false)}
            if (e.target.name==='compney_information_updated'){
                setcompney_information_updated(false)}
            if (e.target.name==='social_profile_updated'){
                setsocial_profile_updated(false)}
            if (e.target.name==='application_message_added'){
                setapplication_message_added(false)}
            if (e.target.name==='service_charge_edited'){
                setservice_charge_edited(false)}
            if (e.target.name==='new_query'){
                setnew_query(false)}
            if (e.target.name==='inquiry_resolved'){
                setinquiry_resolved(false)}
            if (e.target.name==='faq_added'){
                setfaq_added(false)}
            if (e.target.name==='coupon_deactivated'){
                setcoupon_deactivated(false)}
            if (e.target.name==='banner_added'){
                setbanner_added(false)}
            if (e.target.name==='banner_published'){
                setbanner_published(false)}
            if (e.target.name==='banner_deactivated'){
                setbanner_deactivated(false)}
            if (e.target.name==='about_us_edited'){
                setabout_us_edited(false)}
            if (e.target.name==='noftification_sent'){
                setnoftification_sent(false)}
            if (e.target.name==='terms_edited'){
                setterms_edited(false)}
            if (e.target.name==='privacy_policy_edited'){
                setprivacy_policy_edited(false)}
            if (e.target.name==='coupon_created'){
                setcoupon_created(false)}
            if(e.target.name==='admin_forgot_password'){
                setadmin_forgot_password(false)}
            if(e.target.name==='new_provider_signup'){
                setnew_provider_signup(false)}
            if(e.target.name==='new_admin_added'){
                setnew_admin_added(false)}       
       
       }
    }

    const email_notification=async()=>{

        let formData = new FormData();
        for(var i in arr){
            formData.append(arr[i], true);
        }
        setLoading(true)
        await axiosInstance.post(
            'admin_dash/email_permission',
            formData,
            {headers:{"content-type":"multipart/form-data"} }
        ).then((response)=>{
            setLoading(false)
        }).catch((err)=>{console.log("error",err)})
    }


    return ( 
            
        <div className='container mb-5'>
            <div className='col-md-12 mt-5 Headingstyle'>
                <h4>Alert &amp; Notification</h4>
            </div>
            <div className='col-md-6 mt-5 d-flex justify-content-start'>
                <p className='fw-bold'>Email me when:</p>
            </div>
            {loading? <div className='col-md-12  mt-5 mb-5 d-flex justify-content-center'><Loader  loading={true}/></div>:<><div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check2">
                        <input type="checkbox" checked={pending_approvel===true?true:false} className={_(["mr-2", css.checkbox_size])} name='pending_approvel' onChange={handleOnChange} id="check2"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Pending Approval Notifications (for Providers, Parkings, Etc)</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check3">
                        <input type="checkbox" checked={compney_information_updated} className={_(["mr-2", css.checkbox_size])} name='compney_information_updated' onChange={handleOnChange} id="check3"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Company Information Updated</p>
                </div>

            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check4">
                        <input type="checkbox" checked={admin_access_request} className={_(["mr-2", css.checkbox_size])} name='admin_access_request' onChange={handleOnChange} id="check4"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Admin Access Request</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check5">
                        <input type="checkbox" checked={social_profile_updated}  className={_(["mr-2", css.checkbox_size])} name='social_profile_updated' onChange={handleOnChange} id="check5"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Social Profile Updated</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check6">
                        <input type="checkbox" checked={admin_forgot_password} className={_(["mr-2", css.checkbox_size])} name='admin_forgot_password' onChange={handleOnChange} id="check6"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Admin Forgot password</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check7">
                        <input type="checkbox" checked={application_message_added} className={_(["mr-2", css.checkbox_size])} name='application_message_added' onChange={handleOnChange} id="check7"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Application Message Added</p>
                </div>

            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check8">
                        <input type="checkbox" checked={new_provider_signup} className={_(["mr-2", css.checkbox_size])} name='new_provider_signup' onChange={handleOnChange} id="check8"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>New Provider Signup</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check9">
                        <input type="checkbox" checked={service_charge_edited} className={_(["mr-2", css.checkbox_size])} name='service_charge_edited' onChange={handleOnChange} id="check9"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Service Charge Edited</p>
                </div>

            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check10">
                        <input type="checkbox" checked={new_admin_added} className={_(["mr-2", css.checkbox_size])} name='new_admin_added' onChange={handleOnChange} id="check10"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>New Admin Added</p>
                </div>
                <div className='col-md-6  mt-1 d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check11">
                        <input type="checkbox" checked={new_query}  className={_(["mr-2", css.checkbox_size])} name='new_query' onChange={handleOnChange} id="check11"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>New Query</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check12">
                        <input type="checkbox" checked={new_admin_device_alert} className={_(["mr-2", css.checkbox_size])} name='new_admin_device_alert' onChange={handleOnChange} id="check12"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>New Admin Device Alert</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check13">
                        <input type="checkbox" checked={inquiry_resolved} className={_(["mr-2", css.checkbox_size])} name='inquiry_resolved' onChange={handleOnChange} id="check13"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Inquiry Resolved</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check14">
                        <input type="checkbox" checked={invoice_generated} className={_(["mr-2", css.checkbox_size])} name='invoice_generated' onChange={handleOnChange} id="check14"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Invoice Generated (for Providers Or Parking Spots)</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check15">
                        <input type="checkbox" checked={faq_added} className={_(["mr-2", css.checkbox_size])} name='faq_added' onChange={handleOnChange} id="check15"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>FAQ Added</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check16">
                        <input type="checkbox" checked={payment_initiated} className={_(["mr-2", css.checkbox_size])} name='payment_initiated' onChange={handleOnChange} id="check16"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Payment Initiated</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check17">
                        <input type="checkbox" checked={coupon_created} className={_(["mr-2", css.checkbox_size])} name='coupon_created' onChange={handleOnChange} id="check17"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Coupon Created</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check18">
                        <input type="checkbox" checked={payment_transferred} className={_(["mr-2", css.checkbox_size])} name='payment_transferred' onChange={handleOnChange} id="check18"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Payment Transferred</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check19">
                        <input type="checkbox" checked={coupon_deactivated} className={_(["mr-2", css.checkbox_size])} name='coupon_deactivated' onChange={handleOnChange} id="check19"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Coupon Deactivated</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check20">
                        <input type="checkbox" checked={payment_pending}  className={_(["mr-2", css.checkbox_size])} name='payment_pending' onChange={handleOnChange} id="check20"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Payment Pending</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check21">
                        <input type="checkbox" checked={banner_added}  className={_(["mr-2", css.checkbox_size])} name='banner_added' onChange={handleOnChange} id="check21"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Banner Added</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check22">
                        <input type="checkbox" checked={payment_withdarwn} className={_(["mr-2", css.checkbox_size])} name='payment_withdarwn'onChange={handleOnChange} id="check22"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Payment Withdrawn</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check23">
                        <input type="checkbox" checked={banner_published} className={_(["mr-2", css.checkbox_size])} name='banner_published' onChange={handleOnChange} id="check23"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Banner Published</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check24">
                        <input type="checkbox" checked={alert_sent_to_customers}  className={_(["mr-2", css.checkbox_size])} name='alert_sent_to_customers' onChange={handleOnChange} id="check24"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Alert Sent To Customers</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check25">
                        <input type="checkbox" checked={banner_deactivated} className={_(["mr-2", css.checkbox_size])} name='banner_deactivated' onChange={handleOnChange} id="check25"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Banner Deactivated</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check26">
                        <input type="checkbox" checked={admin_activites}  className={_(["mr-2", css.checkbox_size])} name='admin_activites' onChange={handleOnChange} id="check26"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Admin Activities</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check27">
                        <input type="checkbox" checked={noftification_sent}  className={_(["mr-2", css.checkbox_size])} name='noftification_sent' onChange={handleOnChange} id="check27"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Notification Sent</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check28">
                        <input type="checkbox" checked={admin_access_granted}  className={_(["mr-2", css.checkbox_size])} name='admin_access_granted' onChange={handleOnChange} id="check28"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Admin Access Granted</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check29">
                        <input type="checkbox" checked={terms_edited} className={_(["mr-2", css.checkbox_size])} name='terms_edited' onChange={handleOnChange} id="check29"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Terms Edited</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check30">
                        <input type="checkbox" checked={admin_access_requested} className={_(["mr-2", css.checkbox_size])} name='admin_access_requested' onChange={handleOnChange} id="check30"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Admin Access Requested</p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check31">
                        <input type="checkbox" checked={privacy_policy_edited}  className={_(["mr-2", css.checkbox_size])} name='privacy_policy_edited' onChange={handleOnChange} id="check31"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>Privacy &amp; Policy Edited</p>
                </div>
            </div>
            <div className='col-md-12 d-flex p-0'>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <p className='ml-2'></p>
                </div>
                <div className='col-md-6 mt-1  d-flex justify-content-start'>
                    <label className={_(["", css.checkbox_group2])} htmlFor="check32">
                        <input type="checkbox" checked={about_us_edited}  className={_(["mr-2", css.checkbox_size])} name='about_us_edited' onChange={handleOnChange} id="check32"></input>
                        <span className={_(["mr-1", css.box])}></span>
                    </label>
                    <p className='ml-2'>About Us Edited</p>
                </div>
            </div>
            <div className={_(["d-flex flex-row justify-content-around align-items-center mt-5", css.font1])}
            >
                <button
                    onClick={email_notification }
                    // type='submit'
                    className={_(["btn btn_B d-flex justify-content-center align-items-center w-25 ", css3.inside_button, css.inside_button1, css.font_18])}
                >
                    Save a change
                </button>
            </div></>}
        </div>


    )

}


export default EmailNotification;