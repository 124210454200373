import React, { useState, useEffect } from "react";
import { Link, useNavigate, } from "react-router-dom";
import CountryCodes from "./../../../Dashboard_page/countryCode";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReactCountryFlag from "react-country-flag";
import emptyProfileIcon from "./../../../assets/Icons/Icon feather-user.svg";

import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "./../../../config/ImageFileConfig"
/* csss */
import css2 from "../../../components/Profile/ProfilePage.module.css";
import mainCss from "../../../main.module.css";
//
import validator from "validator";
import axiosInstance from "./../../../axios";
import { DATA_PROCESSOR, ERROR__PROCESSOR } from "../../../Tools";





const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const MyProfileEdit = () => {
    const navigate = useNavigate();

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);

    const [state, setState] = useState({
        profile_pic: null,
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        country_code: "1",
        countryCodeData: "US",
        city: "",
        state: "",
        zip_code: "",
        timezone: "",
    })

    const [stateError, setStateError] = useState({
        profile_pic: null,
        first_name: null,
        last_name: null,
        is_approved: null,
        email: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        country: null,
        country_code: null,
        city: null,
        state: null,
        zip_code: null,
        timezone: null,
        website_url: null,
        user_type: null,
    })

    const [editMyAccount, setEditMyAccount] = useState({
        countryCode: "",
        

    })

    async function makerequest() {
        setImageLoading(true)

        let data = ""
        await axiosInstance.get(
            "admin_dash/edit_admin_profile"
        ).then((response) => {
            data = response.data.response.user;
            console.log("data",data)
            DATA_PROCESSOR(data, state, setState)
            setImageLoading(false)

        }).catch((err) => {
            console.log('editprofile_error',err.response.data);

        })

    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        setStateError({ ...stateError, [e.target.name]: null })
        
    }


    useEffect(() => {
        makerequest()

    }, [])

    console.log('country data', state.countryCodeData);

    const [changePassword, setChangePassword] = useState(false);

    const ChangedPasswordToggle = () => {
        if (changePassword) {
            setChangePassword(false)
        }
        else {
            setChangePassword(true)
        }
    }


    const handleImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            setProfilePhoto(null);

            if (file) {
                setImageLoading(true);
                //compressing image
                // console.log("Options", options);
                const compressedFile = await imageCompression(file, options);
                console.log(
                    `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
                );
                if (compressedFile.size / 1024 / 1024 > 2) {
                    // throw {response:{data:"Image size is too large"}};
                    throw "Image size is too large";
                }

                const fileEE = new File([compressedFile], file.name);
                setProfilePhoto(fileEE);

                let base64Image = await new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (err) => {
                        reject(err);
                    };
                });

                if (base64Image !== undefined) {
                    setState({
                        ...state,
                        profile_pic: file,
                        profile_preview : base64Image,
                    });
                    setImageLoading(false);
                }
            } else {
                // setImageError (false)
                setState({
                    ...state,
                    profile_pic: null,
                });
                setProfilePhoto(null);
                setImageLoading(false);
            }
        } catch (err) {
            console.log("Errror in image upload ", err);
        }
    };

    async function Save() {

        if (validator.isEmpty(state.first_name)) {
            setStateError({ ...stateError, first_name: "Cannot be empty" })
            return
        }
        if (validator.isEmpty(state.last_name)) {
            setStateError({ ...stateError, last_name: "Cannot be empty" })
            return
        }
        if (!validator.isEmail(state.email)) {
            setStateError({ ...stateError, email: "Invalid Email" })
            return
        }

        if (validator.isEmpty(state.phone_number)) {
            setStateError({ ...stateError, phone_number: "Cannot be empty" })
            return
        }


        let formData = new FormData();
        formData.append('email', state.email);
        //formData.append('profile_pic',state.email);
        formData.append('first_name', state.first_name);
        formData.append('last_name', state.last_name);
        formData.append('email', state.email);
        formData.append('phone_number', state.phone_number);
        formData.append('address_line_1', state.address_line_1);
        formData.append('address_line_2', state.address_line_2);
        formData.append('country_code', state.country_code);
        formData.append('country', state.country);
        formData.append('city', state.city);
        formData.append('state', state.state);
        formData.append('zip_code', state.zip_code);
        formData.append('timezone', state.timezone);
        if (state.profile_pic.type) {
            console.log(state.profile_pic)
            formData.append("profile_pic", state.profile_pic);
        }
        await axiosInstance.post(
            "admin_dash/edit_admin_profile",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success) {
                window.location.reload()
            }
            return
        }).catch((err) => {
            console.log("error in saving");
            if (err.response && err.response.data.errors) {
                ERROR__PROCESSOR(err.response.data.errors, stateError, setStateError)
            }
        })
    };





    return (
        <div className="container bg-white mt-5">
            <div className='container mt-4 mb-3'>
                <p className={_(["align-self-start mb-4 mt-4"])}><span onClick={()=>navigate('/Dashboard/details')}>Profile</span> {'>'} My Profile {'>'} Edit</p>
                <h4 className="linkstyle fw-bold">Edit Profile </h4>
            </div>

            <div className={_(["bg-white", css2.edit_account, mainCss.font2])}>
                <div className="d-flex flex-row w-100 justify-content-end">
                    <Link className="linkstyle" to="/Dashboard/MyProfile/MyAccount">Cancel</Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center ">
                    {imageLoading ? (
                        <div className='spinner-border text-muted mt-4 mb-4'></div>
                    ) : (
                        <>
                            <div className="ml-5">
                                <img
                                    src={state.profile_pic ? (state.profile_preview ? state.profile_preview : state.profile_pic) : (emptyProfileIcon)}
                                    alt="loading..."
                                    className={_(["", css2.profile_img])}></img>
                            </div>
                            <label className={_(["", css2.upload_button, mainCss.inside_button1, mainCss.font1])}>
                                Choose File
                                <input type="file" id='uploadImage' accept='image/*' onChange={handleImage} hidden></input>
                            </label>



                        </>
                    )}
                </div>
                <div className={_(["d-flex mt-4 flex-row w-100 justify-content-between align-items-center ", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            First Name
                        </label>
                        


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='First Name'
                            name='first_name'
                            value={state.first_name}
                            onChange={handleChange}
                            required
                        />
                        {stateError.first_name &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.first_name}</span>

                            </div>
                        }
                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Last Name
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Last Name'
                            name='last_name'
                            value={state.last_name}
                            onChange={handleChange}
                            required
                        />
                        {stateError.last_name &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.last_name}</span>

                            </div>
                        }
                    </div>
                </div>
                <div className={_(["d-flex mt-4 flex-row w-100 justify-content-between align-items-center ", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Email
                        </label>


                        <input
                            type='email'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Email address'
                            name='email'
                            value={state.email}
                            onChange={handleChange}
                        />
                        {stateError.email &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.email}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["b-0", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Phone Number
                        </label>

                        <div className={_(["d-flex flex-row align-items-center"])}>
                            <ReactCountryFlag
                                className="mr-2"
                                countryCode={state.countryCodeData}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                style={{
                                    width: '1.5em',
                                    height: '1.5em',
                                    marginTop:'0.5rem'
                                }}
                            />

                            <CountryCodes cs={_(['input_box ml-1 mr-2 w-25', mainCss.input_box_style, mainCss.font2])}
                                name="country_code"
                                value={state.country_code}
                                handleContryCodeChange={(e) => {

                                    setState({
                                        ...state,
                                        countryCodeData: e.target[e.target.selectedIndex].getAttribute('data'),
                                        country_code: e.target.value
                                    });
                                    
                                }}
                                onChange={handleChange}
                            />

                            <input
                                type='input'
                                className={_(['input_box ml-1', mainCss.input_box_style])}
                                placeholder=''
                                name='number'
                                id='number'
                                value={state.phone_number}
                                onChange={(e) => {
                                    if (validator.isNumeric(e.target.value) || e.target.value == '') {
                                        setState({ ...state, phone_number: e.target.value })
                                        setStateError({
                                            ...stateError, phone_number:null
                                        })
                                    }
                                }}
                                required
                            />
                        </div>
                        {stateError.phone_number &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.phone_number}</span>

                            </div>
                        }
                        {stateError.country_code &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.country_code}</span>

                            </div>
                        }
                    </div>
                </div>
                <hr style={{ width: "100%" }}></hr>
                <p className={_(["d-flex mt-3 w-100 align-items-center ", mainCss.font1, mainCss.font_24])}>
                    Address
                </p>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}
                >
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Address Line 1
                        </label>
                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='123 Street'
                            value={state.address_line_1}
                            name="address_line_1"
                            onChange={handleChange}
                        />
                        {stateError.address_line_1 &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.address_line_1}</span>

                            </div>
                        }
                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Address Line 2
                        </label>
                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Address Line 2'
                            name="address_line_2"
                            value={state.address_line_2}
                            onChange={handleChange}
                        />
                        {stateError.address_line_2 &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.address_line_2}</span>

                            </div>
                        }
                    </div>

                </div>

                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}
                >


                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            City
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder=''
                            name="city"
                            value={state.city}
                            onChange={handleChange}
                        />
                        {stateError.city &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.city}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            State
                        </label>

                        <RegionDropdown
                            name="state"
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            blankOptionLabel="Select State"
                            country={state.country}
                            value={state.state}
                            onChange={(e) => setState({ ...state, state: e })}
                        />
                        {stateError.state &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.state}</span>

                            </div>
                        }
                    </div>

                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}
                >
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Zip Code
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder=''
                            name="zip_code"
                            value={state.zip_code}
                            onChange={handleChange}
                        />
                        {stateError.zip_code &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.zip_code}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Country
                        </label>


                        <CountryDropdown
                            name="country"
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            value={state.country}
                            onChange={(e) => setState({ ...state, country: e })}
                        />
                        {stateError.country &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.country}</span>

                            </div>
                        }

                    </div>
                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-start", css2.input_font, mainCss.font_18])}
                >
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Timezone
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='i.e. 123'
                            name='timezone'
                            value={state.timezone}
                            onChange={handleChange}
                            required
                        />

                    </div>

                </div>

                <div className={_(["d-flex mt-5 flex-row justify-content-center", css2.input_font, mainCss.font_18])}>
                    <button
                        type='button'
                        className={_([" d-flex justify-content-center align-items-center ", css2.inside_button, mainCss.inside_button1, mainCss.font1])}
                        onClick={Save}
                    >
                        Save
                    </button>
                </div>





            </div>




        </div>



    )

}


export default MyProfileEdit