import React, { useState, useEffect } from "react";
import css from "../../main.module.css";
import css2 from "./../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import PaginationComponent from "../../components/Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "../../assets/Icons/Icon material-delete1.svg";
import { DATETOWORDS } from "../../Tools";
import { Link } from "react-router-dom";
import actionIcon from "../../assets/Icons/Repeat Grid 15.svg";
import { Modal } from "reactstrap";
import "./parkingdata.css";
import "../../styles.css";
//
import Loader from "../Reusable Popup/Loader";
import ClipLoader from "react-spinners";
import axiosInstance from "../../axios";
import ParButton from "../../usable/ParButton";
import noParkings from "../../assets/Icons/Group 26816.svg";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const RESULT_LIMIT_IN_PAGE = 6;

function Coupons() {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#33D5A1");
  let [couponCode, setCode] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const [create, setcreate] = useState(false);
  const [inputclick, setinputclick] = useState(false);

  const openCreateCoupons = (event) => {
    if (create) {
      setcreate(false);
      setinputclick(false);
      setdata({
        couponcode: "",
        discount: "",
        redeem_limit: "",
        minimum_spend: "",
        maximum_discount: "",
        expire_time: "",
      });
      setCode("");
    } else {
      setcreate(true);
    }

    //cancelCreateCoupons.current.toggleModal();
  };

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
  };

  const [search, setSearch] = useState("");
  const [state, setState] = useState([]);
  const [page, setPage] = useState(1);

  const generateCode = () => {
    setinputclick(true);
    let code = Math.random().toString(36).slice(2);
    setCode(code);
  };

  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });
  const [data, setdata] = useState({
    couponcode: "",
    discount: "",
    redeem_limit: "",
    minimum_spend: "",
    maximum_discount: "",
    expire_time: "",
  });
  const onChange = (e) => {
    console.log(e.target.name, e.target.value);
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const couponcreated = async () => {
    console.log(data, `${data.expire_time}T23:59:00.000000Z`, couponCode);
    let formData = new FormData();
    if (couponCode === "") {
      formData.append("name", data.couponcode);
    } else {
      formData.append("name", couponCode);
    }
    formData.append("discount", data.discount);
    formData.append("redeem_limit", data.redeem_limit);
    formData.append("minimum_spend", data.minimum_spend);
    formData.append("maximum_discount", data.maximum_discount);
    formData.append("expire_time", `${data.expire_time}T23:59:00.000000Z`);

    await axiosInstance
      .post("admin_dash/add_coupon--1", formData)
      .then((response) => {
        openCreateCoupons();
        alert("Successfully created");
        setinputclick(false);
        setdata({
          couponcode: "",
          discount: "",
          redeem_limit: "",
          minimum_spend: "",
          maximum_discount: "",
          expire_time: "",
        });
      })
      .catch((err) => {
        openCreateCoupons();
        setinputclick(false);
        alert("Try again");
        console.log(err.response.request.status);
      });
  };

  const handleDelete = async (event) => {
    // setLoading(true);
    // setColor('#5c5b5b');
    const element = event.target;
    const amenitiesId = element.getAttribute("data-id");
    console.log("id", amenitiesId);

    let formData = new FormData();

    formData.append("id", amenitiesId);

    await axiosInstance
      .post("admin_dash/delete_coupan", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response);
        // alertToggle()
        // setLoading(false);
        callSearch();
      })
      .catch((err) => {
        console.log("got an error", err.response);
      });
  };

  const ActiveToggle = async (event) => {
    const element = event.target;
    const vehicleId = await element.getAttribute("data-id");

    let formData = new FormData();
    formData.append("id", vehicleId);

    await axiosInstance
      .post("admin_dash/active_deactive_coupan", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        callSearch();
      })
      .catch((err) => {
        console.log("got an error", err);
        alert("Failed !");
      });
  };

  const callSearch = async () => {
    setLoading(true);
    setColor("#5c5b5b");
    setState([]);
    console.log(selectedOption);

    let formData = new FormData();

    formData.append("status", selectedOption);
    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("page", page);
    formData.append("filter", "");

    await axiosInstance
      .post("admin_dash/search_coupon_admin_api", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response.result);
        setState(response.data.response.result);
        setPagination({
          count: response.data.pagination.count,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  useEffect(() => {
    callSearch();
  }, [selectedOption, page]);

  return (
    <>
      <div className="col-md-12 p-0 mb-5">
        {console.log()}
        <Modal
          className="d-flex flex-column align-items-center mt-5 h-100"
          isOpen={create}
          heading=""
          toggle={openCreateCoupons}
        >
          <div className="col-md-12">
            <h5 className="text-center mt-4 mb-4">Create Coupon</h5>

            <div className="d-flex row">
              <div className="col-md-12">
                <div
                  className={_(["row d-flex", css2.input_font, css.font_18])}
                >
                  <div
                    className={_(["col-md-9 form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>Coupon Code</label>

                    <input
                      type="input"
                      className={_([
                        "input_box form-control",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Coupon Code"
                      name="couponcode"
                      disabled={inputclick}
                      value={couponCode === "" ? data.couponcode : couponCode}
                      onChange={onChange}
                      //}}
                      required
                    />
                  </div>
                  <div className="col-md-3 form-group">
                    <label style={{ opacity: 0 }}>Hello</label>
                    <ParButton
                      className="pt-2 pb-2 pl-3 pr-3 d-block"
                      name="Generate Code"
                      onClick={generateCode}
                    />
                  </div>

                  <div
                    className={_(["col-md-6 form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>Discount</label>

                    <input
                      type="input"
                      className={_([
                        "input_box form-control",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Discount"
                      name="discount"
                      value={data.discount}
                      onChange={onChange}
                      //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                      //}}
                      required
                    />
                  </div>
                  <div
                    className={_(["col-md-6  form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>Redeem Limit</label>

                    <input
                      type="input"
                      className={_([
                        "input_box form-control",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Redeem Limit"
                      name="redeem_limit"
                      value={data.redeem_limit}
                      onChange={onChange}
                      //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                      //}}
                      required
                    />
                  </div>
                  <div
                    className={_(["col-md-6 form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>
                      Minimum Amount Spend (Optional)
                    </label>

                    <input
                      type="input"
                      className={_([
                        "input_box form-control",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Min Amount"
                      name="minimum_spend"
                      value={data.minimum_spend}
                      onChange={onChange}
                      //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                      //}}
                      required
                    />
                  </div>
                  <div
                    className={_(["col-md-6 form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>
                      Maximum Discount (Optional)
                    </label>

                    <input
                      type="input"
                      className={_([
                        "input_box form-control",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Max Discount"
                      name="maximum_discount"
                      value={data.maximum_discount}
                      onChange={onChange}
                      //onChange={(event)=>{setStateFunc(event,setState,state,'username')

                      //}}
                      required
                    />
                  </div>
                  <div
                    className={_(["col-md-6 form-group", css2.column_width3])}
                  >
                    <label className={_([" ", css.font1])}>
                      Coupon Expiry Date
                    </label>
                    <input
                      type="date"
                      name="expire_time"
                      value={data.expire_time}
                      className={_([
                        "input_box form-control",
                        css.font_18,
                        css.font2,
                        css.input_box_style,
                      ])}
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="col-md-12 d-flex justify-content-center mb-4">
                <button
                  className={_(["", css.cancel])}
                  onClick={openCreateCoupons}
                >
                  Cancel
                </button>
                <button className={_(["", css.submit])} onClick={couponcreated}>
                  Create a Coupon
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="d-flex justify-content-end">
          <button
            className={_([
              "align-self-end",
              css.font_18,
              css.inside_button1,
              css2.add_new_button,
            ])}
            onClick={openCreateCoupons}
          >
            Create A Coupons
          </button>
        </div>

        <div className={_([" w-100", css2.search_row])}>
          <div>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "" ? css2.selected_search_button : "",
              ])}
              name=""
              onClick={toggleButtonSearch}
            >
              All
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "active" ? css2.selected_search_button : "",
              ])}
              name="active"
              onClick={toggleButtonSearch}
            >
              Active
            </button>
            <button
              className={_([
                "mr-4",
                css.font_18,
                css2.search_buttons,
                selectedOption === "deactive"
                  ? css2.selected_search_button
                  : "",
              ])}
              name="deactive"
              onClick={toggleButtonSearch}
            >
              Deactivated
            </button>
          </div>
          <div className={_(["d-flex justify-content-end"])}>
            <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
              <img src={searchIcon} alt="search"></img>

              <input
                className={_(["ml-2 w-100", css.font_18, css.font2])}
                type="input"
                name="search"
                placeholder="Search by name or location"
                style={{ border: "0px", background: "none" }}
              ></input>
            </div>
          </div>
        </div>
        <div
          className={_([
            "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
            css2.search_row_2,
          ])}
        >
          {selectedOption === "" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  All
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  {state.length} Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "active" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Active
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  {state.length} Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          {selectedOption === "deactive" ? (
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  Deactivated
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  {state.length} Users
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
          ) : null}
          <div
            className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
          >
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            >
              <input
                type="text"
                onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                placeholder="Expire date"
                className={_([
                  "mr-2",
                  css.font_18,
                  css.font1,
                  css2.select_box_style,
                ])}
              ></input>
            </div>
          </div>
        </div>
        <div className={_(["flex-grow-1", css2.table])}>
          <table
            className={_([
              "mt-2 w-100",
              css.font_18,
              css.font2,
              css2.search_list,
            ])}
          >
            <tr className={_(["", css2.search_header])}>
              <td>#</td>
              <td>Code</td>
              <td>Type</td>
              <td>Discount</td>
              <td>Max Discount</td>
              <td>Redeem Limit</td>
              <td>Min. spend</td>
              <td>Expiry Date</td>
              <td>Actions</td>
            </tr>

            {state.map((spot, index) => {
              return (
                <tr className={_([""])}>
                  <td>
                    <div className="d-flex justify-content-center align-items-center p-1">
                      <label
                        className={_(["", css.checkbox_group])}
                        htmlFor={"check" + index}
                      >
                        <input
                          type="checkbox"
                          className={_(["mr-2", css.checkbox_size])}
                          id={"check" + index}
                        ></input>
                      </label>
                      <div className="index d-flex align-items-center mt-3">
                        <p>{index + 1}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <label className={_(["", css.font4])}>{spot.name}</label>
                  </td>
                  <td>{spot.coupons_type}</td>
                  <td>{spot.discount}</td>
                  <td>{spot.maximum_discount}</td>
                  <td>{spot.redeem_limit}</td>
                  <td>{spot.minimum_spend}</td>
                  <td>{DATETOWORDS(spot.expire_time)}</td>
                  <td>
                    {selectedOption === "" ? (
                      <div className="dropdown">
                        <p
                          className="mb-0 dropdown-toggle"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src={actionIcon}></img>
                        </p>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            className="dropdown-item"
                            to="/Dashboard/ViewParkingSpot"
                          >
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={handleDelete}
                            data-id={spot.id}
                          >
                            Delete
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={ActiveToggle}
                            data-id={spot.id}
                          >
                            {spot.is_active_coupans ? "Pause" : "Active"}
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    {selectedOption === "active" ? (
                      <div className="dropdown">
                        <p
                          className="mb-0 dropdown-toggle"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src={actionIcon}></img>
                        </p>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            className="dropdown-item"
                            to="/Dashboard/ViewParkingSpot"
                          >
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={handleDelete}
                            data-id={spot.id}
                          >
                            Delete
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={ActiveToggle}
                            data-id={spot.id}
                          >
                            {spot.is_active_coupans ? "Pause" : "Active"}
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    {selectedOption === "deactive" ? (
                      <div className="dropdown">
                        <p
                          className="mb-0 dropdown-toggle"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src={actionIcon}></img>
                        </p>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            className="dropdown-item"
                            to="/Dashboard/ViewParkingSpot"
                          >
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={ActiveToggle}
                            data-id={spot.id}
                          >
                            {spot.is_active_coupans ? "Pause" : "Active"}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={handleDelete}
                            data-id={spot.id}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </table>
          {state?.length === 0 ? (
            <>
              <div className="mt-5 w-100 d-flex flex-column align-items-center">
                <img src={noParkings} alt="No Parkings"></img>
                <span className={_(["mt-4", css.font2, css.font_18])}>
                  No parking spots available right now. :({" "}
                </span>
              </div>
            </>
          ) : null}
        </div>
        <div className={_(["", css.font_18, css.font2, css2.pagination])}>
          {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
            <PaginationComponent
              count={pagination.noOfPages}
              page={page}
              setPage={setPage}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Coupons;
