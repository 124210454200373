import css from "./../../main.module.css";
import css2 from "./AddParkingSpot.module.css";

import DropFiles from "../../Dashboard_page/DropFiles.js";
import React, { useState } from "react";
import img1 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Group 25632.png";
import alertIcon from "../../assets/parking-bud-provider-assets/add-parking-aspace/alert-icon.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios.js";
import { Select } from "@mui/material";
import Loader from "react-spinners/ClipLoader.js";

import CountryCodes from "./../../Dashboard_page/countryCode";
import ReactCountryFlag from "react-country-flag";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function BasicDetails() {
  const [loading, setLoading] = useState(false);

  const [availabilityOptions, setAvailabilityOptions] = useState({
    hourly: true,
    daily: false,
    overnight: false,
    weekly: false,
    monthly: false,
    weekend: false,
  });

  const history = useNavigate();

  const [spacePhotos1, setSpacePhotos1] = useState([]);
  const [spacePhotos2, setSpacePhotos2] = useState([]);
  const [spacePhotos3, setSpacePhotos3] = useState([]);
  const [spacePhotos4, setSpacePhotos4] = useState([]);

  const allPhotos = [spacePhotos1, spacePhotos2, spacePhotos3, spacePhotos4];
  const setFiles = [
    setSpacePhotos1,
    setSpacePhotos2,
    setSpacePhotos3,
    setSpacePhotos4,
  ];

  //   const handleSubmit = async () => {
  //     const payload = {
  //       parking_name,
  //       parking_spot_number,
  //       company_name,
  //       parking_spot_type,
  //       parking_phone_number,
  //       parking_country_code,
  //       landlord_phone_number,
  //       landlord_country_code,
  //       max_height,
  //     };

  //     try {
  //       const response = await fetch(
  //         "admin_dash/add_parking_spot_by_admin_api--1",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(payload),
  //         }
  //       );

  //       if (response.ok) {
  //         const result = await response.json();
  //         // Handle the response data as needed
  //         console.log("Parking spot added:", result);
  //       } else {
  //         // Handle the error response
  //         console.error("Failed to add parking spot");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  const [isAM, setIsAM] = useState(true);

  const toggleSwitch = () => {
    setIsAM(!isAM);
  };

  const hoursOperation = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const vehicleTypesData = [
    "Motorcycle",
    "Compact",
    "Supplier's Choice",
    "Full-Size",
    "Mid-Size",
    "Standard",
    "Economy",
    "Standard Suv",
    "Mid-Size Suv",
    "Compact SUV",
    "Mini Van",
    "Premium",
    "Full-Size SUV",
    "Sports Car",
    "Luxury Car",
    "Pickup Truck",
    "Standard Elite SUV",
    "Electric Vehical",
    "Premium Crossover",
    "Premium SUV",
    "Luxury SUV",
    "Van",
    "Mid-Size Elite SUV",
    "Full-Size Elite SUV",
  ];

  const [fields, setFields] = useState(
    hoursOperation.map(() => [
      {
        start: "",
        end: "",
        visible: false,
        isStartAM: true,
        isEndAM: true,
        starthour: "1",
        startminute: "00",
        endhour: "1",
        endminute: "00",
      },
    ])
  );

  const toggleAMPM = (dayIndex, fieldIndex, isStart) => {
    setFields((prevFields) => {
      // Create a deep copy of the fields array
      const updatedFields = prevFields.map((dayFields) =>
        dayFields.map((field) => ({ ...field }))
      );

      // Toggle the appropriate AM/PM field
      const field = updatedFields[dayIndex][fieldIndex];
      if (isStart) {
        field.isStartAM = !field.isStartAM;
      } else {
        field.isEndAM = !field.isEndAM;
      }

      return updatedFields;
    });
  };

  const toggleTimeSelectionVisibility = (dayIndex, fieldIndex) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[dayIndex][fieldIndex].visible = true;
      return newFields;
    });
  };

  const addField = (dayIndex) => {
    setFields((prevFields) => {
      // Create a copy of prevFields
      const newFields = prevFields.map((dayFields, index) => {
        if (index === dayIndex) {
          // Add a new field only for the selected dayIndex
          return [
            ...dayFields,
            {
              isAM: true,
              start: "",
              end: "",
              isStartAM: true,
              isEndAM: true,
              starthour: "1",
              startminute: "00",
              endhour: "1",
              endminute: "00",
              visible: true,
            },
          ];
        }
        // Return unchanged dayFields for other indices
        return dayFields;
      });

      return newFields;
    });
  };

  const removeField = (dayIndex, fieldIndex) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];

      newFields[dayIndex] = newFields[dayIndex].filter(
        (_, i) => i !== fieldIndex
      );

      if (newFields[dayIndex].length === 0) {
        newFields[dayIndex] = [{ isAM: true, visible: false }];
      }

      return newFields;
    });
  };
  const convertTo24HourFormat = (hour, isAM) => {
    let hour24 = parseInt(hour, 10);
    if (!isAM) {
      if (hour24 !== 12) {
        hour24 += 12;
      }
    } else {
      if (hour24 === 12) {
        hour24 = 0; // Midnight case
      }
    }
    return String(hour24).padStart(2, "0");
  };

  const handleTimeChange = (dayIndex, fieldIndex, type, key, value) => {
    const updatedFields = [...fields];
    updatedFields[dayIndex][fieldIndex][key] = value;

    if (key === "starthour") {
      updatedFields[dayIndex][fieldIndex].starthour = convertTo24HourFormat(
        value,
        updatedFields[dayIndex][fieldIndex].isStartAM
      );
    }
    if (key === "endhour") {
      updatedFields[dayIndex][fieldIndex].endhour = convertTo24HourFormat(
        value,
        updatedFields[dayIndex][fieldIndex].isEndAM
      );
    }
    console.log("updatedFiasdelds", updatedFields);
    setFields(updatedFields);
  };
  const [formValues, setFormValues] = useState({
    parking_name: "",
    parking_spot_number: "",
    company_name: "",
    parking_spot_type: "Driveway",
    parking_country_code: "",
    parking_phone_number: "",
    landlord_country_code: "",
    landlord_phone_number: "",
    vehicle_type: "",
    max_height: "",
    hourly: false,
    monthly: false,
    hourly_rate: "",
    monthly_rate: "",
    vehicle_type: "",
    max_height: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevValues) => ({
      ...prevValues,
      [name]: "",
    }));
  };

  const formatFieldsToString = (fields) => {
    return fields.map((dayFields) => {
      return dayFields
        .filter((field) => field.visible) // Only include visible fields
        .map((field) => {
          const startHour = field.isStartAM
            ? field.starthour
            : convertTo24HourFormat(field.starthour, false);
          const endHour = field.isEndAM
            ? field.endhour
            : convertTo24HourFormat(field.endhour, false);
          return `${startHour}:${field.startminute}-${endHour}:${field.endminute}`;
        })
        .join(", ");
    });
  };

  const [editMyAccount, setEditMyAccount] = useState({
    countryCode: "US",
    countryCodeData: "US",
  });

  const handleSubmit = async (e) => {
    const formattedFields = formatFieldsToString(fields);
    const formdata = new FormData();
    formdata.append("parking_name", formValues?.parking_name);
    formdata.append("parking_country_code", "1");
    formdata.append("parking_phone_number", formValues?.parking_phone_number);
    formdata.append("landlord_country_code", "1");
    formdata.append("landlord_phone_number", formValues?.landlord_phone_number);
    formdata.append("company_name", formValues?.company_name);
    formdata.append("max_height", formValues?.max_height);
    formdata.append("parking_spot_type", formValues?.parking_spot_type);
    formdata.append("parking_spot_number", formValues?.parking_spot_number);
    formdata.append("hourly", availabilityOptions?.hourly);
    formdata.append("monthly", availabilityOptions?.monthly);
    formdata.append("hourly_rate", formValues?.hourly_rate);
    formdata.append("monthly_rate", formValues?.monthly_rate);
    formdata.append("sun_hourly", formattedFields[0] || '""');
    formdata.append("mon_hourly", formattedFields[1] || '""');
    formdata.append("tus_hourly", formattedFields[2] || '""');
    formdata.append("wed_hourly", formattedFields[3] || '""');
    formdata.append("thu_hourly", formattedFields[4] || '""');
    formdata.append("fri_hourly", formattedFields[5] || '""');
    formdata.append("sat_hourly", formattedFields[6] || '""');
    formdata.append("vehicle_type", formValues?.vehicle_type);
    formdata.append("provider", "6");
    if (spacePhotos1?.[0]) {
      formdata.append("pic_1", spacePhotos1?.[0]);
    }
    if (spacePhotos2?.[0]) {
      formdata.append("pic_2", spacePhotos2?.[0]);
    }
    if (spacePhotos3?.[0]) {
      formdata.append("pic_3", spacePhotos3?.[0]);
    }
    if (spacePhotos4?.[0]) {
      formdata.append("pic_4", spacePhotos4?.[0]);
    }
    e.preventDefault();
    const newErrors = {};

    if (!formValues.parking_name) {
      newErrors.parking_name = "Parking Name is required";
    } else if (!formValues.parking_phone_number) {
      newErrors.parking_phone_number = "Parking Phone No. is required";
    } else if (!formValues.parking_spot_type) {
      newErrors.parking_spot_type = "Parking Type is required";
    } else if (!formValues.vehicle_type || formValues.vehicle_type === "") {
      newErrors.vehicle_type = "Vehicle Type is required";
    } else if (!formValues.max_height) {
      newErrors.max_height = `Please provide valid Max Height. ( Ex.: 6'4" )`;
    } else if (availabilityOptions?.hourly) {
      if (!formValues?.hourly_rate) {
        newErrors.hourly_rate = `Please provide the hourly rate.`;
      }
    } else if (availabilityOptions?.monthly) {
      if (!formValues?.monthly_rate) {
        newErrors.monthly_rate = `Please provide the monthly rate.`;
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          "admin_dash/add_parking_spot_by_admin_api--1",
          formdata,
          { headers: { "content-type": "multipart/form-data" } }
        );
        if (response?.status === 202) {
          setLoading(false);
          localStorage.setItem("parkingId", response?.data?.response?.id);
          history(`/Dashboard/ParkingSpots/AddParkingSpot/ParkingSpotAddress`, {
            replace: true,
          });
        } else {
          // Handle the error response
          setLoading(false);
          console.error("Failed to add parking spot");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div
        className={_([
          "d-flex flex-column justify-content-center align-items-center w-100",
          css.shade_background,
        ])}
      >
        <div
          className={_([
            "d-flex flex-column justify-content-center align-items-center ",
            css2.basic_details_container,
            css2.center_container,
          ])}
        >
          <div className={_(["w-100", css2.space_photos])}>
            {allPhotos.map((photoSet, index) => (
              <div key={index} className={_(["w-100", css2.upload_box])}>
                <DropFiles
                  file={photoSet}
                  setFile={setFiles[index]}
                  text="Drag and drop or browse to choose a file"
                  imgsrc={img1}
                  existingFiles={allPhotos.flat()}
                />
              </div>
            ))}
          </div>
          <label
            className={_([
              "align-self-start mt-4 mb-3",
              css.font1,
              css.font_24,
            ])}
          >
            About Parking
          </label>
          <div className={_(["", css2.input_row])}>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_(["", css.font1])}>
                Parking Name{" "}
                <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                  *
                </sup>
              </label>
              <input
                required
                type="input"
                className={_(["input_box", css.input_box_style, css.font2])}
                placeholder="Parking Name"
                value={formValues?.parking_name}
                name="parking_name"
                onChange={(e) => handleChange(e)}
              />
              {errors?.parking_name && (
                <span className={_(["", css2.error_style])}>
                  <img src={alertIcon} alt="alert img" />
                  {errors?.parking_name}
                </span>
              )}
            </div>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Parking Spot No. (If Any)
              </label>
              <input
                type="input"
                className={_(["input_box", css.input_box_style, css.font2])}
                placeholder="Parking Spot No."
                value={formValues?.parking_spot_number}
                name="parking_spot_number"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Company Name (If Any)
              </label>
              <input
                type="input"
                className={_(["input_box", css.input_box_style, css.font2])}
                placeholder="Company Name"
                value={formValues?.company_name}
                name="company_name"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className={_(["", css2.input_row])}>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Parking Type{" "}
                <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                  *
                </sup>
              </label>
              <select
                type="input"
                className={_(["input_box", css.input_box_style, css.font2])}
                value={formValues?.parking_spot_type}
                name="parking_spot_type"
                onChange={(e) => handleChange(e)}
                required
              >
                <option value="Driveway">Driveway</option>
                <option value="Overnight">Overnight</option>
              </select>
            </div>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Parking Phone No.{" "}
                <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                  *
                </sup>
              </label>
              <div className={_(["d-flex flex-row align-items-center"])}>
                <div className={_(["d-flex", css.flex_column])}>
                  <div className={_(["d-flex flex-row align-items-center"])}>
                    <ReactCountryFlag
                      className="mr-2"
                      countryCode={editMyAccount.countryCodeData}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                    />
                    <CountryCodes
                      cs={_([
                        "input_box ml-1 mr-2 w-25",
                        css.input_box_style,
                        css.font2,
                      ])}
                      name="countryCode"
                      value={formValues.countryCode}
                      handleContryCodeChange={(e) => {
                        handleChange(e);
                        setEditMyAccount({
                          ...editMyAccount,
                          countryCodeData:
                            e.target[e.target.selectedIndex].getAttribute(
                              "data"
                            ),
                          countryCode: e.target.value,
                        });
                      }}
                    />

                    <input
                      type="number"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Parking Phone No."
                      value={formValues?.parking_phone_number}
                      name="parking_phone_number"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              {errors?.parking_phone_number && (
                <span className={_(["", css2.error_style])}>
                  <img src={alertIcon} alt="alert img" />
                  {errors?.parking_phone_number}
                </span>
              )}
            </div>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Landlord Phone Number (If Any)
              </label>
              <div className={_(["d-flex flex-row align-items-center"])}>
                <div className={_(["d-flex", css.flex_column])}>
                  <div className={_(["d-flex flex-row align-items-center"])}>
                    <ReactCountryFlag
                      className="mr-2"
                      countryCode={editMyAccount.countryCodeData}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                    />
                    <CountryCodes
                      cs={_([
                        "input_box ml-1 mr-2 w-25",
                        css.input_box_style,
                        css.font2,
                      ])}
                      name="countryCode"
                      value={formValues.countryCode}
                      handleContryCodeChange={(e) => {
                        handleChange(e);
                        setEditMyAccount({
                          ...editMyAccount,
                          countryCodeData:
                            e.target[e.target.selectedIndex].getAttribute(
                              "data"
                            ),
                          countryCode: e.target.value,
                        });
                      }}
                    />

                    <input
                      type="number"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Landlord Number"
                      value={formValues?.landlord_phone_number}
                      name="landlord_phone_number"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={_(["", css2.input_row])}>
            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Vehicle Type{" "}
                <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                  *
                </sup>
              </label>
              <select
                type="input"
                className={_(["input_box", css.input_box_style, css.font2])}
                value={formValues?.vehicle_type}
                name="vehicle_type"
                onChange={(e) => handleChange(e)}
                required
              >
                <option disabled value={""}>
                  Select
                </option>
                {vehicleTypesData?.map((type) => {
                  return <option value={type}>{type}</option>;
                })}
              </select>
              {errors?.vehicle_type && (
                <span className={_(["", css2.error_style])}>
                  <img src={alertIcon} alt="alert img" />
                  {errors?.vehicle_type}
                </span>
              )}
            </div>

            <div className={_(["", css2.column_width, css.font_18])}>
              <label className={_([" ", css.font1])}>
                Max Height{" "}
                <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                  *
                </sup>
              </label>
              <input
                type="text"
                className={_(["input_box", css.input_box_style, css.font2])}
                placeholder="i.e 6'5'' "
                value={formValues?.max_height}
                name="max_height"
                onChange={(e) => handleChange(e)}
                required
              />
              {errors?.max_height && (
                <span className={_(["", css2.error_style])}>
                  <img src={alertIcon} alt="alert img" />
                  {errors?.max_height}
                </span>
              )}
            </div>

            <div className={_(["", css2.column_width, css.font_18])}></div>
          </div>
          <hr style={{ width: "100%" }}></hr>
          <label
            className={_(["align-self-start mt-4", css.font1, css.font_24])}
          >
            Parking Spot Availability Options:
          </label>
          <div
            className={_([
              "d-flex flex-row justify-content-start align-items-center w-100 mb-3",
              css.font2,
            ])}
          >
            <button
              className={_([
                "mr-3",
                availabilityOptions.hourly ? css.inside_button1 : "",
                css2.button2,
              ])}
              onClick={() => {
                setAvailabilityOptions({
                  ...availabilityOptions,
                  hourly: !availabilityOptions?.hourly,
                  monthly: false,
                });
                setFormValues({ ...formValues, monthly_rate: "" });
              }}
            >
              Hourly
            </button>

            <button
              className={_([
                "mr-3",
                availabilityOptions.monthly ? css.inside_button1 : "",
                css2.button2,
              ])}
              onClick={() => {
                setAvailabilityOptions({
                  ...availabilityOptions,
                  monthly: !availabilityOptions.monthly,
                  hourly: false,
                });
                setFormValues({ ...formValues, hourly_rate: "" });
              }}
            >
              Monthly
            </button>
          </div>
          <div className={_(["", css2.input_row])}>
            {availabilityOptions?.hourly && (
              <div
                className={_([
                  "",
                  css2.column_width,
                  css.font_18,
                  availabilityOptions.hourly ? "" : css2.disabled,
                ])}
              >
                <label className={_([" ", css.font1])}>
                  Parking Price (per hour){" "}
                  <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                    *
                  </sup>
                </label>
                <input
                  type="input"
                  className={_(["input_box", css.input_box_style, css.font2])}
                  placeholder="Parking Price"
                  value={formValues?.hourly_rate}
                  name="hourly_rate"
                  onChange={(e) => handleChange(e)}
                  required
                />
                {errors?.hourly_rate && (
                  <span className={_(["", css2.error_style])}>
                    <img src={alertIcon} alt="alert img" />
                    {errors?.hourly_rate}
                  </span>
                )}
              </div>
            )}

            {availabilityOptions?.monthly && (
              <div
                className={_([
                  "",
                  css2.column_width,
                  css.font_18,
                  availabilityOptions.monthly ? "" : css2.disabled,
                ])}
              >
                <label className={_([" ", css.font1])}>
                  Parking Price (per month){" "}
                  <sup style={{ color: "red", fontSize: "17px", top: "-3px" }}>
                    *
                  </sup>
                </label>
                <input
                  type="input"
                  className={_(["input_box", css.input_box_style, css.font2])}
                  placeholder="Parking Price"
                  value={formValues?.monthly_rate}
                  name="monthly_rate"
                  onChange={(e) => handleChange(e)}
                  required
                />
                {errors?.monthly_rate && (
                  <span className={_(["", css2.error_style])}>
                    <img src={alertIcon} alt="alert img" />
                    {errors?.monthly_rate}
                  </span>
                )}
              </div>
            )}
          </div>
          <hr style={{ width: "100%" }}></hr>
          <label
            className={_(["align-self-start mt-4", css.font1, css.font_24])}
          >
            Hours Of Operation
          </label>
          {/* time */}
          <div
            className={_([
              "d-flex flex-column justify-content-start align-items-center w-100 mb-3",
              css.font2,
              css.font_18,
            ])}
          >
            <div
              className={_([
                "d-flex flex-row justify-content-start align-items-center w-100 mb-2",
                availabilityOptions.monthly ? "" : css2.disabled,
              ])}
            >
              <input
                type="checkbox"
                className={_(["mr-3", css2.checkbox, css2.cursor_pointer])}
              />
              <label className={_(["mb-0 mr-3 flex-grow"])}>
                24x7 hours* (for monthly bookings)
              </label>
            </div>

            {hoursOperation.map((day, dayIndex) => (
              <div
                className={_([
                  "d-flex flex-wrap justify-content-start align-items-center w-100 mb-2",
                  availabilityOptions.monthly ? css2.disabled : "",
                ])}
                key={dayIndex}
              >
                <div className={_(["mb-0 mr-3", css2.yes_no, css2.h_full])}>
                  {day}
                </div>
                <div>
                  {fields[dayIndex].map((field, fieldIndex) => (
                    <div
                      className={_([
                        "d-flex flex-row justify-content-start align-items-center",
                        css2.mb_10,
                      ])}
                      key={fieldIndex}
                    >
                      {field.visible ? (
                        <>
                          <div className="pr-0">
                            <select
                              className={_([
                                "",
                                css2.form_control,
                                css2.select_word_wrap,
                              ])}
                              value={field.hour}
                              onChange={(e) =>
                                handleTimeChange(
                                  dayIndex,
                                  fieldIndex,
                                  "hour",
                                  "starthour",
                                  e.target.value
                                )
                              }
                            >
                              {[...Array(12).keys()].map((hour) => (
                                <option
                                  key={hour}
                                  value={String(hour + 1).padStart(2, "0")}
                                >
                                  {String(hour + 1).padStart(2, "0")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="pr-0">
                            <select
                              className={_([
                                "",
                                css2.form_control,
                                css2.select_word_wrap,
                              ])}
                              value={field.minute}
                              onChange={(e) =>
                                handleTimeChange(
                                  dayIndex,
                                  fieldIndex,
                                  "minute",
                                  "startminute",
                                  e.target.value
                                )
                              }
                            >
                              {["00", "15", "30", "45"].map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className={_([
                              "",
                              css2.time_input_group,
                              css2.pl_15,
                              css2.cursor_pointer,
                            ])}
                          >
                            <label className={_(["", css2.switch])}>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  toggleAMPM(dayIndex, fieldIndex, true)
                                }
                                checked={!field.isStartAM}
                              />
                              <span className={_(["", css2.slider])}></span>
                              <div
                                className={`${css2.am_time} ${
                                  !field.isStartAM ? css2.hidden : ""
                                }`}
                              >
                                AM
                              </div>
                              <div
                                className={`${css2.pm_time} ${
                                  field.isStartAM ? css2.hidden : ""
                                }`}
                              >
                                PM
                              </div>
                            </label>
                          </div>
                          <div className={_(["mb-0 mr-2 ml-2", css2.pl_15])}>
                            to
                          </div>
                          <div className="pr-0">
                            <select
                              className={_([
                                "",
                                css2.form_control,
                                css2.select_word_wrap,
                              ])}
                              value={field.hour}
                              onChange={(e) =>
                                handleTimeChange(
                                  dayIndex,
                                  fieldIndex,
                                  "hour",
                                  "endhour",
                                  e.target.value
                                )
                              }
                            >
                              {[...Array(12).keys()].map((hour) => (
                                <option
                                  key={hour}
                                  value={String(hour + 1).padStart(2, "0")}
                                >
                                  {String(hour + 1).padStart(2, "0")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="pr-0">
                            <select
                              className={_([
                                "",
                                css2.form_control,
                                css2.select_word_wrap,
                              ])}
                              value={field.minute}
                              onChange={(e) =>
                                handleTimeChange(
                                  dayIndex,
                                  fieldIndex,
                                  "minute",
                                  "endminute",
                                  e.target.value
                                )
                              }
                            >
                              {["00", "15", "30", "45"].map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className={_([
                              "",
                              css2.time_input_group,
                              css2.pl_15,
                              css2.cursor_pointer,
                            ])}
                          >
                            <label className={_(["", css2.switch])}>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  toggleAMPM(dayIndex, fieldIndex, false)
                                }
                                checked={!field.isEndAM}
                              />
                              <span className={_(["", css2.slider])}></span>
                              <div
                                className={`${css2.am_time} ${
                                  !field.isEndAM ? css2.hidden : ""
                                }`}
                              >
                                AM
                              </div>
                              <div
                                className={`${css2.pm_time} ${
                                  field.isEndAM ? css2.hidden : ""
                                }`}
                              >
                                PM
                              </div>
                            </label>
                          </div>
                          <div className={_(["d-flex"])}>
                            <button
                              className={_([
                                "",
                                css2.inside_button2,
                                css2.pl_15,
                              ])}
                              onClick={() => addField(dayIndex)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                className="bi bi-plus-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <defs>
                                  <linearGradient
                                    id="linear-gradient"
                                    x1="0.5"
                                    x2="0.5"
                                    y1="0"
                                    y2="1"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0%" stopColor="#21BFC0" />
                                    <stop offset="100%" stopColor="#B4F38B" />
                                  </linearGradient>
                                </defs>
                                <path
                                  fill="url(#linear-gradient)"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"
                                />
                              </svg>
                            </button>
                            <button
                              className={_([
                                "",
                                css2.inside_button2,
                                css2.pl_15,
                              ])}
                              onClick={() => removeField(dayIndex, fieldIndex)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-dash-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <defs>
                                  <linearGradient
                                    id="linear-gradient"
                                    x1="0.5"
                                    x2="0.5"
                                    y1="0"
                                    y2="1"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0%" stopColor="#21BFC0" />
                                    <stop offset="100%" stopColor="#B4F38B" />
                                  </linearGradient>
                                </defs>
                                <path
                                  fill="url(#linear-gradient)"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"
                                />
                              </svg>
                            </button>
                          </div>
                        </>
                      ) : (
                        <button
                          className={_(["", css2.inside_button2, css2.pl_15])}
                          onClick={() =>
                            toggleTimeSelectionVisibility(dayIndex, fieldIndex)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <defs>
                              <linearGradient
                                id="linear-gradient"
                                x1="0.5"
                                x2="0.5"
                                y1="0"
                                y2="1"
                                gradientUnits="objectBoundingBox"
                              >
                                <stop offset="0%" stopColor="#21BFC0" />
                                <stop offset="100%" stopColor="#B4F38B" />
                              </linearGradient>
                            </defs>
                            <path
                              fill="url(#linear-gradient)"
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div
            className={_([
              "mt-3 d-flex flex-row justify-content-between align-items-center w-100 mb-3 ",
              css.font2,
            ])}
          >
            <button
              className={_(["", css.inside_button2, css2.button1])}
              onClick={() => history("/Dashboard/ParkingSpots")}
            >
              Cancel
            </button>
            <button
              className={_(["", css.inside_button1, css2.button1])}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {loading ? (
                <div className={_(["", css2.cursor_loader, css2.loader_div])}>
                  Loading...
                </div>
              ) : (
                "Save & Continue"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicDetails;
