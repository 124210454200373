import css2 from "../../components/common.module.css";
import css from "./../../main.module.css";
import css3 from "../../components/Profile/ProfilePage.module.css";
import css4 from "../Parking_spot/AddParkingSpot.module.css";
import React, {  useState } from "react";
import CountryCodes from "./../../Dashboard_page/countryCode";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "reactstrap";
import "./../../styles.css";
import "./popup.css";
import emptyProfileIcon from "../../assets/Icons/Icon feather-user.svg";
import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "../../config/ImageFileConfig";
import toast from "react-hot-toast";
import axiosInstance from "../../axios";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function CreateProviderPopup(props) {
  const [editMyAccount, setEditMyAccount] = useState({
    countryCode: "US",
    countryCodeData: "US",
  });

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    countryCode: "1",
    phoneNumber: "",
    userType:'Content_Provider'
  });

  const [errors, setErrors] = useState({});

  const [profilePhoto, setProfilePhoto] = useState(null);
  const handleImage = (event) => {
    const file = event.target.files[0];
    console.log("filefilefile",file)
    if (file) {
      setFormValues({...formValues , profilePhoto : file})
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result);
        setErrors({ ...errors, profilePhoto: "" });
      };
      reader.readAsDataURL(file);
    }
  };
  const validateForm = (formValues) => {
    const errors = {};

    if (!formValues.firstName) {
      errors.firstName = "First name is required";
    }

    if (!formValues.lastName) {
      errors.lastName = "Last name is required";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email is invalid";
    }

    if (!formValues.password) {
      errors.password = "Password is required";
    } else if (formValues.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!formValues.countryCode) {
      errors.countryCode = "Country code is required";
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!profilePhoto) {
      errors.profilePhoto = "Please upload your profile";
    }

    return errors;
  };
  const handleSubmit = async () => {
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formdata = new FormData();
      formdata.append("first_name", formValues?.firstName);
      formdata.append("last_name", formValues?.lastName);
      formdata.append("email", formValues?.email);
      formdata.append("country_code", formValues?.countryCode);
      formdata.append("phone_number", formValues?.phoneNumber);
      formdata.append("country", editMyAccount?.countryCodeData);
      formdata.append("user_type", formValues?.userType);
      if (profilePhoto) {
        formdata.append("profile_pic", formValues?.profilePhoto);
      }
      await axiosInstance
        .post("admin_dash/add_provider_from_admin", formdata, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            toast.success("Provider created successfully");
            setFormValues({})
            props.toggle();
          }
          return;
        })
        .catch((err) => {
          console.log(err.response.response.data);
        });

      console.log("Form submitted successfully", formValues);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };


  return (
    <>
      <Modal
        isOpen={props.isOpen}
        className={_([
          "d-flex flex-column align-items-center mt-5 h-100",
          css2.container,
        ])}
        backdrop={true}
        toggle={props.toggle}
      >
        <div
          className={_([
            " shadow d-flex flex-column justify-content-center align-items-center p-4",
            css2.center_container,
          ])}
        >
          <div
            className={_([
              "d-flex flex-column justify-content-center align-items-center",
              css2.body_header,
            ])}
          >
            <h1 className={_(["mb-0", css.font_18, css.font1])}>
              Create An Provider
            </h1>
            <p className={_(["mb-0", css.font_18, css.font2])}>
              Create a new administrative role.
            </p>
          </div>
          <div class="container p-3">
            <div className="row">
              <div
                className={_([
                  "col d-flex flex-column",
                  css2.inside_body,
                  css.font_18,
                ])}
              >
                <div className={_(["row mb-3", css2.input_font, css.font_18])}>
                  <div className={_(["col", css2.column_width3])}>
                    <label className={_([" ", css.font1])}>First Name</label>
                    <sup
                      style={{
                        color: "red",
                        fontSize: "17px",
                        top: "-3px",
                        marginLeft: "0.25rem",
                      }}
                    >
                      *
                    </sup>
                    <input
                      type="input"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="First Name"
                      name="firstName"
                      value={formValues.firstName}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.firstName && (
                      <p className={`${css4.error_style}`}>
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className={_(["col", css2.column_width3])}>
                    <label className={_([" ", css.font1])}>Last Name</label>
                    <sup
                      style={{
                        color: "red",
                        fontSize: "17px",
                        top: "-3px",
                        marginLeft: "0.25rem",
                      }}
                    >
                      *
                    </sup>
                    <input
                      type="input"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Last Name"
                      name="lastName"
                      value={formValues.lastName}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.lastName && (
                      <p className={`${css4.error_style}`}>{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className={_(["mb-3", css2.input_font, css.font_18])}>
                  <div className={_(["", css2.column_width3])}>
                    <label className={_([" ", css.font1])}>Email</label>
                    <sup
                      style={{
                        color: "red",
                        fontSize: "17px",
                        top: "-3px",
                        marginLeft: "0.25rem",
                      }}
                    >
                      *
                    </sup>
                    <input
                      type="email"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Email"
                      name="email"
                      value={formValues.email}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.email && (
                      <p className={`${css4.error_style}`}>{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className={_(["mb-3", css2.input_font, css.font_18])}>
                  <div className={_(["", css2.column_width3])}>
                    <label className={_([" ", css.font1])}>Password</label>
                    <sup
                      style={{
                        color: "red",
                        fontSize: "17px",
                        top: "-3px",
                        marginLeft: "0.25rem",
                      }}
                    >
                      *
                    </sup>
                    <input
                      type="password"
                      className={_([
                        "input_box",
                        css.input_box_style,
                        css.font2,
                      ])}
                      placeholder="Password"
                      name="password"
                      value={formValues.password}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.password && (
                      <p className={`${css4.error_style}`}>{errors.password}</p>
                    )}
                  </div>
                </div>
                <div className={_(["mb-3", css2.column_width3])}>
                  <label className={_(["", css.font1])}>Phone Number</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <div className={_(["d-flex flex-row align-items-center"])}>
                    <div className={_(["d-flex", css.flex_column])}>
                      <div
                        className={_(["d-flex flex-row align-items-center"])}
                      >
                        <ReactCountryFlag
                          className="mr-2"
                          countryCode={editMyAccount.countryCodeData}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        />
                        <CountryCodes
                          cs={_([
                            "input_box ml-1 mr-2 w-25",
                            css.input_box_style,
                            css.font2,
                          ])}
                          name="countryCode"
                          value={formValues.countryCode}
                          handleContryCodeChange={(e) => {
                            handleChange(e);
                            setEditMyAccount({
                              ...editMyAccount,
                              countryCodeData:
                                e.target[e.target.selectedIndex].getAttribute(
                                  "data"
                                ),
                              countryCode: e.target.value,
                            });
                          }}
                        />

                        <input
                          type="input"
                          className={_(["input_box ml-1", css.input_box_style])}
                          placeholder="Phone Number"
                          name="phoneNumber"
                          value={formValues.phoneNumber}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {errors.phoneNumber && (
                        <p className={`${css4.error_style}`}>
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={_(["mb-3", css2.input_font, css.font_18])}>
                  <div className={_(["", css2.column_width3])}>
                    <label className={_(["mb-0", css.font1])}>Type</label>
                    <select
                      className={_([
                        "input_box mt-1",
                        css.font_18,
                        css.font1,
                        css.input_box_style,
                      ])}
                      name="userType"
                      value={formValues?.userType}
                      onChange={(e)=>handleChange(e)}
                    >
                      <option value="Content_Provider">Content Provider</option>
                      <option value="Users">Admin</option>
                      <option value="Individuals">Sub Admin</option>
                    </select>
                  </div>
                </div>
                <div className={_(["row"])}>
                  <div className={_(["col mt-2"])}>
                    <label
                      className={_(["d-flex align-items-center container56"])}
                    >
                      <input type="checkbox" id="agreement" name="ts_agree" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-md-11 d-flex justify-content-start mt-1 p-0">
                    <p className="color1">
                      Always ask for approval before publish
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-row w-100 align-items-center ">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-12 align-items-center justify-content-center d-flex">
                      <img
                        src={profilePhoto ? profilePhoto : emptyProfileIcon}
                        alt="loading..."
                        className={css3.profile_img3}
                      />
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                      <label
                        htmlFor="uploadImage"
                        className="submitbtn p-2 mt-2"
                      >
                        Choose File
                        <input
                          type="file"
                          name=""
                          id="uploadImage"
                          accept="image/*"
                          onChange={handleImage}
                          hidden
                        />
                      </label>
                    </div>
                    {errors.profilePhoto && (
                      <p className={`${css4.error_style}`}>
                        {errors.profilePhoto}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={_(["d-flex flex-column", css2.inside_body, css.font_18])}
          >
            <div
              className={_([
                "d-flex flex-row justify-content-around align-items-center mt-3 mb-5",
                css.font1,
              ])}
            >
              <button
                onClick={() => {
                  props.toggle();
                  setErrors({});
                }}
                // type='submit'
                className={[
                  "btn btn_B d-flex justify-content-center align-items-center",
                  css2.inside_button,
                  css.inside_button2,
                  css.font_18,
                ].join(" ")}
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  handleSubmit();
                }}
                // type='submit'
                className={_([
                  "btn btn_B d-flex justify-content-center align-items-center ",
                  css2.inside_button,
                  css.inside_button1,
                  css.font_18,
                ])}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateProviderPopup;
