import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import ParkingData from "../../components/parkingdata/ParkingData";
import Amenities from "../../components/parkingdata/Amenities";
import ApplicationMessages from "../../components/parkingdata/ApplicationMessages";
import CancellationReasons from "../../components/parkingdata/CancellationReasons";
// import TimeExtension from "../../components/parkingdata/TimeExtension";
import Vehicles from "../../components/parkingdata/Vehicles";
import Coupons from "../../components/parkingdata/Coupons";
import NoPermission from '../../Dashboard_page/NoPermission';


import NotificationContext from '../../components/notification/NotificationContext';

export default function ParkingDataRouters() {
    const Dashpermis = useContext(NotificationContext)
    // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
    const permissions = Object.keys(Dashpermis?.permis)?.length === 0 ? {} : Dashpermis?.permis;
    return (
        <Routes>
            <Route path='/Dashboard/ParkingData' element={<ParkingData />}>
                <Route path='/Dashboard/ParkingData/Amenities' element={permissions.manage_amenities?<Amenities />:<NoPermission/>} />
                <Route path='/Dashboard/ParkingData/ApplicationMessages' element={permissions.manage_message?<ApplicationMessages />:<NoPermission/>} />
                <Route path='/Dashboard/ParkingData/CancellationReasons' element={permissions.manage_cancellations_reasons?<CancellationReasons />:<NoPermission/>} />
                <Route path='/Dashboard/ParkingData/Vehicles' element={permissions.manage_vehicles?<Vehicles />:<NoPermission/>} />
                <Route path='/Dashboard/ParkingData/Coupons' element={permissions.manage_coupans?<Coupons />:<NoPermission/>} />
            </Route>
        </Routes>
    )

}

