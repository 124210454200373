import css from "../../../main.module.css";
import css2 from "./../../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import img1 from "./../../../assets/Icons/Group 26701.svg";
import img2 from "./../../../assets/Icons/Group 26700.svg";
import img3 from "./../../../assets/Icons/Group 26718.svg";
import React, { useEffect, useState } from "react";

import "../../../styles.css";
import axiosInstance from "../../../axios";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const ProviderEarnings = (object) => {
const [loading , setLoading] = useState({});
const [color , setColor] = useState('')
const [state , setState] = useState({})

const getEarnings = async () => {
    setLoading(true);
    setColor("#5c5b5b");
    await axiosInstance
      .get("/admin_dash/earnings?providerId=112", {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        setState(response.data.response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };

  useEffect(() => {
    getEarnings();
  }, []);

  return (
    <div>
      <div className={_(["d-flex justify-content-start", css.font1])}>
        <h5>Earnings</h5>
      </div>
      <div className={_([" w-100 bg-white", css2.card_row])}>
        <div className={_(["", css2.cards3])}>
          <div
            className={_([
              "d-flex flex-row justify-content-between align-items-start w-100",
            ])}
          >
            <label
              className={_([
                "align-self-start flex-grow-1",
                css.font1,
                css.font_24,
              ])}
            >
              Total Earnings
            </label>
            <img alt="earning" src={img1}></img>
          </div>
          <label
            className={_(["mb-0 align-self-start", css.font2, css.font_32])}
          >
            {state?.total_earning || 0}
          </label>
        </div>
        <div className={_(["", css2.cards3])}>
          <div
            className={_([
              "d-flex flex-row justify-content-between align-items-start w-100",
            ])}
          >
            <label
              className={_([
                "align-self-start flex-grow-1",
                css.font1,
                css.font_24,
              ])}
            >
              Sent
            </label>
            <img alt="earning" src={img2}></img>
          </div>
          <label
            className={_(["mb-0 align-self-start", css.font2, css.font_32])}
          >
            {state?.sent || 0}
          </label>
        </div>
        <div className={_(["", css2.cards3])}>
          <div
            className={_([
              "d-flex flex-row justify-content-between align-items-start w-100",
            ])}
          >
            <label
              className={_([
                "align-self-start flex-grow-1",
                css.font1,
                css.font_24,
              ])}
            >
              Pending To Send
            </label>
            <img alt="earning" src={img3}></img>
          </div>
          <label
            className={_(["mb-0 align-self-start", css.font2, css.font_32])}
          >
            {state?.pending || 0}
          </label>
        </div>
      </div>
      <div
        className={_([
          "d-flex row bg-white shadow mb-5 mt-3 bg-body rounded-3",
          css2.notification_row,
          css2.display1,
        ])}
      >
        <div className="p-3 col-md-12">
          <h5>Timeline</h5>
        </div>
        <div>
          <ul className="col-md-12">
            <li>
              <div className="d-flex p-2">
                <div className="col-md-6 d-flex justify-content-start">
                  <div className={_(["ml-4 row", css2.message])}>
                    <img
                      alt="earning"
                      src={object ? object.image : null}
                      className={_(["", css2.notification_img])}
                    />
                    <label className={_(["mb-0", css.font4, css.font_18])}>
                      <b>Tribeca Parking</b>
                    </label>
                    <label className={_(["mb-0", css.font2, css.font_14])}>
                      just now
                    </label>
                  </div>
                </div>

                <div className="col-md-6 d-flex justify-content-end">
                  <div
                    className={_([
                      "ml-4",
                      css2.inside_button_section,
                      css.font_18,
                    ])}
                  >
                    <button className={_(["", css.font1, css.inside_button1])}>
                      Get Invoice
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex p-2">
                <div className="col-md-6 d-flex justify-content-start">
                  <div className={_(["ml-4 row", css2.message])}>
                    <img
                      alt="earning"
                      src={object ? object.image : null}
                      className={_(["", css2.notification_img])}
                    />
                    <label className={_(["mb-0", css.font4, css.font_18])}>
                      <b>Tribeca Parking</b>
                    </label>
                    <label className={_(["mb-0", css.font2, css.font_14])}>
                      just now
                    </label>
                  </div>
                </div>

                <div className="col-md-6 d-flex justify-content-end">
                  <div
                    className={_([
                      "ml-4",
                      css2.inside_button_section,
                      css.font_18,
                    ])}
                  >
                    <button className={_(["", css.font1, css.inside_button1])}>
                      Get Invoice
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProviderEarnings;
