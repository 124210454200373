import css from "../../../main.module.css";
import css2 from "./Bookings.module.css";

import timerIcon from "./../../../assets/Icons/Icon ionic-ios-timer.svg"
import React, { useState} from "react";

import searchIcon from "./../../../assets/Icons/Icon feather-search.svg"
import defaultSPotImg from "./../../../assets/Default_spot_img.svg"
import deleteIcon from "./../../../assets/Icons/Icon material-delete.svg"

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};



function UsersBookings() {


    const [selectedBooking, setSelectedBooking] = useState('Current')

    const toggleButtonSearch = (e) => {
        setSelectedBooking(e.target.name);
    }

    return (


        <div className={_(["bg-white", css2.center_container, css2.booking_search])}>
            <div className="p-3">
                <h5>Bookings</h5>
            </div>
            <div className={_(["mt-3 w-100", css2.search_row])}>
                <div>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedBooking === 'Current' ? css2.selected_search_button : '')])}
                        name='Current'
                        onClick={toggleButtonSearch}
                    >
                        Current
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedBooking === 'Upcoming' ? css2.selected_search_button : '')])}
                        name='Upcoming'
                        onClick={toggleButtonSearch}
                    >
                        Upcoming
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedBooking === 'Past' ? css2.selected_search_button : '')])}
                        name='Past'
                        onClick={toggleButtonSearch}
                    >
                        Past
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedBooking === 'Cancelled' ? css2.selected_search_button : '')])}
                        name='Cancelled'
                        onClick={toggleButtonSearch}
                    >
                        Cancelled
                    </button>
                    <button
                        className={_(["mr-4", css.font_18, css2.search_buttons, (selectedBooking === 'Extend_Request' ? css2.selected_search_button : '')])}
                        name='Extend_Request'
                        onClick={toggleButtonSearch}
                    >
                        Extend Request
                    </button>
                </div>
                <div className={_(["d-flex justify-content-end"])}>
                    <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
                        <img src={searchIcon} alt="search">
                        </img>

                        <input
                            className={_(["ml-2 w-100", css.font_18, css.font2])}
                            type="input"
                            name="search"
                            placeholder="Search by booking id, name or location"
                            style={{ border: '0px', background: 'none' }}
                        >

                        </input>
                    </div>


                </div>
            </div>
            <div className={_(["mt-1 w-100 d-flex flex-row justify-content-between align-items-center", css2.search_row_2])}>
                <div className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}>
                    <select className={_(["mr-2", css.font_18, css.font1, css2.select_box_style])}>
                        <option value='InWeb'>
                            Export As PDF
                        </option>
                        <option value='InApp'>Application</option>
                    </select>
                    <button className={_([" pl-2 pr-2 d-flex align-items-center", css.font_18, css.inside_button4])}>
                        <img src={deleteIcon} alt="..." className={_(["mr-2"])}></img>
                        Delete
                    </button>
                </div>

            </div>
            <div className={_(["flex-grow-1 mt-3", css2.table])}>
                <table className={_([" w-100", css.font_18, css.font2, css2.search_list])}>
                    <tr className={_(["", css2.search_header])}>
                        <td>#</td>
                        <td>Parking Name</td>
                        <td>Amount</td>
                        <td>Type</td>
                        <td>Arrival</td>
                        <td>Checkout</td>
                        <td>Total Time</td>
                        <td>Extension</td>
                        <td>Status</td>
                    </tr>
                    <tr className={_([""])}>
                        <td>
                            <input type="checkbox" className={_(["mr-2", css2.checkbox])}>
                            </input>
                            <label>1</label>
                        </td>
                        <td className={_(["d-flex flex-row justify-content-center align-items-center"])}>
                            <img alt="user booking" src={defaultSPotImg} className={_(["", css2.search_spot_img])}></img>
                            <div className={_([" d-flex flex-column justify-content-start align-items-center flex-grow-1"])}>
                                <label className={_(["", css.font4])}>Tribeca Parking</label>
                                <label className={_(["", css.font_14])}>Spot No. 5</label>
                            </div>
                        </td>
                        <td>
                            $13
                        </td>
                        <td>
                            Hourly
                        </td>
                        <td >
                            <label>Dec 18, 2020</label>
                            <br></br>
                            <label>9:30 AM</label>
                        </td>
                        <td>
                            <label>Dec 18, 2020</label>
                            <br></br>
                            <label>9:30 AM</label>
                        </td>
                        <td>
                            <label>1h 15m</label>
                        </td>
                        <td>
                            <img alt="timer" src={timerIcon} className={_(["mr-2"])}></img>
                            <label>15m</label>
                        </td>
                        <td>Active & Extended</td>
                    </tr>
                </table>
            </div>


        </div>


    );
}

export default UsersBookings;