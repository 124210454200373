import React, {useState} from 'react';
// import { Outlet } from 'react-router';
import { Link, useNavigate} from "react-router-dom";
/* csss */
import './../Companyprofile.css';
import css from "../../../main.module.css";
import css2 from "./../../Parking_spot/parkingspots/SearchParkingSpot.module.css";
import css3 from "../../Profile/ProfilePage.module.css"
import CompanyProRole from './CompanyProRole';
import ParkingDataRole from './ParkingDataRole';
import ReportInquiryRole from './ReportInquiryRole';
import TermsRole from './TermsRole';
import AboutusRole from './AboutusRole';
import MyProfileRole from './MyProfileRole';
import axiosInstance from "../../../axios";
import Loader from "../../Reusable Popup/Loader";



const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const CreateARole = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("#ffffff");
    const history= useNavigate();
    const [selected, setSelected] = useState("Company_Profile");
    const [role,setrole] =useState(null)
    const [desc,setdesc] =useState(null)
    const [arr, setarr]=useState([])
    const getdata=(name)=>{
        setarr([...arr,name])
    
    }
    console.log(role,desc,'d12345')
    const removedata=(name)=>{
        if (arr.includes(name))
            {
                arr.splice(arr.indexOf(name),1)
            }
    }
    async function createrole() {
        let formData=new FormData()
        formData.append("job_task",role)
        formData.append("description",desc)

        for(var i in arr){
            formData.append(arr[i],true)
        }
    
        setLoading(true)
       
    
        await axiosInstance.post(
            "admin_dash/all_role_permission",
            formData
        ).then((response) => {
            console.log(response.data)
            setLoading(false);
            history('/Dashboard/CompanyProfile/Roles')
            
    
        }).catch((err) => {
            setLoading(false)
            console.log(err.response.data);
    
        })
    
    }

    const handleClick = (e) => {
        setSelected(e.target.name)
        console.log(e.target.name)
    }
    return (<>
        
        <div className="container bg-white mt-5">
            <div className='container mt-4 mb-3'>
                <p className={_(["align-self-start mb-4 mt-4"])} onClick={()=>navigate('/Dashboard/details')}>Profile {'>'} Company Profile {'>'} Roles {'>'} Create A Role </p>
                <h4 className='row Headingstyle'>Create A Role </h4>
            </div>
            
            <div className={_(["bg-white",])}>
                <div className='text-center'>
                    <h5 className='Headingstyle'>Create a new custom role &amp; permissions for sub-admins.</h5>
                </div>
                <div className='d-flex col-md-12 p-5 shadow p-3 mb-5 mt-4 bg-body rounded'>
                    <div className={_([" col-md-6", css2.column_width3])}>
                        <label className={_([" ", css.font1])}>
                            Role
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', css.input_box_style, css.font2])}
                            placeholder='Job task'
                            name='job-type'
                            value={role}
                            onChange={(e)=>{setrole(e.target.value)

                            }}
                            required
                        />
                    </div>
                    <div className={_(["col-md-6", css2.column_width3])}>
                        <label className={_([" ", css.font1])}>
                            Description
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', css.input_box_style, css.font2])}
                            placeholder='Description'
                            name='description'
                            value={desc}
                            onChange={(e)=>{setdesc(e.target.value)

                            }}
                            required
                        />
                    </div>
                </div>
                <div className=''>
                    <div className='p-3'>
                        <h5 className='Headingstyle'>Manage permissions</h5>
                    </div>
                    <div className='col-md-12 p-0'>
                        <ul className={_(["col-md-12 p-0", css3.nav_style2, css.font_18])}>
                            <Link to="/Dashboard/CreateARole/CompanyProfile"  name="Company_Profile" className={selected === "Company_Profile" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick}>Company Profile
                            </Link>
                            <Link to="/Dashboard/CreateARole/MyProfile"  name="My_Profile" className={selected === "My_Profile" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick}> My Profile 
                            </Link>
                            <Link to="/Dashboard/CreateARole/ParkingData"  name="Parking_Data" className={selected === "Parking_Data" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick} > Parking Data 
                            </Link>
                            <Link to="/Dashboard/CreateARole/Terms"  name="Terms" className={selected === "Terms" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick} > Terms 
                            </Link>
                            <Link to="/Dashboard/CreateARole/Aboutus"  name="About_Us" className={selected === "About_Us" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick}> About Us 
                            </Link>
                            <Link to="/Dashboard/CreateARole/ReportInquiries"  name="Report_Inquiries" className={selected === "Report_Inquiries" ? _(["py-2", css3.active_link3, css3.link_text2]) : _(["py-2",  css3.link_text2])} onClick={handleClick}> Report &amp; Inquiries 
                            </Link>

                        </ul>
                    </div>
                    <div className='col-md-12 p-0 border m-0 Contnt d-flex flex-column align-items-center'>
                    {
        loading ? (
            <div className="d-flex mt-4 justify-content-center align-items-center">
                <Loader color={color} loading={loading} />
            </div>):null}
                        {selected==="Company_Profile"?<CompanyProRole submitdata={getdata} done={createrole} setremove={removedata}/>:
                        selected==="My_Profile"? <MyProfileRole submitdata={getdata} done={createrole} setremove={removedata}/>:
                        selected==="Parking_Data"? <ParkingDataRole submitdata={getdata} done={createrole} setremove={removedata} />:
                        selected==="Terms"?<TermsRole submitdata={getdata} done={createrole} setremove={removedata}/>:
                        selected==="About_Us"?<AboutusRole submitdata={getdata} done={createrole} setremove={removedata}/>:
                        selected==="Report_Inquiries"?<ReportInquiryRole submitdata={getdata} done={createrole} setremove={removedata}/>:null

                        }
                    </div>
                </div>
            </div>
        </div>

</>
    )

}


export default CreateARole