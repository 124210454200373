import axios from "axios";


const axiosInstance = axios.create({
	baseURL: 'https://dev-backend.parkingbud.com/',
	headers: {
		accept: "*/*",
		Authorization: ((localStorage.getItem("__admin__token&"))? localStorage.getItem("__admin__token&"): sessionStorage.getItem("__admin__token&")) || '',
		// Authorization: localStorage.getItem("__admin__token&"),
	},
});

export default axiosInstance;