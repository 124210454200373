import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './Companyprofile.css'
import Loader from '../Reusable Popup/Loader';

/* icons */
import emailIcon from "../../assets/Icons/Icon material-email.svg"
import Group28745 from "../../assets/Icons/Group 28745.svg"
import giftbox from "../../assets/Icons/giftbox.svg"
import phoneIcon from "../../assets/Icons/Icon awesome-phone-alt.svg"
import webIcon from "../../assets/Icons/Group 155.svg"
import pointerIcon from "../../assets/Icons/Icon material-location-on.svg"
import timezoneIcon from "../../assets/Icons/Mask Group 145.svg"
//
import axiosInstance from "./../../axios";
import { DATA_PROCESSOR } from "./../../Tools";
import { useHistory } from "react-router-dom";






const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const CompanyInformation = () => {

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    const [imageLoading, setImageLoading] = useState(null);



    //

    const [state, setState] = useState({
        company_pic: null,
        company_url: "",
        a_product_of: "",
        tagline: "",
        company_name: "",
        company_email: "",
        company_country_code: "1",
        company_phone_no: "",
        company_address_line_1: "",
        company_address_line_2: "",
        company_country: "",
        company_city: "",
        company_state: "",
        company_zip_code: "",
        timezone: "",

    })
    const [stateError, setStateError] = useState({
        company_pic: null,
        company_url: null,
        a_product_of: null,
        tagline: null,
        company_name: null,
        company_email: null,
        company_country_code: null,
        company_phone_no: null,
        company_address_line_1: null,
        company_address_line_2: null,
        company_country: null,
        company_city: null,
        company_state: null,
        company_zip_code: null,
        timezone: null,
    })

    async function makerequest() {
        setImageLoading(true)
        setLoading(true);
        setColor('#5c5b5b');

        let data = ""
        await axiosInstance.get(
            "admin_dash/general_settings/"
        ).then((response) => {
            //let formData = new FormData();
            //console.log(this.confirmPassword);


            console.log(response.data.data.company_pic);
            data = response.data.data;
            DATA_PROCESSOR(data, state, setState)
            setLoading(false);
            setImageLoading(false)

            // console.log(state.email)

        }).catch((err) => {
            console.log(err.response.data);

        })

    }

    useEffect(() => {
        makerequest()
    }, [])




    return (

        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader color={color} loading={loading} />
                </div>
            ) :
                <div className={_(["col-md-12", mainCss.font2, mainCss.font_18])}>
                    <div className="col-md-12 px-4 pt-4 d-flex justify-content-end">
                        <Link className='linkstyle' to="/Dashboard/CompanyProfile/CompnyInformation/Edit">Edit</Link>
                    </div>
                    <div className='row p-3 mb-5'>
                        <div className='col-md-3 col-sm-5' >
                            <div className=''>
                                {imageLoading ? (
                                    <div className='spinner-border text-muted mt-4 mb-4'>
                                        <Loader color={color} loading={loading} />
                                    </div>
                                ) : (
                                    <img className={_(["", css2.profile_img1])}
                                        src={state.company_pic ? state.company_pic : 'https://images.pexels.com/photos/4571943/pexels-photo-4571943.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
                                        alt='avatar'
                                    />
                                )}
                            </div>
                            <div className='d-flex justify-content-start mt-4'>
                                <button className='sharebtn'>Share Info</button>
                            </div>

                        </div>
                        <div className={_(["col-md-8", css2.account_inside_details])}>
                            <div>
                                <label className='headtitle'><strong>{state.company_name}</strong></label>
                            </div>
                            <div>
                                <img src={giftbox} alt="loading..." className="mr-2"></img>
                                <label>{state.a_product_of}</label>
                            </div>
                            <div>
                                <img src={Group28745} alt="loading..." className="mr-2"></img>
                                <label>{state.tagline}</label>
                            </div>
                            <div>
                                <img src={emailIcon} alt="loading..." className="mr-2"></img>
                                <label>{state.company_email}</label>
                            </div>
                            <div>
                                <img src={phoneIcon} alt="loading..." className="mr-2"></img>
                                <label>{state.company_phone_no}</label>
                            </div>
                            <div>
                                <img src={webIcon} alt="loading..." className="mr-2"></img>
                                <label>{state.company_url}</label>
                            </div>
                            <div>
                                <img src={pointerIcon} alt="loading..." className="mr-2"></img>
                                <label>{state.company_address_line_1 != "" ? state.company_address_line_1 + ", " : "-"}
                                    {state.company_address_line_2 != "" ? state.company_address_line_2 + ", " : "-"}
                                    {state.company_city != "" ? state.company_city + ", " : "-"}
                                    {state.company_state != "" ? state.company_state + ", " : "-"}
                                    {state.company_country != "" ? state.company_country : "-"}</label>
                            </div>
                            <div>
                                <img src={timezoneIcon} alt="loading..." className="mr-2"></img>
                                <label>{state.timezone ? state.timezone : "-"}</label>
                            </div>

                        </div>
                    </div>

                </div>

            }
        </>


    )

}


export default CompanyInformation;