import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NoPermission from "../../Dashboard_page/NoPermission";
import NotificationContext from "../../components/notification/NotificationContext";

import CompanyProfile from "../../components/companyprofile/CompanyProfile";
import CompanyInformation from "../../components/companyprofile/CompanyInformtion";
import SocialProfile from "../../components/companyprofile/SocialProfiles";
import Admins from "../../components/companyprofile/Admins";
import Roles from "../../components/companyprofile/Roles";
import UserReviews from "../../components/companyprofile/UserReviews";
import ManageBanners from "../../components/companyprofile/ManageBanners";
import CompnyInformationEdit from "../../components/companyprofile/CPcomponent/CompnyInformationEdit";
import CreateARole from "../../components/companyprofile/RoleComponent/CreateARole";
import AboutusRole from "../../components/companyprofile/RoleComponent/AboutusRole";
import CompanyProRole from "../../components/companyprofile/RoleComponent/CompanyProRole";
import MyProfileRole from "../../components/companyprofile/RoleComponent/MyProfileRole";
import ParkingDataRole from "../../components/companyprofile/RoleComponent/ParkingDataRole";
import ReportInquiryRole from "../../components/companyprofile/RoleComponent/ReportInquiryRole";
import TermsRole from "../../components/companyprofile/RoleComponent/TermsRole";

export default function CompanyProfileRouters() {
  const Dashpermis = useContext(NotificationContext);
  // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
  const permissions =
    Object.keys(Dashpermis.permis)?.length === 0 ? {} : Dashpermis?.permis;
  console.log(permissions);
  return (
    <>
      <Routes>
        {/* <Route path='/Dashboard/CompanyProfile' element={<CompanyProfile />}>
            <Route path='/Dashboard/CompanyProfile/CompanyInformation' element={permissions.manage_company_profile?<CompanyInformation />:<NoPermission/>} />
            <Route path='/Dashboard/CompanyProfile/SocialProfile' element={permissions.manage_social_profile?<SocialProfile />:<NoPermission/>} />
            <Route path='/Dashboard/CompanyProfile/Admins' element={permissions.manage_subadmin?<Admins />:<NoPermission/>} />
            <Route path='/Dashboard/CompanyProfile/Roles' element={permissions.manage_role?<Roles />:<NoPermission/>} />
            <Route path='/Dashboard/CompanyProfile/UserReviews' element={permissions.manage_review?<UserReviews />:<NoPermission/>} />
            <Route path='/Dashboard/CompanyProfile/ManageBanners' element={<ManageBanners />} />
        </Route>
        <Route path='/Dashboard/CompanyProfile/CompnyInformation/Edit' element={permissions.manage_company_profile?<CompnyInformationEdit />:<NoPermission/>} />
        <Route path='/Dashboard/CreateARole' element={permissions.manage_role?<CreateARole />:<NoPermission/>}>
            <Route path='/Dashboard/CreateARole/CompanyProfile' element={<CompanyProRole />} />
            <Route path='/Dashboard/CreateARole/MyProfile' element={<MyProfileRole />} />
            <Route path='/Dashboard/CreateARole/ParkingData' element={<ParkingDataRole />} />
            <Route path='/Dashboard/CreateARole/ReportInquiries' element={<ReportInquiryRole />} />
            <Route path='/Dashboard/CreateARole/Terms' element={<TermsRole />} />
            <Route path='/Dashboard/CreateARole/Aboutus' element={<AboutusRole />} />
        </Route> */}
        <Route path="/Dashboard/CompanyProfile" element={<CompanyProfile />}>
          <Route
            path="/Dashboard/CompanyProfile/CompanyInformation"
            element={<CompanyInformation />}
          />
          <Route
            path="/Dashboard/CompanyProfile/SocialProfile"
            element={<SocialProfile />}
          />
          <Route path="/Dashboard/CompanyProfile/Admins" element={<Admins />} />
          <Route path="/Dashboard/CompanyProfile/Roles" element={<Roles />} />
          <Route
            path="/Dashboard/CompanyProfile/UserReviews"
            element={<UserReviews />}
          />
          <Route
            path="/Dashboard/CompanyProfile/ManageBanners"
            element={<ManageBanners />}
          />
        </Route>
        <Route
          path="/Dashboard/CompanyProfile/CompnyInformation/Edit"
          element={<CompnyInformationEdit />}
        />
        <Route path="/Dashboard/CreateARole" element={<CreateARole />}>
          <Route
            path="/Dashboard/CreateARole/CompanyProfile"
            element={<CompanyProRole />}
          />
          <Route
            path="/Dashboard/CreateARole/MyProfile"
            element={<MyProfileRole />}
          />
          <Route
            path="/Dashboard/CreateARole/ParkingData"
            element={<ParkingDataRole />}
          />
          <Route
            path="/Dashboard/CreateARole/ReportInquiries"
            element={<ReportInquiryRole />}
          />
          <Route path="/Dashboard/CreateARole/Terms" element={<TermsRole />} />
          <Route
            path="/Dashboard/CreateARole/Aboutus"
            element={<AboutusRole />}
          />
        </Route>
      </Routes>
    </>
  );
}
