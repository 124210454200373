import { green } from '@mui/material/colors';
import React, { useState } from 'react';
import { BarLoader } from 'react-spinners';



function BarLoaders(props) {
    const [color, setColor] = useState("#55f2b4");
    const css = {
        width: '100px',
        height: '4px',
}


return (
    <div className="d-flex justify-content-center align-items-center">
        <BarLoader color={color} loading={props.loading} css={css} />
    </div>


)
}

export default BarLoaders;