import React, { useState, useEffect, useContext} from "react";

import mainCss from "./../../main.module.css";

import css from "./../common.module.css";
import css2 from "./Notifications.module.css";
import axiosInstance from "../../axios";
import { useNavigate } from "react-router-dom";
import NotificationContext from "./NotificationContext";
import { UTCTimeconvert ,DATETOWORDS} from "../../Tools";
import noParkings from '../../assets/JSON-Gifs/GIF/15.Empty list.json'
import LottieGIF from "../lottieComponent";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};


const NotificationComponent = () => {
    const notificationData=useContext(NotificationContext)
    const [loading, setLoader] = useState(true)
    //const [notifications,setNotifications]=useState([])
    const [state, setState] = useState({
    })
    const history= useNavigate();

    const notifications = notificationData.data === undefined ? [] : notificationData.data.notifications

    console.log('check',notificationData)
    const date = new Date()
    const view=(id)=>{
    history("/Dashboard/ViewParkingSpot?search=" +id)}

    // async function makerequest() {

    //     await axiosInstance.get(
    //         "admin_dash/edit_admin_profile"
    //     ).then((response) => {

    //         setState(response.data.response.user.id)

    //     }).catch((err) => {
    //         console.log(err.response.data);

    //     })

    // }

    // useEffect(() => {
    //     makerequest()
    // }, [])


    // useEffect(() => {

    //     var token = ((localStorage.getItem("user__token")) ? localStorage.getItem("user__token") : sessionStorage.getItem("user__token")) || ''
    //     let io = new WebSocket(
    //         process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET
    //         + 'ws/admin_notifications/'
    //         + state +
    //         '/'
    //     );

    //     io.onopen = (e) => {
    //         if (io.readyState === WebSocket.OPEN) {
    //             io.send(JSON.stringify({ 'token': token }))
    //         }
    //         //io.send(JSON.stringify({'token':token}))
    //     }
    //     io.onclose=(e)=>{}
    //     io.onmessage = (e) => {
    //         let data = JSON.parse(e.data);

    //         if (data.type === 'notification') {
    //             setNotifications(data.notifications)
    //         }
    //         setLoader(false)
    //     };
    //     return()=>{
    //         io.close()
    //     }

    // })
    return (
        <>
            <div className={_(["", css.container, mainCss.shade_background])}
            >
                <div className={_(["", css2.heading, mainCss.font1])}>
                    <p className={_(["", mainCss.font_32, mainCss.font1])}>
                        Notifications
                    </p>

                </div>

                <div
                    className={_([" shadow ", css2.center_container])}>
                    {notifications.map((notification,i)=>(
                    <div key={i} className={_(["col-md-12",css2.bg_notification])}>
                        <div className={_(["row ",css2.bottomline])}>
                            
                            <img src={notification.notification_image} className={_(["col-md-1 mt-2 mb-2", css2.notification_img])} />
                            
                            <div className={_(["col-md-10"])}>
                                <div>
                                    <label className={_(["mb-0", mainCss.font3, mainCss.font_18])}>{notification.notification_title} :</label>
                                    <label className={_(["mb-0", mainCss.font4, mainCss.font_14])}>{ notification.notification_text}<br /></label>
                                </div>
                                {notification.date_created.split(':')[0]===date.toISOString().split(':')[0] && notification.date_created.split(':')[1]===date.toISOString().split(':')[1]?<label className={_(["mb-0", mainCss.font2, mainCss.font_14])}>Just Now</label>:
                                    <label className={_(["mb-0", mainCss.font2, mainCss.font_14])}>{DATETOWORDS(notification.date_created)} {UTCTimeconvert(notification.date_created)}</label>}
                                
                            </div>
                            <div className={_(["col-md-1 mt-2 mb-2", css2.inside_button_section, mainCss.font_14])}>
                                <button className={_(["", mainCss.font1, mainCss.inside_button1])} onClick={()=>view(notification.parking_id)}>
                                    view
                                </button>
                            </div>

                        </div>
                    </div>))}
                    {loading?<div className="w-100">
                            <div className="d-flex justify-content-center spinner w-100">
                            <div className="spinner-border text-muted mt-4 mb-4"></div>
                            </div>
                            {setLoader(false)} 
                            </div>:(notifications.length===0?<>
                                    <div className="mt-5 w-100 d-flex flex-column align-items-center">
                                    <LottieGIF json={noParkings} loop={true} height="15rem" width="15rem"></LottieGIF>
                                        <span className={_(["mt-4", css.font2, css.font_18])}>No notfications available right now. :(</span>
                                    </div></>:null)
                                }

                </div>
            </div>


        </>

    )
}



export default NotificationComponent;




