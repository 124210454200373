import { Loader } from "@googlemaps/js-api-loader";
import { useCallback, useEffect, useState } from "react";
import Pin from "../../assets/Icons/locationDrop.svg";

function addMarker(props) {
    console.log("props",props)
    const marker = new window.google.maps.Marker({
        position: props.location,
        label: {
            // text: props.label || "",
            color: props.color || "black",
            fontSize: props.fontSize || "18px",
            fontFamily: props.font || "ProximaNova-Regular",
        },
        map: props.map,
        icon: props.svg,
        draggable: props.draggable || false,
        animation: window.google.maps.Animation.DROP,
    });

    if (props.infoContent) {
        const infoWindow = new window.google.maps.InfoWindow({
            content: props.infoContent,
        });

        marker.addListener("click", () => {
            infoWindow.open(props.map, marker);
        });
    }

    return marker;
}

function Map(props) {
    console.log("aldskefn",props)
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [globalPosition, setGlobalPosition] = useState({
        lat: -33.9,
        lng: 151.2,
    });
    const [googleLoaded, setGoogleLoaded] = useState(false);

    // Load Google Maps API
    useEffect(() => {
        const loader = new Loader({
            apiKey: "AIzaSyD63MgQGF6XKHDeImM8PcuwWVCghDdkK8c",
            version: "weekly",
          });

        loader
            .load()
            .then(() => {
                setGoogleLoaded(true);
            })
            .catch((error) => {
                console.error("Failed to load Google Maps API:", error);
            });
    }, []);

    // Update global position when props.latLng changes
    useEffect(() => {
        if (props?.lat) {
            setGlobalPosition({
                lat: props?.lat,
                lng: props?.lng,
            });
        }
    }, [props?.lat]);

    // Initialize or update the map
    const initMap = useCallback(
        (lat, lng , ) => {
            if (window.google) {
                const mapOptions = {
                    center: { lat, lng },
                    zoom: props.zoom || 18,
                };

                const mapInstance = new window.google.maps.Map(
                    document.getElementById("map"),
                    mapOptions
                );
                setMap(mapInstance);

                const markerInstance = addMarker({
                    location: { lat, lng },
                    map: mapInstance,
                    svg: Pin,
                    draggable: true,
                    color: "black",
                    fontSize: "18px",
                    font: "ProximaNova-Regular",
                    label: props?.locationName,
                    infoContent: `<div><strong>${props?.locationName}</strong><br>Parking Spot</div>`, // Parking name displayed in InfoWindow
                });

                setMarker(markerInstance);
            }
        },
        [props.zoom]
    );

    // Update map and marker when googleLoaded or globalPosition changes
    useEffect(() => {
        if (googleLoaded) {
            if (map && marker) {
                map.setCenter(globalPosition);
                marker.setPosition(globalPosition);
            } else {
                initMap(globalPosition.lat, globalPosition.lng , props);
            }
        }
    }, [googleLoaded, globalPosition, map, marker, initMap , props]);

    // Update global position on marker drag end
    useEffect(() => {
        if (marker) {
            const handleDragEnd = () => {
                const position = marker.getPosition();
                setGlobalPosition({
                    lat: position.lat(),
                    lng: position.lng(),
                });
            };

            marker.addListener("dragend", handleDragEnd);

            // Cleanup listener on component unmount
            return () => {
                window.google.maps.event.clearListeners(marker, "dragend");
            };
        }
    }, [marker]);

    return <div id="map" style={{ width: "100%", height: "inherit" }}></div>;
}

export default Map;
