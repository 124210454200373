import css from "../../../main.module.css";
import css2 from "./Bookings.module.css";

import timerIcon from "./../../../assets/Icons/Icon ionic-ios-timer.svg";
import React, { useEffect, useState } from "react";

import searchIcon from "./../../../assets/Icons/Icon feather-search.svg";
import defaultSPotImg from "./../../../assets/Default_spot_img.svg";
import deleteIcon from "./../../../assets/Icons/Icon material-delete1.svg";
import noParkings from "../../../assets/Icons/Group 26816.svg";

import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios";
import { useLocation } from "react-router-dom";
import {  formatDate } from "../../../utils";
import PaginationComponent from "../../Pagination";
import Loader from "../../Reusable Popup/Loader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function ProviderBookings() {
  const dispatch = useDispatch();
  const id = localStorage.getItem("providerId");
  let [color, setColor] = useState("#ffffff");
  const [selectedBooking, setSelectedBooking] = useState("Current");
  const [bokkingTable, setBookingTable] = useState([]);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });
  const [page, setPage] = useState(1);

  const toggleButtonSearch = (e) => {
    setSelectedBooking(e.target.name);
  };
  function calculateTotalPages(totalCount, pageLimit) {
    return Math.ceil(totalCount / pageLimit);
  }

  const RESULT_LIMIT_IN_PAGE = 6;
  const userBooking = async (id) => {
    
    const data = {
      providerId: id,
      resultLimit: 6,
      page: page,
    };
    let apiUrl = `providerId=${id}&resultLimit=${data?.resultLimit}&pageNo=${data?.page}&bookingStatus=${selectedBooking}`;
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/admin_dash/get_provider_bookings?${apiUrl}`
      );
      const result = response?.data;
      setPagination({
        count: response.data.pagination.count,
        previous: response.data.pagination.previous,
        next: response.data.pagination.next,
        startIndex: response.data.pagination.startIndex,
        endIndex: response.data.pagination.endIndex,
        totalResults: response.data.pagination.totalResults,
        noOfPages: calculateTotalPages(
          response.data.pagination.totalResults,
          RESULT_LIMIT_IN_PAGE
        ),
      });
      setBookingTable(result?.response?.bookings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    userBooking(id);
  }, [id, page, selectedBooking]);

  return (
    <>
        <div
          className={_([
            "col-md-12 bg-white p-0 mb-5 shadow mb-5 bg-body rounded-3",
            css2.center_container,
            css2.booking_search,
          ])}
        >
          <div className={_(["p-3",css.font1])}>
            <h5>Bookingssdvsd</h5>
          </div>
          <div className={_(["w-100 justify-content-between",css2.search_row_custom_1])}>
            <div className={_(["",])}>
              <button
                className={_([
                  "mr-4",
                  css.font_18,
                  css2.search_buttons,
                  selectedBooking === "Current"
                    ? css2.selected_search_button
                    : "",
                ])}
                name="Current"
                onClick={toggleButtonSearch}
              >
                Current
              </button>
              <button
                className={_([
                  "mr-4",
                  css.font_18,
                  css2.search_buttons,
                  selectedBooking === "Upcoming"
                    ? css2.selected_search_button
                    : "",
                ])}
                name="Upcoming"
                onClick={toggleButtonSearch}
              >
                Upcoming
              </button>
              <button
                className={_([
                  "mr-4",
                  css.font_18,
                  css2.search_buttons,
                  selectedBooking === "Past" ? css2.selected_search_button : "",
                ])}
                name="Past"
                onClick={toggleButtonSearch}
              >
                Past
              </button>
              <button
                className={_([
                  "mr-4",
                  css.font_18,
                  css2.search_buttons,
                  selectedBooking === "Cancelled"
                    ? css2.selected_search_button
                    : "",
                ])}
                name="Cancelled"
                onClick={toggleButtonSearch}
              >
                Cancelled
              </button>
              <button
                className={_([
                  "mr-4",
                  css.font_18,
                  css2.search_buttons,
                  selectedBooking === "Extend_Request"
                    ? css2.selected_search_button
                    : "",
                ])}
                name="Extend_Request"
                onClick={toggleButtonSearch}
              >
                Extend Request
              </button>
            </div>
            <div className={_(["d-flex justify-content-end"])}>
              <div className={_(["", css.font_18, css.font2, css2.search_bar_custom,css.w_full])}>
                <img alt="booking" src={searchIcon}></img>

                <input
                  className={_(["ml-2 w-100", css.font_18, css.font2])}
                  type="input"
                  name="search"
                  placeholder="Search by booking id, name or location"
                  style={{ border: "0px", background: "none" }}
                ></input>
              </div>
            </div>
          </div>
          <div
            className={_([
              "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
              css2.search_row_2,
            ])}
          >
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            >
              <select
                className={_([
                  "mr-2",
                  css.font_18,
                  css.font1,
                  css2.select_box_style,
                ])}
              >
                <option value="InWeb">Export As PDF</option>
                <option value="InApp">Application</option>
              </select>
              <button
                className={_([
                  " pl-2 pr-2 d-flex align-items-center",
                  css.font_18,
                  css.inside_button4,
                ])}
              >
                <img
                  alt="booking"
                  src={deleteIcon}
                  className={_(["mr-2"])}
                ></img>
                Delete
              </button>
            </div>
          </div>
          <div className={_(["flex-grow-1", css2.table])}>
            <table
              className={_([
                "mt-3 w-100",
                css.font_18,
                css.font2,
                css2.search_list,
              ])}
            >
              <tr className={_(["", css2.search_header])}>
                <td>#</td>
                <td>Parking Name</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Arrival</td>
                <td>Checkout</td>
                {/* <td>Total Time</td>
                <td>Extension</td> */}
                <td>Status</td>
              </tr>
              {bokkingTable?.length > 0 &&
                bokkingTable?.map((item, index) => {
                return (
                  <tr className={_([""])}>
                    <td>
                      <input
                        type="checkbox"
                        className={_(["mr-2", css2.checkbox])}
                      ></input>
                      <label>{index + 1}</label>
                      {/* <label
                        className={_([
                          "flex-grow-1 d-flex justify-content-center align-items-center mb-0",
                          css.checkbox_group,
                        ])}
                        htmlFor="check1"
                      >
                        <input
                          type="checkbox"
                          className={_(["mr-2", css.checkbox])}
                          id="check1"
                        ></input>
                        <span className={_(["mr-1", css.box])}></span>
                      </label> */}
                    </td>
                    <td
                      className={_([
                        "d-flex flex-row justify-content-center align-items-center",
                      ])}
                    >
                      <img
                        alt="booking"
                        src={
                          item?.parking_img ? item?.parking_img : defaultSPotImg
                        }
                        className={_(["", css2.search_spot_img])}
                      ></img>
                      <div
                        className={_([
                          " d-flex flex-column justify-content-start align-items-center flex-grow-1",
                        ])}
                      >
                        <label className={_(["", css.font4])}>
                          {item?.parking_name}
                        </label>
                        <label className={_(["", css.font_14])}>
                          Spot No. {item?.booked_by}
                        </label>
                      </div>
                    </td>
                    <td>${item?.amount}</td>
                    <td>{item?.booking_type}</td>
                    <td>
                      <label>{formatDate(item?.start_time)}</label>
                    </td>
                    <td>
                      <label>{formatDate(item?.end_time)}</label>
                    </td>
                    {/* <td>
                      <label>{convertTimeToHM(item?.total_time)}</label>
                    </td>
                    <td>
                      <img
                        alt="booking"
                        src={timerIcon}
                        className={_(["mr-2"])}
                      ></img>
                      <label>{convertTimeToHM(item?.extended_time)}</label>
                    </td> */}
                    <td>{item?.status}</td>
                  </tr>
                );
              })}
            </table>
            {loading ? (
          <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
            <Loader color={color} loading={loading} />
          </div>
        ) : state?.length === 0 ? (
          <>
            <div className="mt-5 w-100 d-flex flex-column align-items-center">
              <img src={noParkings} alt="No Parkings"></img>
              <span className={_(["mt-4", css.font2, css.font_18])}>
                No parking spots available right now. :({" "}
              </span>
            </div>
          </>
        ) : null}
          </div>
          <div className={_(["", css.font_18, css.font2, css2.pagination])}>
            {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
              <PaginationComponent
                count={pagination.noOfPages}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </div>
        </div>
      
    </>
  );
}

export default ProviderBookings;
