import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate, } from "react-router-dom";
import CountryCodes from "./../../../Dashboard_page/countryCode";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReactCountryFlag from "react-country-flag";

import emptyProfileIcon from "./../../../assets/Icons/Icon feather-user.svg";
import imageCompression from "browser-image-compression"; // image compressor for limiting the size of dp
import { options } from "./../../../config/ImageFileConfig"
/* csss */
import css2 from "../../../components/Profile/ProfilePage.module.css";
import mainCss from "../../../main.module.css";
//
import validator from "validator";
import axiosInstance from "./../../../axios";
import { DATA_PROCESSOR, ERROR__PROCESSOR } from "../../../Tools";
import AccessRequestPopup from "../../Reusable Popup/AccessRequest";

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

const CompnyInformationEdit = () => {
    const history = useNavigate();


    const [state, setState] = useState({
        company_pic: null,
        company_url: "",
        a_product_of: "",
        tagline: "",
        company_name: "",
        company_email: "",
        company_country_code: "1",
        countryCodeData: "US",
        company_phone_no: "",
        company_address_line_1: "",
        company_address_line_2: "",
        company_country: "",
        company_city: "",
        company_state: "",
        company_zip_code: "",
        timezone: "",
    })

    const [stateError, setStateError] = useState({
        company_pic: null,
        company_url: null,
        a_product_of: null,
        tagline: null,
        company_name: null,
        company_email: null,
        company_country_code: null,
        company_phone_no: null,
        company_address_line_1: null,
        company_address_line_2: null,
        company_country: null,
        company_city: null,
        company_state: null,
        company_zip_code: null,
        timezone: null,
    })

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [imageLoading, setImageLoading] = useState(null);
    const [Accessrequest, setAccessrequest]=useState(false)

    async function makerequest() {
        setImageLoading(true)

        let data = ""
        await axiosInstance.get(
            "admin_dash/general_settings/"
        ).then((response) => {
            data = response.data.data;
            DATA_PROCESSOR(data, state, setState)
            setImageLoading(false)
            

        }).catch((err) => {
            console.log(err.response.data);

        })

    }

    useEffect(() => {
        makerequest()

    }, [])


    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        setStateError({ ...stateError, [e.target.name]: null })
    }

    
    console.log("---state", state)


    const handleImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            setProfilePhoto(null);

            if (file) {
                setImageLoading(true);
                //compressing image
                // console.log("Options", options);
                const compressedFile = await imageCompression(file, options);
                console.log(
                    `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
                );
                if (compressedFile.size / 1024 / 1024 > 2) {
                    // throw {response:{data:"Image size is too large"}};
                    throw "Image size is too large";
                }

                const fileEE = new File([compressedFile], file.name);
                setProfilePhoto(fileEE);

                let base64Image = await new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (err) => {
                        reject(err);
                    };
                });

                if (base64Image !== undefined) {
                    setState({
                        ...state,
                        company_pic: file,
                        profile_preview: base64Image,
                    });
                    setImageLoading(false);
                }
            } else {
                // setImageError (false)
                setState({
                    ...state,
                    company_pic: null,
                });
                setProfilePhoto(null);
                setImageLoading(false);
            }
        } catch (err) {
            console.log("Errror in image upload ", err);
            
        }
    };

    async function Save() {

        if (validator.isEmpty(state.company_name)) {
            setStateError({ ...stateError, first_name: "Cannot be empty" })
            return
        }
        if (!validator.isEmail(state.company_email)) {
            setStateError({ ...stateError, company_email: "Invalid Email" })
            return
        }

        if (validator.isEmpty(state.company_phone_no)) {
            setStateError({ ...stateError, company_phone_no: "Cannot be empty" })
            return
        }


        let formData = new FormData();
        
        formData.append('company_name', state.company_name);
        formData.append('company_url', state.company_url);
        formData.append('a_product_of', state.a_product_of);
        formData.append('tagline', state.tagline);
        formData.append('company_email', state.company_email);
        formData.append('company_phone_no', state.company_phone_no);
        formData.append('company_address_line_1', state.company_address_line_1);
        formData.append('company_address_line_2', state.company_address_line_2);
        formData.append('company_country_code', state.company_country_code);
        formData.append('company_country', state.company_country);
        formData.append('company_city', state.company_city);
        formData.append('company_state', state.company_state);
        formData.append('company_zip_code', state.company_zip_code);
        formData.append('timezone', state.timezone);
        if (state.company_pic.type) {
            console.log(state.company_pic)
            formData.append("company_pic", state.company_pic);
        }

        await axiosInstance.post(
            "admin_dash/general_settings/",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.data.success==='false'){
                alert(response.data.error_msg)}
            else{
                history('/Dashboard/CompanyProfile/CompanyInformation')}
            

        }).catch((err) => {
            console.log("error in saving", err.response);
            if (err.response.data.error_msg==='you have no access'){
                setAccessrequest(true)
            }
            else{
            if (err.response && err.response.data.errors) {
                ERROR__PROCESSOR(err.response.data.errors, stateError, setStateError)
            }}

        })




    };





    return (
        <div className="container bg-white mt-5">
            <div className='container mt-4 mb-3 p-0'>
                <p className={_(["align-self-start mb-4 mt-4"])} onClick={()=>history('/Dashboard/details')}>Profile {'>'} Company Profile {'>'} Edit</p>
                <h4 className="linkstyle fw-bold">Update Information </h4>
            </div>

            <div className={_(["bg-white", css2.edit_account, mainCss.font2])}>
                <div className="d-flex flex-row w-100 justify-content-end">
                    <Link className="linkstyle" to="/Dashboard/CompanyProfile/CompanyInformation">Cancel</Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center ">
                    {imageLoading ? (
                        <div className='spinner-border text-muted mt-4 mb-4'>
                        </div>
                    ) : (
                        <>
                            <div className="ml-5">
                                <img
                                    src={state.company_pic ? (state.profile_preview ? state.profile_preview : state.company_pic) : (emptyProfileIcon)}
                                    alt="loading..."
                                    className={_(["", css2.profile_img])}></img>
                            </div>
                            <label className={_(["", css2.upload_button, mainCss.inside_button1, mainCss.font1, mainCss.font_18])}>
                                Choose File
                                <input type="file" id='uploadImage' accept='image/*' onChange={handleImage} hidden ></input>
                            </label>



                        </>
                    )}
                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Business Name
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='First Name'
                            name='company_name'
                            value={state.company_name}
                            onChange={handleChange}
                            required
                        />
                        {stateError.company_name &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_name}</span>

                            </div>
                        }
                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            A Product of
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='A Product of'
                            name='a_product_of'
                            value={state.a_product_of}
                            onChange={handleChange}
                            required
                        />
                        {stateError.a_product_of &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.a_product_of}</span>

                            </div>
                        }

                    </div>
                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Tagline
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Tagline'
                            name='tagline'
                            value={state.tagline}
                            onChange={handleChange}
                            required
                        />
                        {stateError.tagline &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.tagline}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Website URL
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Website URL'
                            name='company_url'
                            value={state.company_url}
                            onChange={handleChange}
                            required
                        />
                        {stateError.company_url &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_url}</span>

                            </div>
                        }

                    </div>
                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Email
                        </label>


                        <input
                            type='email'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Email address'
                            name='company_email'
                            value={state.company_email}
                            onChange={handleChange}
                        />
                        {stateError.company_email &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_email}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["b-0", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Phone Number
                        </label>

                        <div className={_(["d-flex flex-row align-items-center"])}>
                            <ReactCountryFlag
                                className="mr-2"
                                countryCode={state.countryCodeData}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                style={{
                                    width: '1.5em',
                                    height: '1.5em',
                                }}
                            />

                            <CountryCodes cs={_(['input_box ml-1 mr-2 w-25', mainCss.input_box_style, mainCss.font2])}
                                name="company_country_code"
                                value={state.company_country_code}
                                handleContryCodeChange={(e) => {

                                    setState({
                                        ...state,
                                        countryCodeData: e.target[e.target.selectedIndex].getAttribute('data'),
                                        company_country_code: e.target.value
                                    });
                                }}
                                onChange={handleChange}
                            />

                            <input
                                type='input'
                                className={_(['input_box ml-1', mainCss.input_box_style])}
                                placeholder='Number'
                                name='company_phone_no'
                                id='company_phone_no'
                                value={state.company_phone_no}
                                onChange={(e) => {
                                    if (validator.isNumeric(e.target.value) || e.target.value == '') {
                                        setState({ ...state, company_phone_no: e.target.value })
                                        setStateError({
                                            ...stateError, company_phone_no:null
                                        })
                                    }

                                }}
                                required
                            />
                        </div>
                        {stateError.company_phone_no &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_phone_no}</span>

                            </div>
                        }
                        {stateError.company_country_code &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_country_code}</span>

                            </div>
                        }
                    </div>
                </div>
                <hr style={{ width: "100%" }}></hr>
                <p className={_(["d-flex mt-3 w-100 align-items-center ", mainCss.font1, mainCss.font_24])}>
                    Address
                </p>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Address Line 1
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='123 Street'
                            value={state.company_address_line_1}
                            name="company_address_line_1"
                            onChange={handleChange}
                        />
                        {stateError.company_address_line_1 &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_address_line_1}</span>

                            </div>
                        }
                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            Address Line 2
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='Address Line 2'
                            name="company_address_line_2"
                            value={state.company_address_line_2}
                            onChange={handleChange}
                        />
                        {stateError.company_address_line_2 &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_address_line_2}</span>

                            </div>
                        }
                    </div>

                </div>

                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}
                >


                    <div className={_(["", css2.column_width3])}>
                        <label className={_([" ", mainCss.font1])}>
                            City
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder=''
                            name="company_city"
                            value={state.company_city}
                            onChange={handleChange}
                        />
                        {stateError.company_city &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_city}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            State / Province
                        </label>

                        <RegionDropdown
                            name="company_state"
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            blankOptionLabel="Select State"
                            country={state.company_country}
                            value={state.company_state}
                            onChange={(e) => setState({ ...state, company_state: e })}
                        />
                        {stateError.company_state &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_state}</span>

                            </div>
                        }
                    </div>

                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-between", css2.input_font, mainCss.font_18])}
                >
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Zip Code
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder=''
                            name="company_zip_code"
                            value={state.company_zip_code}
                            onChange={handleChange}
                        />
                        {stateError.company_zip_code &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_zip_code}</span>

                            </div>
                        }

                    </div>
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Country
                        </label>


                        <CountryDropdown
                            name="company_country"
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            value={state.company_country}
                            onChange={(e) => setState({ ...state, company_country: e })}
                        />
                        {stateError.company_country &&
                            <div className="input__err__group">
                                <img className="mr-2"></img>
                                <span>{stateError.company_country}</span>

                            </div>
                        }
                    </div>
                </div>
                <div className={_(["d-flex mt-4 flex-row justify-content-start", css2.input_font, mainCss.font_18])}
                >
                    <div className={_(["", css2.column_width3])}>
                        <label className={_(["", mainCss.font1])}>
                            Time Zone
                        </label>


                        <input
                            type='input'
                            className={_(['input_box', mainCss.input_box_style, mainCss.font2])}
                            placeholder='i.e. 123'
                            name="timezone"
                            value={state.timezone}
                            onChange={handleChange}
                        />

                    </div>

                </div>

                <div className={_(["d-flex mt-5 flex-row justify-content-center", css2.input_font, mainCss.font_18])}>
                    <button
                        type='button'
                        className={_([" d-flex justify-content-center align-items-center ", css2.inside_button, mainCss.inside_button1, mainCss.font1])}
                        onClick={Save}
                    >
                        Save
                    </button>
                </div>





            </div>
            <AccessRequestPopup  isOpen={Accessrequest} heading={'Alert!'} subheading={'edit the company profile'} title={'Request to edit'} msg={'Manage company profile'} permissionname={'manage_company_profile'} path={''}  toggle={()=>{setAccessrequest(false)}}/>
        </div>



    )

}


export default CompnyInformationEdit