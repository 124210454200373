import css2 from './../common.module.css'
import css from './../../main.module.css'
import css3 from './UpdatePrice1.module.css'
import React, { useState, useCallback, useEffect} from "react";
import { Modal } from "reactstrap";
import "./../../styles.css"
import { Loader } from "@googlemaps/js-api-loader";
import mySpotPriceIcon from "../../assets/Icons/Path 20551.svg"

const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

function addMarker(props) {
	// Add the marker at the clicked location, and add the next-available label
	// from the array of alphabetical characters.


	return new window.google.maps.Marker({
	  position: props.location,
	  label: {
		text: props.label || "",
		color: props.color|| 'white',
		fontSize: props.fontSize || '18px',
		fontFamily: props.font || 'ProximaNova-Regular',
	  },
	  map: props.map,
	  icon: props.svg,
	  draggable: props.dragable || false,
	  animation: window.google.maps.Animation.DROP
	});
  }
function UpdatePricePopup(props){
    const [map, setMap] = useState(null)

    const [myspot, setMySpot] = useState({
        lat: -33.9,
        lng: 151.2,
    })

    const [nearbySpots, setNearBySpots] = useState([
        {
            lat: -33.9,
            lng: 151.2,
        },
        {
            lat: -33.9,
            lng: 151.2,
        }
    ])

    const initMap = useCallback((lat, lng) => {
        // position
        //let tempPosition = { lat, lng };
        var geocoder = new window.google.maps.Geocoder();

        var tempPosition = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({'latLng': tempPosition}, function(results, status) {
            if(status === window.google.maps.GeocoderStatus.OK) {
                //alert(results[0]['formatted_address']);
            };
            console.log('map')

        });
        
        /* /maps
        let map = new window.google.maps.Map(document.getElementById("map"), {
            //disableDefaultUI: true,
            center: tempPosition,
            zoom: props.zoom || 18,
            location: tempPosition
        });

        setMap(map)
        */
       


        
        //addMarker(tempPosition, map, mySpotPriceIcon, "$5");
        //addMarker(tempPosition, map, otherSpotPriceIcon, "$5", '14px');

    }, []);

    useEffect(() => {
        const loader = new Loader({
            apiKey: "AIzaSyD63MgQGF6XKHDeImM8PcuwWVCghDdkK8c",
            version: "weekly",
        });
        if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				let lat = position.coords.latitude;
				let lng = position.coords.longitude;

				
			});
            loader.load().then(() => {
                initMap(myspot.lat, myspot.lng);
            });
		}
        

        
    }, [initMap]);
    useEffect(() => {
		if(!map){
			return
		}
		

        addMarker({location: myspot, map: map, svg: mySpotPriceIcon, label: "$5"});
		
	}, [map])

    return (
    <>
    <Modal  isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center h-100",css2.container])} backdrop={true} toggle={props.toggle}
      >
          <div
            className={_([" shadow d-flex flex-column justify-content-center align-items-center p-4",css2.center_container])}
            >
                
            <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                <p className={_(["", css.font1, css.font_32])}>Tribeca Parking</p>
                <p className={_(["", css.font2, css.font_18])}>Insert new price for your parking spot</p>
                
            </div>
            <div className={_(["", css3.price_row])}>
                <div className={_([""])}>
                    <label className={_(["mb-0 ", css.font1])}>
                        Old Price (hourly)
                    </label>
                    
                    <input
                        type='input'
                        className={_(['input_box',css.input_box_style, css.font2])}
                        name='oldPrice'
                        //onChange={(event)=>{setStateFunc(event,setState,state,'password')}}
                        // ref={password}
                    />
                    
                    
                </div>
                <div className={_([""])}>
                    <label className={_(["mb-0 ", css.font1])}>
                        Updated Price (hourly)
                    </label>
                    <input
                        type='input'
                        className={_(['input_box',css.input_box_style, css.font2])}
                        name='updatedPrice'
                        //onChange={(event)=>{setStateFunc(event,setState,state,'password')}}
                        // ref={password}
                    />
                    
                </div>
            </div>
            <div className={_([" ", css3.map])}>
                <div id='map' style = {{width: "100%", height:"inherit"}}></div>

            </div>
            <div className={_(["d-flex flex-row justify-content-around align-items-center mt-3", css3.button_row, css.font1])}
                >
                
                <button
                    onClick={props.toggle}
                    // type='submit'
                    className={_(["btn btn_B d-flex justify-content-center align-items-center",  css.inside_button2, css.font_18])	}
                    >
                    Cancel
                </button>
                <button
                    onClick={()=>{
                    }}
                    // type='submit'
                    className={_(["btn btn_B d-flex justify-content-center align-items-center", css.inside_button1, css.font_18])	}
                    >
                    Save
                </button>
            </div>
        </div>

      </Modal>
    </>
    )
}

export default UpdatePricePopup;