import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NoPermission from '../../Dashboard_page/NoPermission';
import NotificationContext from '../../components/notification/NotificationContext';

import MyProfile from "../../components/myprofile/MyProfile";
import MyAccount from "../../components/myprofile/MyAccount";
import EmailNotification from "../../components/myprofile/EmailNotification";
import SendNotification from "../../components/myprofile/SendNotification";
import AdminActivities from "../../components/myprofile/AdminActivities";
import AdminAccess from "../../components/myprofile/AdminAccess";


export default function MyProfileRouters() {
  const Dashpermis = useContext(NotificationContext)
  // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
  const permissions = Object.keys(Dashpermis.permis)?.length === 0 ? {} : Dashpermis?.permis;
  return (
    <Routes>
      <Route path='/Dashboard/MyProfile' element={<MyProfile />}>
        <Route path='/Dashboard/MyProfile/MyAccount' element={<MyAccount />} />
        <Route path='/Dashboard/MyProfile/EmailNotification' element={ permissions.email_notification?<EmailNotification />:<NoPermission/>} />
        <Route path='/Dashboard/MyProfile/SendNotification' element={permissions.manage_noftification?<SendNotification />:<NoPermission/>} />
        <Route path='/Dashboard/MyProfile/AdminActivities' element={permissions.admin_activities?<AdminActivities />:<NoPermission/>} />
        <Route path='/Dashboard/MyProfile/AdminAccess' element={permissions.admin_access?<AdminAccess />:<NoPermission/>} />

      </Route>
    </Routes>
  )
}
