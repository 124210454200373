import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

/* css */
import css from "./../../../components/common.module.css";
import css2 from "./../../Profile/ProfilePage.module.css";
import mainCss from "./../../../main.module.css";

/* icons */
import emailIcon from "./../../../assets/Icons/Icon material-email.svg";
import phoneIcon from "./../../../assets/Icons/Icon awesome-phone-alt.svg";
import webIcon from "./../../../assets/Icons/Group 155.svg";
import pointerIcon from "./../../../assets/Icons/Icon material-location-on.svg";
import timezoneIcon from "./../../../assets/Icons/Mask Group 145.svg";
import defaultimg from "./../../../assets/dummy-profile-pic.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
} from "../../../store/actions/providerActions";
import axiosInstance from "../../../axios";
import ChangePassword from "../../popups/Myprofile/ChangePassword";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

const ProviderProfile = () => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("providerId");
  const [changePassword, setChangePassword] = useState(false);
  const ChangedPasswordToggle = () => {
    if (changePassword) {
      setChangePassword(false);
    } else {
      setChangePassword(true);
    }
  };
  const { data, loadingg, error } = useSelector((state) => state.provider);
  const userData = data?.data?.response?.provider_data;

  const fetchData = async (id) => {
    dispatch(fetchDataRequest());
    try {
      const response = await axiosInstance.get(`admin_dash/get_provider-${id}`);
      console.log("responseppp", response);
      const result = response;
      dispatch(fetchDataSuccess(result));
    } catch (error) {
      dispatch(fetchDataFailure(error.toString()));
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);
console.log("userData",userData)
  return (
    <div class="container">
      <div class="row">
        <div
          className={_([
            "bg-white",
            css2.account_details,
            mainCss.font2,
            mainCss.font_18,
          ])}
        >
          <div>
            <img
              alt=""
              className={_(["", css2.profile_img])}
              src={userData?.profile_pic ? userData?.profile_pic : defaultimg}
            />
          </div>
          <div className={_(["", css2.account_inside_details])}>
            <div>
              <label style={{ color: "black" }}>
                <b>
                  {userData?.first_name || userData?.last_name
                    ? `${userData?.first_name} ${userData?.last_name}`
                    : "-"}
                </b>
              </label>
            </div>
            <div>
              <img src={emailIcon} alt="loading..." className="mr-2"></img>
              <label>{userData?.email ? `${userData.email}` : "-"}</label>
            </div>
            <div>
              <img src={phoneIcon} alt="loading..." className="mr-2"></img>
              <label>
                {userData?.country_code || userData?.phone_number
                  ? `+${userData?.country_code} ${userData?.phone_number}`
                  : "-"}
              </label>
            </div>
            <div>
              <img src={webIcon} alt="loading..." className="mr-2"></img>
              <label>
                {userData?.website_url ? userData?.website_url : "-"}
              </label>
            </div>
            <div>
              <img src={pointerIcon} alt="loading..." className="mr-2"></img>
              <label>
                {userData?.address_line_1 || userData?.address_line_2
                  ? `${userData.address_line_1}, ${userData.address_line_2}`
                  : "-"}
              </label>
            </div>
            <div>
              <img src={timezoneIcon} alt="loading..." className="mr-2"></img>
              <label>{userData?.timezone ? userData?.timezone : "-"}</label>
            </div>

            <div
              className="mt-3"
              onClick={() => {
                ChangedPasswordToggle();
              }}
            >
              <Link to="#">Change Password</Link>
            </div>
            <div className="">
              <Link to="#">Logout</Link>
            </div>
          </div>
          <div className=" ml-3">
            <Link to="/dashboard/profile/myaccount/edit">Edit</Link>
          </div>
        </div>
        <div
          className={_([
            "mt-4 bg-white",
            css2.business_details,
            css.font2,
            mainCss.font_18,
          ])}
        >
          <div
            style={{
              borderBottom: "1px solid #D4DBE8",
              paddingBottom: "2rem",
              paddingTop: "1rem",
            }}
          >
            <div className="d-flex w-100 flex-row justify-content-between">
              <p className={_(["", mainCss.font1, mainCss.font_24])}>
                Business Information
              </p>
            </div>
            <div
              className={_([
                "d-flex mt-2 justify-content-between",
                css2.column_width,
                mainCss.font_18,
              ])}
            >
              <label className={_([" ", mainCss.font1])}>Tax ID No.</label>

              <label className={_([" ", mainCss.font2])}>
                {userData?.tax_id_no ? `${userData?.tax_id_no}` : "-"}
              </label>
            </div>
            <div
              className={_([
                "d-flex mt-2 justify-content-between",
                css2.column_width,
                mainCss.font_18,
              ])}
            >
              <label className={_([" ", mainCss.font1])}>
                Employer Identification Number (EIN)
              </label>

              <label className={_([" ", mainCss.font2])}>
                {userData?.employer_identification_number
                  ? `${userData?.employer_identification_number}`
                  : "-"}
              </label>
            </div>
          </div>
          <div className="d-flex mt-3 w-100 flex-row justify-content-between">
            <p className={_(["", mainCss.font1, mainCss.font_24])}>
              Person Incharge Information
            </p>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>First Name</label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_first_name ? `${userData?.pi_first_name}` : "-"}
            </label>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>Last Name</label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_last_name ? `${userData?.pi_last_name}` : "-"}
            </label>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>
              Person Email (if any)
            </label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_person_email ? `${userData?.pi_person_email}` : "-"}
            </label>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>Person Title</label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_title_email ? `${userData?.pi_title_email}` : "-"}
            </label>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>
              Person Phone Number
            </label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_country_code || userData?.pi_phone_number
                ? `+${userData?.pi_country_code} ${userData?.pi_phone_number}`
                : "-"}
            </label>
          </div>
          <div
            className={_([
              "d-flex mt-2 justify-content-between",
              css2.column_width,
              mainCss.font_18,
            ])}
          >
            <label className={_([" ", mainCss.font1])}>Person Extension</label>

            <label className={_([" ", mainCss.font2])}>
              {userData?.pi_person_extension
                ? `${userData?.pi_person_extension}`
                : "-"}
            </label>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D4DBE8",
              paddingBottom: "2rem",
              paddingTop: "1rem",
            }}
          ></div>
          <div className="d-flex mt-3 w-100 flex-row justify-content-between">
            <p className={_(["", mainCss.font1, mainCss.font_24])}>
              Address Information
            </p>
          </div>

          <div className={_(["d-flex mt-2 flex-row justify-content-between"])}>
            <div
              className={_([
                "d-flex flex-column justify-content-between",
                css2.column_width2,
                mainCss.font_18,
              ])}
            >
              <label className={_([" ", mainCss.font1])}>
                Business Address
              </label>

              <label className={_([" ", mainCss.font2])}>
                {userData?.address_line_1 || userData?.address_line_2
                  ? `${userData.address_line_1}, ${userData.address_line_2}`
                  : "-"}
                {/*
                                    businessInfoContext2.passData.businessAddressLine1
                                    +','+
                                    businessInfoContext2.passData.businessAddressLine2
                                    +','+
                                    businessInfoContext2.passData.businessCity
                                    +','+
                                    businessInfoContext2.passData.businessState
                                    +','+
                                    businessInfoContext2.passData.businessCountry
                                    +','+
                                    businessInfoContext2.passData.businessZipCode
                                    */}
              </label>
            </div>
            <div
              className={_([
                "d-flex flex-column justify-content-between",
                css2.column_width2,
                mainCss.font_18,
              ])}
            >
              <label className={_([" ", mainCss.font1])}>Timezone</label>

              <label className={_([" ", mainCss.font2])}>
                {userData?.timezone ? userData?.timezone : "-"}
                {/*businessInfoContext2.passData.businessTimezone*/}
              </label>
            </div>
          </div>
          <div style={{ paddingBottom: "2rem", paddingTop: "1rem" }}>
            <div
              className={_(["d-flex mt-2 flex-row justify-content-between"])}
            >
              <div
                className={_([
                  "d-flex flex-column justify-content-between",
                  css2.column_width2,
                  mainCss.font_18,
                ])}
              >
                <label className={_([" ", mainCss.font1])}>
                  Mailing Address
                </label>

                <label className={_([" ", mainCss.font2])}>
                  {userData?.ma_address_line_1 || userData?.ma_address_line_2
                    ? `${userData.ma_address_line_1} ${userData.ma_address_line_2}`
                    : "-"}
                  {/* 
                                    businessInfoContext2.passData.mailingAddressLine1
                                    +','+
                                    businessInfoContext2.passData.mailingAddressLine2
                                    +','+
                                    businessInfoContext2.passData.mailingCity
                                    +','+
                                    businessInfoContext2.passData.mailingState
                                    +','+
                                    businessInfoContext2.passData.mailingCountry
                                    +','+
                                    businessInfoContext2.passData.mailingZipCode
                                    */}
                </label>
              </div>

              <div
                className={_([
                  "d-flex flex-column justify-content-between",
                  css2.column_width2,
                  mainCss.font_18,
                ])}
              >
                <label className={_([" ", mainCss.font1])}>Timezone</label>

                <label className={_([" ", mainCss.font2])}>
                  {userData?.ma_timezone ? `${userData?.ma_timezone}` : "-"}
                  {/*businessInfoContext2.passData.mailingTimezone*/}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword isOpen={changePassword} toggle={ChangedPasswordToggle} />
    </div>
  );
};

export default ProviderProfile;
