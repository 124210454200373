import React,{useEffect, useState} from "react";
import css from "./../../main.module.css";
import css2 from "./AddParkingSpot.module.css"
import DropFiles from "../../Dashboard_page/DropFiles.js"

import img1 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Group 25632.png"
import alertIcon from "../../assets/parking-bud-provider-assets/add-parking-aspace/alert-icon.svg";

import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../axios.js";
/* import { options } from './../../config/ImageFileConfig'; */
const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};

function OtherDetails (){

    const history = useNavigate();
    const ParkingId = localStorage.getItem("parkingId");
    const [loading, setLoading] = useState(false);

    const [instruction, setInstruction] = useState('');
    const [instructionList, setInstructionList] = useState([]);
    console.log(instruction,instructionList,'instruction')

    const [bestDirection, setBestDirection] = useState('no')
    const [gateCode, setGateCode] = useState('no')
    const [spacePhotos1, setSpacePhotos1] = useState([])
    const [spacePhotos2, setSpacePhotos2] = useState([])
    const [spacePhotos3, setSpacePhotos3] = useState([])
    const [spacePhotos4, setSpacePhotos4] = useState([])
console.log("gateCode",gateCode)
  const allPhotos = [spacePhotos1, spacePhotos2, spacePhotos3, spacePhotos4];
    const setFiles = [
        setSpacePhotos1,
        setSpacePhotos2,
        setSpacePhotos3,
        setSpacePhotos4,
      ];
    const [formValues, setFormValues] = useState({
        parking_brief_discription: "",
        parking_instruction: [],
        is_your_parking_can_list_at_least_3_months: "no",
        are_u_owner_of_parking: "no",
        is_parking_on_deed_restricted_community: "no",
        is_parking_unser_cctv: "no",
        gated_core_required: "no",
        add_gate_code: "",
        ts_agree: "",
        ts_agree_error: null,
        termsCondition:false
      });
      const [errors, setErrors] = useState({});
      const [amenitiesData , setAmenitiesData] = useState([]);

      const handleInstructionChange = (e) => {
        setInstruction(e.target.value);
      };
      
      const handleAddInstruction = () => {
        if (instruction !== "") {
          const newInstructionList = [...instructionList, instruction];
          setInstructionList(newInstructionList);
          setFormValues({
            ...formValues,
            parking_instruction: newInstructionList
          });
          setInstruction("");
        }
      };
      const handleOnChange = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
    console.log("sdjfn",name)
        setFormValues({
          ...formValues,
          [name]: newValue,
        });
    
        setErrors({
          ...errors,
          [name]: '',
        });
      };

      async function getamenities() {
        try {
            const response = await axiosInstance.get(`/provider_dash/show_provider_amenities/${ParkingId}`);
            if (response?.status === 202) {
                setAmenitiesData(response.data.response);
            }
            console.log(response, "djnj");
        } catch (err) {
            if (err.response) {
                console.log(err.response.data);
            } else {
                console.log(err.message);
            }
        }
    }
    
    useEffect(() => {
        getamenities();
    }, []);
    
    async function Addamenities(id) {
        const formdata = new FormData();
        formdata.append("amenities", id);
        try {
            const response = await axiosInstance.post(`/provider_dash/show_provider_amenities/${ParkingId}`,
                formdata,
                { headers: { "content-type": "multipart/form-data" } });
            console.log(response, "djnj");
        } catch (err) {
            if (err.response) {
                console.log(err.response.data);
            } else {
                console.log(err.message);
            }
        }
    }
console.log("xxxx",formValues)
    const handleSubmit = async (e) => {
        const formdata = new FormData();
        formdata.append("parking_brief_discription", formValues?.parking_brief_discription);
        formdata.append("parking_instruction", [formValues?.parking_instruction]);
        formdata.append("is_your_parking_can_list_at_least_3_months", formValues?.is_your_parking_can_list_at_least_3_months === "yes" ? true : false);
        formdata.append("are_u_owner_of_parking", formValues?.are_u_owner_of_parking === "yes" ? true : false);
        formdata.append("is_parking_on_deed_restricted_community", formValues?.is_parking_on_deed_restricted_community === "yes" ? true : false);
        formdata.append("is_parking_unser_cctv", formValues?.is_parking_unser_cctv === "yes" ? true : false);
        formdata.append("gated_core_required", formValues?.gated_core_required === "yes" ? true : false);
        formdata.append("add_gate_code", formValues?.add_gate_code);
        formdata.append("parking_instruction", [formValues?.parking_instruction]);
        formdata.append("ts_agree", formValues?.termsCondition);
        formdata.append("ts_agree_error", formValues?.ts_agree_error);
        if (bestDirection) {

            if (spacePhotos1?.[0]) {
              formdata.append("pic_1", spacePhotos1?.[0]);
            }
            if (spacePhotos2?.[0]) {
              formdata.append("pic_2", spacePhotos2?.[0]);
            }
            if (spacePhotos3?.[0]) {
              formdata.append("pic_3", spacePhotos3?.[0]);
            }
            if (spacePhotos4?.[0]) {
              formdata.append("pic_4", spacePhotos4?.[0]);
            }
        }
        // e.preventDefault();
        const newErrors = {};
    
        if (!formValues.parking_brief_discription) {
          newErrors.parking_brief_discription = "Add the brief instruction about your parking.";
        } 
    
        if (!formValues.termsCondition) {
          newErrors.termsCondition = "Terms & condition not checked.";
        } 
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
            setLoading(true)
            try {
                const response = await axiosInstance.post(
                  `admin_dash/draft_add_parking_spot_3-${ParkingId}`,
                  formdata,
                  { headers: { "content-type": "multipart/form-data" } }
                );
                if (response?.status === 202) {
                    setLoading(false)
                  console.log("response.success", response.success);
                  history('/Dashboard/ParkingSpots/AddParkingSpot/AppliedForApproval', { replace: true })
                } else {
                    setLoading(false)
                  console.error("Failed to add parking spot");
                }
              } catch (error) {
                setLoading(false)
                console.error("Error:", error);
              }
        }
      };

    return (
        <>
        <div className={_(["d-flex flex-column justify-content-center align-items-center w-100", css.shade_background])}>
        <div className={_(["d-flex flex-column justify-content-center mb-5", css2.basic_details_container, css2.center_container])}>
            
            <label className={_(["align-self-start mb-3", css.font1, css.font_24])}>
                Brief Description
            </label>

            <textarea className={_(["" ,errors?.parking_brief_discription ? css2.error_message : css2.text_area  ])}   
            value={formValues?.parking_brief_discription}
            onChange={(e)=>handleOnChange(e)}
            name="parking_brief_discription"
            >

            </textarea>
            {errors?.parking_brief_discription && (
                    <span className={_(["mt-1", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.parking_brief_discription}
                    </span>
                  )}
            
            
        </div>
        <div className={_(["d-flex flex-column justify-content-start mb-4", css2.basic_details_container, css2.center_container])}>
            
            <label className={_(["align-self-start mb-3", css.font1, css.font_24])}>
                Amenities
            </label>

            <div className={_(["align-self-start d-flex flex-wrap justify-content-start ", css2.amenities])}>
                {amenitiesData?.map((item)=> {
                    return (

                <div className={_(["d-flex flex-row justify-content-start align-items-center mb-3"])} onClick={()=>Addamenities(item?.id)}>
                    <input type='checkbox' className={_(["", css2.checkbox])}></input>
                    <img className={_(["", css2.amenities_img])} src={item?.icon_img} alt='amenities img' />
                    <label className={_(["mb-0 ml-2", css.font2, css.font_18])}>{item?.name}</label>
                </div>
                    )
                })}
            

            </div>
            
            
        </div>
        <div className={_(["d-flex flex-column justify-content-center align-items-center mb-4", css2.basic_details_container, css2.center_container])}>
            
            <label className={_(["align-self-start mb-3", css.font1, css.font_24])}>
                Instructions
            </label>

            <div className={_(["w-100", css.font_18])}>
                <label className={_([" ", css.font1])}>
                    Add instructions for parking (If any):
                </label>
                <input
                    type='input'
                    className={_(['input_box', css.input_box_style, css.font2])}
                    placeholder='i.e scan parking pass at entry gate'
                    value={instruction}
                    name='Instruction'
                    onChange={handleInstructionChange}
                    required
                />
                <button className={_(['', css2.inside_button])} onClick={handleAddInstruction}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16" style={{ pointerEvents: 'none' }}>
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                </button>
            </div>
            <div className={_(["align-self-start mb-3 mt-3", css.font2, css.font_18])}>
                {instructionList.map((value) => (
                    <>
                    <div className={_(["d-flex flex-row justify-content-start align-items-center"])}>
                    <div className={_(["mr-3", css2.dot])}></div>
                    <p className={_(["mb-0 "])}>{value}</p>
                    </div>
                    </>
                ))}
                
            </div>
            <hr style={{width:'100%'}}></hr>
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font1])}>
                    Parking Best Using Directions?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    value={bestDirection}
                    onChange = {(e) => {
                        setBestDirection(e.target.value)
                    }}
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            { bestDirection === 'yes' &&
            <div className={_([" w-100 mt-4", css2.space_photos])}>
                 {allPhotos.map((photoSet, index) => (
              <div key={index} className={_(["w-100", css2.upload_box])}>
                <DropFiles
                  file={photoSet}
                  setFile={setFiles[index]}
                  text="Drag and drop or browse to choose a file"
                  imgsrc={img1}
                  existingFiles={allPhotos.flat()}
                />
              </div>
            ))}
            </div>
            }
            

            
        </div>
        <div className={_(["d-flex flex-column justify-content-center align-items-center mb-4", css2.basic_details_container, css2.center_container])}>
            
            <label className={_(["align-self-start mb-3", css.font1, css.font_24])}>
                About The Parking Area
            </label>

            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font2])}>
                    Is your parking spot available to list for at least 3 consecutive months?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    name="is_your_parking_can_list_at_least_3_months"
                    value={formValues?.is_your_parking_can_list_at_least_3_months}
                    onChange={handleOnChange}
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font2])}>
                    Are you the owner of the parking spots you want to list?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    name="are_u_owner_of_parking"
                    value={formValues?.are_u_owner_of_parking}
                    onChange={handleOnChange}
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font2])}>
                Is the parking spot located in a deed restricted or HOA community?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    name="is_parking_on_deed_restricted_community"
                    value={formValues?.is_parking_on_deed_restricted_community}
                    onChange={handleOnChange}
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font2])}>
                Is the parking spot under the surveillance of a security camera?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    name="is_parking_unser_cctv"
                    value={formValues?.is_parking_unser_cctv}
                    onChange={handleOnChange}
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <label className={_([" ", css.font2])}>
                    Do you need a gate code to reach the parking spot?
                </label>
                <select 
                    className={_(['input_box',css.input_box_style, css.font2, css2.yes_no])} 
                    name="gated_core_required"
                    value={formValues?.gated_core_required}
                    onChange={handleOnChange}
                  
                >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            </div>
            {formValues?.gated_core_required === 'yes' &&
            <div className={_(["w-100 d-flex flex-row justify-content-between align-items-center mt-2", css.font_18])}>
                <div className={_(["w-100 d-flex flex-column justify-content-between ", css.font_18])}>
                <label className={_([" ", css.font1])}>
                    Gate Code
                </label>
                <input
                    type='input'
                    className={_(['input_box',css2.text_area2, css.font2])}
                    placeholder='i.e A123x'
                    value={formValues?.add_gate_code}
                    name='add_gate_code'
                    required
                    onChange={handleOnChange}
                />
                </div>
            </div>
            }

            <div className={_(["d-flex flex-column",])}>
                <div className={_(["d-flex align-self-start justify-content-start align-items-center mt-5", css.font_18, css.font2])}>
                    <label className={_(["flex-grow-1 d-flex align-items-center mb-0", css.checkbox_group])} htmlFor="check1">
                        <input type="checkbox" className={_(["mr-2",css.checkbox_size])} id="check1" value={formValues.checkbox} name="termsCondition" onChange={(e)=>handleOnChange(e)}></input>
                        <span className={_(["mr-2", css.box])}></span>
                        {/* <label className="mb-0">Remember me</label> */}
                    </label>
                    <p className="mb-0">I have read and agree to Parking Bud's <a href="#tandC" className={_(['', css.font1])}>Terms and Conditions</a> and <a href="#privacy_policy" className={_(['', css.font1])}>Privacy Policy</a></p>
                </div>
                
                {errors?.termsCondition && (
                        <span className={_(["", css2.error_style])}>
                        <img src={alertIcon} alt="alert img" />
                        {errors?.termsCondition}
                        </span>
                )}
            </div>
            <div className={_(["mt-5 d-flex flex-row justify-content-between align-items-center w-100 mb-3", css.font2])}>
                <button 
                    className={_(["", css.inside_button2, css2.button1])}
                    onClick = {() => {
                        history('/Dashboard/ParkingSpots/AddParkingSpot/ParkingSpotAddress', { replace: true })
                    }}
                >
                    Back
                </button>
                <button 
                    className={_(["", css.inside_button1, css2.button1])}
                    onClick = {() => {
                        handleSubmit()
                    }}
                >
                    {loading ?  
                    <div className={_(["",css2.cursor_loader,css2.loader_div])}>Loading...</div>
                :
                    'Save & Continue'
                }
                </button>
             </div>
        </div>
        </div>
        </>
    )
}

export default OtherDetails;
