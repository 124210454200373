export const makeBookingData=(arr) =>
{
    for(let i=0;i<arr.length;i++)
    {
        arr[i].name=arr[i].parking_name
        arr[i].phoneNumber=arr[i].parking_phone_number;

    }

    return arr
}