import React, { useEffect, useState } from "react";
import css from "../../../main.module.css";
import css2 from "./../../Parking_spot/parkingspots/SearchParkingSpot.module.css";

import searchIcon from "./../../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "./../../../assets/Icons/Icon material-delete.svg";
import editIcon from "./../../../assets/Icons/Icon material-edit.svg";
import { Link } from "react-router-dom";
import actionIcon from "./../../../assets/Icons/Repeat Grid 15.svg";
import noParkings from '../../../assets/JSON-Gifs/GIF/15.Empty list.json'

import "../../../styles.css";
import axiosInstance from "../../../axios";
import Loader from "../../Reusable Popup/Loader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function ProviderCoupons() {
  const [selectedOption, setSelectedOption] = useState("All");
  const [couponData, setCouponData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const id = localStorage.getItem("providerId");

  // const cancelCreateCoupons = React.createRef();

  // const openCreateCoupons = (event) => {
  //     cancelCreateCoupons.current.toggleModal();
  // }

  const toggleButtonSearch = (e) => {
    setSelectedOption(e.target.name);
  };

  const [editPricePopup, setEditPricePopup] = useState({
    popup: false,
    spotId: "",
  });

  const toggleEditPricePopup = () => {
    if (editPricePopup.popup) {
      setEditPricePopup({
        ...editPricePopup,
        popup: false,
      });
    } else {
      setEditPricePopup({
        ...editPricePopup,
        popup: true,
      });
    }
  };

  const userCoupon = async (id) => {
    setLoadingData(true);
    const data = {
      providerId: id,
      resultLimit: 6,
      page: 1,
    };
    let apiUrl = `providerId=${"8"}&resultLimit=${data?.resultLimit}&pageNo=${
      data?.page
    }&couponStatus=${selectedOption === "All" ? "" : selectedOption}`;
    try {
      const response = await axiosInstance.get(
        `/admin_dash/get_provider_coupons?${apiUrl}`
      );
      console.log("adfjdfhb", response);
      setCouponData(response?.data?.response?.coupons);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log(error);
    }
  };

  useEffect(() => {
    userCoupon(id);
  }, [id, selectedOption]);

  return (
    <div
      className={_(["col-md-12 bg-white p-0 mb-5 shadow mb-5 bg-body rounded-3", css2.center_container, css2.booking_search])}
    >
      <div className={_(["d-flex p-3 justify-content-start",css.font1])}>
        <h5>Couponss</h5>
      </div>

      <div className={_([" w-100", css2.search_row])}>
        <div>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "All" ? css2.selected_search_button : "",
            ])}
            name="All"
            onClick={toggleButtonSearch}
          >
            All
          </button>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "Active" ? css2.selected_search_button : "",
            ])}
            name="Active"
            onClick={toggleButtonSearch}
          >
            Active
          </button>
          <button
            className={_([
              "mr-4",
              css.font_18,
              css2.search_buttons,
              selectedOption === "Deactivated"
                ? css2.selected_search_button
                : "",
            ])}
            name="Deactivated"
            onClick={toggleButtonSearch}
          >
            Deactivated
          </button>
        </div>
        <div className={_(["d-flex justify-content-end"])}>
          <div className={_(["", css.font_18, css.font2, css2.search_bar])}>
            <img alt="coupons" src={searchIcon}></img>

            <input
              className={_(["ml-2 w-100", css.font_18, css.font2])}
              type="input"
              name="search"
              placeholder="Search by name or location"
              style={{ border: "0px", background: "none" }}
            ></input>
          </div>
        </div>
      </div>
      <div
        className={_([
          "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
          css2.search_row_2,
        ])}
      >
        {selectedOption === "All" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>All</label>
              <label className={_(["", css.font_18, css.font2])}>4 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        {selectedOption === "Active" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>
                Active
              </label>
              <label className={_(["", css.font_18, css.font2])}>6 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        {selectedOption === "Deactivated" ? (
          <div>
            <div className={_([""])}>
              <label className={_(["mr-3", css.font_24, css.font1])}>
                Deactivated
              </label>
              <label className={_(["", css.font_18, css.font2])}>6 Users</label>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            ></div>
          </div>
        ) : null}
        <div
          className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
        >
          <div
            className={_(["flex-grow-1 d-flex flex-wrap justify-content-end"])}
          >
            <select
              className={_([
                "mr-2",
                css.font_18,
                css.font1,
                css2.select_box_style,
              ])}
            >
              <option value="Business">Paused</option>
              <option value="Users">Admin</option>
              <option value="Individuals">Sub Admin</option>
            </select>
            <select
              className={_([
                "mr-2",
                css.font_18,
                css.font1,
                css2.select_box_style,
              ])}
            >
              <option value="Business">Fixed Amount Discount</option>
              <option value="Users">Admin</option>
              <option value="Individuals">Sub Admin</option>
            </select>
            <button
              className={_([
                " pl-2 pr-2 d-flex align-items-center",
                css.font_18,
                css.inside_button4,
              ])}
            >
              <img alt="coupons" src={deleteIcon} className={_(["mr-2"])}></img>
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className={_(["flex-grow-1", css2.table])}>
        <table
          className={_([
            "mt-2 w-100",
            css.font_18,
            css.font2,
            css2.search_list,
          ])}
        >
          <tr className={_(["", css2.search_header])}>
            <td>#</td>
            <td>Code</td>
            <td>Discount</td>
            <td>Max Discount</td>
            <td>Redeem Limit</td>
            <td>Min. spend</td>
            <td>Expiry Date</td>
            <td>Actions</td>
          </tr>
          {couponData?.map((item) => {
            return (
              <tr className={_([""])}>
                <td>
                  <label
                    className={_([
                      "flex-grow-1 d-flex justify-content-center align-items-center mb-0",
                      css.checkbox_group,
                    ])}
                    htmlFor="check1"
                  >
                    <input
                      type="checkbox"
                      className={_(["mr-2", css.checkbox_size])}
                      id="check1"
                    ></input>
                    <span className={_(["mr-1", css.box])}></span>
                  </label>
                </td>
                <td>
                  <div>{item?.coupon_code}</div>
                </td>
                <td>{item?.discount}</td>
                <td>{item?.maximum_discount}</td>
                <td>{item?.redeem_limit}</td>
                <td>{item?.minimum_discount}</td>
                <td>{item?.coupon_expiry_date}</td>
                <td>
                  {selectedOption === "All" ? (
                    <div className="dropdown">
                      <p
                        className="mb-0 dropdown-toggle"
                        to="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="coupons" src={actionIcon}></img>
                      </p>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="/Dashboard/ViewParkingSpot"
                        >
                          Edit
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Share
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Delete
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Pause
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {selectedOption === "Active" ? (
                    <div className="dropdown">
                      <p
                        className="mb-0 dropdown-toggle"
                        to="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="coupons" src={actionIcon}></img>
                      </p>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="/Dashboard/ViewParkingSpot"
                        >
                          Edit
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Share
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Delete
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Pause
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {selectedOption === "Deactivated" ? (
                    <div className="dropdown">
                      <p
                        className="mb-0 dropdown-toggle"
                        to="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="coupons" src={actionIcon}></img>
                      </p>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link className="dropdown-item" to="#">
                          Approve
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Deny
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Delete
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </table>
        {loadingData ? (
          <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
            <Loader  loading={loadingData} />
          </div>
        ) : couponData?.length === 0 ? (
          <>
            <div className="mt-5 w-100 d-flex flex-column align-items-center">
              <img src={noParkings} alt="No Parkings"></img>
              <span className={_(["mt-4", css.font2, css.font_18])}>
                No parking spots available right now. :({" "}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ProviderCoupons;
