// playground requires you to assign document definition to a variable called dd

import { logo } from './logo';

export const setDefinition = (obj) => {
    imageObj.image = obj.image ? obj.image : logo;
    parkingDescription.text = obj.description ? obj.description : 'Nill';
    parkingName.text = obj.name ? obj.name : 'Nill';
    parkingAddress.text = obj.adderess ? 'Address: ' + obj.adderess : 'Address: Nill';
    phoneNumber.text = obj.phoneNumber ? 'Phone: '+ obj.phoneNumber : 'Phone: Nill';
    monthlyPrice.text = obj.monthlyPrice ? obj.monthlyPrice : 'Nill';
    hourlyPrice.text = obj.hourlyPrice ? obj.hourlyPrice : 'Nill';
    parkingEmail.text = obj.email ? 'Email:' + obj.email : 'Email: Nill';
    parkingRating.text = obj.rating ? "Rating: " + obj.rating : 'Rating: ' + '0'
    ammenity.stack[1].ul = obj.ammenityList ? obj.ammenityList : [];
    operationList.stack[1].ul = obj.operationList ? obj.operationList : [];
    instructionList.stack[1].ul = obj.instructionList ? obj.instructionList : [];
    otherImages.columns = obj.otherImages ? obj.otherImages : []
    return dd;
}

var otherImages = {
    alignment: 'justify',
    columns: [
    ]
}



var imageObj = {
    image: '',
    fit: [200, 200],
}
var parkingDescription = {
    text: '',
    style: 'description',
}
var parkingName = {
    text: '',
    style: 'name',
}

var parkingAddress = {
    text: '',
}

var phoneNumber = {
    text: '',
}

var parkingEmail = {
    text: '',
}

var parkingRating = {
    text: ''
}

var monthlyPrice = {
    text: '',
    style: 'monthly',
    background: 'gray',
    color: '#233874',
}
var hourlyPrice = {
    text: '',
    style: 'monthly',
    background: 'gray',
    color: '#233874',
}




var ammenity = {
    stack: [
        { text: 'Amenities', style: 'heading', margin: [0, 20, 0, 10] },
        {
            markerColor: '#7E318E',
            ul: []
        }
    ]
}

var operationList = {
    stack: [
        { text: 'Hours of Operation', style: 'heading', margin: [0, 20, 0, 10] },
        {
            markerColor: '#7E318E',
            ul: [
            ]
        }
    ]
}

var instructionList = {
    stack: [
        { text: 'Instructions', style: 'heading', margin: [0, 20, 0, 10] },
        {
            markerColor: '#7E318E',
            ul: [
            ]
        }
    ]
}


var dd = {
    content: [
        {
            alignment: 'justify',
            columns: [
                imageObj,
                {
                    style: 'tableExample',
                    table: {
                        body: [
                            [''],
                            [parkingName],
                            [parkingEmail],
                            [parkingRating],
                            [phoneNumber],
                            [parkingAddress],
                            [parkingDescription]
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }

                }
            ]
        },
        {
            alignment: 'justify',
            style:'imageColumn',
            margin:[0,20,0,0],
            columns: [
                otherImages,
                {
                    columns: [
                        hourlyPrice,
                        monthlyPrice
                    ]
                }
            ]
        },
        {
            columns: [
                operationList,
                ammenity
            ]
        },
        {
            columns: [
                {
                    stack: [
                        { text: 'Parking spot selling options', style: 'heading', style: 'heading', margin: [0, 20, 0, 10] },
                        {
                            markerColor: '#7E318E',
                            ul: [
                                'item 1',
                                'item 2'
                            ]
                        }
                    ]
                },
            ]
        },
        {
            columns: [
                instructionList,
                {
                    table: {
                        body: [
                            [{ text: 'About parking area', style: 'heading', margin: [0, 20, 0, 10] }, ''],
                            ['One value goes here', { text: 'yes', style: 'subHeading' }],
                            ['One value goes here', { text: 'yes', style: 'subHeading' }],
                            ['One value goes here', { text: 'yes', style: 'subHeading' }],
                            ['One value goes here', { text: 'yes', style: 'subHeading' }]
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ]
        },
    ],
    styles: {
        name: {
            fontSize: 20,
            bold: true
        },
        heading: {
            fontSize: 18,
            bold: true,
            color: '#233874',
        },
        contentStyle: {
            fontSize: 16,
        },
        description:
        {
            color: 'gray'
        },
        subHeading: {
            color: 'gray'
        },
        tableExample: {

        },
        imageColumn:{
            columnGap:20
        }

    }

}

