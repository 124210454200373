import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Link, useNavigate} from "react-router-dom";
/* csss */
import css2 from "../../components/Profile/ProfilePage.module.css"
import mainCss from "../../main.module.css"
import './myprofile.css'




const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};







const MyProfile = () => {

    const [title, setTitle] = useState("");
    const [selected, setSelected] = useState("My_Account");
    const navigate = useNavigate();

    const handleClick = (e) => {
        setSelected(e.target.name)
        let name = e.target.name;
        if (name === "MyAccount") {
            setTitle("My Account")
        } else if (name === "EmailNotification") {
            setTitle("Email Notification")
        } else if (name === "SendNotification") {
            setTitle("Send Notification")
        } else if (name === "AdminAccess") {
            setTitle("Admin Access")
        } else {
            setTitle("Admin Activities")
        }
    }

    const url = new URL(window.location.href);
    useEffect(()=>{
    setSelected(url.pathname.split('/')[3])
    setTitle(url.pathname.split('/')[3].replace(/([A-Z])/g, ' $1').trim())
},[])




    return (
        <div className='container bg-white mt-5'>
            <div className='container mt-4'>
                <p className={_(["align-self-start mb-4 mt-4 font-family-regular",mainCss.cursor_pointer])} onClick={() =>
              navigate("/Dashboard/details", {
                replace: true,
              })}>Profile {'>'} <span className='link-font'>My Profile</span></p>
                <h4 className={_(["linkstyle fw-bold",])}>{title} </h4>
            </div>
            <div className='container mt-4'>

                <div className='col-md-12 p-0'>
                    <ul className={_(["col-md-12 p-0", css2.nav_style2, mainCss.font_18])}>
                        <Link to="/Dashboard/MyProfile/MyAccount" name="MyAccount" className={selected === "MyAccount" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>My Account
                        </Link>
                        <Link to="/Dashboard/MyProfile/EmailNotification" name="EmailNotification" className={selected === "EmailNotification" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Email Notification
                        </Link>
                        <Link to="/Dashboard/MyProfile/SendNotification" name="SendNotification" className={selected === "SendNotification" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick}>Send Notification
                        </Link>
                        <Link to="/Dashboard/MyProfile/AdminAccess" name="AdminAccess" className={selected === "AdminAccess" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >Admin Access
                        </Link>
                        <Link to="/Dashboard/MyProfile/AdminActivities" name="AdminActivities" className={selected === "AdminActivities" ? _(["py-2", css2.active_link4, css2.link_text4]) : _(["py-2", css2.link_text4])} onClick={handleClick} >Admin Activities
                        </Link>

                    </ul>
                </div>
                <div className='col-md-12 border m-0 contnt-myprofile d-flex flex-column align-items-center'>
                    <Outlet />
                </div>
            </div>
        </div>
    )

}

export default MyProfile;