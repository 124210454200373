import React from "react";
import { Outlet } from 'react-router';
import css from "./../../main.module.css";
import css2 from "./AddParkingSpot.module.css"
import img1 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Mask Group 131.svg"
import img2 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Group 23627.png"
import img3 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Mask Group 1381.png"
import img3_gradient from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Mask Group 138.png"
import img4 from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Group 256851.png"
import img4_gradient from "./../../assets/parking-bud-provider-assets/add-parking-aspace/Group 25685.png"
import { Link, useMatch } from "react-router-dom";




const _ = (classes) => {
	let s = "";
	classes.map((i) => (s += i + " "));
	return s;
};


function AddParkingSpot(){ 
    const basicDetails = useMatch("/Dashboard/ParkingSpots/AddParkingSpot/BasicDetails");
    const parkingSpotAddress = useMatch("/Dashboard/ParkingSpots/AddParkingSpot/ParkingSpotAddress");
    const otherDetails = useMatch("/Dashboard/ParkingSpots/AddParkingSpot/OtherDetails");
    const appliedForApproval = useMatch("/Dashboard/ParkingSpots/AddParkingSpot/AppliedForApproval");

    return(
        <>
        <div className={_(["d-flex flex-column align-items-center",css2.addparking_container, css.shade_background2])}>
            <div className={_(["d-flex flex-column align-items-center",css2.center_container])}>
                <p className={_(["align-self-start",css.font_18, css.font2])}>
                    <Link className={_([css.custom_sublink])} to="/Dashboard/ParkingSpots"> Parkings Spots </Link> &gt; Add New Parking</p>
                <div className={_(["d-flex flex-row justify-content-between w-100"])}>
                    <p className={_(["flex-grow",css.font_32, css.font1])}>Add New Parking Spot</p>
                    <div className={_(["d-flex flex-column align-items-center"])}>
                        <button  className={_(["", css.inside_button1, css2.button1])}>
                            + Import Parking List
                        </button>
                        <p className={_(["mt-1",css.font_14, css.font2])}>Upload Excel or CSV</p>
                    </div>
                </div>

                <div className={_(["d-flex flex-row justify-content-between w-100", css2.progress_section])}>
                    <div className={_(["d-flex flex-column justify-content-start align-items-center", css2.selected_step])}>
                        <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                            <img src={img1} alt="...">
                            </img>
                        </div>
                        <p className={_(["mt-3",css.font_24, css.font1])}>Basic Details</p>
                        
                    </div>
                    <span className={_(["",css2.sp1, (parkingSpotAddress || otherDetails || appliedForApproval? css2.border_color_on_selected: "red" )])}></span>
                    <div className={_(["d-flex flex-column justify-content-start align-items-center", (parkingSpotAddress || otherDetails || appliedForApproval? css2.selected_step :css2.not_selected_step )])}>
                        
                        <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                            <img src={img2} alt="...">
                            </img>
                        </div>
                        <p className={_(["mt-3",css.font_24, css.font1])}>Address</p>
                        
                    </div>
                    <span className={_(["",css2.sp2, (otherDetails || appliedForApproval? css2.border_color_on_selected: "" )])}></span>
                    <div className={_(["d-flex flex-column justify-content-start align-items-center", ( otherDetails || appliedForApproval? css2.selected_step :css2.not_selected_step )])}>
                        <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                            <img src={otherDetails || appliedForApproval? img3_gradient: img3 } alt="...">
                            </img>
                        </div>
                        <p className={_(["mt-3",css.font_24, css.font1])}>Other Details</p>
                        
                    </div>
                    <span className={_(["",css2.sp3, ( appliedForApproval? css2.border_color_on_selected: "" )])}></span>
                    <div className={_(["d-flex flex-column justify-content-start align-items-center", (appliedForApproval? css2.selected_step :css2.not_selected_step )])}>
                        <div className={_(["d-flex flex-column justify-content-center align-items-center"])}>
                            <img src={appliedForApproval ? img4_gradient: img4 } alt="...">
                            </img>
                        </div>
                        <p className={_(["mt-3",css.font_24, css.font1])}>Applied for Approval</p>
                        
                    </div>
                </div>

            </div>
            <Outlet/>
            
        </div>
        </>
    );
}

export default AddParkingSpot;