import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NoPermission from '../../Dashboard_page/NoPermission';
import NotificationContext from '../../components/notification/NotificationContext';

import AboutUs from "../../components/aboutus2/AboutUs";
import AboutParkingBud from "../../components/aboutus2/AboutParkingBud";
import HelpFAQs from "../../components/aboutus2/Help_FAQs";
import PrivacyPolicy from "../../components/aboutus2/Privacy_Policy";
import WhoAreWe from "../../components/aboutus2/WhoAreWe";
import OurVision from "../../components/aboutus2/OurVision";

export default function AboutusRouters() {
    const Dashpermis = useContext(NotificationContext)
    // const permissions = Dashpermis.permis === {} ? [] : Dashpermis.permis
    const permissions = Object.keys(Dashpermis.permis)?.length === 0 ? {} : Dashpermis?.permis;
    return (<Routes>
        <Route path='/Dashboard/AboutUs' element={<AboutUs />}>
            <Route path='/Dashboard/AboutUs/AboutParkingBud' element={permissions.manage_about_parking_bud ? <AboutParkingBud /> : <NoPermission />} />
            <Route path='/Dashboard/AboutUs/HelpFAQs' element={permissions.manage_help_and_faq ? <HelpFAQs /> : <NoPermission />} />
            <Route path='/Dashboard/AboutUs/PrivacyPolicy' element={permissions.manage_privacy_and_policy ? <PrivacyPolicy /> : <NoPermission />} />
            <Route path='/Dashboard/AboutUs/WhoWeAre' element={permissions.manage_who_we_are ? <WhoAreWe /> : <NoPermission />} />
            <Route path='/Dashboard/AboutUs/OurVision' element={permissions.manage_our_vision ? <OurVision /> : <NoPermission />} />

        </Route>
    </Routes>
    )
}
