import PublicRoute from './routes/PublicRoutes';
import { Toaster } from 'react-hot-toast';
import './App.css'

import ForgotPass from './store/public/forgotPassword/forgotPass';
import BusinessInfo from './store/public/BusinessInfo/BusinessInfo';
import BusinessInfo2 from './store/public/BusinessInfo2/BusinessInfo';
import BusinessInfo3 from './store/public/BusinessSignup/BusinessInfo';
import { Provider } from 'react-redux';
import store from './store/store';

function App() {

  return (
    <Provider store={store}>
    <ForgotPass>
      <Toaster />
      <BusinessInfo>
      <BusinessInfo2>
      <BusinessInfo3>
      <PublicRoute/>
      </BusinessInfo3>
      </BusinessInfo2>
      </BusinessInfo>
    </ForgotPass>
    </Provider>
    
  );
}

export default App;

