import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axiosInstance from "../../axios";
import Loader from '../Reusable Popup/Loader';
import SavedPopup from '../Reusable Popup/SavedPopup';
import UpdateAlert from '../Reusable Popup/Updatealert';
import { Link } from 'react-router-dom';
/* csss */
import './terms.css'












const MonthlyParkingTerms = () => {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const [content, setContent] = useState("")
    
    const [confirmation, setconfirmation]=useState(false)
    const [save, setSave] = useState(false);
    const SaveToggle = (e) => {
        if(e.target.value==='Cancel'){
            setSave(false)
        }
        else{
            setSave(false)
            Save()
            setconfirmation(true)}
    }

    const [opener,setopener]=useState(false)
    const openeditor=()=>{
        if (opener){
            setopener(false)

        }
        else{
            setopener(true)
        }
    }




    async function makerequest() {
        setLoading(true);
        setColor('#5c5b5b');


        await axiosInstance.get(
            "admin_dash/cms_terms_catag-mon_park_terms"
        ).then((response) => {
            setContent(response.data.response.data.content);
            setLoading(false);

        }).catch((err) => {
            console.log(err.response.response.data);

        })

    }

    useEffect(() => {
        makerequest()

    }, [])

    async function Save() {


        let formData = new FormData();

        formData.append('content', content);

        await axiosInstance.post(
            "admin_dash/cms_terms_catag-mon_park_terms",
            formData,
            { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
           
                setopener(false)
                setconfirmation(false)
            

        }).catch((err) => {
            alert("Network error")
            setopener(false)

        })
    }

    return (
        <>
                
                <div className="col-md-12 d-flex justify-content-end  align-items-end">
                    <Link to='#' onClick={openeditor} hidden={opener}>Edit</Link>
                </div>
                <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                    <Loader color={color} loading={loading} />
                </div>
                {opener?<div className="container">
                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 mb-5  trmcontnt">
                                <Editor
                                   
                                    apiKey="y6ee112drn29hukuvhfknti1tccnqa9uk0t0amfwk59579v5"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic underline forecolor| align bullist numlist outdent indent blockquote | removeformat ',
                                        
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         
                                    
                                        }}

                                    value={content}
                                    onEditorChange={(newValue, editor) => setContent(newValue)}
                                />
                                <div className="col d-flex justify-content-center align-self-center">
                                    <button
                                        className="align-self-end font_18 inside_button0 add_new_button"
                                        onClick={() => {setopener(false)}}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="align-self-end font_18 inside_button add_new_button"
                                        onClick={() => {setSave(true)}}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    
                    <SavedPopup  isOpen={confirmation} heading={'Great!'} Subheading={ 'Updated Successfully'} subheading2={'Monthly Terms & Conditions'} buttontitle={'Okay'} toggle={()=>{setconfirmation(false)}}/>
                    <UpdateAlert  isOpen={save} heading={'Update Alert!'} Subheading={'Are you sure you want to update'} Subheading2={'Monthly Terms & Conditions?'} buttontitle={'Cancel'} buttontitle2={'Yes'} toggle={SaveToggle}/>
                
                </div >:<div className="container mb-4">
                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 mb-5 border trmcontnt">
                                
                                <div dangerouslySetInnerHTML={{__html:content}}/>
                            </div>
                        </div>
                
                </div >}
                
        </>

    )

}




export default MonthlyParkingTerms;