import css from "./../../main.module.css";
import cssx from "./../../components/common.module.css";
import css2 from "./Users.module.css";

import React, { useState, useEffect, useContext } from "react";
import UpdatePrice from "./../../components/popups/UpdatePrice1";
import searchIcon from "./../../assets/Icons/Icon feather-search.svg";
import deleteIcon from "./../../assets/Icons/Icon material-delete1.svg";
import editIcon from "./../../assets/Icons/Icon material-edit.svg";
import starIcon from "./../../assets/Icons/Icon awesome-star.svg";
import PaginationComponent from "./../../components/Pagination";
import noParkings from "./../../assets/Icons/Group 26816.svg";
import { Link } from "react-router-dom";
import actionIcon from "./../../assets/Icons/Repeat Grid 15.svg";
import arrowUpDown from "./../../assets/useicon/Arrow Up Down.svg";

import defaultSPotImg from "./../../assets/Default_spot_img.svg";
import CreateProviderPopup from "../popups/CreateProviderPopup";

import "./../../styles.css";
import Loader from "../Reusable Popup/Loader";
import axiosInstance from "../../axios";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { DownloadExcel } from "../algo/DownloadExcel";
import { makeBookingData } from "../algo/madeArrayData";
import { downloadBookingCSV } from "../algo/DownloadCSV";
import { generateUsersPDF } from "./usersPDF";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
} from "../../store/actions/providerActions";
import SavedPopup from "../Reusable Popup/SavedPopup";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

var Data_Format = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  phone_number: null,
  is_active: null,
  is_user_blocked: null,
  user_type: null,
  is_approved: null,
  is_verified: null,
  parking: [],
};

const RESULT_LIMIT_IN_PAGE = 6;

const DATA_PROCESSOR = (data, setState) => {
  let temp = [];

  for (const spot in data) {
    var obj = {};
    console.log(data[spot]);
    for (const key in Data_Format) {
      if (key in data[spot]) {
        obj = { ...obj, [key]: data[spot][key] };
      } else {
        obj = { ...obj, [key]: null };
      }
    }
    temp.push(obj);
  }

  setState(temp);
};

function Users() {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [pause, setPause] = useState(false);
  const [pauseStatus, setPauseStatus] = useState(null);
  const [approve, setApprove] = useState(false);
  const [statusloading, setStatusLoading] = useState(false);
  const [block, setBlock] = useState(false);

  const [CreateProvider, setCreateProvider] = useState(false);
  const CreateProviderToggle = () => {
    if (CreateProvider) {
      setCreateProvider(false);
    } else {
      setCreateProvider(true);
    }
  };

  const [editPricePopup, setEditPricePopup] = useState({
    popup: false,
    spotId: "",
  });

  const toggleEditPricePopup = () => {
    if (editPricePopup.popup) {
      setEditPricePopup({
        ...editPricePopup,
        popup: false,
      });
    } else {
      setEditPricePopup({
        ...editPricePopup,
        popup: true,
      });
    }
  };

  // const [token, setToken] = useState(localStorage.getItem("__admin__token&") || sessionStorage.getItem("__admin__token&"))

  const [selectedOption, setSelectedOption] = useState("");
  console.log(selectedOption, "selectedOption");
  const [providertype, setProviderType] = useState("Business");
  const [search, setSearch] = useState("");

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
  };

  const selectProviderType = (name) => {
    setProviderType(name);
  };

  const [state, setState] = useState([]);
  const [page, setPage] = useState(1);

  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });
  const callUserSearch = async () => {
    setLoading(true);
    setColor("#5c5b5b");
    setState([]);

    let formData = new FormData();

    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("page", page);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("Filter_by", selectedOption);
    formData.append("provider_type", providertype);

    await axiosInstance
      .post("admin_dash/search_provider_api", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.response.result, "aiua");
        setState(response.data.response.result);
        // DATA_PROCESSOR(response.data.response.result, setState)
        setPagination({
          count: response.data.pagination.count,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("selectedOptasASion", selectedOption);
    callUserSearch();
  }, [selectedOption, page, providertype]);

  const callProviderSearch = async () => {
    setLoading(true);
    setColor("#5c5b5b");
    setState([]);

    let formData = new FormData();

    formData.append("search", search);
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("page", page);
    formData.append("order_by", "");
    formData.append("order_by_type", "");
    formData.append("Filter_by", selectedOption);
    formData.append("provider_type", providertype);

    if (providertype === "Business" || providertype === "Individuals") {
      console.log(providertype, "providertypeghjk");
      await axiosInstance
        .post("admin_dash/search_provider_api", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          setState(response.data.response.data);
          // DATA_PROCESSOR(response.data.response.result, setState)
          setPagination({
            count: response.data.pagination.count,
            previous: response.data.pagination.previous,
            next: response.data.pagination.next,
            startIndex: response.data.pagination.startIndex,
            endIndex: response.data.pagination.endIndex,
            totalResults: response.data.pagination.totalResults,
            noOfPages: response.data.pagination.noOfPages,
          });

          setLoading(false);
        })
        .catch((err) => {
          console.log("got an error", err);
          setLoading(false);
        });
    } else {
      let formData = new FormData();

      formData.append("search", search);
      formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
      formData.append("page", page);
      formData.append("order_by", "");
      formData.append("order_by_type", "");
      formData.append("Filter_by", selectedOption);

      await axiosInstance
        .post("admin_dash/search_consumer_api", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response.data.response.result, "asjibcuasf");
          setState(response.data.response.result);
          // DATA_PROCESSOR(response.data.response.result, setState)
          setPagination({
            count: response.data.pagination.count,
            previous: response.data.pagination.previous,
            next: response.data.pagination.next,
            startIndex: response.data.pagination.startIndex,
            endIndex: response.data.pagination.endIndex,
            totalResults: response.data.pagination.totalResults,
            noOfPages: response.data.pagination.noOfPages,
          });

          setLoading(false);
        })
        .catch((err) => {
          console.log("user got an error", err);
          setLoading(false);
        });
    }
  };

  const [exportInfo, setExport] = useState("");

  const download = (e) => {
    const value = e.target.value;
    setExport(e.target.value);
    const data = makeBookingData(state);
    // var bookingData=data.map(({data, ...keepAttrs}) => keepAttrs);
    console.log("download", data, state);
    if (value === "exportAsExcel") {
      DownloadExcel(data, "users.xlsx");
    }
    if (value === "exportAsCSV") {
      downloadBookingCSV(data, "users.csv");
    }
    if (value === "exportAsPDF") {
      generateUsersPDF(data, providertype);
    }
    setExport("");
  };

  const dispatch = useDispatch();
  const { data, loadingg, error } = useSelector((state) => state.provider);

  const fetchData = async (id) => {
    dispatch(fetchDataRequest());
    try {
      const response = await axiosInstance.get(`admin_dash/get_provider-${id}`);
      console.log("responseppp", response);
      const result = response;
      localStorage?.setItem("providerId", result?.data?.response?.provider_data?.id);
      dispatch(fetchDataSuccess(result));
    } catch (error) {
      dispatch(fetchDataFailure(error.toString()));
    }
  };

  const pauseToggle = () => {
    if (pause) {
      setPause(false);
    } else {
      setPause(true);
    }
  };

  const approveToggle = () => {
    if (approve) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  };

  const blockToggle = () => {
    if (block) {
      setBlock(false);
    } else {
      setBlock(true);
    }
  };

  const approveUser = async (e) => {
    e.preventDefault();
    setStatusLoading(true);

    const element = e.target;
    const userId = element.getAttribute("data-id");
    console.log(userId, "uguiy");
    const userStatus = element.getAttribute("data-status");

    setPauseStatus(userStatus);

    const formData = new FormData();
    formData.append("id", userId);

    try {
      const response = await axiosInstance.post(
        "admin_dash/approved_verified_provider",
        formData
      );
      console.log(response.data);
      approveToggle();
      callUserSearch();
    } catch (err) {
      console.log("got an error", err);
    } finally {
      setStatusLoading(false);
    }
  };

  //block user
  const blockUser = async (e) => {
    e.preventDefault();
    setStatusLoading(true);

    const element = e.target;
    const userId = element.getAttribute("data-id");
    const userStatus = element.getAttribute("data-status");

    setPauseStatus(userStatus);

    const formData = new FormData();
    formData.append("id", userId);

    try {
      const response = await axiosInstance.post(
        "admin_dash/block_provider",
        formData
      );
      console.log(response.data);
      blockToggle();
      callUserSearch();
    } catch (err) {
      console.log("got an error", err);
    } finally {
      setStatusLoading(false);
    }
  };

  return (
    <>
      <div
        className={_([
          "d-flex flex-column align-items-center",
          cssx.container,
          css.shade_background,
        ])}
      >
        <div
          className={_([
            "d-flex flex-column align-items-center",
            css2.center_container,
          ])}
        >
          <p className={_(["align-self-start", css.font1, css.font_32])}>
            Users
          </p>

          <div
            className={_([
              "d-flex flex-column align-items-center",
              css2.center_container,
              css2.parking_search,
            ])}
          >
            <button
              className={_([
                " align-self-end",
                css.font_18,
                css.inside_button1,
                css2.add_new_button,
              ])}
              onClick={CreateProviderToggle}
            >
              Add New Provider
            </button>

            <div className={_([" w-100", css2.search_row])}>
              <div>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "" ? css2.selected_search_button : "",
                  ])}
                  name=""
                  onClick={toggleButtonSearch}
                >
                  All
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "active"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="active"
                  onClick={toggleButtonSearch}
                >
                  Active
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "Registered"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="Registered"
                  onClick={toggleButtonSearch}
                >
                  Registered
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "Under_Review"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="Under_Review"
                  onClick={toggleButtonSearch}
                >
                  Under Review
                </button>
                <button
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === "Blocked"
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name="Blocked"
                  onClick={toggleButtonSearch}
                >
                  Blocked
                </button>
              </div>
              <div className={_(["d-flex justify-content-end"])}>
                <div
                  className={_(["", css.font_18, css.font2, css2.search_bar])}
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    style={{ height: "15px" }}
                  ></img>

                  <input
                    className={_(["ml-2 w-100", css.font_18, css.font2])}
                    type="input"
                    placeholder="Search here"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        callUserSearch();
                      }
                    }}
                    style={{ border: "0px", background: "none" }}
                  ></input>
                </div>
              </div>
            </div>
            <div
              className={_([
                "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
                css2.search_row_2,
              ])}
            >
              {selectedOption === "" ? (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      All
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Users
                    </label>
                  </div>

                  <div
                    className={_([
                      "flex-grow-1 d-flex flex-wrap justify-content-end",
                    ])}
                  >
                    <select
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                      value={providertype}
                      onChange={(e) => selectProviderType(e.target.value)}
                    >
                      <option defaultValue value="Business">
                        Business
                      </option>
                      <option value="Users">Users</option>
                      <option value="Individuals">Individuals</option>
                    </select>
                    <select
                      placeholder="Export As PDF"
                      value={exportInfo}
                      onChange={(e) => {
                        setExport(e.target.value);
                        download(e);
                      }}
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                    >
                      <option value="" disabled defaultValue>
                        Select Export
                      </option>
                      <option value="exportAsPDF">Export As PDF</option>
                      <option value="exportAsCSV">Export As CSV</option>
                      <option value="exportAsExcel">Export As Excel</option>
                    </select>
                    <button
                      className={_([
                        " pl-2 pr-2 d-flex align-items-center",
                        css.font_18,
                        css.inside_button4,
                      ])}
                    >
                      <img
                        src={deleteIcon}
                        alt="..."
                        className={_(["mr-2"])}
                      ></img>
                      Delete
                    </button>
                  </div>
                </>
              ) : null}
              {selectedOption === "active" ? (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      Active
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Users
                    </label>
                  </div>
                  <div
                    className={_([
                      "flex-grow-1 d-flex flex-wrap justify-content-end",
                    ])}
                  >
                    <select
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                      value={providertype}
                      onChange={(e) => selectProviderType(e.target.value)}
                    >
                      <option defaultValue value="Business">
                        Business
                      </option>
                      <option value="Users">Users</option>
                      <option value="Individuals">Individuals</option>
                    </select>
                    <select
                      placeholder="Export As PDF"
                      value={exportInfo}
                      onChange={(e) => {
                        setExport(e.target.value);
                        download(e);
                      }}
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                    >
                      <option value="" selected disabled>
                        Select Export
                      </option>
                      <option value="exportAsPDF">Export As PDF</option>
                      <option value="exportAsCSV">Export As CSV</option>
                      <option value="exportAsExcel">Export As Excel</option>
                    </select>
                    <button
                      className={_([
                        " pl-2 pr-2 d-flex align-items-center",
                        css.font_18,
                        css.inside_button4,
                      ])}
                    >
                      <img
                        src={deleteIcon}
                        alt="..."
                        className={_(["mr-2"])}
                      ></img>
                      Delete
                    </button>
                  </div>
                </>
              ) : null}
              {selectedOption === "Registered" ? (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      Registered
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Users
                    </label>
                  </div>
                  <div
                    className={_([
                      "flex-grow-1 d-flex flex-wrap justify-content-end",
                    ])}
                  >
                    <select
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                      value={providertype}
                      onChange={(e) => selectProviderType(e.target.value)}
                    >
                      <option defaultValue value="Business">
                        Business
                      </option>
                      <option value="Users">Users</option>
                      <option value="Individuals">Individuals</option>
                    </select>
                    <select
                      placeholder="Export As PDF"
                      value={exportInfo}
                      onChange={(e) => {
                        setExport(e.target.value);
                        download(e);
                      }}
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                    >
                      <option value="" selected disabled>
                        Select Export
                      </option>
                      <option value="exportAsPDF">Export As PDF</option>
                      <option value="exportAsCSV">Export As CSV</option>
                      <option value="exportAsExcel">Export As Excel</option>
                    </select>
                    <button
                      className={_([
                        " pl-2 pr-2 d-flex align-items-center",
                        css.font_18,
                        css.inside_button4,
                      ])}
                    >
                      <img
                        src={deleteIcon}
                        alt="..."
                        className={_(["mr-2"])}
                      ></img>
                      Delete
                    </button>
                  </div>
                </>
              ) : null}
              {selectedOption === "Under_Review" ? (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      Under Review
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Users
                    </label>
                  </div>
                  <div
                    className={_([
                      "flex-grow-1 d-flex flex-wrap justify-content-end",
                    ])}
                  >
                    <select
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                      value={providertype}
                      onChange={(e) => selectProviderType(e.target.value)}
                    >
                      <option defaultValue value="Business">
                        Business
                      </option>
                      <option value="Users">Users</option>
                      <option value="Individuals">Individuals</option>
                    </select>
                    <select
                      placeholder="Export As PDF"
                      value={exportInfo}
                      onChange={(e) => {
                        setExport(e.target.value);
                        download(e);
                      }}
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                    >
                      <option value="" selected disabled>
                        Select Export
                      </option>
                      <option value="exportAsPDF">Export As PDF</option>
                      <option value="exportAsCSV">Export As CSV</option>
                      <option value="exportAsExcel">Export As Excel</option>
                    </select>
                    <button
                      className={_([
                        " pl-2 pr-2 d-flex align-items-center",
                        css.font_18,
                        css.inside_button4,
                      ])}
                    >
                      <img
                        src={deleteIcon}
                        alt="..."
                        className={_(["mr-2"])}
                      ></img>
                      Delete
                    </button>
                  </div>
                </>
              ) : null}
              {selectedOption === "Blocked" ? (
                <>
                  <div className={_([""])}>
                    <label className={_(["mr-3", css.font_24, css.font1])}>
                      Blocked
                    </label>
                    <label className={_(["", css.font_18, css.font2])}>
                      {state.length} Users
                    </label>
                  </div>
                  <div
                    className={_([
                      "flex-grow-1 d-flex flex-wrap justify-content-end",
                    ])}
                  >
                    <select
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                      value={providertype}
                      onChange={(e) => selectProviderType(e.target.value)}
                    >
                      <option defaultValue value="Business">
                        Business
                      </option>
                      <option value="Users">Users</option>
                      <option value="Individuals">Individuals</option>
                    </select>
                    <select
                      placeholder="Export As PDF"
                      value={exportInfo}
                      onChange={(e) => {
                        setExport(e.target.value);
                        download(e);
                      }}
                      className={_([
                        "mr-3",
                        css.font_18,
                        css.font1,
                        css2.select_box_style,
                      ])}
                    >
                      <option value="" selected disabled>
                        Select Export
                      </option>
                      <option value="exportAsPDF">Export As PDF</option>
                      <option value="exportAsCSV">Export As CSV</option>
                      <option value="exportAsExcel">Export As Excel</option>
                    </select>
                    <button
                      className={_([
                        " pl-2 pr-2 d-flex align-items-center",
                        css.font_18,
                        css.inside_button4,
                      ])}
                    >
                      <img
                        src={deleteIcon}
                        alt="..."
                        className={_(["mr-2"])}
                      ></img>
                      Delete
                    </button>
                  </div>
                </>
              ) : null}
            </div>

            <div className={_(["flex-grow-1", css2.table])}>
              <table
                className={_([
                  "mt-2 w-100",
                  css.font_18,
                  css.font2,
                  css2.search_list,
                ])}
              >
                <tr className={_(["", css2.search_header])}>
                  <td>#</td>
                  <td>Name</td>
                  <td>Email</td>
                  <td>Phone Number</td>
                  <td>
                    <div
                      className={_([
                        "d-flex flex-row justify-content-center align-items-center",
                      ])}
                    >
                      <div
                        className={_([
                          " d-flex flex-column justify-content-center align-items-center",
                        ])}
                      >
                        <label>Number of</label>
                        <label>Parking</label>
                      </div>

                      <img
                        alt=""
                        src={arrowUpDown}
                        className={_(["ml-1"])}
                        onClick={toggleEditPricePopup}
                      ></img>
                    </div>
                  </td>
                  <td>
                    <div
                      className={_([
                        "d-flex flex-row justify-content-center align-items-center",
                      ])}
                    >
                      <div
                        className={_([
                          " d-flex flex-column justify-content-center align-items-center",
                        ])}
                      >
                        <label>Number of</label>
                        <label>Booking</label>
                      </div>

                      <img
                        alt=""
                        src={arrowUpDown}
                        className={_(["ml-1"])}
                        onClick={toggleEditPricePopup}
                      ></img>
                    </div>
                  </td>
                  <td>Status</td>
                  <td>Actions</td>
                </tr>
                {state.map((spot, index) => {
                  const url = `/Dashboard/ProviderDetail/Profile?id=${spot?.id}`;
                  console.log(spot?.id, "spot1234");
                  const continuousIndex =
                    index + 1 + (page - 1) * pagination.count;
                  return (
                    <tr className={_([""])}>
                      <td>
                        <div className="d-flex align-items-center p-1">
                          <label
                            className={_(["", css.checkbox_group])}
                            htmlFor={"check" + index}
                          >
                            <input
                              type="checkbox"
                              className={_(["mr-2", css.checkbox_size])}
                              id={"check" + index}
                            ></input>
                            <span className={_(["mr-1", css.box])}></span>
                          </label>
                          <div className="index d-flex align-items-center mt-3">
                            <p>{continuousIndex}</p>
                          </div>
                        </div>
                      </td>
                      <td
                        className={_([
                          "d-flex flex-row justify-content-start align-items-center",
                        ])}
                      >
                        <img
                          src={
                            spot?.profile_pic
                              ? spot?.profile_pic
                              : defaultSPotImg
                          }
                          className={_(["", css2.search_spot_img])}
                        ></img>
                        <div className={_(["ml-2 row"])}>
                          <label className={_(["row", css.font4])}>
                            {spot.first_name} {spot.last_name}
                          </label>
                          <label className={_(["row text-start", css.font_14])}>
                            {spot.user_type == "Business" ||
                            spot.user_type == "Individuals" ? (
                              <p>Provider</p>
                            ) : (
                              <p>Users</p>
                            )}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div
                          className={_([
                            " d-flex flex-column justify-content-center align-items-center",
                          ])}
                        >
                          <label className={_(["row", css.font4])}>
                            {spot.email ? spot.email : "-"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div
                          className={_([
                            " d-flex flex-column justify-content-center align-items-center",
                          ])}
                        >
                          <label className={_(["row", css.font4])}>
                            {spot.phone_number ? spot.phone_number : "-"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div
                          className={_([
                            " d-flex flex-column justify-content-center align-items-center",
                          ])}
                        >
                          <label className={_(["row", css.font4])}>
                            {spot?.parking?.length
                              ? spot?.parking?.length
                              : "-"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div
                          className={_([
                            " d-flex flex-column justify-content-center align-items-center",
                          ])}
                        >
                          <label className={_(["row", css.font4])}>
                            <p>{spot?.no_of_bookings || "0"}</p>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div
                          className={_([
                            " d-flex flex-column justify-content-center align-items-center",
                          ])}
                        >
                          <label className={_(["row", css.font4])}>
                            {/* {spot.is_active ? (
                              <p className="badge mb-0">Active</p>
                            ) : spot?.is_user_blocked ? (
                              <p className="badge2 mb-0">Blocked</p>
                            ) : (
                              <p className="badge2 mb-0">Deactivated</p>
                            )} */}

                            {/* {spot.is_active ? (
                              <p className="badge mb-0">Active</p>
                            ) : 
                            spot?.is_user_blocked ? (
                              <p className="badge2 mb-0">Blocked</p>
                            ) : 
                            (!spot.is_active && spot.is_verified) ? (
                              <p className="badge-red mb-0">Pending</p>
                            ) : (
                              <p className="badge-blue mb-0">Under Review</p>
                            )} */}

                            <>
                              {selectedOption === "Registered" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot?.is_verified &&
                                    !spot?.is_approved &&
                                    spot?.is_active ? (
                                      <p className="badge-red mb-0">Pending</p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "Under_Review" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot?.is_active && !spot?.is_approved ? (
                                      <p className="badge-blue mb-0">Review</p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "Blocked" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot?.is_provider_blocked ||
                                    spot?.is_user_blocked ? (
                                      <p className="badge2 mb-0">Blocked</p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "active" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot?.is_active ? (
                                      <p className="badge mb-0">Active</p>
                                    ) : (
                                      "-"
                                    )}
                                  </label>
                                </div>
                              ) : null}
                              {selectedOption === "" ? (
                                <div className={_([""])}>
                                  <label className={_(["", css.font4])}>
                                    {spot.is_active ? (
                                      <p className="badge mb-0">Active</p>
                                    ) : spot.is_user_blocked ? (
                                      <p className="badge2 mb-0">Blocked</p>
                                    ) : !spot.is_verified && spot.is_active ? (
                                      <p className="badge-red mb-0">Pending</p>
                                    ) : (
                                      <p className="badge-red mb-0">Pause</p>
                                    )}
                                  </label>
                                </div>
                              ) : null}
                            </>
                          </label>
                        </div>
                      </td>
                      <td>
                        {selectedOption === "" ? (
                          <div>
                            <div className="dropdown">
                              <p
                                className="mb-0 dropdown-toggle"
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src={actionIcon}></img>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link
                                  className="dropdown-item"
                                  to={url}
                                  onClick={() => {
                                    fetchData(spot?.id);
                                  }}
                                >
                                  Edit
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Pause
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Share
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "active" ? (
                          <div>
                            <div className="dropdown">
                              <p
                                className="mb-0 dropdown-toggle"
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src={actionIcon}></img>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/Dashboard/ProviderDetail/Profile"
                                  onClick={() => fetchData(spot?.id)}
                                >
                                  Edit
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Delete
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={blockUser}
                                  data-id={spot?.id}
                                >
                                  Block
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Reset Password
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "Registered" ? (
                          <div>
                            <div className="dropdown">
                              <p
                                className="mb-0 dropdown-toggle"
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src={actionIcon}></img>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/Dashboard/ProviderDetail/Profile"
                                  onClick={() => fetchData(spot?.id)}
                                >
                                  Edit
                                </Link>
                                {/* <Link className="dropdown-item" to="#">
                                  Approve
                                </Link> */}
                                <Link className="dropdown-item" to="#">
                                  Delete
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Send Reminder
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={blockUser}
                                  data-id={spot?.id}
                                >
                                  Block
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "Under_Review" ? (
                          <div>
                            <div className="dropdown">
                              <p
                                className="mb-0 dropdown-toggle"
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src={actionIcon}></img>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/Dashboard/ProviderDetail/Profile"
                                  onClick={() => fetchData(spot?.id)}
                                >
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={approveUser}
                                  data-id={spot?.id}
                                >
                                  Approve
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Delete
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={blockUser}
                                  data-id={spot?.id}
                                >
                                  Block
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {selectedOption === "Blocked" ? (
                          <div>
                            <div className="dropdown">
                              <p
                                className="mb-0 dropdown-toggle"
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src={actionIcon}></img>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/Dashboard/ProviderDetail/Profile"
                                  onClick={() => fetchData(spot?.id)}
                                >
                                  Edit
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Delete
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={blockUser}
                                  data-id={spot?.id}
                                >
                                  Unblock
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </table>
              {loading ? (
                <div className="col-md-12 d-flex justify-content-center align-items-center">
                  <Loader color={color} loading={loading} />
                </div>
              ) : state.length === 0 ? (
                <>
                  <div className="mt-5 w-100 d-flex flex-column align-items-center">
                    <img src={noParkings} alt="No Parkings"></img>
                    <span className={_(["mt-4", css.font2, css.font_18])}>
                      No Users available right now. :(
                    </span>
                  </div>
                </>
              ) : null}
            </div>
            <div className={_(["", css.font_18, css.font2, css2.pagination])}>
              {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
                <PaginationComponent
                  count={pagination.noOfPages}
                  page={page}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
        <UpdatePrice
          isOpen={editPricePopup.popup}
          toggle={toggleEditPricePopup}
        ></UpdatePrice>
        <CreateProviderPopup
          isOpen={CreateProvider}
          toggle={CreateProviderToggle}
        />

        <SavedPopup
          isOpen={pause}
          heading={`User ${pauseStatus === "Pause" ? "Unpaused" : "Paused"}`}
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={pauseToggle}
        />
        <SavedPopup
          isOpen={approve}
          heading="User Approved"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={approveToggle}
        />
        <SavedPopup
          isOpen={block}
          heading="User Blocked"
          subheading="Updated Successfully"
          buttontitle="Okay"
          toggle={blockToggle}
        />
      </div>
    </>
  );
}

export default Users;
