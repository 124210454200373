import css2 from '../../common.module.css'
import css from '../../../main.module.css'

import css3 from "../../Profile/ProfilePage.module.css"
import React, { useState, useContext } from "react";
import { Redirect, useNavigate, Link } from "react-router-dom";
import validator from 'validator';
import { Modal, ModalBody } from "reactstrap";
import emailIcon from "../../../assets/Icons/Icon material-email.svg"
import phoneIcon from "../../../assets/Icons/Icon awesome-phone-alt.svg"
import webIcon from "../../../assets/Icons/Group 155.svg"
import pointerIcon from "../../../assets/Icons/Icon material-location-on.svg"
import timezoneIcon from "../../../assets/Icons/Mask Group 145.svg"
import "../../../styles.css"
import '../popup.css'

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
};

function AdminEditPopup(props) {

    const history = useNavigate();

    return (
        <>
            <Modal isOpen={props.isOpen} className={_(["d-flex flex-column align-items-center mt-5 h-100", css2.container])} backdrop={true} toggle={props.toggle}
            >
                <div className={_([" d-flex flex-column justify-content-center align-items-center p-4", ])}>
                    <div className="container">
                        <div className="row">
                            <div className={_(["bg-white", css3.account_details3, css.font2, css.font_18])}>
                                <div className={_([" d-flex justify-content-center align-items-center", ])}>
                                    <img className={_(["", css3.profile_img])}></img>
                                </div>
                                <div className={_(["", css3.account_inside_details])}>
                                    <div>
                                        <label style={{ color: "black" }}><b>John Doe</b></label>
                                    </div>
                                    <div>
                                        <img src={emailIcon} alt="loading..." className="mr-2"></img>
                                        <label>dassaysoy@gmail.com</label>
                                    </div>
                                    <div>
                                        <img src={phoneIcon} alt="loading..." className="mr-2"></img>
                                        <label>+1 123131313</label>
                                    </div>
                                    <div>
                                        <img src={webIcon} alt="loading..." className="mr-2"></img>
                                        <label>www.url.com</label>
                                    </div>
                                    <div>
                                        <img src={pointerIcon} alt="loading..." className="mr-2"></img>
                                        <label>123 Street, NJ 07307, United States</label>
                                    </div>
                                    <div>
                                        <img src={timezoneIcon} alt="loading..." className="mr-2"></img>
                                        <label>timezone</label>
                                    </div>

                                    <div className="mt-3">
                                        <Link to="#">Change Password</Link>
                                    </div>
                                    <div className="">
                                        <Link to="#">Logout</Link>
                                    </div>
                                </div>
                                <div className=" ml-3">
                                    <Link to="/dashboard/profile/myaccount/edit">Edit</Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </Modal>

        </>
    );
}

export default AdminEditPopup;