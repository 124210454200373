import css from "./../../main.module.css";
import css2 from "./AddParkingSpot.module.css";
import React, { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import alertIcon from "../../assets/parking-bud-provider-assets/add-parking-aspace/alert-icon.svg";
import axiosInstance from "../../axios";
import GoogleMaps from "./GoogleMap";
import debounce from "lodash.debounce";
import axios from "axios";
import Loader from "react-spinners/ClipLoader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function ParkingSpotAddress(props) {
  // const [location, setLocation] = useState(null)
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({ lat: -33.9, lng: 151.2 });
  const [options, setOptions] = useState([]);
  const [state, setState] = useState({
    country: "",
    state: "",
    parking_spot_address1: "",
    parking_spot_address2: "",
    city: "",
    zipCode: "",
    timezone: "",
    loc_lat: null,
    loc_lng: null,
  });
console.log("location",location)
  const ParkingId = localStorage.getItem("parkingId");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    setErrors((prevValues) => ({
      ...prevValues,
      [name]: "",
    }));
  };

  const timeZoneData = ["HST", "AKST", "PST", "MST", "CST", "EST", "SST"];

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    const formdata = new FormData();
    formdata.append("parking_spot_address1", state?.parking_spot_address1);
    formdata.append("parking_spot_address2", state?.parking_spot_address2);
    formdata.append("city", state?.city);
    formdata.append("state", state?.state);
    formdata.append("zipCode", state?.zipCode);
    formdata.append("country", state?.country);
    formdata.append("timezone", state?.timezone);

    formdata.append("loc_lat", state?.loc_lat);
    formdata.append("loc_lng", state?.loc_lng);

    e.preventDefault();
    const newErrors = {};

    if (!state.parking_spot_address1) {
      newErrors.parking_spot_address1 = "Please enter Address Line 1";
    } else if (!state.city) {
      newErrors.city = "Please enter City";
    } else if (!state.state) {
      newErrors.state = "Please Select State";
    } else if (!state.zipCode) {
      newErrors.zipCode = "Please enter ZipCode";
    } else if (!state.country) {
      newErrors.country = "Please Select Country";
    } else if (!state.timezone) {
      newErrors.timezone = "Please Select Time Zone";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `admin_dash/draft_add_parking_spot_2-${ParkingId}`,
          formdata,
          { headers: { "content-type": "multipart/form-data" } }
        );
        console.log("response123", response);
        if (response?.status === 202) {
          console.log("response.success", response.success);
          setLoading(false);
          history("/Dashboard/ParkingSpots/AddParkingSpot/OtherDetails", {
            replace: true,
          });
        } else {
          setLoading(false);
          console.error("Failed to add parking spot");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  /*
    let [autocomplete, setAutocomplete] = useState(null);
    let [address1Field, setAddress1Field] = useState(null);
    let [address2Field, setAddress2Field] = useState(null);
    let [postalField, setPostalField] = useState(null);

    
    function initAutocomplete() {

        address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener("place_changed", fillInAddress);
    }
    
    function fillInAddress() {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        let address1 = "";
        let postcode = "";
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
        const componentType = component.types[0];
    
        switch (componentType) {
            case "street_number": {
            state.parking_spot_address1 = `${component.long_name} ${address1}`;
            break;
            }
    
            case "route": {
            state.parking_spot_address2 = `${component.short_name}`;
            break;
            }
    
            case "postal_code": {
            state.zipCode = `${component.long_name}${postcode}`;
            break;
            }
    
            case "postal_code_suffix": {
            state.zipCode = `${state.zipCode}-${component.long_name}`;
            break;
            }
            case "locality":
            state.city = `${component.long_name}`;
            break;
    
            case "administrative_area_level_1": {
            state.state = `${component.short_name}`;
            break;
            }
            case "country":
            state.country = `${component.long_name}`;
            break;
        }
        }
        // After filling the form with address components from the Autocomplete
        // prediction, set cursor focus on the second address line to encourage
        // entry of subpremise information such as apartment, unit, or floor number.
        address2Field.focus();
    }
    */

  const history = useNavigate();

  useEffect(() => {
    if (state.parking_spot_address1) {
      axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: `${state.parking_spot_address1}+${state.parking_spot_address2}+${state.city},+${state.state}+${state.country},+${state.zipCode}`,
            key: "AIzaSyD63MgQGF6XKHDeImM8PcuwWVCghDdkK8c",
          },
        })
        .then((result) => {
          const location = result.data.results[0].geometry.location;
          console.log("locationdd",location ,result.data )
          setLocation(location);
          setState((prevState) => ({
            ...prevState,
            loc_lat: location.lat,
            loc_lng: location.lng,
          }));
        })
        .catch((err) => {
          console.error("Geocoding error:", err);
        });
    }
  }, [
    state.parking_spot_address1,
    state.parking_spot_address2,
    state.city,
    state.state,
    state.country,
    state.zipCode,
  ]);

console.log("statsde",state)
  // const fetchSuggestions = async (inputValue) => {
  //     if (inputValue.length > 2) {
  //         try {
  //             const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
  //                 params: {
  //                     address: inputValue,
  //                     key: "AIzaSyD63MgQGF6XKHDeImM8PcuwWVCghDdkK8c"
  //                 }
  //             });

  //             const suggestions = response.data.results.map(result => ({
  //                 label: result.formatted_address,
  //                 value: result.formatted_address
  //             }));

  //             setOptions(suggestions);
  //         } catch (error) {
  //             console.error('Error fetching suggestions:', error);
  //         }
  //     }
  // };

  const fetchSuggestions = debounce(async (inputValue) => {
    if (inputValue.length === 0) {
      setOptions([]);
      return;
    }

    if (inputValue.length > 0) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input: inputValue, types: ["geocode"] },
        (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            console.error("Error fetching suggestions:", status);
            return;
          }
          const suggestions = predictions.map((prediction) => ({
            label: prediction.description,
            placeId: prediction.place_id,
          }));
          setOptions(suggestions);
        }
      );
    }
  }, 300);; // Debounce with 300ms delay

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    fetchSuggestions(newValue);
    handleChange({ target: { name: "parking_spot_address1", value: newValue } });
  };

  const handleSelectSuggestion = async (suggestion) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId: suggestion.placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const fullAddress = place.formatted_address;
        const addressParts = fullAddress.split(",");
        const newAddressLine1 = addressParts
          .slice(0, addressParts.length - 2)
          .join(", ")
          .trim();
        const newAddressLine2 = addressParts
          .slice(addressParts.length - 2)
          .join(", ")
          .trim();

        const newState = {
          parking_spot_address1: newAddressLine1,
          parking_spot_address2: newAddressLine2,
          city: "",
          state: "",
          zipCode: "",
          country: "",
          timezone: state.timezone,
          loc_lat: place.geometry.location.lat(),
          loc_lng: place.geometry.location.lng(),
        };

        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "locality":
              newState.city = component.long_name;
              break;
            case "administrative_area_level_1":
              newState.state = component.short_name;
              break;
            case "country":
              newState.country = component.long_name;
              break;
            case "postal_code":
              newState.zipCode = component.long_name;
              break;
            default:
              break;
          }
        }
        console.log("newState",newState)
        setState(newState);
        setLocation({ lat: newState.loc_lat, lng: newState.loc_lng });
        setOptions([]);
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  return (
    <>
      <div
        className={_([
          "d-flex flex-column justify-content-center align-items-center w-100",
          css.shade_background,
        ])}
      >
        <div
          className={_([
            "d-flex flex-column justify-content-center align-items-center",
            css2.basic_details_container,
            css2.center_container,
          ])}
        >
          <label
            className={_(["align-self-start mb-3", css.font1, css.font_24])}
          >
            Parking Spot Address
          </label>
          <div
            className={_(["w-100", css2.address_group, css2.align_item_fstart])}
          >
            <div className={_(["", css2.input_group])}>
              <div className={_(["", css2.input_row])}>
                <div className={_(["w-100", css.font_18])}>
                  <label className={_(["", css.font1])}>Address Line 1</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <input
                    type="input"
                    id="parking_spot_address1"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    placeholder="Search Places..."
                    value={state?.parking_spot_address1}
                    name="parking_spot_address1"
                    onChange={(e) => handleInputChange(e)}
                    autoComplete="off"
                    required
                  />
                  {options.length > 0 && (
                    <ul className="suggestions pl-0">
                      {options.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectSuggestion(option)}
                          className={_([
                            "mt-1",
                            css.cursor_pointer,
                            css2.bgclr,
                          ])}
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  )}
                  {errors?.parking_spot_address1 && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.parking_spot_address1}
                    </span>
                  )}
                </div>
              </div>
              <div className={_(["", css2.input_row])}>
                <div className={_(["w-100", css.font_18])}>
                  <label className={_([" ", css.font1])}>Address Line 2</label>
                  <input
                    type="input"
                    id="parking_spot_address2"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    placeholder="Address Line 2"
                    value={state?.parking_spot_address2}
                    name="parking_spot_address2"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>
              <div className={_(["", css2.input_row])}>
                <div className={_(["", css2.column_width2, css.font_18])}>
                  <label className={_([" ", css.font1])}>City</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <input
                    id="city"
                    type="input"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    placeholder="City"
                    value={state?.city}
                    name="city"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {errors?.city && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.city}
                    </span>
                  )}
                </div>

                <div className={_(["", css2.column_width2, css.font_18])}>
                  <label className={_([" ", css.font1])}>
                    State / Province
                  </label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <RegionDropdown
                    id="state"
                    name="businessState"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    blankOptionLabel="Select State"
                    country={state?.country}
                    value={state?.state}
                    onChange={(e) => {
                      setState({
                        ...state,
                        state: e,
                      });
                    }}
                  />
                  {errors?.state && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.state}
                    </span>
                  )}
                </div>
              </div>
              <div className={_(["", css2.input_row])}>
                <div className={_(["", css2.column_width2, css.font_18])}>
                  <label className={_([" ", css.font1])}>Zip Code</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <input
                    id="zipCode"
                    type="input"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    placeholder="Zip Code"
                    value={state?.zipCode}
                    name="zipCode"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {errors?.zipCode && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.zipCode}
                    </span>
                  )}
                </div>
                <div className={_(["", css2.column_width2, css.font_18])}>
                  <label className={_([" ", css.font1])}>Country</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <CountryDropdown
                    id="country"
                    name="businessCountry"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    value={state?.country}
                    onChange={(e) => {
                      setState({
                        ...state,
                        country: e,
                      });
                    }}
                  />
                  {errors?.country && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.country}
                    </span>
                  )}
                </div>
              </div>
              <div className={_(["", css2.input_row])}>
                <div className={_(["", css2.column_width2, css.font_18])}>
                  <label className={_([" ", css.font1])}>Time Zone</label>
                  <sup
                    style={{
                      color: "red",
                      fontSize: "17px",
                      top: "-3px",
                      marginLeft: "0.25rem",
                    }}
                  >
                    *
                  </sup>
                  <select
                    type="input"
                    className={_(["input_box", css.input_box_style, css.font2])}
                    placeholder="Time zone"
                    value={state?.timezone}
                    name="timezone"
                    onChange={(e) => handleChange(e)}
                    required
                  >
                    <option disabled value={""}>
                      Select
                    </option>
                    {timeZoneData?.map((type) => {
                      return <option value={type}>{type}</option>;
                    })}
                  </select>
                  {errors?.timezone && (
                    <span className={_(["", css2.error_style])}>
                      <img src={alertIcon} alt="alert img" />
                      {errors?.timezone}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className={_([" ", css2.map])}>
              <GoogleMaps
                height="100%"
                latLng={location}
                state={state.state}
                country={state.country}
                city={state.city}
              />
              {console.log(state.country, "statedscsdd")}
            </div>
          </div>

          <div
            className={_([
              "mt-3 d-flex flex-row justify-content-between align-items-center w-100 mb-3",
              css.font2,
            ])}
          >
            <button
              className={_(["", css.inside_button2, css2.button1])}
              onClick={() => {
                history("/Dashboard/ParkingSpots/AddParkingSpot/BasicDetails", {
                  replace: true,
                });
              }}
            >
              Back
            </button>
            <button
              className={_(["", css.inside_button1, css2.button1])}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
            {loading ?  
              <div className={_(["",css2.cursor_loader,css2.loader_div])}>Loading...</div>
          :
              'Save & Continue'
          }
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ParkingSpotAddress;
