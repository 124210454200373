import css from "./../../main.module.css";
import cssx from "./../../components/common.module.css";
import css2 from "./Bookings.module.css";

import timerIcon from "../../assets/Icons/Icon ionic-ios-timer.svg";
import React, { useState, useEffect } from "react";
import PaginationComponent from "../Pagination";
import searchIcon from "../../assets/Icons/Icon feather-search.svg";
import defaultSPotImg from "../../assets/Default_spot_img.svg";
import noParkings from "../../assets/Icons/Group 26816.svg";
import {
  DATETOWORDS,
  GETTIMEFROMDATE,
  TIMETOWORDS,
  TIMEDIFFERENCECALC,
} from "../../Tools";
//
import axiosInstance from "../../axios";
import Loader from "../Reusable Popup/Loader";
import { Link, useNavigate } from "react-router-dom";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

var Data_Format = {
  parking_name: null,
  status: null,
  checkin: null,
  checkout: null,
  extended_time: null,
  parking: {
    id: null,
    email: null,
    parking_spot_number: null,
    pic_1: null,
  },
};

const RESULT_LIMIT_IN_PAGE = 6;

const DATA_PROCESSOR = (data, setState) => {
  let temp = [];

  for (const spot in data) {
    var obj = {};
    console.log(data[spot]);
    for (const key in Data_Format) {
      if (key in data[spot]) {
        obj = { ...obj, [key]: data[spot][key] };
      } else {
        obj = { ...obj, [key]: null };
      }
    }
    temp.push(obj);
  }

  setState(temp);
};

function Bookings() {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const history = useNavigate();

  // search booking

  const [selectedOption, setSelectedOption] = useState("All");
  const [search, setSearch] = useState("");
  const [bookingType, seBookingType] = useState("");

  const [state, setState] = useState([]);
  console.log("stassste", state);
  const [page, setPage] = useState(1);

  const [pagination, setPagination] = useState({
    count: 0,
    previous: false,
    next: false,
    startIndex: 0,
    endIndex: 0,
    totalResults: 0,
    noOfPages: 0,
  });

  const optionLabels = {
    "": "All",
    Current: "Current",
    Upcoming: "Upcoming",
    Past: "Past",
    Cancelled: "Cancelled",
  };
  const options = ["All", "Current", "Upcoming", "Past", "Cancelled"];
  const label = optionLabels[selectedOption];

  const callSearch = async (bookingType = "All") => {
    setLoading(true);
    setColor("#5c5b5b");
    setState([]);

    let formData = new FormData();

    formData.append("search", search);
    formData.append("type", selectedOption === "All" ? "" : selectedOption);
    formData.append("filter", "");
    formData.append("result_limit", RESULT_LIMIT_IN_PAGE);
    formData.append("page", page);
    formData.append("order_by", "");
    formData.append(
      "order_by_type",
      bookingType === "All" ? "" : bookingType.toLocaleLowerCase()
    );

    await axiosInstance
      .post("admin_dash/admin_search_all_bookings", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        setState(response.data.response);
        setPagination({
          count: response.data.pagination.count,
          previous: response.data.pagination.previous,
          next: response.data.pagination.next,
          startIndex: response.data.pagination.startIndex,
          endIndex: response.data.pagination.endIndex,
          totalResults: response.data.pagination.totalResults,
          noOfPages: response.data.pagination.noOfPages,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log("got an error", err);
      });
  };
  console.log("selzsectedOption", selectedOption);
  useEffect(() => {
    callSearch();
  }, [selectedOption, page, search]);

  const toggleButtonSearch = (e) => {
    setSearch("");
    setSelectedOption(e.target.name);
    console.log(e.target.name, "e.target.name");
  };

  const handleBookingType = (type) => {
    console.log("typezd", type);
    callSearch(type);
    seBookingType(type);
  };

  return (
    <>
      <div
        className={_([
          "d-flex flex-column align-items-center",
          cssx.container,
          css.shade_background,
        ])}
      >
        <div
          className={_([
            "d-flex flex-column align-items-center",
            css2.center_container,
          ])}
        >
          <p className={_(["align-self-start", css.font1, css.font_32])}>
            Bookings
          </p>
        </div>
        <div
          className={_([
            "mt-1 d-flex flex-column align-items-center",
            css2.center_container,
            css2.booking_search,
          ])}
        >
          <div className={_(["mt-3 w-100", css2.search_row])}>
            <div>
              {options.map((option) => (
                <button
                  key={option}
                  className={_([
                    "mr-4",
                    css.font_18,
                    css2.search_buttons,
                    selectedOption === option
                      ? css2.selected_search_button
                      : "",
                  ])}
                  name={option}
                  onClick={toggleButtonSearch}
                >
                  {option}
                </button>
              ))}
            </div>
            <div className={_(["d-flex justify-content-end"])}>
              <div
                className={_(["", css.font_18, css.font2, css2.search_bar1])}
              >
                <img
                  src={searchIcon}
                  alt="search"
                  style={{ height: "15px" }}
                ></img>

                <input
                  className={_(["ml-2 w-100", css.font_18, css.font2])}
                  type="input"
                  name="search"
                  placeholder="Search by booking id, name or location"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      callSearch();
                    }
                  }}
                  style={{ border: "0px", background: "none" }}
                ></input>
              </div>
            </div>
          </div>
          <div
            className={_([
              "mt-1 w-100 d-flex flex-row justify-content-between align-items-center",
              css2.search_row_2,
            ])}
          >
            <div>
              <div className={_([""])}>
                <label className={_(["mr-3", css.font_24, css.font1])}>
                  {label}
                </label>
                <label className={_(["", css.font_18, css.font2])}>
                  {state.length} Bookings
                </label>
              </div>
              <div
                className={_([
                  "flex-grow-1 d-flex flex-wrap justify-content-end",
                ])}
              ></div>
            </div>
            <div
              className={_([
                "flex-grow-1 d-flex flex-wrap justify-content-end",
              ])}
            >
              <select
                className={_([
                  "mr-2",
                  css.font_18,
                  css.font1,
                  css2.select_box_style,
                ])}
                onChange={(e) => handleBookingType(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Hourly">Hourly</option>
                <option value="Monthly">Monthly</option>
              </select>
              <select
                className={_([
                  "mr-2",
                  css.font_18,
                  css.font1,
                  css2.select_box_style,
                ])}
              >
                <option value="last07Days">Last 07 Days</option>
                <option value="last30Days">Last 30 Days</option>
                <option value="last3months">Last 3 Months</option>
                <option value="last6months">Last 6 Months</option>
                <option value="last1year">Last 1 Year</option>
              </select>
              <input
                type="date"
                className={_([
                  "mr-2",
                  css.font_18,
                  css.font1,
                  css2.select_box_style,
                ])}
              ></input>
            </div>
          </div>
          <div className={_(["flex-grow-1 mt-3", css2.table])}>
            <table
              className={_([
                " w-100",
                css.font_18,
                css.font2,
                css2.search_list,
              ])}
            >
              <tr className={_(["", css2.search_header])}>
                <td>#</td>
                <td>Parking Name</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Arrival</td>
                <td>Checkout</td>
                <td>Total Time</td>
                <td>Extension</td>
                <td>Status</td>
              </tr>
              {state?.data?.map((spot, index) => {
                const continuousIndex =
                  index + 1 + (page - 1) * pagination.count;
                const bookingType = spot.booking_type;
                // Capitalize only the first letter
                const formattedBookingType =
                  bookingType.charAt(0).toUpperCase() +
                  bookingType.slice(1).toLowerCase();
                return (
                  <tr
                    className={_(["", css.cursor_pointer])}
                    onClick={() =>
                      history(`/Dashboard/ViewBooking?search=${spot?.id}`, {
                        replace: true,
                      })
                    }
                  >
                    <td>
                      <div className="d-flex align-items-center p-1">
                        <label
                          className={_(["", css.checkbox_group])}
                          htmlFor={"check" + index}
                        >
                          <input
                            type="checkbox"
                            className={_(["mr-2", css.checkbox_size])}
                            id={"check" + index}
                          ></input>
                          <span className={_(["mr-1", css.box])}></span>
                        </label>
                        <div className="index d-flex align-items-center mt-3">
                          <p>{continuousIndex}</p>
                        </div>
                      </div>
                    </td>

                    <td
                      className={_([
                        "d-flex flex-row justify-content-start align-items-center mt-2",
                      ])}
                    >
                      <img
                        alt=""
                        src={
                          spot?.parking_img ? spot?.parking_img : defaultSPotImg
                        }
                        className={_(["", css2.search_spot_img])}
                      ></img>
                      <div
                        className={_([
                          "ml-2 d-flex flex-column justify-content-start align-items-start ",
                        ])}
                      >
                        <label className={_(["row", css.font4])}>
                          {spot?.parking_name}
                        </label>
                        <label className={_(["row", css.font_14])}>
                          {!spot?.parking?.parking_spot_number
                            ? "Spot no. " + "-"
                            : "Spot no. " + spot?.parking?.parking_spot_number}
                        </label>
                      </div>
                    </td>

                    <td>
                      <div className={_(["row"])}>
                        <label className={_(["", css.font4])}>
                          {"$" + spot.amount}
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className={_(["row mt-3"])}>
                        <label className={_(["", css.font4])}>
                          <p>{formattedBookingType}</p>
                        </label>
                      </div>
                    </td>
                    <td>
                      <label>{DATETOWORDS(spot.checkin)}</label>
                      <br></br>
                      <label>{GETTIMEFROMDATE(spot.checkin)}</label>
                    </td>
                    <td>
                      <label>{DATETOWORDS(spot.checkout)}</label>
                      <br></br>
                      <label>{GETTIMEFROMDATE(spot.checkout)}</label>
                    </td>
                    <td>
                      <div className={_(["px-1 bg-blue-200 text-blue-800"])}>
                        <label>
                          {TIMEDIFFERENCECALC(spot.checkin, spot.checkout)}
                        </label>
                      </div>
                    </td>
                    <td>
                      <img alt="" src={timerIcon} className={_(["mr-2"])}></img>
                      <label> {spot.extended_time}m</label>
                    </td>
                    <td>
                      <div className={_([""])}>
                        <label className={_(["", css.font4])}>
                          {spot.status === "success" ? (
                            <p className="badge">
                              Active &<br />
                              Extension
                            </p>
                          ) : spot.status === "cancelled" ? (
                            <p className="badge2">Cancelled</p>
                          ) : (
                            <p className="badge2">Deactivated</p>
                          )}
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
            {loading ? (
              <div className=" mt-5 mb-5 d-flex justify-content-center align-items-center">
                <Loader color={color} loading={loading} />
              </div>
            ) : state?.data?.length === 0 ? (
              <>
                <div className="mt-5 w-100 d-flex flex-column align-items-center">
                  <img src={noParkings} alt="No Parkings"></img>
                  <span className={_(["mt-4", css.font2, css.font_18])}>
                    No parking spots available right now. :(
                  </span>
                </div>
              </>
            ) : null}
          </div>
          <div className={_(["", css.font_18, css.font2, css2.pagination])}>
            {pagination.totalResults > RESULT_LIMIT_IN_PAGE ? (
              <PaginationComponent
                count={pagination.noOfPages}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Bookings;
